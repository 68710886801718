import React, { useEffect, useState, memo } from "react";
import { Polyline } from '@pbe/react-yandex-maps';
import { decode } from "@googlemaps/polyline-codec";

import { useAppSelector } from '../../redux/hooks';
import { Coordinate } from "../../types/common.type";
import { TRACKER_LINE_STROKE_WIDTH } from "../../util/constants";


export default memo(function TrackLines() {
  const trackers = useAppSelector((state) => state.trackerReducer.trackers);
  const trackLineIds = useAppSelector((state) => state.trackerReducer.trackLineIds);
  const [trackLines, setTrackLines] = useState<Array<Array<Coordinate>>>();
  
  useEffect(() => {
    const trackersForTrackLines = trackers?.filter((tracker) => trackLineIds.includes(tracker.id));
    const encodePolylines = trackersForTrackLines?.map((tracker) => tracker.tracker_info?.tracker_polyline);
    const trackLines = encodePolylines?.map((item) => {
      if (item) {
        const coords = decode(item, 5) as Coordinate[];
        return coords;
      } else return [];
    });
    // console.log('trackLines', trackLines);
    
    setTrackLines(trackLines);
  }, [trackLineIds, trackers]);

  return (
    <>
      {trackLines?.map((trackLine, index) => {
        return (
          <Polyline
            key={index}
            geometry={trackLine}
            options={{
              zIndex: 2,
              strokeColor: "#8B5CF6",
              strokeWidth: TRACKER_LINE_STROKE_WIDTH,
              strokeStyle: 'solid',
            }}
          />
        );
      })}
    </>
  )
})

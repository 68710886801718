import React, { useState } from 'react';
import { Button, notification, Form, Space, Select } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

import { useGetFarmsQuery } from '../../redux/api/farm.api';
import { useGetDivisionsQuery } from '../../redux/api/divisions.api';
import { useGetBuildingsQuery, useLazyGetShortBuildingQuery } from '../../redux/api/building.api';
import { useCreateFeedingMutation } from '../../redux/api/feeding.api';
import { Farm } from '../../types/farm.type';
import { Division } from '../../types/division.type';
import { Building, ShortBuilding } from '../../types/building.type';
import { FeedingEventSide } from '../../types/feeding.type';
import { range } from '../../util/helpers';
import FeedingEventDatePicker from "./FeedingEventDatePicker";
import {
  DATE_FORMAT,
  MAX_DAY_COUNT,
  MAX_HOURS_COUNT,
  MAX_MIN_COUNT,
  MAX_MONTH_COUNT, MAX_SEC_COUNT, MIN_DAY_COUNT, MIN_TIME_COUNT
} from "../../util/constants";

const FeedingEventForm = () => {
  const [form] = Form.useForm();
  const farmIdValue = Form.useWatch('farm_id', form);
  const divisionIdValue = Form.useWatch('division_id', form);
  const buildingIdValue = Form.useWatch('building_id', form);
  const itemValue = Form.useWatch('item', form);
  const eventStartValue = Form.useWatch('event_start', form);
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery(null);
  const { data: divisionsData, isFetching: isDivisionsLoading } = useGetDivisionsQuery({ farm_id: farmIdValue, is_feeding: true });
  const { data: buildingsData, isFetching: isBuildingsLoading } = useGetBuildingsQuery(divisionIdValue ? { division_ids: [divisionIdValue] } : null);
  const [getShortBuilding, { isFetching: isShortBuildingLoading }] = useLazyGetShortBuildingQuery();
  const [createFeeding, { isLoading: isCreateFeedingLoading }] = useCreateFeedingMutation();
  const [filterItems, setFilterItems] =
    useState<Array<{ aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }>>([]);

  const handleChangeFarm = (value: number) => {
    form.setFieldsValue({ farm_id: value, division_id: null, building_id: null, item: null, side: null });
    setFilterItems([]);
  };

  const handleChangeDivision = (value: number) => {
    form.setFieldsValue({ division_id: value, building_id: null, item: null, side: null });
    setFilterItems([]);
  };

  const handleChangeBuilding = (value: number) => {
    form.setFieldsValue({ building_id: value, item: null, side: null });
    setFilterItems([]);
    let items: any[] = [];
    if (value) {
      getShortBuilding(value).unwrap()
      .then((payload) => {
        const building = payload.data as ShortBuilding;
        const aislesOptions = building.aisles?.map((aisle) => {
          return { aisle_id: aisle.id, name: aisle.name };
        }) || [];
        const grazingsOptions = building.grazings?.map((grazing) => {
          return { grazing_id: grazing.id, name: grazing.name };
        }) || [];
        items = [...items, ...aislesOptions, ...grazingsOptions];
        setFilterItems(items);
      });
    }
  };

  const handleChangeFilterItem = (    
    value: { aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }
  ) => {    
    form.setFieldsValue({ item: value, side: null });    
  };

  const handleChangeEventStart = () => {
    form.setFieldsValue({ event_end: null });
  };

  const prepareDateForSubmit = (date: string | Dayjs) => {
    return typeof date === 'string' ? dayjs(date, DATE_FORMAT) : dayjs(date);
  }

  const onFormFinish = (values: any) => {
    const dateStart = prepareDateForSubmit(values.event_start);
    const dateEnd = prepareDateForSubmit(values.event_end);

    let item: any = null;
    if (values.item) item = JSON.parse(values.item);
    const body = {
      building_id: values.building_id,
      side: values.side,
      aisle_id: item?.aisle_id,
      grazing_id: item?.grazing_id,
      event_start: dateStart.format('YYYY-MM-DDTHH:mm:ss'),
      event_end: dateEnd.format('YYYY-MM-DDTHH:mm:ss'),
    };    
    createFeeding(body).unwrap()
    .then((payload) => {
      if (payload.success) {
        notification.success({ 
          message: 'Событие создано',
        });
      }
      form.resetFields();
    })
    .catch((error) => {
      notification.error({ 
        message: 'Ошибка создания события',
        description: error.data?.error || 'Проверьте данные или попробуйте позже',
      });
    });
  };

  const getDisabledDateStart: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  }

  const getDisabledTimeStart = (date: Dayjs | null) => {
    const now = dayjs();
    const result: any = {};
    if (date?.date() && date?.year() >= now?.year() && date?.month() >= now?.month() && date?.date() >= now?.date()) {
      result.disabledHours = () => range(now?.hour() + 1, 24);
      if (date?.hour() >= now?.hour()) {
        result.disabledMinutes = () => range(now?.minute() + 1, 60);
        if (date?.minute() >= now?.minute()) {
          result.disabledSeconds = () => range(now?.second() + 1, 60);
        }
      }
    }
    return result;
  };

  const getDisabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
    return current ? current.isBefore(dayjs(prepareDateForSubmit(eventStartValue)?.endOf('second')).subtract(1, 'day').endOf('day')) : false;
  };


  const getDisabledTimeEnd = (date: Dayjs | null) => {
    const convertedStartValue = prepareDateForSubmit(eventStartValue);

    const result: any = {};
    if (date?.date() && date?.year() <= convertedStartValue?.year() && date?.month() <= convertedStartValue?.month() && date?.date() <= convertedStartValue?.date()) {
      result.disabledHours = () => range(0, convertedStartValue?.hour());
      if (date?.hour() <= convertedStartValue?.hour()) {
        result.disabledMinutes = () => range(0, convertedStartValue?.minute());
        if (date?.minute() <= convertedStartValue?.minute()) {
          result.disabledSeconds = () => range(0, convertedStartValue?.second() + 1);
        }
      }
    }
    return result;
  };

  const getEventDateErrorMessage =  (date: string) => {
    const nonExistErrorMessage = 'Пожалуйста, выберите дату и время!';
    const dateErrorMessage = 'Пожалуйста, выберите корректную дату!';
    const timeErrorMessage = 'Пожалуйста, выберите корректное время!';
    if (!date) return nonExistErrorMessage;

    const [dateValue, time] = date.split(' ');
    const [DD, MM] = dateValue.split('.');
    const [HH, mm, ss] = time.split(':');

    if (!date || date === 'Invalid Date' || date === 'undefined') return nonExistErrorMessage;

    if (+DD < MIN_DAY_COUNT || +DD > MAX_DAY_COUNT || +MM < MIN_DAY_COUNT || +MM > MAX_MONTH_COUNT) {
      return dateErrorMessage
    }

    if (+HH < MIN_TIME_COUNT || +HH > MAX_HOURS_COUNT
      || +mm < MIN_TIME_COUNT || +mm > MAX_MIN_COUNT
      || +ss < MIN_TIME_COUNT || +ss > MAX_SEC_COUNT) {
      return timeErrorMessage
    }

    return '';
  }

  const handleValidateFeedingDate = (_: unknown, value: string | Dayjs) => {
    const nonExistErrorMessage = 'Пожалуйста, выберите дату и время!';

    if (!value) return Promise.reject(new Error(nonExistErrorMessage));

    const parsedValue = typeof value === 'string' ? value : dayjs(value).format(DATE_FORMAT);
    const errorMessage = getEventDateErrorMessage(parsedValue);

    if (errorMessage) return Promise.reject(new Error(errorMessage))

    return Promise.resolve()
  }

  const handleValidateEndFeedingDate = (_: unknown, value: string | Dayjs) => {
    const nonExistErrorMessage = 'Пожалуйста, выберите дату и время окончания!';
    const beforeStartErrorMessage = 'Дата окончания не может быть раньше даты начала';

    if (!value) return Promise.reject(new Error(nonExistErrorMessage));

    const parsedValue = typeof value === 'string' ? value : dayjs(value).format(DATE_FORMAT);
    const dateStart = form.getFieldValue('event_start');

    if (dayjs(value, DATE_FORMAT).isBefore(dayjs(dateStart, DATE_FORMAT))) return Promise.reject(new Error(beforeStartErrorMessage))

    const errorMessage = getEventDateErrorMessage(parsedValue);

    if (errorMessage) return Promise.reject(new Error(errorMessage))

    return Promise.resolve()
  }


  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ farm_id: 1 }}
        onFinish={onFormFinish}
        disabled={isCreateFeedingLoading}
      >
        <Space direction="vertical" size="large">
          <Space size="large">
            <Form.Item
              name="farm_id"
              label="Хозяйство:"
              rules={[{ required: true, message: 'Пожалуйста, выберите хозяйство!' }]}
            >
              <Select
                className="min-w-[300px]"
                placeholder="Выберите хозяйство"
                loading={isFarmsLoading}
                onChange={handleChangeFarm}
                options={farmsData?.data.map((farm: Farm) => {
                  return { value: farm.id, label: farm.name };
                })}
              />
            </Form.Item>
            <Form.Item
              name="division_id"
              label="Подразделение:"
              rules={[{ required: true, message: 'Пожалуйста, выберите подразделение!' }]}
            >
              <Select
                className="min-w-[300px]"
                placeholder="Выберите подразделение"
                onChange={handleChangeDivision}
                loading={isDivisionsLoading}
                disabled={!farmIdValue}
                options={divisionsData?.data?.map((division: Division) => {
                  return { value: division.id, label: division.name };
                })}
              />
            </Form.Item>
          </Space>
          <Space size="large">
            <Form.Item
              name="building_id"
              label="Строение:"
              rules={[{ required: true, message: 'Пожалуйста, выберите строение!' }]}
            >
              <Select
                className="min-w-[300px]"
                placeholder="Выберите строение"
                onChange={handleChangeBuilding}
                disabled={!divisionIdValue}
                loading={isBuildingsLoading || isDivisionsLoading}
                options={buildingsData?.data?.map((building: Building) => {
                  return { value: building.id, label: building.name };
                })}
              />
            </Form.Item>
            <Form.Item
              name="item"
              label="Проход или Выпас:"
              rules={[{ required: true, message: 'Пожалуйста, выберите проход или выпас!' }]}
            >
              <Select
                className="min-w-[300px]"
                placeholder="Выберите проход или выпас"
                onChange={handleChangeFilterItem}
                loading={isShortBuildingLoading || isBuildingsLoading || isDivisionsLoading}
                disabled={!buildingIdValue}
                options={
                  filterItems?.map(
                    (item: { aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }) =>  ({ value: JSON.stringify(item), label: item.name })
                  )
                }
              />
            </Form.Item>
            {itemValue && JSON.parse(itemValue)?.aisle_id &&
              <Form.Item
                name="side"
                label="Сторона:"
                rules={[{ required: true, message: 'Пожалуйста, выберите сторону!' }]}
              >
                <Select
                  className="min-w-[300px]"
                  placeholder="Выберите сторону"
                  loading={isShortBuildingLoading || isBuildingsLoading || isDivisionsLoading}
                  options={[
                    { value: FeedingEventSide.SIDE_1, label: `Сторона ${FeedingEventSide.SIDE_1}`},
                    { value: FeedingEventSide.SIDE_2, label: `Сторона ${FeedingEventSide.SIDE_2}`},
                  ]}
                />
              </Form.Item>
            }
          </Space>
          <Space size="large">
            <Form.Item
              name="event_start"
              label="Начало:"
              rules={[{validator: handleValidateFeedingDate}]}
            >
              {/*<DatePicker*/}
              {/*  locale={locale}*/}
              {/*  className="min-w-[300px]"*/}
              {/*  placeholder="Выберите дату и время"*/}
              {/*  showTime={{ format: 'HH:mm:ss' }}*/}
              {/*  format="DD.MM.YYYY HH:mm:ss"*/}
              {/*  changeOnBlur={true}*/}
              {/*  onChange={handleChangeEventStart}*/}
              {/*  disabledDate={getDisabledDateStart}*/}
              {/*  disabledTime={getDisabledTimeStart}*/}
              {/*/>*/}

              <FeedingEventDatePicker
                form={form}
                disabledDate={getDisabledDateStart}
                disabledTime={getDisabledTimeStart}
                fieldValue={'event_start'}
                placeholder={'Выберите дату и время'}
              />
            </Form.Item>
            <Form.Item
              name="event_end"
              label="Окончание:"
              rules={[{validator: handleValidateEndFeedingDate}]}
            >
              {/*<DatePicker*/}
              {/*  locale={locale}*/}
              {/*  className="min-w-[300px]"*/}
              {/*  placeholder="Выберите дату и время"*/}
              {/*  showTime={{ format: 'HH:mm:ss' }}*/}
              {/*  format="DD.MM.YYYY HH:mm:ss"*/}
              {/*  changeOnBlur={true}*/}
              {/*  disabled={!eventStartValue}*/}
              {/*  disabledDate={getDisabledDateEnd}*/}
              {/*  disabledTime={getDisabledTimeEnd}*/}
              {/*/>*/}

              <FeedingEventDatePicker
                form={form}
                disabledDate={getDisabledDateEnd}
                disabledTime={getDisabledTimeEnd}
                fieldValue={'event_end'}
                placeholder={'Выберите дату и время'}
              />
            </Form.Item>
          </Space>
          <Space size="large">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Создать
              </Button>
            </Form.Item>
          </Space>
        </Space>
      </Form>
    </div>
  );
};

export default FeedingEventForm;
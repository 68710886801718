import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  AllReportTools,
  DateFlag,
  FeedingReportTools,
  ReportType,
  TrackersReportTools,
  WorkedAreaReportTools
} from '../../types/report.type';
import {Tracker} from '../../types/tracker.type';
import {Area} from '../../types/area.type';
import {RootState} from "../../redux/store";

const initialState = {
  allTrackers: [] as Tracker[],
  allAreas: [] as Area[],
  allBuildingsIds:[] as number[],
  allDivisionsIds: [] as number[],
  trackersDivisionsIds: [] as number[],
  dates: null as any,
  date_flag: DateFlag.TODAY as DateFlag,
  isToolsLoaded: false as boolean,

  settings: {
    trackers_mileage : {
      is_show_zeros: true,
      is_show_division: true,
      is_without_division: true,
      is_descend: true,
      division_ids: [],
      trackers_ids: [],
    } as TrackersReportTools,
    trackers_worked_area: {
      is_show_zeros: true,
      is_show_division: true,
      is_without_division: true,
      is_descend: true,
      division_ids: [],
      trackers_ids: [],
    } as TrackersReportTools,
    worked_area: {
      is_show_zeros: true,
      is_show_division: true,
      is_without_division: true,
      is_descend: true,
      division_ids: [],
      area_ids: [],
    } as WorkedAreaReportTools,
    report_feeding: {
      building_ids: [],
      division_ids: [],
    } as FeedingReportTools,
  }
};

type InitialStateType = typeof initialState;

const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setAllTrackers: (
      state: InitialStateType,
      action: PayloadAction<Tracker[]>,
    ) => {
      state.allTrackers = action.payload;
    },
    setAllAreas: (
      state: InitialStateType,
      action: PayloadAction<Area[]>,
    ) => {
      state.allAreas = action.payload;
    },
    setAllBuildingsIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.allBuildingsIds = action.payload;
    },
    setTrackersForMileageReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.trackers_mileage.trackers_ids = action.payload;
    },
    setTrackersForWorkedAreaReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.trackers_worked_area.trackers_ids = action.payload;
    },
    setDivisionsForMileageReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.trackers_mileage.division_ids = action.payload;
    },
    setDivisionsForTrackersWorkedAreaReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.trackers_worked_area.division_ids = action.payload;
    },
    setDivisionsForWorkedAreaReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.worked_area.division_ids = action.payload;
    },
    setDivisionsForFeedingReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.report_feeding.division_ids = action.payload;
    },
    setBuildingsForFeedingReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.report_feeding.building_ids = action.payload;
    },
    setIsShowZerosForMileageReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_mileage.is_show_zeros = action.payload;
    },
    setIsShowZerosForTrackersWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_worked_area.is_show_zeros = action.payload;
    },
    setIsShowZerosForWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.worked_area.is_show_zeros = action.payload;
    },
    setIsShowDivisionForMileageReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_mileage.is_show_division = action.payload;
    },
    setIsShowDivisionForTrackersWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_worked_area.is_show_division = action.payload;
    },
    setIsShowDivisionForWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.worked_area.is_show_division = action.payload;
    },
    setIsDescentForMileageReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_mileage.is_descend = action.payload;
    },
    setIsDescentForWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.worked_area.is_descend = action.payload;
    },
    setIsDescentForTrackersWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_worked_area.is_descend = action.payload;
    },
    setIsWithoutDivisionForMileageReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_mileage.is_without_division = action.payload},
    setIsWithoutDivisionForTrackersWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.trackers_worked_area.is_without_division = action.payload},
    setIsWithoutDivisionForWorkedAreaReport: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.settings.worked_area.is_without_division = action.payload},

    setAreasForWorkedAreaReportIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.worked_area.area_ids = action.payload;
    },
    setDates: (
      state: InitialStateType,
      action: PayloadAction<any>,
    ) => {
      state.dates = action.payload;
    },
    setDateFlag: (
      state: InitialStateType,
      action: PayloadAction<DateFlag>,
    ) => {
      state.date_flag = action.payload;
    },
    setTrackersDivisionsIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.trackersDivisionsIds = action.payload;
    },
    setAreasDivisionsIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.settings.worked_area.division_ids = action.payload;
    },
    setAllDivisionsIds: (state, action: PayloadAction<number[]>) => {
      state.allDivisionsIds = action.payload;
    },
    setIsToolsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isToolsLoaded = action.payload;
    },
    setDefaultReportTools: (state) => {
      const trackersId = state.allTrackers.map((tracker) => tracker.id);

      return {
        ...state,
        settings: {
          ...initialState.settings,
          trackers_mileage: getDefaultTrackersMileage(state),
          trackers_worked_area: getDefaultTrackersForWorkedArea(state),
          worked_area: getDefaultWorkedArea(state),
          report_feeding: getDefaultReportFeeding(state),
        },
      };
    },
    updateReportTools: (state, action: PayloadAction<AllReportTools>) => {
      const {trackers_worked_area, worked_area, trackers_mileage, report_feeding} = action.payload;

      return {
        ...state,
        settings: {
          trackers_mileage: trackers_mileage
            ? { ...initialState.settings.trackers_mileage, ...trackers_mileage }
            : getDefaultTrackersMileage(state),
          trackers_worked_area: trackers_worked_area
            ? { ...initialState.settings.trackers_worked_area, ...trackers_worked_area }
            : getDefaultTrackersForWorkedArea(state),
          worked_area: worked_area
            ? { ...initialState.settings.worked_area, ...worked_area }
            : getDefaultWorkedArea(state),
          report_feeding: report_feeding
            ? { ...initialState.settings.report_feeding, ...report_feeding }
            : getDefaultReportFeeding(state),
        }
      }
    },
    resetCurrentReportTools: (state, action: PayloadAction<Exclude<ReportType, 'ALL'>>) => {
      const reportType = action.payload;

      if (reportType === ReportType.TRACKERS_WORKED_AREA) {
        state.settings.trackers_worked_area = getDefaultTrackersForWorkedArea(state);
      }
      if (reportType === ReportType.TRACKERS_MILEAGE) {
        state.settings.trackers_mileage = getDefaultTrackersMileage(state)
      }
      if (reportType === ReportType.WORKED_AREA) {
        state.settings.worked_area = getDefaultWorkedArea(state)
      }
      if (reportType === ReportType.REPORT_FEEDING) {
        state.settings.report_feeding = getDefaultReportFeeding(state)
      }
    }
  },
});

function getDefaultTrackersMileage(state: typeof initialState) {
  return {
    ...initialState.settings.trackers_mileage,
    trackers_ids: state.allTrackers.map((tracker) => tracker.id),
    division_ids: state.allDivisionsIds,
  }
}
function getDefaultTrackersForWorkedArea(state: typeof initialState) {
  return {
    ...initialState.settings.trackers_worked_area,
    trackers_ids: state.allTrackers.map((tracker) => tracker.id),
    division_ids: state.allDivisionsIds,
  }
}

function getDefaultWorkedArea(state: typeof initialState) {
  return {
    ...initialState.settings.worked_area,
    division_ids: state.allDivisionsIds,
    area_ids: state.allAreas.map((area) => area.id),
  }
}

function getDefaultReportFeeding(state: typeof initialState) {
  return {
    ...initialState.settings.report_feeding,
    division_ids: state.allDivisionsIds,
    building_ids: state.allBuildingsIds,
  }
}

export const {
  setAllTrackers,
  setAllAreas,
  setAllBuildingsIds,
  setTrackersForMileageReportIds,
  setTrackersForWorkedAreaReportIds,
  setDivisionsForMileageReportIds,
  setDivisionsForTrackersWorkedAreaReportIds,
  setDivisionsForWorkedAreaReportIds,
  setDivisionsForFeedingReportIds,
  setBuildingsForFeedingReportIds,
  setIsDescentForMileageReport,
  setIsDescentForTrackersWorkedAreaReport,
  setIsDescentForWorkedAreaReport,
  setIsShowZerosForMileageReport,
  setIsShowZerosForTrackersWorkedAreaReport,
  setIsShowZerosForWorkedAreaReport,
  setIsShowDivisionForTrackersWorkedAreaReport,
  setIsShowDivisionForWorkedAreaReport,
  setIsShowDivisionForMileageReport,
  setIsWithoutDivisionForWorkedAreaReport,
  setIsWithoutDivisionForMileageReport,
  setIsWithoutDivisionForTrackersWorkedAreaReport,
  resetCurrentReportTools,
  setAreasForWorkedAreaReportIds,
  setDates,
  setDateFlag,
  setTrackersDivisionsIds,
  setAreasDivisionsIds,
  setAllDivisionsIds,
  setDefaultReportTools,
  updateReportTools,
  setIsToolsLoaded,
} = reportsSlice.actions;

export default reportsSlice.reducer;

export const getTrackersForMileageReportIds = (state: RootState) => state.reportsReducer.settings.trackers_mileage.trackers_ids;
export const getTrackersForWorkedAreaReportIds = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.trackers_ids;
export const getDivisionsForTrackersWorkedAreaReportIds = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.division_ids;
export const getDivisionsForWorkedAreaReportIds = (state: RootState) => state.reportsReducer.settings.worked_area.division_ids;
export const getDivisionsForFeedingReportIds = (state: RootState) => state.reportsReducer.settings.report_feeding.division_ids;
export const getDivisionsForMileageReportIds = (state: RootState) => state.reportsReducer.settings.trackers_mileage.division_ids;
export const getBuildingsForFeedingReportIds = (state: RootState) => state.reportsReducer.settings.report_feeding.building_ids;
export const getIsDescentForMileageReport = (state: RootState) => state.reportsReducer.settings.trackers_mileage.is_descend;
export const getIsDescentForTrackersWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.is_descend;
export const getIsDescentForWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.worked_area.is_descend;
export const getIsShowDivisionForTrackersWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.is_show_division;
export const getIsShowDivisionForWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.worked_area.is_show_division;
export const getIsShowDivisionForMileageReport = (state: RootState) => state.reportsReducer.settings.trackers_mileage.is_show_division;
export const getIsShowZerosForMileageReport = (state: RootState) => state.reportsReducer.settings.trackers_mileage.is_show_zeros;
export const getIsShowZerosForTrackersWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.is_show_zeros;
export const getIsShowZerosForWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.worked_area.is_show_zeros;
export const getIsWithoutDivisionForTrackersWorkedAreaReport  = (state: RootState) => state.reportsReducer.settings.trackers_worked_area.is_without_division;
export const getIsWithoutDivisionForWorkedAreaReport = (state: RootState) => state.reportsReducer.settings.worked_area.is_without_division;
export const getIsWithoutDivisionForMileageReport = (state: RootState) => state.reportsReducer.settings.trackers_mileage.is_without_division;
export const getDates = (state: RootState) => state.reportsReducer.dates;
export const getDateFlag = (state: RootState) => state.reportsReducer.date_flag;
export const getAreasForWorkedAreaReportIds = (state: RootState) => state.reportsReducer.settings.worked_area.area_ids;
export const getAllTrackers = (state: RootState) => state.reportsReducer.allTrackers;
export const getTrackersDivisionsIds = (state: RootState) => state.reportsReducer.trackersDivisionsIds;
export const getAreasDivisionsIds = (state: RootState) => state.reportsReducer.settings.worked_area.division_ids;
export const getAllAreas = (state: RootState) => state.reportsReducer.allAreas;
export const getAllBuildingsIds = (state: RootState) => state.reportsReducer.allBuildingsIds;
export const getAllDivisionsIds = (state: RootState) => state.reportsReducer.allDivisionsIds;
export const getIsToolsLoaded = (state: RootState) => state.reportsReducer.isToolsLoaded;
import React, { memo } from "react";
// import ymaps from "yandex-maps";

import { useAppSelector } from '../../redux/hooks';
import AreaComponent from "./area";

// type AreasProps = {
//   calculateArea: (polygon?: ymaps.Polygon) => void;
// };

export default memo(function Areas() {
  const areas = useAppSelector((state) => state.areaReducer.areas);
  // console.log('areas', areas);

  return (
    <>
      {areas?.map((area) => (
        <AreaComponent
          key={area.id}
          area={area}
          // calculateArea={calculateArea}
        />
      ))}
    </>
  );
})

import { createSlice } from '@reduxjs/toolkit';

import { MulticamEvent } from '../../types/multicam';
import {RootState} from "../../redux/store";

interface InitialState  {
  eventColors: Record<string, string> | null;
  activeEvent: MulticamEvent | null;
  loadedCams: string[];
}

const initialState: InitialState = {
  eventColors: null,
  activeEvent: null,
  loadedCams: [],
};

const multicamSlice = createSlice({
  name: 'multicamSlice',
  initialState,
  reducers: {
    setEventColors: (state, action) => {
      state.eventColors = action.payload
    },
    setActiveEvent: (state, action) => {
      state.activeEvent = action.payload;
    },
    setLoadedCams: (state, action) => {
      state.loadedCams = action.payload;
    },
    resetLoadedCams: (state) => {
      state.loadedCams = [];
    },
    addLoadedCam: (state, action) => {
      state.loadedCams.push(action.payload);
    }
  },
});

export const { setEventColors, setActiveEvent, resetLoadedCams, setLoadedCams, addLoadedCam } = multicamSlice.actions;
export default multicamSlice.reducer;


export const getEventColors = (state: RootState) => state.multicamReducer.eventColors;
export const getActiveMulticamEvent = (state: RootState) => state.multicamReducer.activeEvent;
export const getLoadedCams = (state: RootState) => state.multicamReducer.loadedCams;
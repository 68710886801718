import React from "react";
import { Placemark, useYMaps } from '@pbe/react-yandex-maps';

import { useGetInfoMarkersQuery } from '../../redux/api/infoMarkers.api';
import { useAppSelector } from "../../redux/hooks";
import { InfoMarker } from '../../types/infoMarker.type';
import { MARKER_ICON_SIZE, MARKER_ICON_OFFSET } from "../../util/constants";

type InfoMarkersProps = {
  onContextMenu: (e: ymaps.Event, infoMarker: InfoMarker) => void;
};


export default function InfoMarkers({ onContextMenu }: InfoMarkersProps) {
  const { data } = useGetInfoMarkersQuery({});
  const infoMarkers = data?.data;
  // console.log('infoMarkers', infoMarkers);
  const selectedInfoMarker = useAppSelector((state) => state.infoMarkerReducer.infoMarker);
  const ymaps = useYMaps();
  const balloonLayout = ymaps?.templateLayoutFactory.createClass(
    '<div><p>{{properties.name}}</p><p>{{properties.description}}</p></div>'
  );
  
  return (
    <>
      {infoMarkers?.map((infoMarker: InfoMarker) => (
        <Placemark
          key={infoMarker.id}
          geometry={infoMarker.coords?.coordinates}
          options={{
            iconLayout: 'default#image',
            iconImageHref: infoMarker.id === selectedInfoMarker?.id ?
              '/images/location_point_blue.svg' : '/images/location_point.svg',
            iconImageSize: MARKER_ICON_SIZE,
            iconImageOffset: MARKER_ICON_OFFSET,
            visible: infoMarker.id !== selectedInfoMarker?.id,
            balloonContentLayout: balloonLayout,
            hideIconOnBalloonOpen: false,
            // openBalloonOnClick: false,
          }}
          properties={{
            hintContent: infoMarker.name,
            name: infoMarker.name,
            description: infoMarker.description,
          }}
          onContextMenu={(e: ymaps.Event) => onContextMenu(e, infoMarker)}
        />
      ))}
    </>
  );
}

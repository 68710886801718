import React from "react";
import { Button } from '@pbe/react-yandex-maps';

import { useAppSelector } from '../../../redux/hooks';
import { MainRoutes } from "../../../util/routes";
import { FEEDING_REPORT_BUTTON_POSITION } from "../../../util/constants";

export default function FeedingReportButton() {
  const farm = useAppSelector((state) => state.farmReducer.farm);

  const onClick = () => {
    window.open(`/${MainRoutes.feeding_report}?farm_id=${farm?.id}`)
  };
  
  return (
    <Button
      options={{
        float: 'none',
        position: FEEDING_REPORT_BUTTON_POSITION,
        size: 'small',
        selectOnClick: false,
      }}
      data={{ image: '/images/report.svg', title: 'отчёт Кормления' }}
      state={{ enabled: !!farm?.id }}
      onClick={onClick}
    />
  );
}

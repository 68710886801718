import React, { useEffect, useState, memo } from "react";
import { Polygon } from '@pbe/react-yandex-maps';
import ymaps from "yandex-maps";
import { AREA_TOOL_FILL_OPACITY, TOOL_STROKE_WIDTH } from "../../util/constants";

type AreaToolProps = {
  calculateArea: (
      polygon?: ymaps.Polygon,
    ) => void;
};

export default memo(function AreaTool({ calculateArea }: AreaToolProps) {
  const [tool, setTool] = useState<ymaps.Polygon>();

  useEffect(() => {
    if (tool) {
      // @ts-ignore
      tool.editor.startDrawing();
      tool.editor.events.add(['vertexdragend', 'vertexadd'], (e) => {
        calculateArea(tool);
      });
      tool.events.add('geometrychange', (e) => {
        calculateArea(tool);
      });
    }
  }, [tool]);
  
  return (
    <Polygon
      instanceRef={(ref) => { 
        // @ts-ignore
        setTool(ref);
      }}
      geometry={[]}
      options={{
        strokeColor: "#0000FF",
        strokeWidth: TOOL_STROKE_WIDTH,
        strokeStyle: 'solid',
        fillColor: "#FFFFFF",
        fillOpacity: AREA_TOOL_FILL_OPACITY,
      }}
      onClick={() => calculateArea(tool)}
    />
  )
})

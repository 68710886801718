import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, NavLink, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  CarOutlined,
  // CameraOutlined,
  UserOutlined,
  UploadOutlined,
  BorderOutlined,
  BankOutlined,
  // SplitCellsOutlined,
  BlockOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';

import { useAppSelector } from '../../redux/hooks';
import { Role } from '../../types/auth.type';
import { AdminLinks } from '../../types/common.type';
import { AdminRoutes } from '../../util/routes';

const { Content, Sider } = Layout;

const Admin = () => {
  const user = useAppSelector((state) => state.authReducer.user);
  const pathname = useLocation().pathname;
  
  useEffect(() => {
    const arrayOfPaths = pathname.split('/').filter((item) => !!item);
    if (arrayOfPaths.length > 1) {
      setTitle(AdminLinks[arrayOfPaths[1] as keyof typeof AdminLinks]);
    } else {
      setTitle(AdminLinks.trackers);
    }
  }, [pathname]);

  const [title, setTitle] = useState<string>('');
  
  return (
    <>
      {user?.role !== Role.ADMIN ?
        <Navigate to="/" replace />
      : 
        <Layout className="h-[calc(100vh-40px)]">
          <Sider breakpoint="lg" width={250} collapsedWidth={0}>
            <Menu
              className="mt-24 px-2"
              theme="dark"
              mode="inline"
              selectedKeys={[title]}
              items={[
                {
                  key: AdminLinks.trackers,
                  icon: <CarOutlined />,
                  label: <NavLink to={AdminRoutes.trackers}>{AdminLinks.trackers}</NavLink>,
                },
                // {
                //   key: AdminLinks.cameras,
                //   icon: <CameraOutlined />,
                //   label: <NavLink to={AdminRoutes.cameras}>{AdminLinks.cameras}</NavLink>,
                // },
                {
                  key: AdminLinks.areas,
                  icon: <BorderOutlined />,
                  label: <NavLink to={AdminRoutes.areas}>{AdminLinks.areas}</NavLink>,
                },
                {
                  key: AdminLinks.photos,
                  icon: <UploadOutlined />,
                  label: <NavLink to={AdminRoutes.photos}>{AdminLinks.photos}</NavLink>,
                },
                {
                  key: AdminLinks.divisions,
                  icon: <BlockOutlined />,
                  label: <NavLink to={AdminRoutes.divisions}>{AdminLinks.divisions}</NavLink>,
                },
                {
                  key: AdminLinks.buildings,
                  icon: <BankOutlined />,
                  label: <NavLink to={AdminRoutes.buildings}>{AdminLinks.buildings}</NavLink>,
                },
                // {
                //   key: AdminLinks.aisles,
                //   icon: <SplitCellsOutlined />,
                //   label: <NavLink to={AdminRoutes.aisles}>{AdminLinks.aisles}</NavLink>,
                // },
                {
                  key: AdminLinks.feeding_events,
                  icon: <FieldTimeOutlined />,
                  label: <NavLink to={AdminRoutes.feeding_events}>{AdminLinks.feeding_events}</NavLink>,
                },
                {
                  key: AdminLinks.users,
                  icon: <UserOutlined />,
                  label: <NavLink to={AdminRoutes.users}>{AdminLinks.users}</NavLink>,
                },
              ]}
            />
          </Sider>
          <Content className="my-6 mx-4 p-4 md:px-6">
            <Outlet />
          </Content>
        </Layout>
      }
    </>
  );
};

export default Admin;


import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { apiBaseUrl } from "../../util/api";
import {BaseResponse} from "../../types/common.type";
import {AllReportTools} from "../../types/report.type";

export const toolsReportsApi = createApi({
    reducerPath: 'toolsReportsQuery',
    tagTypes:['Tools'],
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    endpoints:(build)=>({
        getToolsReports : build.query<BaseResponse<AllReportTools>, unknown>({
            query:()=>({
                url:'/tools_reports/',
                method: 'GET',
            })
        }),
        saveToolsReports : build.mutation({
            query:(data)=>({
                url: '/tools_reports/',
                method:'POST',
                body: data.body,
            }),
            invalidatesTags: [{ type: 'Tools' }]
        }),
        deleteToolsReports : build.mutation({
            query:(data)=>({
                url: '/tools_reports/',
                method: 'DELETE',
                params: data.params,

            })
        })
    }),
});


export const {useGetToolsReportsQuery,useLazyGetToolsReportsQuery, useSaveToolsReportsMutation, useDeleteToolsReportsMutation}=toolsReportsApi;
import React, { memo } from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Popconfirm, notification } from "antd";

import { useAppDispatch } from "../../redux/hooks";
import { deleteInfoMarker } from "../../redux/slices/infoMarkerSlice";
import { useDeleteInfoMarkerMutation } from '../../redux/api/infoMarkers.api';
import { InfoMarker } from "../../types/infoMarker.type";


type InfoMarkerContextMenuProps = {
  top: number;
  left: number;
  selectedInfoMarker: InfoMarker | null;
  onClose: () => void;
  onClick: () => void;
};

export default memo(
  function InfoMarkerContextMenu({ top, left, selectedInfoMarker, onClose, onClick }: InfoMarkerContextMenuProps) {
    const dispatch = useAppDispatch();
    const [deleteMarker, { isLoading: deleteLoading } ] = useDeleteInfoMarkerMutation();

    const handleDeleteMarker = () => {
      deleteMarker(selectedInfoMarker?.id)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          dispatch(deleteInfoMarker(payload.data));
        }
      })
      .catch((error) => {
        console.log('error', error.message);
        notification.error({ 
          message: 'Ошибка удаления маркера',
          description: error.data?.error || 'Проверьте данные или попробуйте позже',
        });
      })
      .finally(() => {
        onClose();
      });
    }

    return (
      <div
        style={{ top: `${top}px`, left: `${left}px` }}
        className="absolute bg-white border border-gray-300 p-2"
      >
        <div className="flex w-full justify-end">
          <CloseOutlined onClick={onClose} />
        </div>
        {deleteLoading ?
          <div className="h-full flex justify-center items-center w-[167px]">
            <LoadingOutlined style={{ fontSize: '30px', color: '#6b7280' }} />
          </div>
        :
          selectedInfoMarker ?
            <div>
              <Popconfirm
                title={`Удаление маркера ${selectedInfoMarker?.name}`}
                description={`Вы действительно хотите удалить маркер ${selectedInfoMarker?.name}?`}
                onConfirm={handleDeleteMarker}
                placement="top"
                okText="Да"
                cancelText="Нет"
              >
                <p className="cursor-pointer hover:text-blue-500">Удалить маркер</p>
              </Popconfirm>
              <p
                className="cursor-pointer hover:text-blue-500"
                onClick={onClick}
              >Редактировать маркер</p>
            </div>
          :
            <p
              className="cursor-pointer hover:text-blue-500"
              onClick={onClick}
            >Создать маркер</p>
        }
      </div>
    )
  }
)

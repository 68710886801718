import React, { Key, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, Button, notification, Tree, Pagination } from "antd";
import type { PaginationProps } from "antd";
import { DataNode } from "antd/es/tree";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { useAppSelector } from "../redux/hooks";
import {
  useGetDetailReportFeedingEventsMutation,
  useGetReportFarmFeedingEventsMutation,
  useGetReportBuildingFeedingEventsMutation,
  useGenerateExcelFarmReportFeedingEventsMutation,
  useCheckFileReportFeedingQuery,
} from "../redux/api/reports.api";
import FeedingDetailReportTable from "../components/statistics/feeding/feedingDetailReportTable";
import FeedingMainFilters from "../components/statistics/feeding/feedingMainFilters";
import FeedingDetailFilters from "../components/statistics/feeding/feedingDetailFilters";
import {
  FarmFeedingEventReport,
  DivisionFeedingEventReport,
  BuildingFeedingEventReport,
  AislesGrazingsFeedingEventReport,
  DateFlag,
  FeedingEventReportFilters,
  ExcellFeedingEventReportFilters,
  DetailFeedingEventReportFilters,
} from "../types/report.type";
import { FeedingEventType } from "../types/feeding.type";
import { Role } from "../types/auth.type";
import { apiBaseMediaUrl } from "../util/api";
// import { FEEDING_DETAIL_REPORT_PAGE_LIMIT } from '../util/constants';
import { getDatesPlaceholder } from "../util/helpers";
import { FEEDING_EXCEL_REPORT_POLLING_INTERVAL, FEEDING_EXCEL_REPORT_TIMEOUT } from "../util/constants";

const { Content, Header, Sider } = Layout;

const FeedingReport = () => {
  const [searchParams] = useSearchParams();
  const farm_id = searchParams.get("farm_id");
  const user = useAppSelector((state) => state.authReducer.user);
  const mainFilters = useAppSelector(
    (state) => state.feedingReportsReducer.mainFilters
  );
  const detailFilters = useAppSelector(
    (state) => state.feedingReportsReducer.detailFilters
  );
  const farmId = useAppSelector((state) => state.feedingReportsReducer.farmId);

  const [currentPage, setCurrentPage] = useState<number>();
  const [datesForPages, setDatesForPages] = useState<string[]>([]);
  const [monthesForPages, setMonthesForPages] = useState<Array<string[]>>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [fullFarmFeedingEventData, setFullFarmFeedingEventData] =
    useState<FarmFeedingEventReport>();
  const [forPageFarmFeedingEventData, setForPageFarmFeedingEventData] =
    useState<FarmFeedingEventReport>();
  const [fullBuildingsFeedingEventData, setFullBuildingsFeedingEventData] =
    useState<{ [key: string]: AislesGrazingsFeedingEventReport[] }>({});
  const [excelUuid, setExcelUuid] = useState<string>('');
  const [excelTimeoutId, setExcelTimeoutId] = useState<NodeJS.Timeout>();
  const [
    getFarmFeedingEvents,
    {
      isLoading: isGetFeedingEventsLoading,
      isError: isGetFeedingEventsError,
      error: feedingEventsError,
    },
  ] = useGetReportFarmFeedingEventsMutation();
  const [getBuildingFeedingEvents] = useGetReportBuildingFeedingEventsMutation();
  const [
    getDetailFeedingEvents,
    {
      isLoading: isGetDetailFeedingEventsLoading,
      isError: isGetDetailFeedingEventsError,
      error: detailFeedingEventsError,
    },
  ] = useGetDetailReportFeedingEventsMutation();
  const [generateExcelFarmReport] = useGenerateExcelFarmReportFeedingEventsMutation();
  const { data: excelFileReportData } = useCheckFileReportFeedingQuery(excelUuid, {
    pollingInterval: FEEDING_EXCEL_REPORT_POLLING_INTERVAL,
    skip: !excelUuid,
  });

  useEffect(() => {
    if (excelFileReportData) {
      setExcelUuid('');
      clearTimeout(excelTimeoutId);
      const a = document.createElement('a');
      a.href = `${apiBaseMediaUrl}${excelFileReportData.data}`;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
      }, 0);
      setExcelLoading(false);
    }
  }, [excelFileReportData]);

  useEffect(() => {
    if (mainFilters) {
      // console.log('mainFilters', mainFilters);
      setFullBuildingsFeedingEventData({});
      if (
        mainFilters.date_flag !== DateFlag.TODAY &&
        mainFilters.date_flag !== DateFlag.DAY_AGO
      ) {
        const params: { farm_id: number; body: FeedingEventReportFilters } = {
          farm_id: farmId,
          body: { ...mainFilters },
        };
        getFarmFeedingEvents(params)
          .unwrap()
          .then((payload) => {
            setFullFarmFeedingEventData(payload.data);
          });
      }
      let end: dayjs.Dayjs;
      let current: dayjs.Dayjs;
      if (mainFilters.date_flag === DateFlag.FROM_TO) {
        current = dayjs(mainFilters.date_from, "YYYY-MM-DD");
        end = dayjs(mainFilters.date_to, "YYYY-MM-DD");
      } else {
        const datesRange = getDatesPlaceholder(mainFilters.date_flag);
        current = dayjs(datesRange[0], "DD.MM.YYYY");
        end = dayjs(datesRange[1], "DD.MM.YYYY");
      }
      if (dayjs(end).diff(current, 'month', true) > 1) {
        const monthesForPages: Array<string[]> = [];
        while (current <= end) {
          const currentMonthDates: string[] = [];
          currentMonthDates.push(current.format("DD.MM.YYYY"));
          const endOfCurrentMonth = current.endOf("month");
          if (endOfCurrentMonth < end) {
            currentMonthDates.push(endOfCurrentMonth.format("DD.MM.YYYY"));
          } else {
            currentMonthDates.push(end.format("DD.MM.YYYY"));
          }
          monthesForPages.push(currentMonthDates);
          current = current.add(1, "month").startOf("month");
        }
        setDatesForPages([]);
        setMonthesForPages(monthesForPages);
      } else {
        const datesForPages: string[] = [];
        while (current <= end) {
          datesForPages.push(current.format("DD.MM.YYYY"));
          current = current.add(1, "day");
        }        
        setMonthesForPages([]);
        setDatesForPages(datesForPages);
      }
      setCurrentPage(1);
    }
  }, [mainFilters]);

  const getFarmFeedingEventsDataForPage = useCallback(
    (page: number, isMonth?: boolean) => {
      if (datesForPages.length || monthesForPages.length) {
        const params: { farm_id: number; body: FeedingEventReportFilters } = {
          farm_id: farmId,
          body: { ...mainFilters, date_flag: DateFlag.FROM_TO },
        };
        params.body.date_from = dayjs(
          isMonth ? monthesForPages[page - 1][0] : datesForPages[page - 1],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        params.body.date_to = dayjs(
          isMonth ? monthesForPages[page - 1][1] : datesForPages[page - 1],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        getFarmFeedingEvents(params)
          .unwrap()
          .then((payload) => {
            setForPageFarmFeedingEventData(payload.data);
            if (
              mainFilters?.date_flag === DateFlag.TODAY ||
              mainFilters?.date_flag === DateFlag.DAY_AGO
            ) {
              setFullFarmFeedingEventData(payload.data);
            }
          });
      }
    },
    [
      farmId,
      monthesForPages,
      datesForPages,
      getFarmFeedingEvents,
      setForPageFarmFeedingEventData,
      setFullFarmFeedingEventData,
    ]
  );

  useEffect(() => {
    // console.log('currentPage', currentPage);
    if (currentPage) {
      getFarmFeedingEventsDataForPage(currentPage, !!monthesForPages.length);
    }
  }, [currentPage, getFarmFeedingEventsDataForPage]);

  useEffect(() => {
    if (isGetFeedingEventsError) {
      notification.error({
        // @ts-ignore
        message: feedingEventsError?.data?.error || "Попробуйте позже",
      });
    }
  }, [isGetFeedingEventsError, feedingEventsError]);

  useEffect(() => {
    if (isGetDetailFeedingEventsError) {
      notification.error({
        // @ts-ignore
        message: detailFeedingEventsError?.data?.error || "Попробуйте позже",
      });
    }
  }, [isGetDetailFeedingEventsError, detailFeedingEventsError]);

  useEffect(() => {
    if (forPageFarmFeedingEventData && fullFarmFeedingEventData) {
      // setExpandedKeys([]);
      // setLoadedKeys([]);
      const treeData = {
        title: (
          <div className="px-4 text-xl font-medium bg-red-200 border border-red-500">
            <p>
              <span className="text-2xl font-bold mr-4">
                {forPageFarmFeedingEventData.name}
              </span>
              {`Нарушения:  неполных ${forPageFarmFeedingEventData.data.half_event}(${fullFarmFeedingEventData.data.half_event}), отсутствие ${forPageFarmFeedingEventData.data.absent_event}(${fullFarmFeedingEventData.data.absent_event}).`}
            </p>
            <p>
              {`Подтверждённых событий ${forPageFarmFeedingEventData.data.confirm_event}(${fullFarmFeedingEventData.data.confirm_event}); Важных событий ${forPageFarmFeedingEventData.data.important_event}(${fullFarmFeedingEventData.data.important_event});`}
              {user?.role === Role.ADMIN &&
                ` Ложных событий ${forPageFarmFeedingEventData.data.rejected_event}(${fullFarmFeedingEventData.data.rejected_event}); Событий всего ${forPageFarmFeedingEventData.data.all_event}(${fullFarmFeedingEventData.data.all_event});`}
            </p>
          </div>
        ),
        key: `farm-${forPageFarmFeedingEventData?.id}`,
        isLeaf: !forPageFarmFeedingEventData.divisions.length,
        children: forPageFarmFeedingEventData.divisions.map(
          (division: DivisionFeedingEventReport, divisionIndex: number) => {
            const fullDivision =
              fullFarmFeedingEventData.divisions[divisionIndex];
            return {
              title: (
                <div className="px-4 text-lg font-medium bg-yellow-200 border border-yellow-500">
                  <p>
                    <span className="text-xl font-bold mr-4">
                      {division.name}
                    </span>
                    {`Нарушения:  неполных ${division.data.half_event}(${fullDivision?.data.half_event}), отсутствие ${division.data.absent_event}(${fullDivision?.data.absent_event}).`}
                  </p>
                  <p>
                    {`Подтверждённых событий ${division.data.confirm_event}(${fullDivision?.data.confirm_event}); Важных событий ${division.data.important_event}(${fullDivision?.data.important_event});`}
                    {user?.role === Role.ADMIN &&
                      ` Ложных событий ${division.data.rejected_event}(${fullDivision?.data.rejected_event}); Событий всего ${division.data.all_event}(${fullDivision?.data.all_event});`}
                  </p>
                </div>
              ),
              key: `division-${division?.id}`,
              isLeaf: !division.buildings.length,
              children: division.buildings.map(
                (
                  building: BuildingFeedingEventReport,
                  buildingIndex: number
                ) => {
                  const fullBuilding = fullDivision?.buildings[buildingIndex];
                  return {
                    title: (
                      <div className="px-4 text-base font-medium bg-green-200 border border-green-500">
                        <p>
                          <span className="text-lg font-bold mr-4">
                            {building.name}
                          </span>
                          {`Кормлений:  полных ${building.data.full_event}(${fullBuilding?.data.full_event}), неполных ${building.data.half_event}(${fullBuilding?.data.half_event}), отсутствие ${building.data.absent_event}(${fullBuilding?.data.absent_event}).`}
                        </p>
                        <p>
                          {`Подтверждённых событий ${building.data.confirm_event}(${fullBuilding?.data.confirm_event}); Важных событий ${building.data.important_event}(${fullBuilding?.data.important_event});`}
                          {user?.role === Role.ADMIN &&
                            ` Ложных событий ${building.data.rejected_event}(${fullBuilding?.data.rejected_event}); Событий всего ${building.data.all_event}(${fullBuilding?.data.all_event});`}
                        </p>
                      </div>
                    ),
                    key: `building-${building?.id}`,
                  };
                }
              ),
            };
          }
        ),
      };
      setTreeData([treeData]);
    }
  }, [fullFarmFeedingEventData, forPageFarmFeedingEventData, setTreeData]);

  const updateTreeData = (
    list: DataNode[],
    key: React.Key,
    children: DataNode[] | undefined
  ): DataNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  const onDetailReportTablePageChange = useCallback(
    (
      /*page: number,*/ key: string,
      resolve?: () => void,
      reject?: () => void
    ) => {
      if (!currentPage) return resolve?.();
      const params: DetailFeedingEventReportFilters = {
        date_flag: DateFlag.FROM_TO,
        ...detailFilters,
      };
      if (monthesForPages.length) {
        params.date_from = dayjs(
          monthesForPages[currentPage - 1][0],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        params.date_to = dayjs(
          monthesForPages[currentPage - 1][1],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
      } else {
        params.date_from = dayjs(
          datesForPages[currentPage - 1],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
        params.date_to = dayjs(
          datesForPages[currentPage - 1],
          "DD.MM.YYYY"
        ).format("YYYY-MM-DD");
      }
      if (key.startsWith("aisle-")) {
        params.aisle_id = +key.split("-")[1];
      } else {
        params.grazing_id = +key.split("-")[1];
      }
      getDetailFeedingEvents(params)
        .unwrap()
        .then((payload) => {
          setTreeData((treeData) => {
            return updateTreeData(treeData, key, [
              {
                title: (
                  <FeedingDetailReportTable
                    treeKey={key}
                    data={payload.data}
                    // onPageChange={onDetailReportTablePageChange}
                    loading={isGetDetailFeedingEventsLoading}
                  />
                ),
                key: `table-${key}`,
                isLeaf: true,
              },
            ]);
          });
          resolve?.();
        })
        .catch(() => {
          if (reject) {
            setExpandedKeys((expandedKeys) => {
              const newKeys = expandedKeys.filter((item) => item !== key);
              return newKeys;
            });
            reject();
          }
        });
    },
    [currentPage, mainFilters, detailFilters, monthesForPages, datesForPages]
  );

  const onLoadData = useCallback(
    ({ key, children }: any) => {
      return new Promise<void>(async (resolve, reject) => {
        if (!currentPage) return reject();
        if (children) {
          reject();
          return;
        }
        if (key.startsWith("building-")) {
          let fullBuildingFeedingEventData = fullBuildingsFeedingEventData[key] || [];
          if (
            !fullBuildingFeedingEventData.length &&
            mainFilters?.date_flag !== DateFlag.TODAY &&
            mainFilters?.date_flag !== DateFlag.DAY_AGO
          ) {
            const params: {
              building_id: string;
              body: FeedingEventReportFilters;
            } = {
              building_id: key.split("-")[1],
              body: { ...mainFilters } as FeedingEventReportFilters,
            };
            await getBuildingFeedingEvents(params)
              .unwrap()
              .then((payload) => {
                setFullBuildingsFeedingEventData(data => {
                  data[key] = payload.data;
                  return data;
                });
                fullBuildingFeedingEventData = payload.data;
              })
              .catch(() => {
                setExpandedKeys((expandedKeys) => {
                  const newKeys = expandedKeys.filter((item) => item !== key);
                  return newKeys;
                });
                reject();
              });
          }
          const params: {
            building_id: string;
            body: FeedingEventReportFilters;
          } = {
            building_id: key.split("-")[1],
            body: {
              ...mainFilters,
              date_flag: DateFlag.FROM_TO,
            } as FeedingEventReportFilters,
          };
          if (monthesForPages.length) {
            params.body.date_from = dayjs(
              monthesForPages[currentPage - 1][0],
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");
            params.body.date_to = dayjs(
              monthesForPages[currentPage - 1][1],
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");
          } else {
            params.body.date_from = dayjs(
              datesForPages[currentPage - 1],
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");
            params.body.date_to = dayjs(
              datesForPages[currentPage - 1],
              "DD.MM.YYYY"
            ).format("YYYY-MM-DD");
          }
          getBuildingFeedingEvents(params)
            .unwrap()
            .then(async (payload) => {
              if (
                !fullBuildingFeedingEventData.length &&
                (mainFilters?.date_flag === DateFlag.TODAY ||
                mainFilters?.date_flag === DateFlag.DAY_AGO)
              ) {
                setFullBuildingsFeedingEventData(data => {
                  data[key] = payload.data;
                  return data;
                });
                fullBuildingFeedingEventData = payload.data;
              }
              setTreeData((treeData) => {
                return updateTreeData(
                  treeData,
                  key,
                  payload.data.map(
                    (item: AislesGrazingsFeedingEventReport, index: number) => {
                      return {
                        title: (
                          <div className="px-4 font-medium border border-gray-500">
                            <p>
                              <span className="text-base font-bold mr-4">
                                {item.name}
                              </span>
                              {`Кормлений:  полных ${item.data.full_event}(${fullBuildingFeedingEventData[index]?.data?.full_event}), неполных ${item.data.half_event}(${fullBuildingFeedingEventData[index]?.data?.half_event}).`}
                            </p>
                            <p>
                              {`Подтверждённых событий ${item.data.confirm_event}(${fullBuildingFeedingEventData[index]?.data?.confirm_event}); Важных событий ${item.data.important_event}(${fullBuildingFeedingEventData[index]?.data?.important_event});`}
                              {user?.role === Role.ADMIN &&
                                ` Ложных событий ${item.data.rejected_event}(${fullBuildingFeedingEventData[index]?.data?.rejected_event}); Событий всего ${item.data.all_event}(${fullBuildingFeedingEventData[index]?.data?.all_event});`}
                            </p>
                          </div>
                        ),
                        isLeaf: !detailFilters?.is_extended,
                        key:
                          item.type === FeedingEventType.AISLE
                            ? `aisle-${item?.id}`
                            : `grazing-${item?.id}`,
                      };
                    }
                  )
                );
              });
              resolve();
              return;
            })
            .catch(() => {
              setExpandedKeys((expandedKeys) => {
                const newKeys = expandedKeys.filter((item) => item !== key);
                return newKeys;
              });
              reject();
            });
        } else if (key.startsWith("aisle-") || key.startsWith("grazing-")) {
          onDetailReportTablePageChange(key, resolve, reject);
        } else {
          resolve();
          return;
        }
      });
    },
    [
      mainFilters,
      onDetailReportTablePageChange,
      getBuildingFeedingEvents,
      setFullBuildingsFeedingEventData,
      setTreeData,
      updateTreeData,
    ]
  );

  const onExpand = useCallback(
    (expandedKeysValue: Key[]) => {
      setExpandedKeys(expandedKeysValue as string[]);
    },
    [setExpandedKeys]
  );

  useEffect(() => {
    setTreeData((treeData) => {
      treeData[0]?.children?.forEach((division) => {
        division.children?.forEach((building) => {
          if (building.children) {
            building.children.forEach((item) => {
              item.isLeaf = !detailFilters?.is_extended;
              item.children = undefined;
            });
          }
        });
      });
      return treeData;
    });
    setExpandedKeys((expandedKeys) => {
      const newKeys = expandedKeys.filter(
        (expandedKey) =>
          !(
            expandedKey.startsWith("grazing-") ||
            expandedKey.startsWith("aisle-")
          )
      );
      return newKeys;
    });
    setLoadedKeys((loadedKeys) => {
      const newKeys = loadedKeys.filter(
        (loadedKey) =>
          !(loadedKey.startsWith("grazing-") || loadedKey.startsWith("aisle-"))
      );      
      return newKeys;
    });
  }, [detailFilters, setTreeData]);

  const handleDownloadExcel = async () => {
    setExcelLoading(true);
    if (detailFilters?.is_extended) {
      notification.warning({
        message: "Формирование отчета может занять несколько минут",
        duration: 7,
      });
    }
    const body: ExcellFeedingEventReportFilters = {
      ...mainFilters,
      date_flag: mainFilters?.date_flag as DateFlag,
      ...detailFilters,
    };
    generateExcelFarmReport({ farm_id: farmId, body }).unwrap()
    .then((payload) => {
      setExcelUuid(payload.data);
      const timeoutId = setTimeout(() => {
        setExcelUuid('');
        notification.error({
          message: "Превышение времени ожидания",
          description: "Попробуйте позже",
          duration: null,
        });
        setExcelLoading(false);
      }, FEEDING_EXCEL_REPORT_TIMEOUT);
      setExcelTimeoutId(timeoutId);
    })
    .catch((error) => {
      notification.error({
        message: "Ошибка выгрузки в Excel",
        description: error.data?.error || "Попробуйте позже",
      });
      setExcelLoading(false);
    })
  };

  const onPageChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  const getTotalPages = useCallback(() => {
    if (monthesForPages.length) {
      return monthesForPages.length;
    } else {
      return datesForPages.length;
    }
  }, [mainFilters, monthesForPages, datesForPages]);

  const renderPaginationItems = useCallback(
    (
      page: number,
      type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
      originalElement: React.ReactNode
    ) => {
      if (type === "page") {
        const dataForPage = monthesForPages.length ?
          monthesForPages[page - 1]?.[0].slice(3)
        :
          datesForPages[page - 1];
        return <span className="px-2">{dataForPage}</span>;
      } else {
        return originalElement;
      }
    },
    [mainFilters, monthesForPages, datesForPages]
  );

  return (
    <Layout className="h-[calc(100vh-40px)]">
      <Header className="flex justify-between px-4 py-2 h-auto bg-white text-xl font-semibold border-b border-gray-300">
        <FeedingMainFilters />
      </Header>
      <Layout hasSider className="h-full">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={340}
          style={{ backgroundColor: "#fff" }}
          className="border-r border-gray-300 bg-white px-4 py-8"
        >
          <div className="flex flex-col justify-between h-full">
            <FeedingDetailFilters />
            <div className="flex flex-col items-center pt-8 border-t border-gray-200">
              <Button
                className="font-semibold w-44"
                onClick={handleDownloadExcel}
                loading={excelLoading}
                disabled={!farm_id || excelLoading}
              >
                {excelLoading ? 'Выгрузка...' : 'Выгрузить в Excel'}
              </Button>
              <Button
                className="flex justify-center items-center mt-8 w-44"
                icon={<CloseOutlined />}
                onClick={() => window.close()}
              >
                ЗАКРЫТЬ
              </Button>
            </div>
          </div>
        </Sider>
        <Content className="feeding-report p-2 h-full max-h-[calc(100vh-130px)] overflow-auto bg-gray-50 flex flex-col justify-between">
          {isGetFeedingEventsLoading ? (
            <div className="flex justify-center items-center h-full">
              <LoadingOutlined style={{ fontSize: "60px" }} />
            </div>
          ) : (
            <Tree
              className="bg-transparent h-full overflow-y-auto"
              defaultExpandParent={false}
              autoExpandParent={false}
              showLine
              blockNode
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              loadData={onLoadData}
              loadedKeys={loadedKeys}
              treeData={treeData}
              selectable={false}
            />
          )}
          <Pagination
            className="bg-gray-200 py-1"
            pageSize={1}
            showSizeChanger={false}
            total={getTotalPages()}
            itemRender={renderPaginationItems}
            current={currentPage}
            onChange={onPageChange}
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default FeedingReport;

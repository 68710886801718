import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

// import { LoginFields } from '@/app/types/auth.type';
import { apiBaseUrl } from '../../util/api';

export const authApi = createApi({
  reducerPath: 'AuthQuery',
  tagTypes: ['Auth'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    me: build.query({
      query: () => ({
        url: '/auth/me',
      }),
    }),
    login: build.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['Auth'],
    }),
    logout: build.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
      // invalidatesTags: ['Auth'],
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useLoginMutation,
  useLogoutMutation,
} = authApi;

import React from 'react';
import {FileExcelOutlined, LoadingOutlined} from "@ant-design/icons";
import {ReportType} from "../../../types/report.type";
import {useDownloadStatisticReport} from "../../../hooks/useDownloadStatisticReport";
import {notification, Spin, Tooltip} from "antd";

interface Props {
  reportType: ReportType
}

const DownloadExcelReportButton = ({reportType}: Props) => {
  const {downloadStatisticReport: downloadExcelReport, isLoading} = useDownloadStatisticReport();

  const handleClick = (reportType: ReportType) => {
    downloadExcelReport(reportType);

    notification.warning({
      message: "Формирование отчетов может занять несколько минут",
      duration: 5,
    });
  }

  return (
    <>
      {isLoading
        ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />)
          :  (
        <div className="cursor-pointer min-h-[24px]" onClick={() => handleClick(reportType)}>
          <Tooltip title={'Скачать отчет'}>
            <FileExcelOutlined />
          </Tooltip>
        </div>
        )
      }
      </>
  );
};

export default DownloadExcelReportButton;
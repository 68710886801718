import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Area } from '../../types/area.type';

const initialState = {
  areas: null as Area[] | null,
};

type InitialStateType = typeof initialState;

const areaSlice = createSlice({
  name: 'areaSlice',
  initialState,
  reducers: {
    setAreas: (
      state: InitialStateType,
      action: PayloadAction<Area[] | null >,
    ) => {
      state.areas = action.payload;
    },
  },
});

export const { setAreas } = areaSlice.actions;
export default areaSlice.reducer;

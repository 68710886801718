import React from "react";
import { Placemark } from '@pbe/react-yandex-maps';

import { Coordinate } from "../../types/common.type";
import { MARKER_ICON_SIZE, MARKER_ICON_OFFSET } from "../../util/constants";

type NewInfoMarkerProps = {
  coords: Coordinate;
};


export default function NewInfoMarker({ coords }: NewInfoMarkerProps) {

  return (
    <Placemark
      geometry={coords}
      options={{
        iconLayout: 'default#image',
        iconImageHref: '/images/location_point_blue.svg',
        iconImageSize: MARKER_ICON_SIZE,
        iconImageOffset: MARKER_ICON_OFFSET,
        openBalloonOnClick: false,
      }}
      properties={{
        balloonContent: `<div id='newInfoMarker' class='balloon'></div>`
      }}
    />
  )
}

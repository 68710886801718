import React, { useEffect, useRef } from "react";
import { Form, Input, Select, Modal, notification, InputRef } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../redux/api/user.api";
import { useGetFarmsQuery } from "../../redux/api/farm.api";
// import { useAppSelector } from '../../redux/hooks';
import { useGetRegionsQuery } from "../../redux/api/region.api";
import { Farm } from "../../types/farm.type";
import { Region } from "../../types/region.type";
import { Role, RoleNames, User } from "../../types/auth.type";
import { MaskedInput } from "antd-mask-input";

interface UserFormProps {
  open: boolean;
  onCancel: () => void;
  user: User | null;
}

const UserForm: React.FC<UserFormProps> = ({ open, onCancel, user }) => {
  const inputRef = useRef<InputRef>(null);
  // const user = useAppSelector((state) => state.userReducer.user);
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const { data: regionsData, isFetching: isRegionsLoading } =
    useGetRegionsQuery({});
  const [form] = Form.useForm();
  const roleValue = Form.useWatch("role", form);
  const [createUser, { isLoading: isCreateUserLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: isUpdateUserLoading }] =
    useUpdateUserMutation();
  const regExp = /[-,(,),_,\s]/gi;

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
      form.setFieldValue("password", null);
    } else {
      form.setFieldsValue({
        name: null,
        email: null,
        password: null,
        phone: null,
        region_id: null,
        farm_id: null,
      });
      form.resetFields();
    }
  }, [user, form]);
  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
    inputRef.current.blur();
  }, []);
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const submitForm = () => {
    console.log(form.getFieldValue('phone'))
    form
      .validateFields()
      .then((values) => {
        if (/^\+_{3}\s\(_{2}\)\s_{3}-_{2}-_{2}$/.test(values.phone))
          values.phone = "";
        const params = {
          body: {
            ...values,
            phone: values.phone === "" ? "" : values.phone?.replace(regExp, ""),
          },
          pk: user?.id,
        };
        if (user) {
          updateUser(params)
            .unwrap()
            .then((payload) => {
              if (payload.success) {
                handleCancel();
                notification.success({
                  message: "Пользователь обновлён",
                });
              }
            })
            .catch((error) => {
              notification.error({
                message: "Ошибка обновления пользователя",
                description:
                  error.data?.error || "Проверьте данные или попробуйте позже",
              });
            });
        } else {
          createUser({ ...values, phone: values.phone?.replace(regExp, "") })
            .unwrap()
            .then((payload) => {
              if (payload.success) {
                handleCancel();
                notification.success({
                  message: "Пользователь создан",
                });
              }
            })
            .catch((error) => {
              notification.error({
                message: "Ошибка создания пользователя",
                description:
                  error.data?.error || "Проверьте данные или попробуйте позже",
              });
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const onFocusField = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (e.target.hasAttribute("readonly")) e.target.removeAttribute("readonly");
  };

  return (
    <Modal
      open={open}
      title={user ? `Пользователь ${user.name}` : "Новый пользователь"}
      okText={user ? "Сохранить" : "Создать"}
      cancelText="Закрыть"
      destroyOnClose={true}
      okButtonProps={{ disabled: isCreateUserLoading || isUpdateUserLoading }}
      onCancel={handleCancel}
      centered
      onOk={submitForm}
    >
      <div className="h-[610px] mt-8 overflow-auto">
        {isCreateUserLoading || isUpdateUserLoading ? (
          <div className="h-full flex justify-center items-center">
            <LoadingOutlined style={{ fontSize: "30px", color: "#6b7280" }} />
          </div>
        ) : (
          <Form form={form} layout="vertical" autoComplete="off">
            <Form.Item
              name="name"
              label="Имя"
              rules={[{ required: true, message: "Пожалуйста, введите имя!" }]}
            >
              <Input placeholder="Введите имя" />
            </Form.Item>
            <Form.Item
              name="surname"
              label="Фамилия"
              rules={[
                { required: true, message: "Пожалуйста, введите фамилию!" },
              ]}
            >
              <Input placeholder="Введите фамилию" />
            </Form.Item>
            <Form.Item
              name="middle_name"
              label="Отчество"
              // rules={[
              //   { required: !user, message: "Пожалуйста, введите отчество!" },
              // ]}
            >
              <Input ref={inputRef} placeholder="Введите отчество" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Телефон"
              initialValue={user?.phone}
              rules={[
                {
                  required: false,
                },
                {
                  pattern:
                    /(\+\d{3}\s\(\d{2}\)\s\d{3}-\d{2}-\d{2})|(\+\d{12})/,
                  message: "Пожалуйста, введите правильный номер телефона!",
                },
              ]}
            >
              <MaskedInput ref={inputRef} mask={"+000 (00) 000-00-00"} />
            </Form.Item>
            {!user && (
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Пожалуйста, введите email!" },
                  {
                    type: "email",
                    message: "Пожалуйста, введите правильный email!",
                  },
                ]}
              >
                <Input readOnly onFocus={onFocusField} />
              </Form.Item>
            )}
            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                { required: !user, message: "Пожалуйста, введите пароль!" },
              ]}
            >
              <Input.Password readOnly onFocus={onFocusField} />
            </Form.Item>
            <Form.Item
              name="role"
              label="Роль"
              rules={[{ required: true, message: 'Пожалуйста, выберите роль!' }]}
            >
              <Select
                placeholder="Выберите роль"
                options={[
                  { value: Role.ADMIN, label: RoleNames.ADMIN },
                  { value: Role.REGION, label: RoleNames.REGION },
                  { value: Role.FARM, label: RoleNames.FARM },
                  { value: Role.OPERATOR, label: RoleNames.OPERATOR },
                ]}
              />
            </Form.Item>
            {(roleValue === Role.REGION || roleValue === Role.OPERATOR) && (
              <Form.Item
                name="region_id"
                label="Район"
                initialValue={1}
                rules={[{ required: true, message: 'Пожалуйста, выберите район!' }]}
              >
                <Select
                  placeholder="Выберите район"
                  loading={isRegionsLoading}
                  options={regionsData?.data.map((region: Region) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              </Form.Item>
            )}
            {(roleValue === Role.FARM || roleValue === Role.OPERATOR) && (
              <Form.Item
                name="farm_id"
                label="Хозяйство"
                initialValue={1}
                rules={[{ required: true, message: "Пожалуйста, выберите хозяйство!"}]}
              >
                <Select
                  placeholder="Выберите хозяйство"
                  loading={isFarmsLoading}
                  options={farmsData?.data.map((farm: Farm) => {
                    return { value: farm.id, label: farm.name };
                  })}
                />
              </Form.Item>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default UserForm;

import React, {useEffect} from 'react';
import {Button} from "antd";
import clsx from "clsx";
import {ReportType} from "../../../types/report.type";
import {useDeleteReportsTools} from "../../../hooks/useDeleteReportsTools";
import {useAppDispatch} from "../../../redux/hooks";
import {setDefaultReportTools} from "../../../redux/slices/commonReportsSlice";

interface Props {
  className?: string;
}

const ResetAllToolsButton = ({className}: Props) => {
  const dispatch = useAppDispatch();

  const { handleResetTools, isSuccess } = useDeleteReportsTools()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDefaultReportTools())
    }
  }, [isSuccess])

  return (
    <Button danger className={clsx(className)} onClick={() => handleResetTools(ReportType.ALL)}>
      Сбросить все настройки
    </Button>
  );
};

export default ResetAllToolsButton;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const divisionsApi = createApi({
  reducerPath: 'divisionsQuery',
  tagTypes: ['Divisions'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getDivisions: build.query({
      query: (params) => ({
        url: '/divisions/',
        params: params,
      }),
      providesTags: [{ type: 'Divisions' }],
    }),
    createDivision: build.mutation({
      query: (data) => ({
        url: '/divisions/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Divisions' }],
    }),
    getDivision: build.query({
      query: (pk) => ({
        url: `/divisions/${pk}/`,
      }),
    }),
    updateDivision: build.mutation({
      query: (data) => ({
        url: `/divisions/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Divisions' }],
    }),
    deleteDivision: build.mutation({
      query: (pk) => ({
        url: `/divisions/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Divisions' }],
    }),
  }),
});

export const {
  useGetDivisionsQuery,
  useCreateDivisionMutation,
  useDeleteDivisionMutation,
  useLazyGetDivisionQuery,
  useLazyGetDivisionsQuery,
  useUpdateDivisionMutation,
} = divisionsApi;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const trackerApi = createApi({
  reducerPath: 'TrackerQuery',
  // tagTypes: ['Tracker'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getTrackers: build.mutation({
      query: (data) => ({
        url: '/trackers/search/',
        method: 'POST',
        body: data,
      }),
      // providesTags: [{ type: 'Tracker' }],
    }),
    createTracker: build.mutation({
      query: (data) => ({
        url: '/trackers/',
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: [{ type: 'Tracker' }],
    }),
    getTracker: build.query({
      query: (pk) => ({
        url: `/trackers/${pk}/`,
      }),
    }),
    updateTracker: build.mutation({
      query: (data) => ({
        url: `/trackers/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Tracker' }],
    }),
    deleteTracker: build.mutation({
      query: (pk) => ({
        url: `/trackers/${pk}/`,
        method: 'DELETE',
      }),
      // invalidatesTags: [{ type: 'Tracker' }],
    }),
  }),
});

export const {
  useCreateTrackerMutation,
  useDeleteTrackerMutation,
  useLazyGetTrackerQuery,
  useGetTrackersMutation,
  useUpdateTrackerMutation,
} = trackerApi;

import React, {useState} from 'react';
import {Button, notification} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import ImportantEventModal from "./ImportantEventModal";
import dayjs, {Dayjs} from "dayjs";
import {useCreateImportantEventMutation} from "../../redux/api/multicam.api";
import {DATE_FORMAT, UTC_OFFSET} from "../../util/constants";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface Props {
  event_time: number;
  camera_ids: number[];
  date: Dayjs;
  disabled?: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  eventStartTime: number;
}

const ImportantEventButton = ({event_time, camera_ids, date, setPlaying, disabled, eventStartTime}: Props) => {
  const [createImportantEvent] = useCreateImportantEventMutation()

  const [showModal, setShowModal] = useState(false);

  const min = eventStartTime - (date?.utcOffset(UTC_OFFSET).startOf('date') || dayjs().startOf('date')).unix();

  const convertedEventTime = dayjs.unix(min + event_time).utc().format('HH:mm:ss')
  const buttonClassname = 'text-4xl disabled:text-gray-500 disabled:hover:text-gray-500';

  const addImportantEvent = () => {
    setPlaying(false);
    setShowModal(true);
  };

  const handleOk = () => {
    createImportantEvent({
      camera_ids,
      event_time: dayjs(date.startOf('day').add(min + event_time, 'second'), DATE_FORMAT).format('YYYY-MM-DDTHH:mm:ss'),
    })
      .unwrap()
      .catch((error) => {
      notification.error({
        message: error.data.message || 'не удалось установить "Важное событие"',
      })
    })
      .finally(() => {
        setShowModal(false);
        setPlaying(true);
      })


  }

  const handleCancel = () => {
    setShowModal(false);
    setPlaying(true);
  }

  return (
    <>
      <Button
        type="text"
        className={`${buttonClassname}`}
        onClick={addImportantEvent}
        disabled={disabled}
      >
        <ExclamationCircleOutlined />
      </Button>

      <ImportantEventModal
        open={showModal}
        event_time={convertedEventTime}
        camera_ids={camera_ids}
        onOk={handleOk}
        onCancel={handleCancel}
        date={date}
      />
    </>
  );
};

export default ImportantEventButton;
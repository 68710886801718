import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const feedingApi = createApi({
  reducerPath: 'feedingQuery',
  tagTypes: ['Feeding'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getFeedings: build.query({
      query: (data) => ({
        url: `/feeding_events/get_list/${data.farm_id}/`,
        method: 'GET',
        params: data.params,
      }),
      // providesTags: [{ type: 'Feeding' }],
    }),
    getNewFeedings: build.query({
      query: (data) => ({
        url: `/feeding_events/get_new_events/${data.farm_id}/`,
        method: 'GET',
        params: data.params,
      }),
      // providesTags: [{ type: 'Feeding' }],
    }),
    getFeeding: build.query({
      query: (pk) => ({
        url: `/feeding_events/${pk}/`,
      }),
    }),
    getProcessedFeeding: build.query({
      query: (pk) => ({
        url: `/feeding_events/processed_event/${pk}/`,
      }),
      providesTags: [{ type: 'Feeding' }],
    }),
    getLastFeeding: build.mutation({
      query: (params) => ({
        url: `/feeding_events/last/`,
        method: 'POST',
        params: params,
      }),
      invalidatesTags: [{ type: 'Feeding' }],
    }),
    updateFeedingStatus: build.mutation({
      query: (data) => ({
        url: `/feeding_events/update/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Feeding' }],
    }),
    updateProcessedFeedingStatus: build.mutation({
      query: (data) => ({
        url: `/feeding_events/update_processed/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Feeding' }],
    }),
    updateFullProcessedFeeding: build.mutation({
      query: (data) => ({
        url: `/feeding_events/update_full_event/`,
        method: 'POST',
        params: data.params,
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Feeding' }],
    }),
    createFeeding: build.mutation({
      query: (body) => ({
        url: `/feeding_events/create_by_admin/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [{ type: 'Feeding' }],
    }),
  }),
});

export const {
  useGetFeedingsQuery,
  useLazyGetFeedingsQuery,
  useLazyGetFeedingQuery,
  useUpdateFeedingStatusMutation,
  useGetLastFeedingMutation,
  useLazyGetNewFeedingsQuery,
  useGetProcessedFeedingQuery,
  useUpdateProcessedFeedingStatusMutation,
  useCreateFeedingMutation,
  useUpdateFullProcessedFeedingMutation,
} = feedingApi;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const scheduleApi = createApi({
  reducerPath: 'scheduleQuery',
  tagTypes: ['Schedule'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    // getSchedules: build.query({
    //   query: () => ({
    //     url: '/schedules/',
    //   }),
    //   providesTags: [{ type: 'Schedule' }],
    // }),
    createSchedule: build.mutation({
      query: (data) => ({
        url: `/schedules/${data.aisle_id}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Schedule' }],
    }),
    getSchedule: build.query({
      query: (pk) => ({
        url: `/schedules/${pk}/`,
      }),
    }),
    updateSchedule: build.mutation({
      query: (data) => ({
        url: `/schedules/update/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Schedule' }],
    }),
    deleteSchedule: build.mutation({
      query: (pk) => ({
        url: `/schedules/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Schedule' }],
    }),
  }),
});

export const {
  // useGetSchedulesQuery,
  useCreateScheduleMutation,
  useDeleteScheduleMutation,
  useLazyGetScheduleQuery,
  useUpdateScheduleMutation,
} = scheduleApi;

import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactPlayer from 'react-player/file';
import { OnProgressProps } from "react-player/base";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAppDispatch } from "../../redux/hooks";
import { addLoadedCam } from "../../redux/slices/multicamSlice";
import { ArchiveRecord } from "../../types/multicam";
import { StatusConnection } from "../../types/common.type";

const DAY_SECONDS = 86400;

type PlayerProps = {
  url: string;
  name: string;
  height: string;
  playing: boolean;
  timeToSeek: number;
  currentTime: number;
  archiveRecords?: ArchiveRecord[];
  isToday: boolean;
  statusConnection: StatusConnection;
};

const Player = ({
  url,
  name,
  height,
  playing,
  timeToSeek,
  archiveRecords,
  currentTime,
  isToday,
  statusConnection,
}: PlayerProps) => {
  const dispatch = useAppDispatch();
  
  const ref = useRef<ReactPlayer | null>(null);

  const [localUrl, setLocalUrl] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [nextStartTime, setNextStartTime] = useState<number>(); // Начало следующей записи
  const [nextStopTime, setNextStopTime] = useState<number>(); // Начало следующего разрыва
  const [isPause, setIsPause] = useState(true);
  const [emptyTime, setEmptyTime] = useState(0); // Длительность разрывов для корректировки текущего времени воспроизведения
  const [playbackRate, setPlaybackRate] = useState(1); // Скорость воспроизведения

  useEffect(() => {
    setLocalUrl(isToday ? (statusConnection === StatusConnection.ONLINE ? url : '') : url);
  }, [url]);

  useEffect(() => {
    setLoaded(false);
    setNextStartTime(undefined);
    setNextStopTime(undefined);
    setIsPause(false);
    setEmptyTime(0);
    setPlaybackRate(1);
  }, [localUrl]);

  // Подсчёт длительности разрывов от начального времени воспроизведения до текущей записи в архиве
  const getEmptyTimeInRecord = useCallback((records: ArchiveRecord[], i: number) => {
    let emptyTime = records.slice(0, i).reduce((acc, item, index) => {
      return acc + (
        records[index + 1] ? records[index + 1]?.time_start - 1 - (item.time_start + item.duration) : 0
      );
    }, 0);
    if (records[0].time_start > 0) {
      emptyTime += records[0].time_start - 1;
    }
    setEmptyTime(emptyTime);
    return emptyTime || 0;
  }, []);

  useEffect(() => {
    if (!ref.current || !archiveRecords?.length) return;

    // Находимся в разрыве
    if (nextStartTime) {
      if (currentTime >= nextStartTime) {
        setNextStartTime(undefined);
        setIsPause(false);
      } else {
        setIsPause(true);
        setLoaded(true);
      }

    // Находимся в записи
    } else if (nextStopTime) {      
      if (currentTime > nextStopTime) {
        setNextStopTime(undefined);
        setIsPause(true);
      } else {
        setIsPause(false);
        setLoaded(true);
      }

    // Закончилась запись или разрыв
    } else {
      for (let i = 0; i < archiveRecords.length; i++) {
        const record = archiveRecords[i];
        if (currentTime >= record.time_start && currentTime <= record.time_start + record.duration) {
          getEmptyTimeInRecord(archiveRecords, i);
          setNextStopTime(
            isToday && i === archiveRecords.length - 1 ? (statusConnection === StatusConnection.ONLINE ? DAY_SECONDS : record.time_start + record.duration) : record.time_start + record.duration + 1
          );
          setIsPause(false);
          setLoaded(true);
          break;
        } else {
          if (record.time_start > currentTime) {
            setNextStartTime(record.time_start);
            setIsPause(true);
            break;
          }
        }
      }
    }
  }, [currentTime, nextStartTime, nextStopTime, setIsPause, setNextStartTime, setNextStopTime]);

  useEffect(() => {
    if (!ref.current || !archiveRecords?.length) return;

    setEmptyTime(0);
    setNextStartTime(undefined);
    setNextStopTime(undefined);
    let correctTimeToSeek: number | null = null;
    
    // Ищем запись в архиве для текущего значения общего таймлайна
    for (let i = 0; i < archiveRecords.length; i++) {
      const record = archiveRecords[i];
      if (timeToSeek >= record.time_start && timeToSeek <= record.time_start + record.duration) {
        const emptyTime = getEmptyTimeInRecord(archiveRecords, i);
        setNextStopTime(
          isToday && i === archiveRecords.length - 1 ? (statusConnection === StatusConnection.ONLINE ? DAY_SECONDS : record.time_start + record.duration) : record.time_start + record.duration + 1
        );
        correctTimeToSeek = timeToSeek - emptyTime;
        break;
      } else {
        continue;
      }
    }
    if (correctTimeToSeek !== null) {
      setIsPause(false);

    // Если попали в разрыв записей архива
    } else {
      const lastRecord = archiveRecords[archiveRecords.length - 1];
      setIsPause(!(isToday && statusConnection === StatusConnection.ONLINE && timeToSeek > lastRecord.time_start));
      setLoaded(true);
      const previousRecords = archiveRecords.filter((record) => record.time_start + record.duration < timeToSeek);
      if (previousRecords?.length) {
        let emptyTime = previousRecords.reduce((acc, item, index) => {
          return acc + (
            index === previousRecords.length - 1 ?
              (isToday ? 0 : timeToSeek - (item.time_start + item.duration))
            :
              previousRecords[index + 1].time_start - 1 - (item.time_start + item.duration)
          );
        }, 0);
        if (archiveRecords[0].time_start > 0) {
          emptyTime += archiveRecords[0].time_start - 1;
        }
        setEmptyTime(emptyTime);
        correctTimeToSeek = timeToSeek - emptyTime;
        setNextStartTime(archiveRecords[previousRecords.length]?.time_start);
      } else {
        correctTimeToSeek = 0;
        setNextStartTime(archiveRecords[0].time_start);
      }
    }    
    ref.current.seekTo(correctTimeToSeek as number);
  }, [timeToSeek, setIsPause, setNextStartTime, setEmptyTime]);

  const handleDoubleClick = () => {    
    if (!ref.current) return;
    if (!document.fullscreenElement) {
      ref.current.getInternalPlayer().requestFullscreen()
      .catch((err: any) => {
        notification.error({
          message: "Ошибка полноэкранного режима!",
          description: `${err.message} (${err.name})`,
        });
      });
    } else {
      document.exitFullscreen();
    }
  };
  
  const handleReady = () => {        
    dispatch(addLoadedCam(name));
    // setLoaded(true);
  };

  // Синхронизация воспроизведения с общим секундомером таймлайна за счёт изменения скорости воспроизведения
  const handleProgress = useCallback(({ playedSeconds }: OnProgressProps) => {
    const delta = currentTime - (playedSeconds + emptyTime);
    if (delta > 0.5) {
      setPlaybackRate(delta < 16 ? 1 + delta : 16);
    } else {
      setPlaybackRate(1)
    }
  }, [currentTime, emptyTime]);

  const handleError = (error: any) => {
    // console.log(error)
    setLoaded(false);
    setLocalUrl('');
    setTimeout(() => setLocalUrl(url));
  }

  return (
    <div
      className={`bg-black relative ${height}`}
      onDoubleClick={handleDoubleClick}
    >
      <div className="absolute left-2 px-1 text-gray-200 font-semibold bg-black">{name}</div>
      <div className={`absolute left-[calc(50%-36px)] top-[calc(50%-36px)] ${loaded ? 'hidden' : archiveRecords?.length ? 'visible' : 'hidden'}`}>
        <LoadingOutlined className="text-gray-200 text-7xl" />
      </div>
      <ReactPlayer
        ref={ref}
        url={localUrl}
        config={{
          // forceVideo: true,
          forceHLS: true,
          forceSafariHLS: true,
        }}
        playing={!isPause && playing}
        width="100%"
        height="100%"
        style={{ visibility: `${!isPause && archiveRecords?.length ? 'visible' : 'hidden'}` }}
        // controls
        playbackRate={playbackRate}
        onReady={handleReady}
        onProgress={handleProgress}
        progressInterval={200}
        onBuffer={isPause ? undefined : () => setLoaded(false)}
        onBufferEnd={() => setLoaded(true)}
        onPause={isPause ? undefined : () => setLoaded(false)}
        onPlay={() => setLoaded(true)}
        onError={handleError}
      />
    </div>
  );
};

export default Player;
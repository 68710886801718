import { Coords, StatusConnection, Coordinate } from './common.type';

export type Tracker = {
  id: number;
  en: boolean;
  name: string;
  farm_id: number;
  external_id: string;
  status?: string;
  status_movement?: StatusMovementTracker;
  parking_time?: string;
  time_of_movement?: string;
  time_of_disconnect?: string;
  status_connection?: StatusConnection;
  coords?: Coords;
  tracker_info: TrackerInfo;
  type_tracker: TypeTracker;
  ctime: string;
  atime?: string;
  dtime?: string;
  is_not_breakdown?: true;
  breakdown_description?: string;
  breakdown_datetime?: string
  division_id?: number;
  division_name?: string;
};

export enum TypeTracker {
  harvester = 1,
  maz = 2,
  tractor_powerful = 3,
  tractor = 4,
};

export enum TypeTrackerNames {
  harvester = 'Комбайн',
  maz = 'Самосвал МАЗ',
  tractor_powerful = 'Энергонасыщенный трактор',
  tractor = 'Трактор',
};

export enum StatusMovementTracker {
  PARKING = 0,
  TRIP = 1,
  STOP = 2,
};

export type TrackerInfo = {
  coordinates?: Coordinate;
  engine_hours?: number;
  last_position_time?: number;
  mileage?: number;
  phone?: string;
  speed?: number;
  tracker_device_uid?: string;
  tracker_hw_name?: string;
  tracker_polyline?: string;
  direction?: number;
  time_of_movement?: string;
  time_of_disconnect?: string;
};

import React, {useEffect, useRef, useState} from 'react';
import {CloseOutlined, DownOutlined, LoadingOutlined} from "@ant-design/icons";
import {Tree, TreeDataNode} from "antd";
import {FeedingEventStatus, MulticamEvent} from "../../types/multicam";
import dayjs from "dayjs";
import {DirectoryTreeProps} from "antd/es/tree";
import {getActiveMulticamEvent,} from "../../redux/slices/multicamSlice";
import {useAppSelector} from "../../redux/hooks";
import {EVENT_COLORS} from '../../util/constants';


const EVENT_DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

interface Props {
  showEventBox: boolean;
  setShowEventBox: React.Dispatch<React.SetStateAction<boolean>>;
  events?: MulticamEvent[];
  eventsLoading: boolean;
  goToTime: (value: number) => void;
  setIndexOfSelectedEvent: (uuid: string) => void;
}

const EventBox = ({
  showEventBox,
  setShowEventBox,
  events,
  eventsLoading,
  setIndexOfSelectedEvent,
}: Props) => {
  const treeRef = useRef(null);
  const activeEvent = useAppSelector(getActiveMulticamEvent);

  const eventsByType: Record<string, MulticamEvent[]>  = {};
  const eventsByUuid: Record<string, MulticamEvent> = {};

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  
  useEffect(() => {
    if (activeEvent) {
      if (showEventBox) {
        // const element = document.getElementById(activeEvent.uuid_event);

        // element?.scrollIntoView({behavior: 'smooth'});
        // @ts-ignore
        treeRef.current?.scrollTo({ key: activeEvent.uuid_event, align: 'auto' })
      }

      return setSelectedKeys([activeEvent.uuid_event]);
    }

    setSelectedKeys([])
  }, [activeEvent, showEventBox]);

  // useEffect(() => {
  //   if (!showEventBox) {
  //     setSelectedKeys([]);
  //   }
  // }, [showEventBox]);

  events?.forEach(event => {
    eventsByUuid[event.uuid_event] = event;

    if (event.events?.length) {
      event.events.forEach(subEvent => {
        eventsByUuid[subEvent.uuid_event] = subEvent;
      })
    }

    if (eventsByType[event.type]) {
      eventsByType[event.type].push(event);
    } else {
      eventsByType[event.type] = [event];
    }
  })

  const renderEventDates = (event: MulticamEvent) => {
    return <p className={'text-xs'}>{dayjs(event.event_start).format(EVENT_DATE_FORMAT)} {event.event_end && `- ${dayjs(event.event_end).format(EVENT_DATE_FORMAT)}}`}</p>
  }

  const renderEvent = (event: MulticamEvent) => {
    const feedingEventStatus = FeedingEventStatus[event.status];

    return (
      <div
        id={event.uuid_event}
        className={`py-1`}
        style={{color: EVENT_COLORS[event.type]}}
      >
        <div className={'flex gap-2'}>
          <span className={'text-xs'} style={{color: EVENT_COLORS[event.type]}}>{event.type}</span>
          <span
            className={'text-xs'}
          >
            ({event.building_name} {event.aisle_name && <span className={'text-sm'}>{event.aisle_name}</span>} {event.camera_name && <span className={'text-xs'}>{event.camera_name}</span>})
          </span>
        </div>

        {renderEventDates(event)}

        <p style={{color: EVENT_COLORS['Статут события']}}>{feedingEventStatus}</p>
      </div>
    )
  }

  useEffect(() => {
    if (!events) return;
    const treeData: TreeDataNode[] = [];
    events?.forEach((event) => {
      treeData.push( {
        title: renderEvent(event),
        key: event.uuid_event,
        children: event.events?.map(subEvent => {
          return {
            title: renderEvent(subEvent),
            key: subEvent.uuid_event,
          }
        })
      });
    });
    setTreeData(treeData);
    setExpandedKeys(events?.map((event) => event.uuid_event))
  }, [events]);

  const handleSelectEvent: DirectoryTreeProps['onSelect'] = (selectedKeys) => {
    const currentEvent = eventsByUuid[selectedKeys[0]] ?? activeEvent;

    setIndexOfSelectedEvent(currentEvent.uuid_event);
  }

  return (
    <div className={`${showEventBox ? 'mr-0' : 'mr-[-1000px]'} relative  w-[280px] min-w-[280px] [transition:width.2s_.1s,opacity.2s_.1s,margin.2s_0.1s] h-[calc(100vh-240px)] flex flex-col overflow-hidden`}>
      <div className={`flex justify-between px-4 py-2 text-sm text-gray-200 bg-black`}>
        <div>События</div>

        <div className="text-end cursor-pointer">
          <CloseOutlined className="ml-4" onClick={() => setShowEventBox(false)} />
        </div>
      </div>

      <div className={'multicam_events_container bg-black grow pt-2'}>
        {eventsLoading ?
          <div className="flex flex-col justify-center items-center h-full">
            <LoadingOutlined className="text-5xl text-gray-200" />
          </div>
        :
          <Tree
            key={`${events?.length}`}
            ref={treeRef}
            height={660}
            className={'multicam_events_tree bg-black text-gray-200'}
            switcherIcon={<DownOutlined />}
            treeData={treeData}
            selectedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            onSelect={handleSelectEvent}
            defaultExpandAll
          />
        }
      </div>
    </div>
  );
};

export default EventBox;
import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, InputNumber, Select, Button, Modal, Card, Space, notification } from 'antd';
import { EnvironmentOutlined, CloseOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';

import CamerasMap from './camerasMap';
import { useCreateBuildingMutation } from '../../redux/api/building.api';
// import { useGetFarmsQuery } from '../../redux/api/farm.api';
import { useGetDivisionsQuery } from '../../redux/api/divisions.api';
// import { Farm } from '../../types/farm.type';
import { Camera, NewCamera } from '../../types/camera.type';
import { Aisle, NewAisle } from '../../types/aisle.type';
import { Grazing, NewGrazing } from '../../types/grazing.type';
import { Division } from '../../types/division.type';

interface BuildingFormProps {
  open: boolean;
  onCancel: () => void;
}


const CreateBuildingForm: React.FC<BuildingFormProps> = ({
  open,
  onCancel,
}) => {

  // const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [createBuilding, { isLoading: isCreateBuildingLoading }] = useCreateBuildingMutation();  
  const [currentAisleAlias, setCurrentAisleAlias] = useState<number>();
  const [currentGrazingAlias, setCurrentGrazingAlias] = useState<number>();
  const [openMap, setOpenMap] = useState(false);
  const [singleCameras, setSingleCameras] = useState<NewCamera[]>([]);
  const [form] = Form.useForm();
  // const farmIdValue = Form.useWatch('farm_id', form);
  const aislesValue = Form.useWatch('aisles', form);
  const grazingsValue = Form.useWatch('grazings', form);
  const newCamerasValue = Form.useWatch('new_cameras', form);
  const { data: divisionsData, isFetching: isDivisionsLoading } = useGetDivisionsQuery(null);

  useEffect(() => {
    if (aislesValue?.[0]) {
      const lastAisle = aislesValue[aislesValue.length - 1];
      if (lastAisle && !lastAisle.uuid) {
        lastAisle.uuid = v4();
      }
    }
  }, [aislesValue]);

  useEffect(() => {
    if (grazingsValue?.[0]) {
      const lastGrazing = grazingsValue[grazingsValue.length - 1];
      if (lastGrazing && !lastGrazing.uuid) {
        lastGrazing.uuid = v4();
      }
    }
  }, [grazingsValue]);

  const onSetCameras = (cameras: Array<NewCamera | Camera>) => {    
    form.setFieldValue('new_cameras', cameras);
    const allCamerasUuids = cameras?.map(camera => camera.uuid);
    if (aislesValue) {
      aislesValue?.forEach((item: NewAisle) => {
        if (item.cameras_uuids) {
          for (let uuid of item.cameras_uuids) {
            if (!allCamerasUuids.includes(uuid)) {
              const index = item.cameras_uuids.indexOf(uuid);
              item.cameras_uuids.splice(index, 1);
              break;
            }
          }
        }
      });
    }
    if (grazingsValue) {
      for (let item of grazingsValue) {
        if (item.camera_uuid && !allCamerasUuids.includes(item.camera_uuid)) {
          item.camera_uuid = undefined;
          break;
        }
      }
    }
    // form.validateFields(['new_cameras']);
    setOpenMap(false);
    getNotSelectedCameras();
  };

  const handleCancelForm = () => {
    form.setFieldsValue({
      name: '',
      // farm_id: 1,
      division_id: null,
      aisles: null,
      grazings: null,
      new_cameras: null,
    });
    onCancel();
    setOpenMap(false);
    setSingleCameras([]);
  };

  // const checkNotSelectedCamera = () => {
  //   let result = false;
  //   const allCamerasUuids = newCamerasValue?.map((camera: NewCamera) => camera.uuid);
  //   const allAislesCamerasUuids: string[] = [];
  //   aislesValue?.forEach((item: NewAisle) => {
  //     if (item.cameras_uuids) {
  //       item.cameras_uuids.forEach((uuid) => {
  //         allAislesCamerasUuids.push(uuid);
  //       });
  //     }
  //   });
  //   const allGrazingCamerasUuids = grazingsValue?.map((item: NewGrazing) => item.camera_uuid);
  //   if (allCamerasUuids && allCamerasUuids.length) {
  //     for (let uuid of allCamerasUuids) {
  //       if (!allAislesCamerasUuids?.includes(uuid) && !allGrazingCamerasUuids?.includes(uuid)) {
  //         result = true;
  //         break;
  //       }
  //     }
  //   }
  //   return result;
  // };

  const getNotSelectedCameras = useCallback(() => {
    let notSelectedCameras: NewCamera[] = [];
    const allAislesCamerasUuids: string[] = [];
    aislesValue?.forEach((item: NewAisle) => {
      if (item?.cameras_uuids) {
        item.cameras_uuids.forEach((uuid) => {
          allAislesCamerasUuids.push(uuid);
        });
      }
    });
    const allGrazingCamerasUuids = grazingsValue?.map((item: NewGrazing) => item?.camera_uuid);
    notSelectedCameras = newCamerasValue?.filter((newCamera: NewCamera) => (
      !allAislesCamerasUuids?.includes(newCamera.uuid) && !allGrazingCamerasUuids?.includes(newCamera.uuid)
    ));
    setSingleCameras(notSelectedCameras);
  }, [newCamerasValue, aislesValue, grazingsValue]);

  useEffect(() => {
    getNotSelectedCameras();
  }, [getNotSelectedCameras]);

  const submitForm = () => {
    form
    .validateFields()
    .then((values) => {
      // if (checkNotSelectedCamera()) {
      //   notification.error({ 
      //     message: 'Остались не выбранные камеры!',
      //     description: 'Не все камеры распределены по проходам и выпасам.',
      //   });
      // } else {        
        const params = {
          name: values.name,
          // farm_id: values.farm_id,
          division_id: values.division_id,
          aisles: values.aisles || [],
          grazings: values.grazings || [],
          single_cameras: singleCameras,
        };
        params.aisles.forEach((aisle: NewAisle) => {
          const cameras = aisle?.cameras_uuids?.map((uuid, index) => {
            const camera: NewCamera = newCamerasValue?.find((camera: NewCamera) => camera.uuid === uuid);
            if (index === 0) {
              camera.is_enter = true;
              camera.is_exit = false;
            } else if (aisle?.cameras_uuids && index === aisle?.cameras_uuids.length - 1) {
              camera.is_enter = false;
              camera.is_exit = true;
            } else {
              camera.is_enter = false;
              camera.is_exit = false;
            }
            return camera;
          }) as NewCamera[];
          aisle.cameras = cameras;
        });
        params.grazings.forEach((grazing: NewGrazing) => {
          const camera: NewCamera = newCamerasValue?.find((camera: NewCamera) => camera.uuid === grazing.camera_uuid);
          grazing.camera = camera;
        });
        
        createBuilding(params).unwrap()
        .then((payload) => {
          if (payload.success) {
            handleCancelForm();
            notification.success({ 
              message: 'Строение создано',
            });
          }
        })
        .catch((error) => {
          notification.error({ 
            message: 'Ошибка создания строения',
            description: error.data?.error || 'Проверьте данные или попробуйте позже',
          });
        });
      // }
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  const handleClickOpenMap = () => {
    setOpenMap(true);
    setCurrentAisleAlias(undefined);
  };

  const onChangeAisleIndex = (value: number | null, currentIndex: number) => {
    if (value) {
      const aisles = structuredClone(aislesValue);
      const dubleIndex = aislesValue.findIndex((aisle: NewAisle, index: number) => {
        if (aisle.alias === value && index !== currentIndex) return true;
        return false;
      });
      if (dubleIndex !== -1) {
        aisles[dubleIndex].alias = aislesValue[currentIndex].alias || currentAisleAlias;
      }
      aisles[currentIndex].alias = value;
      form.setFieldsValue({ aisles });
    } else {
      setCurrentAisleAlias(aislesValue[currentIndex].alias);
    }
  };

  const onChangeGrazingIndex = (value: number | null, currentIndex: number) => {
    if (value) {
      const grazings = structuredClone(grazingsValue);
      const dubleIndex = grazingsValue.findIndex((grazing: NewGrazing) => {
        if (grazing.alias === value) return true;
        return false;
      });
      if (dubleIndex !== -1) {
        grazings[dubleIndex].alias = grazingsValue[currentIndex].alias || currentGrazingAlias;
      }
      grazings[currentIndex].alias = value;
      form.setFieldsValue({ grazings });
    } else {
      setCurrentGrazingAlias(grazingsValue[currentIndex].alias);
    }
  };

  const getCameraOptionDisabled = (uuid: string | number, targetUuid: string) => {
    let result = false;
    if (aislesValue) {
      for (let aisle of aislesValue) {
        if (aisle?.uuid !== targetUuid && aisle?.cameras_uuids?.includes(uuid)) {
          result = true;
          break;
        }
      }
    }
    if (grazingsValue) {
      for (let grazing of grazingsValue) {
        if (grazing?.uuid !== targetUuid && grazing?.camera_uuid === uuid) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  const getInitialAisleAliasValue = () => {
    if (aislesValue) {
      let aliases = aislesValue?.map((item: Aisle | NewAisle) => item?.alias).sort((a: number, b: number) => b - a);    
      return aliases?.[0] ? aliases[0] + 1 : 1;
    }
  };

  const getInitialGrazingAliasValue = () => {      
    let aliases = grazingsValue?.map((item: Grazing | NewGrazing) => item?.alias).sort((a: number, b: number) => b - a);    
    return aliases?.[0] ? aliases[0] + 1 : 1;
  };

  return (
    <Modal
      open={open}
      title="Новое строение"
      okText="Создать"
      cancelText="Закрыть"
      destroyOnClose={true}
      okButtonProps={{
        disabled: isCreateBuildingLoading,
      }}
      onCancel={handleCancelForm}
      centered
      width={1500}
      onOk={submitForm}
    >
      {isCreateBuildingLoading ?
        <div className="h-[760px] flex justify-center items-center">
          <LoadingOutlined style={{ fontSize: '60px', color: '#6b7280' }} />
        </div>
      :
        <div className="h-[760px]">
          <Form
            form={form}
            layout="vertical"
            className="flex justify-between overflow-hidden"
            autoComplete="off"
          >
            <div className="w-[350px] mt-4">
              <Form.Item
                name="name"
                label="Наименование"
                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
              >
                <Input
                  placeholder="Введите наименование"
                />
              </Form.Item>
              {/* <Form.Item
                name="farm_id"
                label="Хозяйство"
                initialValue={1}
                rules={[{ required: true, message: 'Пожалуйста, выберите хозяйство!' }]}
              >
                <Select
                  placeholder="Выберите хозяйство"
                  loading={isFarmsLoading}
                  options={farmsData?.data.map((farm: Farm) => {
                    return { value: farm.id, label: farm.name };
                  })}
                />
              </Form.Item> */}
              <Form.Item
                name="division_id"
                label="Подразделение"
                rules={[{ required: true, message: 'Пожалуйста, выберите подразделение!' }]}
              >
                <Select
                  allowClear
                  placeholder="Выберите подразделение"
                  loading={isDivisionsLoading}
                  options={divisionsData?.data.map((division: Division) => {
                    return { value: division.id, label: `${division.farm_name} , ${division.name}` };
                  })}
                />
              </Form.Item>
              <Form.Item
                name="new_cameras"
                // rules={[{ required: true, message: 'Пожалуйста, добавьте камеры!' }]}
              >
                <Button
                  type="primary"
                  icon={<EnvironmentOutlined />}
                  className="flex items-center mt-4"
                  onClick={handleClickOpenMap}
                >
                  Отметить камеры на карте
                </Button>
              </Form.Item>
              <Form.Item>
                <div className="mt-2">
                  {singleCameras?.length ? <p>Камеры наблюдения:</p> : null}
                  {singleCameras?.map((camera: NewCamera) => {
                    return (
                      <p key={camera.uuid} className="font-medium">{camera.name}</p>
                    );
                  })}
                </div>
              </Form.Item>
            </div>
            <div className="w-[460px] ml-6">
              <Form.Item
                label="Проходы:"
              >
                <div className=" max-h-[730px] overflow-y-auto">
                  <Form.List
                    name="aisles"
                    // rules={[
                    //   {
                    //     validator: async (_, names) => {
                    //       if (form.getFieldValue('new_cameras')?.length && !names) {
                    //         return Promise.reject(new Error('Пожалуйста, добавьте проход!'));
                    //       }
                    //     },
                    //   },
                    // ]}
                  >
                    {(fields, { remove, add }, { errors }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, aisleIndex) => (
                          <Card
                            size="small"
                            title={`Проход ${aisleIndex + 1}`}
                            key={key}
                            extra={
                              <CloseOutlined
                                className="text-red-500"
                                onClick={() => remove(name)}
                              />
                            }
                            className="mb-4 mr-2 border-gray-300"
                          >
                            <div className={`${!aislesValue?.[aisleIndex]?.cameras_uuids?.length ? 'mb-10' : 'mb-4'}`}>
                              <Form.Item
                                {...restField}
                                name={[name, 'cameras_uuids']}
                                label="Камеры"
                                rules={[
                                  { required: true, message: 'Пожалуйста, выберите камеры!' },
                                  { type: 'array', min: 2, message: 'Пожалуйста, выберите не менее 2 камер!' },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  showSearch={false}
                                  placeholder="Выберите камеры"
                                  options={newCamerasValue?.map((camera: NewCamera) => {
                                    return {
                                      value: camera.uuid,
                                      label: camera.name,
                                      disabled: getCameraOptionDisabled(camera.uuid, aislesValue?.[aisleIndex]?.uuid),
                                    };
                                  })}
                                />
                              </Form.Item>
                              <div className="-mt-4">
                                {aislesValue?.[aisleIndex]?.cameras_uuids
                                  ?.map((cameraUuid: string, cameraUuidIndex: number) => {
                                    return (
                                      <p key={cameraUuidIndex}>
                                        <span>
                                          {cameraUuidIndex ?
                                            cameraUuidIndex === aislesValue?.[aisleIndex]?.cameras_uuids.length - 1 ?
                                              'ВЫХОД: '
                                            :
                                              `ДОПОЛНИТЕЛЬНАЯ КАМЕРА ${cameraUuidIndex}: `
                                          :
                                            'ВХОД: '
                                          }
                                        </span>
                                        <span className="font-medium">
                                          {newCamerasValue?.find((item: NewCamera) => item.uuid === cameraUuid)?.name}
                                        </span>
                                      </p>
                                    );
                                  })
                                }
                              </div>
                            </div>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label="Наименование"
                                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
                              >
                                <Input placeholder="Введите наименование" className="w-[160px]" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'min_time_event']}
                                initialValue={1}
                                label="Мин. время между событиями"
                                rules={[{ required: true, message: 'Пожалуйста, введите мин. время между событиями!' }]}
                              >
                                <InputNumber min={1} addonAfter="сек" />
                              </Form.Item>
                            </Space>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'alias']}
                                initialValue={getInitialAisleAliasValue()}
                                label="Индекс"
                                rules={[{ required: true, message: 'Пожалуйста, введите индекс!' }]}
                              >
                                <InputNumber
                                  min={1}
                                  onChange={(value) => onChangeAisleIndex(value, aisleIndex)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'delta_time']}
                                initialValue={1}
                                label="Макс. время ожидания события"
                                rules={[{ required: true, message: 'Пожалуйста, введите макс. время ожидания события!' }]}
                              >
                                <InputNumber min={1} addonAfter="мин" />
                              </Form.Item>
                            </Space>
                          </Card>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className="flex items-center"
                            disabled={!newCamerasValue?.length}
                          >
                            Добавить проход
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </Form.Item>
            </div>
            <div className="w-[460px] ml-6">
              <Form.Item
                label="Выпасы:"
              >
                <div className="max-h-[730px] overflow-y-auto">
                  <Form.List
                    name="grazings"
                    // rules={[
                    //   {
                    //     validator: async (_, names) => {
                    //       if (form.getFieldValue('new_cameras')?.length && !names) {
                    //         return Promise.reject(new Error('Пожалуйста, добавьте выпас!'));
                    //       }
                    //     },
                    //   },
                    // ]}
                  >
                    {(fields, { remove, add }, { errors }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, grazingIndex) => (
                          <Card
                            size="small"
                            title={`Выпас ${grazingIndex + 1}`}
                            key={key}
                            extra={
                              <CloseOutlined
                                className="text-red-500"
                                onClick={() => remove(name)}
                              />
                            }
                            className="mb-4 mr-2 border-gray-300"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'camera_uuid']}
                              label="Камера"
                              rules={[{ required: true, message: 'Пожалуйста, выберите камеру!' }]}
                            >
                              <Select
                                placeholder="Выберите камеру"
                                allowClear
                                options={newCamerasValue?.map((camera: NewCamera) => {
                                  return {
                                    value: camera.uuid,
                                    label: camera.name,
                                    disabled: getCameraOptionDisabled(camera.uuid, grazingsValue?.[grazingIndex]?.uuid),
                                  };
                                })}
                              />
                            </Form.Item>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label="Наименование"
                                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
                              >
                                <Input placeholder="Введите наименование" className="w-[160px]" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'count_detections']}
                                initialValue={1}
                                label="Предельное число событий"
                                rules={[{ required: true, message: 'Пожалуйста, введите предельное число событий!' }]}
                              >
                                <InputNumber min={1} className="w-full" />
                              </Form.Item>
                            </Space>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'alias']}
                                initialValue={getInitialGrazingAliasValue()}
                                label="Индекс"
                                rules={[{ required: true, message: 'Пожалуйста, введите индекс!' }]}
                              >
                                <InputNumber
                                  min={1}
                                  onChange={(value) => onChangeGrazingIndex(value, grazingIndex)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'delta_time']}
                                initialValue={1}
                                label="Макс. время ожидания события"
                                rules={[{ required: true, message: 'Пожалуйста, введите макс. время ожидания события!' }]}
                              >
                                <InputNumber min={1} addonAfter="мин" />
                              </Form.Item>
                            </Space>
                          </Card>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className="flex items-center"
                            disabled={!newCamerasValue?.length}
                          >
                            Добавить выпас
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      }
      {openMap &&
        <CamerasMap onSetCameras={onSetCameras} cameras={newCamerasValue} />
      }
    </Modal>
  );
};

export default CreateBuildingForm;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const regionApi = createApi({
  reducerPath: 'RegionQuery',
  tagTypes: ['Region'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getRegions: build.query({
      query: () => ({
        url: `/regions/`,
      }),
      providesTags: [{ type: 'Region' }],
    }),
    createRegion: build.mutation({
      query: (data) => ({
        url: '/regions/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Region' }],
    }),
    getRegion: build.query({
      query: (pk) => ({
        url: `/regions/${pk}/`,
      }),
    }),
    updateRegion: build.mutation({
      query: (data) => ({
        url: `/regions/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Region' }],
    }),
    deleteRegion: build.mutation({
      query: (pk) => ({
        url: `/regions/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Region' }],
    }),
  }),
});

export const {
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useLazyGetRegionQuery,
  useLazyGetRegionsQuery,
  useUpdateRegionMutation,
  useGetRegionsQuery,
} = regionApi;

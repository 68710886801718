import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DetailFeedingEventReportFilters, FeedingEventReportFilters } from '../../types/report.type';

const initialState = {
  farmId: 0,
  mainFilters: null as FeedingEventReportFilters | null,
  detailFilters: null as DetailFeedingEventReportFilters | null,
};

type InitialStateType = typeof initialState;

const feedingReportsSlice = createSlice({
  name: 'feedingReports',
  initialState,
  reducers: {
    setFarmId: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.farmId = action.payload;
    },
    setMainFilters: (
      state: InitialStateType,
      action: PayloadAction<FeedingEventReportFilters | null>,
    ) => {
      state.mainFilters = action.payload;
    },
    setDetailFilters: (
      state: InitialStateType,
      action: PayloadAction<DetailFeedingEventReportFilters | null>,
    ) => {
      state.detailFilters = action.payload;
    },
  },
});

export const {
  setFarmId,
  setMainFilters,
  setDetailFilters,
} = feedingReportsSlice.actions;

export default feedingReportsSlice.reducer;

import React, { Fragment, useState, useEffect } from "react";
import {
  Placemark,
  Clusterer,
  useYMaps,
  Polyline,
} from "@pbe/react-yandex-maps";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Portal } from "../portal/portal";
import CameraItem from "./cameraItem";
import { Camera } from "../../types/camera.type";
import { Coordinate } from "../../types/common.type";
import {
  CLASTER_GRID_SIZE,
  CAMERAS_LINE_STROKE_WIDTH,
  CAMERA_ICON_OFFSET,
  CAMERA_ICON_SIZE,
} from "../../util/constants";
import { setSelectedCamerasIds } from "../../redux/slices/cameraSlice";
import { Aisle } from "../../types/aisle.type";

type CamerasProps = {
  map?: ymaps.Map;
};

export default function Cameras({ map }: CamerasProps) {
  const dispatch = useAppDispatch();
  const cameras = useAppSelector((state) => state.cameraReducer.cameras);
  const selectedCamerasIds = useAppSelector(
    (state) => state.cameraReducer.selectedCamerasIds
  );
  const [prepareCameras, setPrepareCameras] = useState<Camera[]>([]);
  const [aislesLines, setAislesLines] = useState<
    Array<{ first_camera_id: number; coords: Coordinate[] }>
  >([]);
  const [activePortal, setActivePortal] = useState(false);
  const [clusterOptions, setClusterOptions] = useState({});
  const [clusterCameras, setClusterCameras] = useState<Camera[]>([]);
  const ymaps = useYMaps();
  // console.log('cameras', cameras);
  // console.log('clusterOptions =', clusterOptions)
  // console.log('clusterCameras =', clusterOptions)

  useEffect(() => {
    let single_cameras = cameras?.single_cameras || [];
    const aislesCameras: Camera[] = [];
    const aislesLines: Array<{
      first_camera_id: number;
      coords: Coordinate[];
    }> = [];
    cameras?.division_groups.map((division) =>
      division.buildings.map((build) => {
          if (build.single_cameras.length) {
            single_cameras  = single_cameras.concat(build.single_cameras);
          }

          return build.aisles?.forEach((aisle) => {
            if (aisle.cameras && aisle.cameras.length) {
              aisle.cameras.forEach((camera) => {
                aislesCameras.push(camera);
              });
              const firstCamera = aisle.cameras.find(
                (camera) => camera.id === aisle.first_camera_id
              );
              const lastCamera = aisle.cameras.find(
                (camera) => camera.id === aisle.second_camera_id
              );
              aislesLines.push({
                first_camera_id: aisle.first_camera_id,
                coords: [
                  firstCamera?.coords?.coordinates as Coordinate,
                  lastCamera?.coords?.coordinates as Coordinate,
                ],
              });
            }
          })

        }
      )
    );

    setAislesLines(aislesLines);
    const grazingsCameras: Camera[] = [];
    cameras?.division_groups.map((division) =>
      division.buildings.map((build) =>
        build.grazings?.forEach((grazing) => {
          if (grazing.camera) {
            grazingsCameras.push(grazing.camera);
          }
        })
      )
    );

    setPrepareCameras([
      ...aislesCameras,
      ...grazingsCameras,
      ...single_cameras,
    ]);
  }, [cameras]);


  useEffect(() => {
    const balloonLayout = ymaps?.templateLayoutFactory.createClass(
      '<div id=camera-cluster class="balloon"></div>'
    );
    const clusterOptions = {
      preset: "islands#invertedVioletClusterIcons",
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: true,
      hideIconOnBalloonOpen: false,
      gridSize: CLASTER_GRID_SIZE,
      clusterBalloonContentLayout: balloonLayout,
      // maxZoom: 1,
    };
    setClusterOptions(clusterOptions);
  }, [ymaps]);

  const onClickPlacemark = (id: number) => {
    setActivePortal(false);
    setTimeout(() => {
      setActivePortal(true);
    }, 0);
    const singleCamera = cameras?.single_cameras.find(
      (camera) => camera.id === id
    );
    // const divisionCamera = cameras?.division_groups.find(camera => camera.id === id)
    if (singleCamera) {
      dispatch(setSelectedCamerasIds([singleCamera.id]));
      return;
    }
    const grazing = cameras?.division_groups
      .map((division) =>
        division.buildings.map((build) =>
          build.grazings.find((griz) => griz.camera.id === id)
        )
      )
      .flat();
    if (grazing && grazing[0] && grazing[0].id) {
      dispatch(setSelectedCamerasIds([grazing[0].camera.id]));
      return;
    }

    // return dispatch(setSelectedCamerasIds([id]));
  };

  const onClusterBalloonOpen = (e: ymaps.Event) => {
    const cluster = e.get("cluster");
    if (cluster) {
      const clusterPlacemarks = e.get("cluster").getGeoObjects();
      const ids = clusterPlacemarks.map((item: ymaps.Placemark) =>
        item.properties.get("placemarkId", {})
      );
      const clusterCameras = prepareCameras?.filter((item) =>
        ids.includes(item.id)
      );
      setClusterCameras(clusterCameras || []);
      const balloon = document.getElementById("camera-cluster");
      const balloonParent: HTMLElement | null | undefined =
        balloon?.closest("ymaps[id]");
      const balloonLayout: HTMLElement | null | undefined = balloon?.closest(
        ".ymaps-2-1-79-balloon"
      );
      // const balloonContent: HTMLElement | null | undefined = balloon?.closest('.ymaps-2-1-79-balloon__content');
      if (balloonParent) {
        balloonParent.style.height = `${24 * (clusterCameras?.length || 1)}px`;
      }
      // if (balloonContent) {
      //   balloonContent.style.maxHeight = `60px`;
      //   balloonContent.style.overflowY = 'auto';
      // }
      if (balloonLayout) {
        balloonLayout.style.top = `${-(
          24 * (clusterCameras?.length || 1) +
          30
        )}px`;
      }
    }
  };

  const getAisleLineColor = (id: number) => {
    return selectedCamerasIds.includes(id) ? "#325FF3" : "#B91C1C";
  };

  return (
    <>
      {aislesLines.map((line, index) => {
        return (
          <Polyline
            key={index}
            geometry={line.coords}
            options={{
              strokeColor: getAisleLineColor(line.first_camera_id),
              strokeWidth: CAMERAS_LINE_STROKE_WIDTH,
              strokeStyle: "solid",
            }}
          />
        );
      })}
      <Clusterer options={clusterOptions} onBalloonOpen={onClusterBalloonOpen}>
        {prepareCameras?.map((camera) => (
          <Fragment key={camera.id}>
            <Placemark
              geometry={camera.coords?.coordinates}
              options={{
                iconLayout: "default#image",
                iconImageHref: selectedCamerasIds.includes(camera.id)
                  ? "/images/camera_blue.png"
                  : "/images/camera.png",
                iconImageSize: CAMERA_ICON_SIZE,
                iconImageOffset: CAMERA_ICON_OFFSET,
                hideIconOnBalloonOpen: false,
              }}
              properties={{
                type: "camera",
                placemarkId: camera.id,
                balloonContent: `<div id=camera-${camera.id} class='balloon'></div>`,
              }}
              onClick={() => onClickPlacemark(camera.id)}
            />
            {activePortal && (
              <Portal elementId={`camera-${camera.id}`}>
                <CameraItem camera={camera} map={map} />
              </Portal>
            )}
          </Fragment>
        ))}
      </Clusterer>
      {clusterCameras.length ? (
        <Portal elementId="camera-cluster">
          <div>
            {clusterCameras.map((camera) => {
              return <CameraItem key={camera.id} camera={camera} map={map} />;
            })}
          </div>
        </Portal>
      ) : null}
    </>
  );
}

import { useEffect, useState } from "react";
import { Form, Select, Modal, notification, Input } from "antd";
// import { useAppSelector } from "../../redux/hooks";

import {
  // useCreateAreaMutation,
  useUpdateAreaMutation,
} from "../../redux/api/area.api";
import { useGetFarmsQuery } from "../../redux/api/farm.api";
import { Farm } from "../../types/farm.type";
import { Division } from "../../types/division.type";
import { useGetDivisionsQuery } from "../../redux/api/divisions.api";
import { Area } from "../../types/area.type";

interface AreaFormProps {
  open: boolean;
  onCancel: () => void;
  areaEdit: Partial<Area>;
}

const AreaEditForm: React.FC<AreaFormProps> = ({
  open,
  onCancel,
  areaEdit,
}) => {
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [areaUpdate, { isLoading: isUpdateAreaLoading }] =
    useUpdateAreaMutation();
  const [farmId, setFarmId] = useState(areaEdit.farm_id);
  const { data: divisionsData, isFetching: isDivisionsLoading } =
    useGetDivisionsQuery({ farm_id: farmId });
  const [form] = Form.useForm();
  // const [area, setArea] = useState(areaEdit);
  const handleCancel = () => {
    // form.resetFields();
    onCancel();
  };
  const submitForm = () => {
    form.validateFields().then((values) => {
      areaUpdate({
        pk: areaEdit.id,
        body: values.division_id
          ? { division_id: values.division_id, farm_id: values.farm_id }
          : { remove_division: areaEdit.division_id, farm_id: values.farm_id },
      })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            handleCancel();
            notification.success({
              message: "Поля созданы/обновлены",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error.data?.error || "Ошибка создания/обновления полей",
          });
        });
    });
  };
  useEffect(() => {
    if (areaEdit) {
      form.setFieldsValue(areaEdit);
    }
  }, [areaEdit, form]);

  return (
    <Modal
      open={open}
      title={areaEdit.name}
      okText={"Сохранить"}
      cancelText="Закрыть"
      //   destroyOnClose={true}
      confirmLoading={isUpdateAreaLoading}
      onCancel={handleCancel}
      centered
      onOk={submitForm}
    >
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        className="mt-8"
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Наименование"
          // rules={[{ required: true, message: "Пожалуйста, выберите район!" }]}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          label="Хозяйство"
          name="farm_id"
          // rules={[{ required: true, message: "Пожалуйста, выберите файл!" }]}
        >
          <Select
            // style={{ width: 210 }}
            placeholder="Выберите хозяйство"
            loading={isFarmsLoading}
            onChange={(value) => setFarmId(value)}
            options={farmsData?.data.map((farm: Farm) => {
              return { value: farm.id, label: farm.name };
            })}
          />
        </Form.Item>
        <Form.Item label="Подразделение" name="division_id">
          <Select
            allowClear
            // style={{ width: 210 }}
            placeholder="Выберите подразделение"
            loading={isDivisionsLoading}
            options={divisionsData?.data.map((division: Division) => {
              return { value: division.id, label: division.name };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AreaEditForm;

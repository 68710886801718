import React from "react";
import { useLocation, Link } from "react-router-dom";
import { LogoutOutlined, ControlOutlined, HomeOutlined, CloseOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useLogoutMutation } from '../../redux/api/auth.api';
import { setUser } from "../../redux/slices/authSlice";
import { Role } from "../../types/auth.type";
import { MainRoutes } from "../../util/routes";
import { setFarm, setFarms } from "../../redux/slices/farmSlice";
import { setRegion, setRegions } from "../../redux/slices/regionSlice";
import { setCamera, setCameras } from "../../redux/slices/cameraSlice";
import { setTracker, setTrackers } from "../../redux/slices/trackerSlice";

const Header = () => {
  const dispatch = useAppDispatch();
  const [logout, result] = useLogoutMutation();
  const user = useAppSelector((state) => state.authReducer.user);
  let location = useLocation();

  const logoutHandler = () => {
    if (result.isLoading) return;
    dispatch(setFarm(null));
    dispatch(setFarms(null));
    dispatch(setRegion(null));
    dispatch(setRegions(null));
    dispatch(setCamera(null));
    dispatch(setCameras(null));
    dispatch(setTracker(null));
    dispatch(setTrackers(null));
    logout({}).unwrap()
    .then(() => {
      localStorage.removeItem('agroToken');
      document.cookie = "sid=null";
      dispatch(setUser({ token: null, me: null }));
    })
    .catch((error) => {
      console.log('error', error.message);
    });
  };

  return (
    <div className="h-[40px] px-4 py-1 bg-gray-400 border-b border-gray-500 text-white flex justify-between">
      <div className="flex items-center">
        <img src="/images/logo_white.png" alt="" />
      </div>
      <div className="flex items-center">
        {(location.pathname.includes(MainRoutes.feeding_report) ||
          location.pathname.includes(MainRoutes.feeding_view)) ?
          <div className="cursor-pointer ml-4">
            <CloseOutlined style={{ fontSize: '24px' }} onClick={() => window.close()} />
          </div>
          :
          <>
            <div className="flex justify-between gap-[0.5px]">
              <p className="mr-[5px]">{user?.surname}</p>
              <p className="mr-[5px]">{user?.name}</p>
              <p className="mr-[5px]">{user?.middle_name}</p>
            </div>
            {(location.pathname.includes(MainRoutes.admin) ||
              location.pathname.includes(MainRoutes.statistics) ||
              location.pathname.includes(MainRoutes.feeding)
            ) &&
              <div className="mb-[1.5px] ml-4">
                <Link to={`/`}>
                  <HomeOutlined style={{ fontSize: '24px' }} />
                </Link>
              </div>
            }
            {user?.role === Role.ADMIN && !location.pathname.includes(MainRoutes.admin) &&
              <div className="ml-4">
                <Link to={`/admin`}>
                  <ControlOutlined style={{ fontSize: '24px' }} />
                </Link>
              </div>
            }
            <div className="cursor-pointer ml-4">
              <LogoutOutlined style={{ fontSize: '24px' }} onClick={logoutHandler} />
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Header;
import exp from "constants";

export const START_MAP_ZOOM = 11;
export const MIN_MAP_ZOOM = 4;
export const CAMERAS_MAP_ZOOM = 18;
export const MAX_CAMERAS_MAP_ZOOM = 19;
export const TRACKERS_MAP_ZOOM = 18;
export const FARM_MAP_ZOOM = 12;
export const MAP_TYPE_SELECTOR_POSITION = { left: 10, top: 10 };
export const CLASTER_GRID_SIZE = 32;

export const AREAS_BUTTON_POSITION = { right: 590, bottom: 30 };
export const COPTER_PHOTOS_BUTTON_POSITION = { right: 470, bottom: 30 };
export const FEEDING_BUTTON_POSITION = { right: 430, bottom: 30 };
export const INFO_MARKERS_BUTTON_POSITION = { right: 550, bottom: 30 };
export const DEVLINE_BUTTON_POSITION = { right: 310, bottom: 30 };
export const WIALON_BUTTON_POSITION = { right: 350, bottom: 30 };
export const FEEDING_REPORT_BUTTON_POSITION = { right: 390, bottom: 30 };
export const TOOLS_BUTTON_POSITION = { right: 510, bottom: 30 };
export const MAP_ZOOM_50_M = 17
export const MAP_ZOOM_300_M = 14
export const TOOL_STROKE_WIDTH = 3;
export const AREA_TOOL_FILL_OPACITY = 0.7;

export const AREA_STROKE_WIDTH = 2;
export const AREA_FILL_OPACITY = 0.1;

export const CAMERA_MARKER_ICON_SIZE = [24, 24];
export const CAMERA_MARKER_ICON_OFFSET = [-12, -22];
export const CAMERAS_LINE_STROKE_WIDTH = 2;
export const CAMERA_ICON_SIZE = [24, 24];
export const CAMERA_ICON_OFFSET = [-12, -12];

export const MARKER_ICON_SIZE = [32, 32];
export const MARKER_ICON_OFFSET = [-15, -30];

export const TRACKER_ICON_SIZE = [32, 32];
export const TRACKER_ICON_OFFSET = [-16, -16];
export const TRACKER_ICON_CONTENT_OFFSET = [0, -32];
export const TRACKER_LINE_STROKE_WIDTH = 2;

export const BUILDING_ICON_SIZE = [32, 32];
export const BUILDING_ICON_OFFSET = [-16, -16];
export const BUILDING_CLUSTER_GRID_SIZE = 64;

export const FEEDING_DETAIL_REPORT_PAGE_LIMIT = 20;
export const FEEDING_EXCEL_REPORT_POLLING_INTERVAL = 1000;
export const FEEDING_EXCEL_REPORT_TIMEOUT = 300000;

export const ADMIN_TABLE_SCROLL_HEIGHT = 700;

export const AREAS_TABLE_SCROLL_HEIGHT = 250;
export const FEEDING_TABLE_SCROLL_HEIGHT = 200;
export const FEEDING_DETAIL_TABLE_SCROLL = { x: 1230, y: 500 };
export const TRACKER_VERTICAL_BAR_ITEMS_HEIGHT = 45;
export const BUILDING_BALLOON_CAMERA_ITEM_HEIGHT = 24;

export const UTC_OFFSET = 3 * 60;


export const DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const MIN_DAY_COUNT = 1;
export const MAX_DAY_COUNT = 31;
export const MAX_MONTH_COUNT = 12;
export const MAX_HOURS_COUNT = 24;
export const MIN_TIME_COUNT = 0;
export const MAX_MIN_COUNT = 59;
export const MAX_SEC_COUNT = 59;

export const EVENT_COLORS: Record<string, string> = {
  'NVR': 'rgba(74,222,128,0.8)',
  'Кормление': 'rgba(56,189,248,.8)',
  'Важное событие': 'rgba(248,113,113,.8)',
  // 'На проверке': 'rgba(251,146,60,.8)',
  // 'Подтвержденное событие': 'rgba(163,230,53,.8)',
  // 'Ложное событие': 'rgba(156,163,175,.8)',
  'Статут события': 'rgba(156,163,175,.8)',
}


// 1: 'На проверке';
// 2: 'Подтвержденное событие';
// 3: 'Ложное событие';
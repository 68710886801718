import React, { useEffect, useRef } from "react";
import { Button } from '@pbe/react-yandex-maps';
import ymaps from "yandex-maps";
import { INFO_MARKERS_BUTTON_POSITION } from "../../util/constants";

type InfoMarkersButtonProps = {
  onClick: () => void;
  selected: boolean;
};

export default function InfoMarkersButton({ onClick, selected }: InfoMarkersButtonProps) {
  const ref = useRef<ymaps.control.Button>();

  useEffect(() => {
    if (selected) {
      ref.current?.select();
    } else {
      ref.current?.deselect();
    }
  }, [ref, selected]);
  
  return (
    <Button
      // @ts-ignore
      instanceRef={ref}
      options={{
        selectOnClick: false,
        float: 'none',
        position: INFO_MARKERS_BUTTON_POSITION,
        size: 'small'
      }}
      data={{ image: '/images/location_point.svg', title: 'информационные маркеры' }}
      onClick={onClick}
    />
  )
}

import React from 'react';

import FeedingEventForm from '../../components/feeding/feedingEventForm';


const FeedingEventsList = () => {
  return (
    <div>
      <div className="mt-4 mb-8">
        <FeedingEventForm />
      </div>
    </div>
  );
};

export default FeedingEventsList;

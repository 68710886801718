import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tracker } from '../../types/tracker.type';

const initialState = {
  trackers: null as Tracker[] | null,
  tracker: null as Tracker | null,
  trackLineIds: [] as number[],
};

type InitialStateType = typeof initialState;

const trackerSlice = createSlice({
  name: 'trackerSlice',
  initialState,
  reducers: {
    setTrackers: (
      state: InitialStateType,
      action: PayloadAction<Tracker[] | null >,
    ) => {
      state.trackers = action.payload;
    },
    updateTrackers: (
      state: InitialStateType,
      action: PayloadAction<Tracker[] | null >,
    ) => {
      state.trackers?.forEach((tracker) => {
        const wsTracker = action.payload?.find((item) => item.id === tracker.id);
        if (wsTracker) {
          tracker.status = wsTracker.status;
          tracker.status_connection = wsTracker.status_connection;
          tracker.coords = wsTracker.coords;
          tracker.tracker_info = wsTracker.tracker_info;
        }
      });
      // state.trackers = action.payload;
    },
    setTracker: (
      state: InitialStateType,
      action: PayloadAction<Tracker | null >,
    ) => {
      state.tracker = action.payload;
    },
    addTrackLineId: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.trackLineIds.push(action.payload);
    },
    removeTrackLineId: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.trackLineIds = state.trackLineIds.filter((item) => item !== action.payload);
    },
    setTrackLineIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>,
    ) => {
      state.trackLineIds = action.payload;
    },
  },
});

export const {
  setTrackers,
  setTracker,
  addTrackLineId,
  removeTrackLineId,
  setTrackLineIds,
  updateTrackers,
} = trackerSlice.actions;
export default trackerSlice.reducer;

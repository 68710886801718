import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseUrl} from '../../util/api';
import {BaseResponse} from "../../types/common.type";
import {
  ImportantEventRequestBody,
  MulticamEvent,
  MulticamEventsRequestBody,
  DownloadVideoFragmentData,
  MulticamArchiveRecordsRequestBody,
  MulticamArchiveRecords,
} from "../../types/multicam";
import { downloadFile } from '../../util/helpers';
import { notification } from 'antd';


export const multicamApi = createApi({
  reducerPath: 'MulticamQuery',
  tagTypes: ['Multicam', 'Events'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
  }),
  endpoints: (build) => ({
    createImportantEvent: build.mutation<BaseResponse<unknown>, ImportantEventRequestBody>({
      query: (data) => ({
        url: '/multicam/important_event/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Events']
    }),
    getMulticamEvents: build.query<BaseResponse<MulticamEvent[]>, MulticamEventsRequestBody>({
      query: (data) => ({
        url: '/multicam/events/',
        method: 'POST',
        body: data,
      }),
      providesTags: ['Events']
    }),
    downloadVideoFragment: build.query<BaseResponse<any>, DownloadVideoFragmentData>({
      query: (data) => ({
        url: `/multicam/download/${data.cam_id}`,
        params: data.params,
        responseHandler: async (response) => {
          if (response.ok) {
            downloadFile(response)
          } else {
            notification.error({
              message: 'Ошибка скачивания фрагмента',
              description: response?.statusText || 'Попробуйте позже',
            });
          }
        },
        cache: 'no-cache',
      }),
    }),
    getMulticamArchiveRecords: build.query<BaseResponse<MulticamArchiveRecords[]>, MulticamArchiveRecordsRequestBody>({
      query: (data) => ({
        url: '/multicam/archive_records/',
        method: 'POST',
        body: data,
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useCreateImportantEventMutation,
  useLazyDownloadVideoFragmentQuery,
  useLazyGetMulticamEventsQuery,
  useGetMulticamEventsQuery,
  useGetMulticamArchiveRecordsQuery,
  useLazyGetMulticamArchiveRecordsQuery,
} = multicamApi;

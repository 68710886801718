import React from 'react';
import { Form, Radio, Select, Checkbox, RadioChangeEvent } from 'antd';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  FeedingEventComment,
  FeedingEventStatus,
  FeedingEventTransport,
} from '../../../types/feeding.type';
import { Role } from '../../../types/auth.type';
import { setDetailFilters } from '../../../redux/slices/feedingReportsSlice';


const FeedingDetailFilters = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.authReducer.user);
  const [detailForm] = Form.useForm();
  const allowDetailValue = Form.useWatch('is_extended', detailForm);

  const applyDetailFilters = () => {
    const values = detailForm.getFieldsValue();
    dispatch(setDetailFilters(values));
  };
 
  const handleChangeIsNVR = (e: RadioChangeEvent) => {
    if (e.target.value !== false) {
      detailForm.setFieldsValue(
        { comment: undefined, transport: undefined, is_important: undefined, status: undefined }
      );
    }
    applyDetailFilters();
  };

  const cancelIsNVR = () => {
    detailForm.setFieldsValue({ is_nvr: false });
    applyDetailFilters();
  };

  return (
    <Form
      form={detailForm}
      initialValues={{ is_nvr: false }}
      // onValuesChange={applyDetailFilters}
    >
      <Form.Item name="is_extended" valuePropName="checked" className="flex w-full justify-center">
        <Checkbox onChange={applyDetailFilters}> Расширенный отчёт</Checkbox>
      </Form.Item>
      <Form.Item name="comment">
        <Select
          allowClear
          mode="multiple"
          showSearch={false}
          style={{ width: '270px' }}
          placeholder="Комментарий"
          onChange={cancelIsNVR}
          disabled={!allowDetailValue}
          options={Object.values(FeedingEventComment).map((value) => {
            return { value, label: value };
          })}
        />
      </Form.Item>
      <Form.Item name="transport">
        <Select
          allowClear
          mode="multiple"
          showSearch={false}
          style={{ width: '270px' }}
          placeholder="Транспорт"
          onChange={cancelIsNVR}
          disabled={!allowDetailValue}
          options={Object.values(FeedingEventTransport).map((value) => {
            return { value, label: value };
          })}
        />
      </Form.Item>
      <Form.Item name="is_important">
        <Radio.Group onChange={cancelIsNVR} disabled={!allowDetailValue}>
          <Radio.Button value={true}>Важные</Radio.Button>
          <Radio.Button value={false}>Не важные</Radio.Button>
          <Radio.Button value={undefined}>Все</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {user?.role === Role.ADMIN &&
        <>
          <Form.Item name="status">
            <Radio.Group onChange={cancelIsNVR} disabled={!allowDetailValue}>
              <Radio.Button value={FeedingEventStatus.CONFIRMED}>Кормление</Radio.Button>
              <Radio.Button value={FeedingEventStatus.REJECTED}>Ложная сработка</Radio.Button>
              <Radio.Button value={undefined}>Все</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_nvr">
            <Radio.Group onChange={handleChangeIsNVR} disabled={!allowDetailValue}>
              <Radio.Button value={true}>NVR</Radio.Button>
              <Radio.Button value={false}>Не NVR</Radio.Button>
              <Radio.Button value={undefined}>Все</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </>
      }
    </Form>
  );
};

export default FeedingDetailFilters;

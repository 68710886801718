import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {ArrowLeftOutlined, LoadingOutlined, ToolFilled,} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  notification,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import type {CheckboxChangeEvent} from "antd/es/checkbox";
// import { AREAS_TABLE_SCROLL_HEIGHT } from "../../../util/constants";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  getAllAreas,
  getAllDivisionsIds,
  getAreasForWorkedAreaReportIds,
  getDateFlag,
  getDates,
  getDivisionsForWorkedAreaReportIds,
  getIsDescentForWorkedAreaReport,
  getIsShowDivisionForWorkedAreaReport,
  getIsShowZerosForWorkedAreaReport,
  getIsToolsLoaded,
  getIsWithoutDivisionForWorkedAreaReport,
  setAreasForWorkedAreaReportIds,
  setDivisionsForWorkedAreaReportIds,
  setIsDescentForWorkedAreaReport,
  setIsShowDivisionForWorkedAreaReport,
  setIsShowZerosForWorkedAreaReport,
  setIsWithoutDivisionForWorkedAreaReport,
} from "../../../redux/slices/commonReportsSlice";
import {useGetReportWorkedAreaMutation} from "../../../redux/api/reports.api";
import {
  AreasReportFilters,
  AreasReportRow,
  ReportType,
} from "../../../types/report.type";
import DownloadExcelReportButton from "./downloadExcelReportButton";
import {useSaveToolsReportsMutation} from "../../../redux/api/toolsReports.api";
import {useGetDivisionsQuery} from "../../../redux/api/divisions.api";
import {Division} from "../../../types/division.type";
import DeleteToolsButton from "../toolsButtons/deleteToolsButton";

const { Text } = Typography;

const AreasTable = () => {
  const dispatch = useAppDispatch();
  const { farm_id } = useParams();

  const [isOpenAreasList, setIsOpenAreasList] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  const allAreas = useAppSelector(getAllAreas);
  const areasForWorkedAreaReportIds = useAppSelector(
    getAreasForWorkedAreaReportIds
  );
  const allDivisionsIds = useAppSelector(getAllDivisionsIds);
  const dates = useAppSelector(getDates);
  const date_flag = useAppSelector(getDateFlag);
  const [
    getWorkedArea,
    {
      data: workedAreaData,
      isLoading: workedAreaLoading,
      isError: workedAreaError,
      error: errorGetWorkedArea,
    },
  ] = useGetReportWorkedAreaMutation();

  const [
    saveToolsReports,
    { isError: isSaveReportError, isLoading: isSaveReportLoading },
  ] = useSaveToolsReportsMutation();
  const { data: divisions, isLoading: isDivisionsLoading } =
    useGetDivisionsQuery({ farm_id: farm_id });

  const isShowZeros = useAppSelector(
    getIsShowZerosForWorkedAreaReport
  );
  const isShowDivisions = useAppSelector(
    getIsShowDivisionForWorkedAreaReport
  );
  const isShowWithoutDivision = useAppSelector(
    getIsWithoutDivisionForWorkedAreaReport
  );
  const isDescentForWorkedAreaReport = useAppSelector(
    getIsDescentForWorkedAreaReport
  );
  const selectedAreasIds = useAppSelector(
    getAreasForWorkedAreaReportIds
  );
  const selectedDivisionsIds = useAppSelector(getDivisionsForWorkedAreaReportIds);
  const isToolsLoaded = useAppSelector(getIsToolsLoaded);

  const sortingOrder = isDescentForWorkedAreaReport ? 'desc' : 'asc';

  const getWorkedAreaReport = () => {
    const params: { farm_id: string; body: AreasReportFilters } = {
      farm_id: farm_id as string,
      body: {
        date_flag,
        area_ids: areasForWorkedAreaReportIds,
        is_show_zeros: isShowZeros,
        is_show_division: isShowDivisions,
        is_without_division: isShowWithoutDivision,
        is_descend: sortingOrder === 'desc',
        division_ids: selectedDivisionsIds,
      },
    };
    if (dates) {
      params.body.date_from = dates[0];
      params.body.date_to = dates[1];
    }
    getWorkedArea(params)
      .then(() => setIsGlobalLoading(false));
  };

  useEffect(() => {
    if (isToolsLoaded) {
      getWorkedAreaReport();
    }
  }, [isToolsLoaded, dates, date_flag]);

  const onChangeSelectedArea = (e: CheckboxChangeEvent) => {
    const value = e.target.value;
    const checked = e.target.checked;

    dispatch(
      setAreasForWorkedAreaReportIds(
        checked
          ? [...selectedAreasIds, value]
          : selectedAreasIds.filter(id => id !== value)
      )
    );
  };

  const onChangeSelectedDivision = (e: CheckboxChangeEvent) => {
    const value = e.target.value;
    const checked = e.target.checked;

    dispatch(
      setDivisionsForWorkedAreaReportIds(
        checked
          ? [...selectedDivisionsIds, value]
          : selectedDivisionsIds.filter(id => id !== value)
      )
    );
  };

  const saveReports = () => {
    saveToolsReports({
      body: {
        worked_area: {
          area_ids: selectedAreasIds,
          is_show_zeros: isShowZeros,
          is_show_division: isShowDivisions,
          is_without_division: isShowWithoutDivision,
          division_ids: selectedDivisionsIds,
          is_descend: sortingOrder === "desc",
        },
        report_type: "worked_area",
      },
    })
      .unwrap()
      .then((payload) => {
        if (payload.success)
          notification.success({ message: "Настройки успешно сохранены" });
      })
      .catch((error) =>
        notification.error({
          message: error.data.message || "не удалось сохранить настройки",
        })
      );
  };

  const onArrowClick = () => {
    setIsOpenAreasList(false);
    getWorkedAreaReport();
  };

  const handleShowWithoutDivisions = (event: CheckboxChangeEvent) => {
    dispatch(setIsWithoutDivisionForWorkedAreaReport(event.target.checked))
  }

  const handleChangeShowDivisions = (event: CheckboxChangeEvent) => {
    dispatch(setIsShowDivisionForWorkedAreaReport(event.target.checked));
  }

  const handleChangeShowZeros = (event: CheckboxChangeEvent) => {
    dispatch(setIsShowZerosForWorkedAreaReport(event.target.checked));
  }

  const renderTableRow = (row: AreasReportRow) => {
    return (
      <div
        key={row.id}
        className={
          "grid grid-cols-[80%,20%] border-b border-[#f0f0f0] p-2 hover:bg-[#fafafa] transition-all"
        }
      >
        <p>
          <span className="icon icon_area text-blue-400" />
          <span>{row.name}</span>
        </p>

        <p className={"ml-3"}>{row.count}</p>
      </div>
    );
  };

  const renderTableData = useMemo(() => {
    return (
      <div className={"overflow-y-scroll pl-3"}>
        {workedAreaData?.data.with_division.map((row, ind) => {
          return (
            <div key={`row-${ind}`}>
              <div className={"py-2"}>
                <span className={"font-medium text-[#525252]"}>
                  {row.name}:
                </span>
              </div>

              {row.areas.map((area) => renderTableRow(area))}
            </div>
          );
        })}

        {workedAreaData?.data?.without_division?.length && workedAreaData.data.without_division.length > 0 && (
          <div>
            {isShowDivisions && (
              <div className={"py-2"}>
              <span className={"font-medium text-[#525252]"}>
                Без подразделений:
              </span>
              </div>
            )}

            {workedAreaData?.data.without_division.map((area) => {
              return renderTableRow(area);
            })}
          </div>
        )}
      </div>
    );
  }, [workedAreaData]);

  const handleChangeSortingOrder = (event: RadioChangeEvent) => {
    const value = event.target.value;

    dispatch(setIsDescentForWorkedAreaReport(value === 'desc'));
  };

  return (
    <div className="relative h-[410px] w-full bg-white rounded px-2">
      {workedAreaLoading || isGlobalLoading ? (
        <div className="flex justify-center items-center h-full">
          <LoadingOutlined style={{ fontSize: "30px" }} />
        </div>
      ) : (
        <>
          <div className="absolute w-full px-4 py-2 flex justify-between font-semibold">
            <div className="text-xl flex items-center">
              {isOpenAreasList && (
                <Button
                  type="text"
                  className="flex justify-center items-center"
                  icon={<ArrowLeftOutlined />}
                  onClick={onArrowClick}
                />
              )}
              <div className="ml-4">
                <p>Поля. Обработано</p>
                {isOpenAreasList && (
                  <p className="text-sm text-gray-400">
                    {`Всего объектов (${allAreas.length})`}
                  </p>
                )}
              </div>
            </div>
            <div className={"flex gap-2"}>
              <DownloadExcelReportButton reportType={ReportType.WORKED_AREA} />

              <div
                className="cursor-pointer"
                onClick={() => setIsOpenAreasList(true)}
              >
                <ToolFilled />
              </div>
            </div>
          </div>
          {isOpenAreasList ? (
            <>
              <div className="mx-auto pb-2 mt-16 w-fit">
                <Button
                  size="small"
                  type="link"
                  loading={isSaveReportLoading}
                  onClick={() => saveReports()}
                >
                  сохранить
                </Button>
                <DeleteToolsButton reportType={ReportType.WORKED_AREA} />
              </div>
              <div className="overflow-auto h-[19rem] ">
                <div className="flex flex-col">
                  <div className={"mb-3 flex flex-col gap-1"}>
                    <Text>Сортировать:</Text>

                    <Radio.Group
                      value={sortingOrder}
                      size={"small"}
                      onChange={handleChangeSortingOrder}
                    >
                      <Radio.Button value={"desc"}>По убыванию</Radio.Button>
                      <Radio.Button value={"asc"}>По возрастанию</Radio.Button>
                    </Radio.Group>
                  </div>

                  <Checkbox
                    checked={isShowZeros}
                    onChange={handleChangeShowZeros}
                  >
                    показывать нулевые значения
                  </Checkbox>
                  <Checkbox
                    checked={isShowDivisions}
                    onChange={handleChangeShowDivisions}
                  >
                    сгруппировать по подразделениям
                  </Checkbox>

                  <details className="z-[2] bg-slate-50 cursor-pointer mt-2">
                    <summary>Список подразделений</summary>
                    <div className="px-4 pb-4">
                      {divisions.data.map((division: Division) => {
                        return (
                          <div
                            key={division.id}
                            className="h-6 flex items-center"
                          >
                            <Checkbox
                              checked={selectedDivisionsIds.includes(
                                division.id
                              )}
                              value={division.id}
                              onChange={onChangeSelectedDivision}
                            >
                              <div className="flex justify-between w-full ml-2 font-semibold">
                                <span>{division.name}</span>
                              </div>
                            </Checkbox>
                          </div>
                        );
                      })}
                      <Checkbox
                        checked={isShowWithoutDivision}
                        onChange={handleShowWithoutDivisions}
                      >
                        без подразделений
                      </Checkbox>
                    </div>
                  </details>
                  <details className="z-[2] bg-slate-50 cursor-pointer mt-2">
                    <summary>Список полей</summary>
                    <div className="px-4 pb-4 h-full">
                      <div className="h-full overflow-y-auto">
                        {allAreas.map((area) => {
                          return (
                            <div
                              key={area.id}
                              className="h-6 flex items-center"
                            >
                              <Checkbox
                                checked={selectedAreasIds.includes(area.id)}
                                value={area.id}
                                onChange={onChangeSelectedArea}
                              >
                                <div className="flex justify-between w-full ml-2 font-semibold">
                                  <span>{area.name}</span>
                                </div>
                              </Checkbox>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </details>
                </div>
              </div>
            </>
          ) : (
            <div className="h-full pt-16">
              {/*{workedAreaError ? (*/}
              {/*  <div className="flex justify-center items-center w-full h-full">*/}
              {/*    <p className="text-red-700 text-2xl">*/}
              {/*      {(errorGetWorkedArea as any)?.data?.error ||*/}
              {/*        "Ошибка получения данных"}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <div className={"flex flex-col h-full rounded-t-lg pb-4"}>*/}
              {/*      <div*/}
              {/*        className={*/}
              {/*          "grid grid-cols-[80%,20%] bg-[#fafafa] rounded-t-lg font-medium p-2 border-b border-[#f0f0f0]"*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <div className={"flex justify-between items-center"}>*/}
              {/*          <span>Наименование</span>*/}
              {/*          <Divider orientation={"right"} type={"vertical"} />*/}
              {/*        </div>*/}

              {/*        <div>*/}
              {/*          <span>Га</span>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      {renderTableData}*/}
              {/*    </div>*/}

              {/*    /!*<div className="h-full flex flex-col justify-end pb-4 px-4">*!/*/}
              {/*    /!*  <Table*!/*/}
              {/*    /!*    size="small"*!/*/}
              {/*    /!*    columns={columns}*!/*/}
              {/*    /!*    rowKey={(record) => record.id}*!/*/}
              {/*    /!*    dataSource={workedAreaData?.data.without_division || []}*!/*/}
              {/*    /!*    pagination={false}*!/*/}
              {/*    /!*    scroll={{ y: AREAS_TABLE_SCROLL_HEIGHT }}*!/*/}
              {/*    /!*  />*!/*/}
              {/*    /!*</div>*!/*/}
              {/*  </>*/}
              {/*)}*/}

              <>
                <div className={"flex flex-col h-full rounded-t-lg pb-4"}>
                  <div
                    className={
                      "grid grid-cols-[80%,20%] bg-[#fafafa] rounded-t-lg font-medium p-2 border-b border-[#f0f0f0]"
                    }
                  >
                    <div className={"flex justify-between items-center"}>
                      <span>Наименование</span>
                      <Divider orientation={"right"} type={"vertical"} />
                    </div>

                    <div>
                      <span>Га</span>
                    </div>
                  </div>
                  {renderTableData}
                </div>

                {/*<div className="h-full flex flex-col justify-end pb-4 px-4">*/}
                {/*  <Table*/}
                {/*    size="small"*/}
                {/*    columns={columns}*/}
                {/*    rowKey={(record) => record.id}*/}
                {/*    dataSource={workedAreaData?.data.without_division || []}*/}
                {/*    pagination={false}*/}
                {/*    scroll={{ y: AREAS_TABLE_SCROLL_HEIGHT }}*/}
                {/*  />*/}
                {/*</div>*/}
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AreasTable;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const userApi = createApi({
  reducerPath: 'UserQuery',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => ({
        url: '/users/',
      }),
      providesTags: [{ type: 'User' }],
    }),
    createUser: build.mutation({
      query: (data) => ({
        url: '/users/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
    // getUser: build.query({
    //   query: (pk) => ({
    //     url: `/users/${pk}/`,
    //   }),
    // }),
    updateUser: build.mutation({
      query: (data) => ({
        url: `/users/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
    deleteUser: build.mutation({
      query: (pk) => ({
        url: `/users/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'User' }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
} = userApi;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../redux/hooks';

export const PublicRoute = () => {
  const user = useAppSelector((state) => state.authReducer.user);

  return !user ? <Outlet /> : <Navigate to="/" replace />;
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Select, Tabs } from "antd";
import {
  AreaChartOutlined,
  CameraFilled,
  CarFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import ymaps from "yandex-maps";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { useGetTrackersMutation } from "../../redux/api/tracker.api";
import { useLazyGetCamerasByGroupQuery } from "../../redux/api/camera.api";
import { useLazyGetAreasQuery } from "../../redux/api/area.api";
// import { setRegion } from '../../redux/features/regionSlice';
import { setFarm } from "../../redux/slices/farmSlice";
import { setTrackers, setTracker } from "../../redux/slices/trackerSlice";
import {
  setCameras,
  setCamera,
  setSelectedCamerasIds,
} from "../../redux/slices/cameraSlice";
import { setAreas } from "../../redux/slices/areaSlice";
import CameraItem from "../cameras/cameraItem";
import TrackerItem from "../trackers/trackerItem";
import { Role } from "../../types/auth.type";
import { Camera, CamerasState, FetchCameras, BuildingType } from "../../types/camera.type";
import { Aisle } from "../../types/aisle.type";
import { Grazing } from "../../types/grazing.type";
import { MainRoutes } from "../../util/routes";

type ControlBlockProps = {
  map?: ymaps.Map;
};

export default function ControlBlock({
  map,
}: ControlBlockProps) {
  const dispatch = useAppDispatch();
  // const regions = useAppSelector((state) => state.regionReducer.regions);
  const region = useAppSelector((state) => state.regionReducer.region);
  const user = useAppSelector((state) => state.authReducer.user);
  const farms = useAppSelector((state) => state.farmReducer.farms);
  const farm = useAppSelector((state) => state.farmReducer.farm);
  const cameras = useAppSelector((state) => state.cameraReducer.cameras);
  const trackers = useAppSelector((state) => state.trackerReducer.trackers);
  const [getTrackers, { isLoading: trackersLoading }] =
    useGetTrackersMutation();
  const [getCameras, { isLoading: camerasLoading }] =
    useLazyGetCamerasByGroupQuery();
  const [getAreas] = useLazyGetAreasQuery();

  useEffect(() => {
    if (farm) {
      const params = {
        farm_id: farm?.id,
        region_id: region?.id || 1,
        limit: 0,
      };
      getTrackers(params)
        .unwrap()
        .then((payload) => {
          dispatch(setTrackers(payload.data));
        });
      getCameras(farm?.id)
        .unwrap()
        .then((payload) => {
          dispatch(setCameras(payload.data));
        });
      getAreas(params)
        .unwrap()
        .then((payload) => {
          dispatch(setAreas(payload.data.items));
        });
    } else {
      if (farms) {
        dispatch(setFarm(farms[0]));
      }
    }
  }, [farm, farms, dispatch, getAreas, getCameras, getTrackers]);

  // const onRegionSelect = (value: { label: string, value: number, key: number }) => {
  //   const region = regions?.find((item) => item.id === value.value);
  //   console.log('region', region);
  //   if (region) dispatch(setRegion(region));
  // };

  const onFarmSelect = (value: {
    label: string;
    value: number;
    key: number;
  }) => {
    const farm = farms?.find((item) => item.id === value.value);
    // console.log('farm', farm);
    if (farm) {
      dispatch(setFarm(farm));
      dispatch(setCamera(null));
      dispatch(setTracker(null));
      dispatch(setSelectedCamerasIds([]));
    }
  };

  const handleClickTabs = () => {
    dispatch(setTracker(null));
    dispatch(setSelectedCamerasIds([]));
  };

  const handleClickBuilding = (building: BuildingType) => {
    dispatch(setCamera(building));
  };

  const renderCameraItems = (
    <>
      {camerasLoading ? (
        <div className="flex justify-center items-center min-h-[100px]">
          <LoadingOutlined style={{ fontSize: "30px" }} />
        </div>
      ) : (
        <>
          {cameras?.division_groups && cameras?.division_groups?.length > 0 && (
            <div>
              {cameras?.division_groups?.map((division) => {
                return (
                  <div key={division.division.id}>
                    <div className="text-start pl-2">
                      {division.division.name}
                    </div>
                    {division.buildings.map((building) => (
                      <div key={building.id} className="text-start">
                        <div
                          className="pl-4 cursor-pointer hover:bg-gray-100"
                          onClick={() => handleClickBuilding(building)}
                        >
                          {building?.name}
                        </div>
                        <div className="pl-6">
                          {building.aisles.map((aisle) => (
                            <div key={`aisle-${aisle.id}`}>
                              <div className="pl-2">
                                {
                                  <CameraItem
                                    aisle={aisle}
                                    map={map}
                                    withBorder
                                  />
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="pl-6">
                          {building.grazings.map((grazing) => (
                            <div key={`aisle-${grazing.id}`}>
                              <div className="pl-2">
                                {
                                  <CameraItem
                                    grazing={grazing}
                                    map={map}
                                    withBorder
                                  />
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="pl-6">
                          {building.single_cameras.map((single_camera) => (
                            <div key={`single-${single_camera.id}`}>
                              <div className="pl-2">
                                {
                                  <CameraItem
                                    camera={single_camera}
                                    map={map}
                                    withBorder
                                  />
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          )}
          {cameras?.single_cameras && cameras?.single_cameras.length > 0 && (
            <div>
              <div>
                {cameras?.single_cameras.map((camera) => (
                  <div key={`aisle-${camera.id}`}>
                    <CameraItem camera={camera} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );

  const renderTrackerItems = (
    <>
      {trackersLoading ? (
        <div className="flex justify-center items-center min-h-[100px]">
          <LoadingOutlined style={{ fontSize: "30px" }} />
        </div>
      ) : (
        trackers?.map((tracker) => {
          return (
            <TrackerItem
              key={tracker.id}
              tracker={tracker}
              map={map}
              withBorder
            />
          );
        })
      )}
    </>
  );

  return (
    <div className="bg-white absolute top-0 right-0 bottom-0 w-[300px] flex flex-col text-white text-center font-medium border border-gray-300">
      <div className="region bg-emerald-600 p-4 text-2xl">
        Добрушский район
        {/* <Select
          style={{ width: '100%' }}
          showArrow={false}
          bordered={false}
          labelInValue
          value={region ? { value: region.id, label: region.name, key: region.id } : undefined}
          options={regions?.map((region) => {
            return { value: region.id, label: region.name };
          })}
          onChange={onRegionSelect}
        /> */}
      </div>
      <div className="farm bg-gray-600 p-2 relative font-roboto">
        {/* Крупец */}
        <Select
          style={{ width: "80%" }}
          showArrow={false}
          bordered={false}
          labelInValue
          value={
            farm
              ? { value: farm.id, label: farm.name, key: farm.id }
              : undefined
          }
          options={farms?.map((farm) => {
            return { value: farm.id, label: farm.name };
          })}
          onChange={onFarmSelect}
          disabled={user?.role === Role.FARM || user?.role === Role.ANONYMOUS}
        />
        <div className="absolute right-2 bottom-3">
          <Link to={`/${MainRoutes.statistics}/${farm?.id}`}>
            <AreaChartOutlined className="text-2xl text-white" />
          </Link>
        </div>
      </div>
      <div className="tabs overflow-hidden flex-grow">
        <Tabs
          className="h-full"
          type="card"
          onTabClick={handleClickTabs}
          items={[
            {
              label: (
                <div className="w-[110px] flex justify-center">
                  <CameraFilled className="text-2xl" />
                </div>
              ),
              key: "cameras",
              children: <div>{renderCameraItems}</div>,
            },
            {
              label: (
                <div className="w-[110px] flex justify-center">
                  <CarFilled className="text-2xl" />
                </div>
              ),
              key: "trackers",
              children: <div>{renderTrackerItems}</div>,
            },
          ]}
        />
      </div>
    </div>
  );
}

import React, { useCallback } from 'react';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

import { useAppSelector } from '../../../redux/hooks';
import { FeedingEvent, FeedingEventStatus } from '../../../types/feeding.type';
import { Role } from '../../../types/auth.type';
import { MainRoutes } from '../../../util/routes';
import { FEEDING_DETAIL_TABLE_SCROLL } from '../../../util/constants';
import { getTwoCharValue } from '../../../util/helpers';

interface FeedingDetailReportTableProps {
  treeKey: string;
  data: FeedingEvent[];
  loading: boolean;
};


const FeedingDetailReportTable = ({ treeKey, data, loading }: FeedingDetailReportTableProps) => {
  const user = useAppSelector((state) => state.authReducer.user);
  
  const openFeedingVew = (id: number) => {
    window.open(`/${MainRoutes.feeding_view}?feeding_id=${id}`)
  };

  const getPrapareData = useCallback(() => {
    const newData = structuredClone(data);
    return newData.map((item, index) => {
      if (user?.role === Role.ADMIN) {
        if (!item.event_end || item.status === FeedingEventStatus.ON_CHECK) {
          item.isNotViewing = true;
        }
        if (item.feedings?.length) {
          let isEqualTimes = false;
          for (let i = 0; i < item.feedings.length; i++) {
            for (let j = 0; j !== i && j < item.feedings.length; j++) {
              if (
                item.feedings[j].event_start === item.feedings[i].event_start &&
                item.feedings[j].event_end === item.feedings[i].event_end
              ) {
                isEqualTimes = true;
                break;
              }
            }
            if (isEqualTimes) {
              break;
            }
          }      
          if (isEqualTimes) {
            item.feedings.forEach((feeding) => {
              feeding.isNotViewing = true;
            });
          } else {
            item.isNotViewing = true;
          }
        }
      }
      if (index) {
        const eventStartDate = item.event_start.slice(0, 10);
        const prevEventStartDate = newData[index - 1].event_start.slice(0, 10);
        if (
          (eventStartDate !== prevEventStartDate && !newData[index - 1].isBackground) ||
          eventStartDate === prevEventStartDate && newData[index - 1].isBackground
        ) {
          item.isBackground = true;
          if (item.feedings) {
            item.feedings.forEach((elem) => {
              elem.isBackground = true;
            });
          }
        }
      }    
      return item;
    });
  }, [data]);

  const getFeedingEventTime = useCallback((eventStart: string, eventEnd: string) => {
    const difference = dayjs(eventEnd).diff(eventStart, 'hour', true);
    const differenceHour = Math.trunc(difference);
    const fullDifferenceMinute = difference % 1 * 60;
    const differenceMinute = Math.trunc(fullDifferenceMinute);
    const fullDifferenceSecond = fullDifferenceMinute % 1 * 60;
    const differenceSecond = Math.round(fullDifferenceSecond);
    return `${getTwoCharValue(differenceHour)}:${getTwoCharValue(differenceMinute)}:${getTwoCharValue(differenceSecond)}`
  }, []);

  const renderActions = (feeding: FeedingEvent) => {
    return feeding.isNotViewing ? '' : (
      <div
        className="text-blue-500 cursor-pointer"
        onClick={() => openFeedingVew(feeding.feedings?.[0]?.id || feeding.id)}
      >
        <EyeOutlined />
      </div>
    );
  };

  const columns: ColumnsType<FeedingEvent> = [
    {
      title: 'Дата',
      dataIndex: 'event_start',
      render: (event_start) => event_start ? dayjs(event_start).format('DD.MM.YYYY') : '',
      width: 160,
    },
    {
      title: 'Время начала',
      dataIndex: 'event_start',
      render: (event_start) => event_start ? dayjs(event_start).format('HH:mm:ss') : '',
      width: 100,
    },
    {
      title: 'Время окончания',
      dataIndex: 'event_end',
      render: (event_end) => event_end ? dayjs(event_end).format('HH:mm:ss') : '',
      width: 100,
    },
    {
      title: 'Длительность кормления',
      render: (record) => !record.event_end || record.status === FeedingEventStatus.REJECTED ?
        ''
      :
        getFeedingEventTime(record.event_start, record.event_end),
      width: 120,
    },
    {
      title: 'Тип события',
      dataIndex: 'type_event',
      width: 180,
    },
    {
      title: 'Транспорт',
      dataIndex: 'transport',
      width: 150,
    },
    {
      title: 'Важное',
      dataIndex: 'is_important',
      render: (is_important, record) => 
        !record.event_end || record.status === FeedingEventStatus.ON_CHECK || record.feedings ?
          ''
        :
          is_important ? 'Да' : 'Нет',
      width: 100,
    },
    {
      title: 'Создано админом',
      dataIndex: 'is_admin',
      render: (is_admin, record) => !record.event_end || record.feedings ?
        ''
      :
        is_admin ? 'Да' : 'Нет',
      width: 100,
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      width: 180,
    },
  ];

  if (user?.role === Role.ADMIN) {
    columns.push({ render: (_, record) => renderActions(record) });
  }

  return (
    <Table
      id={`events-table_${treeKey}`}
      size="small"
      columns={columns}
      rowKey={(record) => record.uuid || record.id}
      dataSource={getPrapareData()}
      pagination={false}
      rowClassName={(record) => {        
        return record.isBackground ? 'bg-gray-100' : '';
      }}
      expandable={{
        childrenColumnName: 'feedings',
        indentSize: 30,
      }}
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: '30px' }} />,
      }}
      scroll={FEEDING_DETAIL_TABLE_SCROLL}
    />
  );
};

export default FeedingDetailReportTable;
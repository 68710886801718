import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import {
  TrackersOnlineFields,
  TrackersOnlineFieldsNames,
  TrackersOnlineFieldsColors,
  TrackersOnlineReport,
  TrackersChartUnits, ReportType,
} from '../../../types/report.type';
import { Tracker, TypeTracker } from '../../../types/tracker.type';
import {convertHoursToDaysMonthsMinutes} from "../../../util/helpers";

type TrackersOnlineProps = {
  data: TrackersOnlineReport;
};

const TrackersOnline = ({ data }: TrackersOnlineProps) => {
  // console.log('data', data);
  const pieData = Object.entries(data || {}).map(([name, value]) => {   
    return { name, value };
  });
  // console.log('pieData', pieData);
  const [total, setTotal] = useState(0);
  const [selectedTrackers, setSelectedTrackers] = useState<Tracker[]>([]);
  const [selectedTrackersName, setSelectedTrackersName] = useState('');

  useEffect(() => {
    setTotal(Object.values(data || {}).reduce((res, item) => res + item.total, 0));
  }, [data]);

  const renderLegendText = (value: string, entry: any) => {    
    return (
      <div
        className="inline-flex justify-between text-black font-semibold w-[150px] mb-1 mr-4 cursor-pointer"
        onClick={() => {
          setSelectedTrackersName(value);
          setSelectedTrackers(pieData.find((item) => item.name === value)?.value.trackers || []);
        }}
      >
        <span>
          {TrackersOnlineFieldsNames[value as keyof typeof TrackersOnlineFieldsNames]}
        </span>
        <span>{entry.payload.value}</span>
      </div>
    );
  };

  const renderCustomTooltip = ({ active, payload }: { active?: boolean, payload: any }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-1 bg-white rounded border border-gray-400">
          {`${(payload[0].value / total * 100).toFixed(2)} %`}
        </div>
      );
    }
    return null;
  };

  const getTimeOfState = (tracker: Tracker) => {
    if (selectedTrackersName === TrackersOnlineFields.in_move) {
      return convertHoursToDaysMonthsMinutes(tracker.tracker_info.time_of_movement);
    } else if (selectedTrackersName === TrackersOnlineFields.no_connection) {
      return convertHoursToDaysMonthsMinutes(tracker.tracker_info.time_of_disconnect);
    } else {
      return convertHoursToDaysMonthsMinutes(tracker.parking_time);
    }
  };

  const getPeriodOfTimeReport = ({days, hours, minutes}: {days: number, hours: number, minutes: number}) => {
    const daysString = days ? `${days}${TrackersChartUnits.day}` : '';
    const hoursString = hours ? `${hours}${TrackersChartUnits.hour}` : '';
    const minutesString = minutes ? `${minutes}${TrackersChartUnits.minute}` : '';

    return `${daysString} ${hoursString} ${minutesString}`
  }

  const renderTotalLabel = ({ cx, cy }: { cx: number, cy: number }) => {  
    return (
      <text
        x={cx}
        y={cy}
        fontSize={24}
        fontWeight={700}
        color="#000"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {total}
      </text>
    );
  };

  return (
    <div className="relative h-[410px] w-full bg-white rounded px-2">
      <div className="absolute w-full px-4 py-2 flex justify-between font-semibold">
        <div className="text-xl flex items-center">
          {selectedTrackersName &&
            <Button
              type="text"
              className="flex justify-center items-center"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setSelectedTrackersName('');
                setSelectedTrackers([]);
              }}
            />
          }
          <div className="ml-4">
            <p>Транспорт. Состояние</p>
            {selectedTrackersName &&
              <p className="text-sm text-gray-400">
                {
                  `${TrackersOnlineFieldsNames[selectedTrackersName as keyof typeof TrackersOnlineFieldsNames]} 
                    (${selectedTrackers.length})`
                }
              </p>
            }
          </div>
        </div>
        <div className="text-green-600 mr-4">ОНЛАЙН-ДАННЫЕ</div>
      </div>
      {selectedTrackersName ?
        <div className="pt-32 px-4 pb-4 h-full overflow-y-auto">
          {selectedTrackers.map((tracker) => {
            return (
              <div key={tracker.id} className="h-6 flex items-center">
                <div className="w-5 h-5 flex items-center">
                  <img className="align-sub w-full" src={`/images/${TypeTracker[tracker.type_tracker]}.png`} alt="" />
                </div>
                <div className="flex justify-between w-full ml-2 font-semibold">
                  <span>{tracker.name}</span>
                  <span>
                    {getPeriodOfTimeReport(getTimeOfState(tracker))}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      :
        <ResponsiveContainer>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value.total"
              isAnimationActive={false}
              data={pieData}
              cx="40%"
              cy="50%"
              outerRadius={90}
              innerRadius={30}
              stroke="none"
              labelLine={false}
              label={renderTotalLabel}
            >
              {pieData.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={TrackersOnlineFieldsColors[entry.name as keyof typeof TrackersOnlineFieldsColors]}
                />
              ))}
            </Pie>
            <Tooltip
              // @ts-ignore
              content={renderCustomTooltip}
              isAnimationActive={false}
            />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="square"
              formatter={renderLegendText}
            />
          </PieChart>
        </ResponsiveContainer>
      }
    </div>
  );
};

export default TrackersOnline;
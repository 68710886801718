import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ArrowLeftOutlined, LoadingOutlined, ToolFilled,} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  notification,
  Progress,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import type {CheckboxChangeEvent} from "antd/es/checkbox";

import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  getAllTrackers,
  getDateFlag,
  getDates,
  getDivisionsForMileageReportIds,
  getDivisionsForTrackersWorkedAreaReportIds,
  getIsDescentForMileageReport,
  getIsDescentForTrackersWorkedAreaReport,
  getIsShowDivisionForMileageReport,
  getIsShowDivisionForTrackersWorkedAreaReport,
  getIsShowZerosForMileageReport,
  getIsShowZerosForTrackersWorkedAreaReport,
  getIsToolsLoaded,
  getIsWithoutDivisionForMileageReport,
  getIsWithoutDivisionForTrackersWorkedAreaReport,
  getTrackersForMileageReportIds,
  getTrackersForWorkedAreaReportIds,
  setDivisionsForMileageReportIds,
  setDivisionsForTrackersWorkedAreaReportIds,
  setIsDescentForMileageReport,
  setIsDescentForTrackersWorkedAreaReport,
  setIsShowDivisionForMileageReport,
  setIsShowDivisionForTrackersWorkedAreaReport,
  setIsShowZerosForMileageReport,
  setIsShowZerosForTrackersWorkedAreaReport,
  setIsWithoutDivisionForMileageReport,
  setIsWithoutDivisionForTrackersWorkedAreaReport,
  setTrackersForMileageReportIds,
  setTrackersForWorkedAreaReportIds,
} from "../../../redux/slices/commonReportsSlice";
import {
  useGetReportTrackersMileageMutation,
  useGetReportTrackersWorkedAreaMutation,
} from "../../../redux/api/reports.api";
import {useSaveToolsReportsMutation} from "../../../redux/api/toolsReports.api";
import {useGetDivisionsQuery} from "../../../redux/api/divisions.api";
import {
  ReportType,
  TrackerReportRow,
  TrackersBarChartColors,
  TrackersChartUnits,
  TrackersMileageReportFilters,
  TrackersReportResponseData,
  TrackerWithDivision,
  VerticalBarTypes,
} from "../../../types/report.type";
import {TypeTracker} from "../../../types/tracker.type";
import DownloadExcelReportButton from "./downloadExcelReportButton";
import {Division} from "../../../types/division.type";
import DeleteToolsButton from "../toolsButtons/deleteToolsButton";

const { Text } = Typography;

type TrackersVerticalBarProps = {
  title: string;
};

const TrackersVerticalBar = ({ title }: TrackersVerticalBarProps) => {
  const dispatch = useAppDispatch();
  const { farm_id } = useParams();

  const [isOpenTrackersList, setIsOpenTrackersList] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  const allTrackers = useAppSelector(getAllTrackers);
  const trackersForMileageReportIds = useAppSelector(
    getTrackersForMileageReportIds
  );
  const trackersForWorkedAreaReportIds = useAppSelector(
    getTrackersForWorkedAreaReportIds
  );
  const divisionsForTrackersWorkedAreaReportIds = useAppSelector(
    getDivisionsForTrackersWorkedAreaReportIds
  );
  const divisionsForMileageReportIds = useAppSelector(
    getDivisionsForMileageReportIds
  );
  const isDescentForMileageReport = useAppSelector(
    getIsDescentForMileageReport
  );
  const isDescentForTrackersWorkedAreaReport = useAppSelector(
    getIsDescentForTrackersWorkedAreaReport
  );
  const isShowDivisionForTrackersWorkedAreaReport = useAppSelector(
    getIsShowDivisionForTrackersWorkedAreaReport
  );
  const isShowDivisionMileageReport = useAppSelector(
    getIsShowDivisionForMileageReport
  );
  const isShowZerosForMileageReport = useAppSelector(
    getIsShowZerosForMileageReport
  );
  const isShowZerosForTrackersWorkedAreaReport = useAppSelector(
    getIsShowZerosForTrackersWorkedAreaReport
  );
  const isToolsLoaded = useAppSelector(getIsToolsLoaded);

  const isWithoutDivisionForMileageReport = useAppSelector(getIsWithoutDivisionForMileageReport);
  const isWithoutDivisionForTrackersWorkedAreaReport = useAppSelector(getIsWithoutDivisionForTrackersWorkedAreaReport);

  const dates = useAppSelector(getDates);
  const date_flag = useAppSelector(getDateFlag);

  const isMileage = title === VerticalBarTypes.mileage;
  const reportType = isMileage
    ? ReportType.TRACKERS_MILEAGE
    : ReportType.TRACKERS_WORKED_AREA;


  const isGroupDivisions = isMileage ? isShowDivisionMileageReport : isShowDivisionForTrackersWorkedAreaReport;
  const isShowZeros = isMileage ? isShowZerosForMileageReport : isShowZerosForTrackersWorkedAreaReport;
  const isShowWithoutDivision = isMileage ? isWithoutDivisionForMileageReport : isWithoutDivisionForTrackersWorkedAreaReport;
  const sortingOrder = isMileage ? isDescentForMileageReport ? 'desc' : 'asc' : isDescentForTrackersWorkedAreaReport ? 'desc' : 'asc';
  const selectedDivisionsIds = isMileage ? divisionsForMileageReportIds : divisionsForTrackersWorkedAreaReportIds;
  const selectedTrackersIds = isMileage ? trackersForMileageReportIds : trackersForWorkedAreaReportIds;

  const [
    getTrackersMileage,
    {
      data: trackersMileageData,
      isLoading: trackersMileageLoading,
      isError: isTrackersMileageError,
      error: trackersMileageError,
    },
  ] = useGetReportTrackersMileageMutation();

  const [
    getTrackersWorkedArea,
    {
      data: trackersWorkedAreaData,
      isLoading: trackersWorkedAreaLoading,
      isError: isTrackersWorkedAreaError,
      error: trackersWorkedAreaError,
    },
  ] = useGetReportTrackersWorkedAreaMutation();

  const [
    saveToolsReports,
    { isError: isSaveReportError, isLoading: isSaveReportLoading },
  ] = useSaveToolsReportsMutation();

  const { data: divisions, isLoading: isDivisionsLoading } =
    useGetDivisionsQuery({ farm_id: farm_id });

  const units =
    title === VerticalBarTypes.mileage
      ? TrackersChartUnits.kilometer
      : TrackersChartUnits.hectare;

  const mileageBarData = convertDataFormat(trackersMileageData?.data);
  const workedAreaBarData = convertDataFormat(trackersWorkedAreaData?.data);
  const isError = isTrackersMileageError || isTrackersWorkedAreaError || false;

  const flatData = isMileage
    ? handleFlatData(trackersMileageData?.data)
    : handleFlatData(trackersWorkedAreaData?.data);
  const maxValueFromData = getBarMaxValue(flatData) ?? 0;
  const middleValueFromData = Math.round((maxValueFromData / 2) * 100) / 100;

  const data = isMileage ? mileageBarData : workedAreaBarData;

  const getMileageReport = () => {
    if (allTrackers?.length) {
      const params: { farm_id: string; body: TrackersMileageReportFilters } = {
        farm_id: farm_id as string,
        body: {
          date_flag,
          trackers_ids: trackersForMileageReportIds,
          division_ids: divisionsForMileageReportIds,
          is_show_zeros: isShowZerosForMileageReport,
          is_show_division: isShowDivisionMileageReport,
          is_descend: isDescentForMileageReport,
          is_without_division: isWithoutDivisionForMileageReport,
        },
      };
      if (dates) {
        params.body.date_from = dates[0];
        params.body.date_to = dates[1];
      }
      getTrackersMileage(params)
        .then(() => setIsGlobalLoading(false));
    }
  }

  const getWorkedAreaReport = () => {
    if (allTrackers?.length) {
      const params: { farm_id: string; body: TrackersMileageReportFilters } = {
        farm_id: farm_id as string,
        body: {
          date_flag,
          trackers_ids: trackersForWorkedAreaReportIds,
          division_ids: divisionsForTrackersWorkedAreaReportIds,
          is_show_zeros: isShowZerosForTrackersWorkedAreaReport,
          is_show_division: isShowDivisionForTrackersWorkedAreaReport,
          is_descend: isDescentForTrackersWorkedAreaReport,
          is_without_division: isWithoutDivisionForTrackersWorkedAreaReport,
        },
      };
      if (dates) {
        params.body.date_from = dates[0];
        params.body.date_to = dates[1];
      }
      getTrackersWorkedArea(params)
        .then(() => setIsGlobalLoading(false));
    }
  }

  useEffect(() => {
    if (isToolsLoaded) {
     isMileage ? getMileageReport() : getWorkedAreaReport();
    }
  }, [title, allTrackers,isToolsLoaded,dates, date_flag]);


  const onChangeSelectedTracker = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const value = e.target.value;

    const dispatchFunction = isMileage
      ? setTrackersForMileageReportIds
      : setTrackersForWorkedAreaReportIds;

    const updatedIds: number[] = checked
      ? [...selectedTrackersIds, value]
      : selectedTrackersIds.filter(id => id !== value);

    dispatch(dispatchFunction(updatedIds));
  };

  const onChangeSelectedDivision = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const value = e.target.value;

    const dispatchFunction = isMileage
      ? setDivisionsForMileageReportIds
      : setDivisionsForTrackersWorkedAreaReportIds;

    const updatedIds: number[] = checked
      ? [...selectedDivisionsIds, value]
      : selectedDivisionsIds.filter(id => id !== value);

    dispatch(dispatchFunction(updatedIds));
  };

  const onArrowClick = () => {
    if (title === VerticalBarTypes.mileage) {
      getMileageReport();
    } else if (title === VerticalBarTypes.worked_area) {
      getWorkedAreaReport();
    }

    setIsOpenTrackersList(false);
  };


  const handleChangeGroupDivisions = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const dispatchFunction = isMileage
      ? setIsShowDivisionForMileageReport
      : setIsShowDivisionForTrackersWorkedAreaReport;

    dispatch(dispatchFunction(checked));
  }

  const handleChangeShowZeros = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const dispatchFunction = isMileage
      ? setIsShowZerosForMileageReport
      : setIsShowZerosForTrackersWorkedAreaReport;

    dispatch(dispatchFunction(checked));
  }

  const handleChangeShowWithoutDivisions = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked;
    const dispatchFunction = isMileage
      ? setIsWithoutDivisionForMileageReport
      : setIsWithoutDivisionForTrackersWorkedAreaReport;

    dispatch(dispatchFunction(checked));
  }

  const saveReports = (type: ReportType) => {
    saveToolsReports({
      body: {
        [type]: {
          trackers_ids: selectedTrackersIds,
          is_show_zeros: isShowZeros,
          is_show_division: isGroupDivisions,
          division_ids: selectedDivisionsIds,
          is_descend: sortingOrder === "desc",
          is_without_division: isShowWithoutDivision,
        },
        report_type: type,
      },
    })
      .unwrap()
      .then((payload) => {
        if (payload.success)
          notification.success({ message: "Настройки успешно сохранены" });
      })
      .catch((error) =>
        notification.error({
          message: error.data.message || "не удалось сохранить настройки",
        })
      );
  };

  const renderYAxisTick = (row: {
    id: number;
    name: string;
    trackers: TrackerReportRow[];
  }) => {
    return row.trackers.map((rowTracker) => {
      const tracker = allTrackers.find(
        (trecker) => trecker.name === rowTracker.name
      );

      const rowCount = flatData?.find(
        (flatTracker) => flatTracker.name === rowTracker.name
      )?.count;
      const count = rowCount ? +rowCount : 0;

      return tracker ? (
        <Fragment key={rowTracker.id}>
          <div className={"flex items-center gap-2 py-2"}>
            <img
              className={"w-[16px] h-[16px]"}
              src={`/images/${TypeTracker[tracker.type_tracker]}.png`}
              alt={tracker.name}
            />

            <p style={{ color: TrackersBarChartColors.text }} className={``}>
              {tracker.name}
            </p>
          </div>

          <div
            className={"grid grid-cols-[35%,27%,27%,10%] items-center relative"}
          >
            <div
              className={
                "border-r h-full flex items-center justify-end pr-1 text-[12px]"
              }
            >
              <p
                style={{ color: TrackersBarChartColors.text }}
                className={`font-semibold align-right`}
              >
                {`${count} ${units}`}
              </p>
            </div>

            <div className={"border-r h-full flex items-center"}>
              <Progress
                className={"absolute left-[35%] w-[54%] m-0"}
                size={["100%", 10]}
                strokeColor={TrackersBarChartColors.main}
                trailColor={"transparent"}
                strokeLinecap="butt"
                percent={calculatePercentage(count)}
                showInfo={false}
                success={{ strokeColor: TrackersBarChartColors.main }}
              />
            </div>

            <div className={"border-r h-full"} />
          </div>
        </Fragment>
      ) : (
        <></>
      );
    });
  };

  const handleChangeSortingOrder = (event: RadioChangeEvent) => {
    const value = event.target.value;

    if (isMileage) {
      return dispatch(setIsDescentForMileageReport(value === 'desc'));
    }

    dispatch(setIsDescentForTrackersWorkedAreaReport(value === 'desc'));
  };

  function getBarMaxValue(data?: TrackerReportRow[]) {
    if (!data?.length) return;

    return +Math.max(...data.map((tracker) => tracker.count));
  }

  function handleFlatData(data?: TrackersReportResponseData) {
    if (!data) return;

    return [
      ...data.with_division.flatMap((division) => division.trackers),
      ...data.without_division,
    ];
  }

  function calculatePercentage(number: number) {
    return (number * 100) / maxValueFromData;
  }

  function convertDataFormat(inputData?: {
    with_division: TrackerWithDivision[];
    without_division: TrackerReportRow[];
  }) {
    if (!inputData) return;

    const { with_division, without_division } = inputData;

    const resultArray = with_division.map((division) => ({
      id: division.id,
      name: division.name,
      trackers: division.trackers,
    }));

    without_division.length &&
      resultArray.push({
        id: 0,
        name: isGroupDivisions ? "Без подразделений " : "",
        trackers: without_division,
      });

    return resultArray;
  }

  return (
    <div className="relative flex flex-col gap-2 pb-4 h-[410px] w-full bg-white rounded px-1">
      {(title === VerticalBarTypes.mileage
        ? trackersMileageLoading
        : trackersWorkedAreaLoading) || isGlobalLoading ? (
        <div className="flex justify-center items-center h-full">
          <LoadingOutlined style={{ fontSize: "30px" }} />
        </div>
      ) : (
        <>
          <div className="px-4 py-2 bg-white flex justify-between font-semibold">
            <div className="text-xl flex items-center">
              {isOpenTrackersList && (
                <Button
                  type="text"
                  className="flex justify-center items-center"
                  icon={<ArrowLeftOutlined />}
                  onClick={onArrowClick}
                />
              )}
              <div className="ml-4">
                <p>{title}</p>
                {isOpenTrackersList && (
                  <p className="text-sm text-gray-400">
                    {`Всего объектов (${allTrackers.length})`}
                  </p>
                )}
              </div>
            </div>

            <div className={"flex gap-2"}>
              <DownloadExcelReportButton reportType={reportType} />

              <div
                className="cursor-pointer"
                onClick={() => setIsOpenTrackersList(true)}
              >
                <ToolFilled />
              </div>
            </div>
          </div>

          {isOpenTrackersList ? (
            <>
              <div className="mx-auto pb-2">
                <Button
                  size="small"
                  type="link"
                  loading={isSaveReportLoading}
                  onClick={() => saveReports(reportType)}
                >
                  сохранить
                </Button>
                <DeleteToolsButton reportType={reportType} />
              </div>
              <div className="overflow-auto">
                <div className="flex flex-col">
                  <div className={"mb-3 flex flex-col gap-1"}>
                    <Text>Сортировать:</Text>

                    <Radio.Group
                      value={sortingOrder}
                      size={"small"}
                      onChange={handleChangeSortingOrder}
                    >
                      <Radio.Button value={"desc"}>По убыванию</Radio.Button>
                      <Radio.Button value={"asc"}>По возрастанию</Radio.Button>
                    </Radio.Group>
                  </div>

                  <Checkbox
                    checked={isShowZeros}
                    onChange={handleChangeShowZeros}
                  >
                    показывать нулевые значения
                  </Checkbox>
                  <Checkbox
                    checked={isGroupDivisions}
                    onChange={handleChangeGroupDivisions}
                  >
                    сгруппировать по подразделениям
                  </Checkbox>
                </div>

                <details className="z-[2] bg-slate-50 cursor-pointer mt-2">
                  <summary>Список подразделений</summary>
                  <div className="px-4 pb-4">
                    {divisions.data.map((division: Division) => {
                      return (
                        <div
                          key={division.id}
                          className="h-6 flex items-center"
                        >
                          <Checkbox
                            checked={selectedDivisionsIds?.includes(
                              division.id
                            )}
                            value={division.id}
                            onChange={onChangeSelectedDivision}>
                            <div className="flex justify-between w-full ml-2 font-semibold">
                              <span>{division.name}</span>
                            </div>
                          </Checkbox>
                        </div>
                      );
                    })}
                    <Checkbox
                      checked={isShowWithoutDivision}
                      onChange={handleChangeShowWithoutDivisions}
                    >
                      без подразделений
                    </Checkbox>
                  </div>
                </details>
                <details className="reltive bg-slate-50 cursor-pointer mt-2 overflow-auto">
                  <summary>Список объектов</summary>
                  <div className="px-4 pb-4">
                    {allTrackers.map((tracker) => {
                      return (
                        <div key={tracker.id} className="h-6 flex items-center">
                          <Checkbox
                            checked={selectedTrackersIds?.includes(tracker.id)}
                            value={tracker.id}
                            onChange={onChangeSelectedTracker}>
                            <div className={'flex'}>
                              <div className="w-6 h-6 flex items-center ml-4">
                                <img
                                  className="align-sub w-full"
                                  src={`/images/${
                                    TypeTracker[tracker.type_tracker]
                                  }.png`}
                                  alt=""
                                />
                              </div>
                              <div className="flex justify-between w-full ml-2 font-semibold">
                                <span>{tracker.name}</span>
                              </div>
                            </div>
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                </details>
              </div>
            </>
          ) : (
            <div className={"flex flex-col flex-1 overflow-hidden"}>
              {/*{isError ? (*/}
              {/*  <div className="flex justify-center items-center w-full h-full">*/}
              {/*    <p className="text-red-700 text-2xl text-center">*/}
              {/*      {(trackersMileageError as any)?.data?.error ||*/}
              {/*        (trackersWorkedAreaError as any)?.data?.error ||*/}
              {/*        "Ошибка получения данных"}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    <div*/}
              {/*      className={*/}
              {/*        "grid grid-cols-[minmax(175px,250px),minmax(50%,70%)] text-[12px]"*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <div />*/}
              {/*      <div*/}
              {/*        className={*/}
              {/*          "grid grid-cols-[35%,27%,27%,10%] relative min-h-[25px] bg-white"*/}
              {/*        }*/}
              {/*      >*/}
              {/*        <div className={"relative"}>*/}
              {/*          <p className={"absolute right-0 translate-x-[50%]"}>0{units}</p>*/}
              {/*        </div>*/}
              {/*        <div className={"relative"}>*/}
              {/*          <p className={"absolute right-[0] translate-x-[40%]"}>*/}
              {/*            {middleValueFromData}*/}
              {/*            {units}*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*        <div className={"relative"}>*/}
              {/*          <p className={"absolute right-[0] translate-x-[40%]"}>*/}
              {/*            {maxValueFromData}*/}
              {/*            {units}*/}
              {/*          </p>*/}
              {/*        </div>*/}

              {/*        <div />*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*    <div className="overflow-y-auto pl-4 flex flex-col">*/}
              {/*      <div className={"overflow-y-auto"}>*/}
              {/*        {data?.map((row) => {*/}
              {/*          return (*/}
              {/*            <div key={row.id} className={"mb-4"}>*/}
              {/*              <p*/}
              {/*                style={{ color: TrackersBarChartColors.text }}*/}
              {/*                className={"font-semibold py-2"}*/}
              {/*              >*/}
              {/*                {row.name}*/}
              {/*                {isGroupDivisions ? ":" : ""}*/}
              {/*              </p>*/}
              {/*              <div*/}
              {/*                className={*/}
              {/*                  "grid grid-cols-[minmax(175px,250px),minmax(50%,70%)] pl-3"*/}
              {/*                }*/}
              {/*              >*/}
              {/*                {renderYAxisTick(row)}*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*)}*/}

              <>
                <div
                  className={
                    "grid grid-cols-[minmax(175px,250px),minmax(50%,70%)] text-[12px]"
                  }
                >
                  <div />
                  <div
                    className={
                      "grid grid-cols-[35%,27%,27%,10%] relative min-h-[25px] bg-white"
                    }
                  >
                    <div className={"relative"}>
                      <p className={"absolute right-0 translate-x-[50%]"}>0{units}</p>
                    </div>
                    <div className={"relative"}>
                      <p className={"absolute right-[0] translate-x-[40%]"}>
                        {middleValueFromData}
                        {units}
                      </p>
                    </div>
                    <div className={"relative"}>
                      <p className={"absolute right-[0] translate-x-[40%]"}>
                        {maxValueFromData}
                        {units}
                      </p>
                    </div>

                    <div />
                  </div>
                </div>

                <div className="overflow-y-auto pl-4 flex flex-col">
                  <div className={"overflow-y-auto"}>
                    {data?.map((row) => {
                      return (
                        <div key={row.id} className={"mb-4"}>
                          <p
                            style={{ color: TrackersBarChartColors.text }}
                            className={"font-semibold py-2"}
                          >
                            {row.name}
                            {isGroupDivisions ? ":" : ""}
                          </p>
                          <div
                            className={
                              "grid grid-cols-[minmax(175px,250px),minmax(50%,70%)] pl-3"
                            }
                          >
                            {renderYAxisTick(row)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TrackersVerticalBar;

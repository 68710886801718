import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { CameraFilled } from "@ant-design/icons";
import ymaps from "yandex-maps";

import InfoIcon from "../ui/infoIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCamera,
  setSelectedCamerasIds,
} from "../../redux/slices/cameraSlice";
import { Camera } from "../../types/camera.type";
import { Coordinate, StatusConnection } from "../../types/common.type";
import { Aisle } from "../../types/aisle.type";
import { Grazing } from "../../types/grazing.type";
import { MainRoutes } from "../../util/routes";
import { CAMERAS_MAP_ZOOM } from "../../util/constants";

type CameraItemProps = {
  camera?: Camera;
  aisle?: Aisle;
  grazing?: Grazing;
  map?: ymaps.Map;
  withBorder?: boolean;
};

export default function CameraItem({
  camera,
  aisle,
  grazing,
  map,
  withBorder,
}: CameraItemProps) {
  const dispatch = useAppDispatch();

  const eventCountClassName = 'bg-orange-500 text-white flex justify-center items-center text-[10px] font-bold px-1 min-w-[19px] h-[19px] rounded-full';

  const selectedCamerasIds = useAppSelector(
    (state) => state.cameraReducer.selectedCamerasIds
  );
  // console.log('camera in CameraItem', camera);
  // console.log('aisle in CameraItem', aisle);
  // console.log('grazing in CameraItem', grazing);

  const handleClickCamera = () => {
    const coordinate = aisle
      ? aisle.coords.coordinates
      : grazing
      ? grazing.camera?.coords?.coordinates
      : camera?.coords?.coordinates;
    if (coordinate) {
      map?.setCenter(coordinate as Coordinate, CAMERAS_MAP_ZOOM, {
        checkZoomRange: true,
      });
    }
    const selectedIds = aisle
      ? /*[aisle.first_camera_id, aisle.second_camera_id]*/ aisle.cameras?.map(
          (camera) => camera.id
        )
      : ((grazing ? [grazing.camera_id] : [camera?.id]) as number[]);
    dispatch(setSelectedCamerasIds(selectedIds || []));
  };

  const handleClickCameraIcon = () => {
    // if (!getStatusConnectionOnline()) return;
    const element = aisle ? aisle : grazing ? grazing : camera || null;
    dispatch(setCamera(element));
    // const selectedIds = aisle ?
    //   /*[aisle.first_camera_id, aisle.second_camera_id]*/aisle.cameras?.map((camera) => camera.id)
    // : (grazing ?
    //     [grazing.camera_id]
    //   : ([camera?.id])) as number[];
    // dispatch(setSelectedCamerasIds(selectedIds || []));
  };

  const getStatusConnectionOnline = () => {
    if (aisle) {
      return aisle.cameras?.[0]?.status_connection === StatusConnection.ONLINE;
    } else if (grazing) {
      return grazing.camera?.status_connection === StatusConnection.ONLINE;
    } else {
      return camera?.status_connection === StatusConnection.ONLINE;
    }
  };

  return (
    <div
      className={`flex justify-between items-center font-roboto px-2 ${
        withBorder ? "border-t last:border-b border-gray-200" : ""
      } hover:bg-gray-100 ${
        aisle
          ? selectedCamerasIds.includes(aisle.first_camera_id) && withBorder
            ? "bg-gray-100"
            : ""
          : grazing
          ? selectedCamerasIds.includes(grazing.camera_id) && withBorder
            ? "bg-gray-100"
            : ""
          : selectedCamerasIds.includes(camera?.id as number) && withBorder
          ? "bg-gray-100"
          : ""
      }`}
    >
      <div className="cursor-pointer" onClick={handleClickCamera}>
        {aisle ? `${aisle.name}` : grazing ? `${grazing.name}` : camera?.name}
      </div>
      <div className="inline-flex items-center">
        {aisle?.feeding_events_count ? (
          <Tooltip
            title="Обработать события"
            color="white"
            overlayInnerStyle={{ color: "black" }}
            placement="topRight"
          >
            <Link
              to={`/${MainRoutes.feeding}/${aisle.farm_id}?aisle_id=${aisle.id}&building_id=${aisle.building_id}`}
            >
              <div className={eventCountClassName}>
                {aisle?.feeding_events_count}
              </div>
            </Link>
          </Tooltip>
        ) : null}
        {grazing?.feeding_events_count ? (
          <Tooltip
            title="Обработать события"
            color="white"
            overlayInnerStyle={{ color: "black" }}
            placement="topRight"
          >
            <Link
              to={`/${MainRoutes.feeding}/${grazing.farm_id}?grazing_id=${grazing.id}&building_id=${grazing.building_id}`}
            >
              <div className={eventCountClassName}>
                {grazing?.feeding_events_count}
              </div>
            </Link>
          </Tooltip>
        ) : null}
        <div
          className={`${
            getStatusConnectionOnline() ? "cursor-pointer" : ""
          } flex items-center ml-3`}
          onClick={handleClickCameraIcon}
        >
          <Tooltip
            title={`${getStatusConnectionOnline() ? "Смотреть" : ""}`}
            color="white"
            overlayInnerStyle={{ color: "black" }}
            placement="topRight"
          >
            <CameraFilled
              style={{ color: getStatusConnectionOnline() ? "black" : "gray" }}
            />
          </Tooltip>
        </div>
        <div className="ml-2 flex items-center">
          <InfoIcon
            tooltip={
              getStatusConnectionOnline()
                ? "Объект на связи"
                : "Объект не на связи"
            }
            iconName="icon_connection"
            iconColorClassName={
              getStatusConnectionOnline() ? "text-green-600" : "text-gray-400"
            }
          />
        </div>
      </div>
    </div>
  );
}

import {Aisle} from "./aisle.type";
import {Grazing} from "./grazing.type";

export type FeedingEvent = {
  id: number;
  status: FeedingEventStatus;
  aisle_id?: number;
  grazing_id?: number;
  type: FeedingEventType;
  side?: FeedingEventSide;
  aisle_name?: string;
  grazing_name?: string;
  building_name?: string;
  farm_name?: string;
  event_start: string;
  event_end?: string;
  video_files?: FeedingVideoFile[];
  operator_id?: number;
  ctime: string;
  atime?: string;
  comment?: FeedingEventComment;
  transport?: FeedingEventTransport;
  is_important?: boolean;
  is_admin?: boolean;
  type_event?: string;
  is_nvr?: boolean;
  feedings?: FeedingEvent[] | null;
  uuid?: string;
  isBackground?: boolean;
  aisle: Aisle;
  grazing: Grazing;
  isNotViewing?: boolean;
  child_ids?: number[];
};

export enum FeedingEventStatus {
  ON_CHECK = 1,
  CONFIRMED = 2,
  REJECTED = 3,
};

export type FeedingVideoFile = {
  id: number;
  feeding_event_id: number;
  storage_id: number;
  storage: Storage;
  ctime: string;
};

export type Storage = {
  id: number;
  name: string;
  path: string;
  url?: string;
  size: number;
  ctime: string;
};

export enum FeedingEventType {
  AISLE = 1,
  GRAZING = 2,
};

export enum FeedingEventTypeNames {
  AISLE = 'Проход',
  GRAZING = 'Выпас',
};

export enum FeedingEventSide {
  SIDE_1 = 1,
  SIDE_2 = 2,
};

export enum FeedingEventSideNames {
  SIDE_1 = 'Выгон',
  SIDE_2 = 'Загон',
};

export enum FeedingEventComment {
  BREAKDOWN = 'Поломка',
  REPEAT_PASSAGE = 'Повторный проезд',
  PASSAGE_WITHOUT_TRAILER = 'Проезд без прицепа',
  PASSAGE_FEEDER_OFF = 'Проезд с выключенным кормораздатчиком',
  CLEANING = 'Уборка прохода',
  ANIMAL_OUT = 'Выгон животных',
  ANIMAL_IN = 'Загон животных',
  VISUAL_DISTURBANCES = 'Помехи в поле зрения',
  TECHNOLOGICAL_PROCESS = 'Техпроцесс',
};

export enum FeedingEventTransport {
  TRACTOR = 'Трактор',
  CART = 'Повозка',
  MANUAL = 'Ручная раздача',
};

export type FeedingEventUpdateBody = {
  comment?: FeedingEventComment;
  transport?: FeedingEventTransport;
  is_important?: boolean;
  status?: FeedingEventStatus;
  side?: FeedingEventSide;
  is_duplicate?: boolean;
  is_full?: boolean;
};

export type FullEventUpdateParams = {
  event_1_id: number | string;
  event_2_id: number | string;
};

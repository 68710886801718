import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const buildingApi = createApi({
  reducerPath: 'buildingQuery',
  tagTypes: ['Building'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getBuildings: build.query({
      query: (params) => {
        let url = '/buildings/?';
        if (params?.farm_id) {
          url += `farm_id=${params.farm_id}`;
        }
        if (params?.division_ids?.length) {
          url += `&division_id=${params.division_ids?.join('&division_id=')}`;
        }
        return {
        url,
      }
      },
      providesTags: [{ type: 'Building' }],
    }),
    createBuilding: build.mutation({
      query: (data) => ({
        url: '/buildings/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Building' }],
    }),
    getBuilding: build.query({
      query: (pk) => ({
        url: `/buildings/${pk}/`,
      }),
    }),
    getShortBuilding: build.query({
      query: (pk) => ({
        url: `/buildings/short_building/${pk}/`,
      }),
    }),
    getShortBuildings: build.mutation({
      query: (body) => ({
        url: `/buildings/short_buildings/`,
        method: 'POST',
        body: body,
      }),
    }),
    updateBuilding: build.mutation({
      query: (data) => ({
        url: `/buildings/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Building' }],
    }),
    deleteBuilding: build.mutation({
      query: (pk) => ({
        url: `/buildings/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Building' }],
    }),
  }),
});

export const {
  useGetBuildingsQuery,
  useCreateBuildingMutation,
  useDeleteBuildingMutation,
  useLazyGetBuildingQuery,
  useLazyGetBuildingsQuery,
  useUpdateBuildingMutation,
  useGetShortBuildingQuery,
  useLazyGetShortBuildingQuery,
  useGetShortBuildingsMutation,
} = buildingApi;

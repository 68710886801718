import locale from "antd/es/date-picker/locale/ru_RU";
import {DatePicker, DatePickerProps, Form, FormInstance} from "antd";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";

import dayjs, {Dayjs} from "dayjs";
import {DisabledTime} from "rc-picker/lib/interface";

import {InputMask, MaskEventDetail} from '@react-input/mask';
import {DATE_FORMAT} from "../../util/constants";

interface Props {
  form: FormInstance<any>;
  disabledDate: (date: dayjs.Dayjs) => boolean;
  disabledTime: DisabledTime<dayjs.Dayjs>;
  fieldValue: string;
  placeholder?: string;
}

const MASK = '__.__.20__ __:__:__';
const REPLACEMENT = { _: /\d/};

const FeedingEventDatePicker = ({form, disabledDate, disabledTime, fieldValue, placeholder}: Props) => {
  const eventStartValue = Form.useWatch('event_start', form);
  const startEventStartValue = useRef(eventStartValue);

  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState<MaskEventDetail | null>(null);

  const [showMask, setShowMask] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fieldValue === 'event_end') {
      if (eventStartValue !== startEventStartValue) {
        setValue('');
        form.setFieldValue('event_end', null);
      }
    }
  }, [startEventStartValue, eventStartValue]);

  const isValidDateFormat = (dateString: string) => {
    const parsedDate = dayjs(dateString, DATE_FORMAT);

    return parsedDate.isValid();
  }

  const handleChangeEventDate: DatePickerProps['onChange'] = (date, dateString) => {
    if (fieldValue === 'event_start') {
      form.setFieldValue('event_end', null)
    }

    form.setFieldValue(fieldValue, dayjs(dateString, DATE_FORMAT))

    setValue(dateString)
    setOpen(false)
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setValue(value)

    if (isValidDateFormat(value)) {
      if (fieldValue === 'event_start') {
        form.setFieldValue('event_end', null)
      }

     return form.setFieldValue(fieldValue, value)
    }

    form.setFieldValue(fieldValue, null)
  }

  const handleFocusInput = () => {
    setOpen(true);
    setShowMask(true);
  }

  const handleCloseDatePicker = () => {
    setOpen(false);
  }

  return (
    <>
       <DatePicker
        open={open}
        locale={locale}
        className="min-w-[300px]"
        placeholder={placeholder}
        showTime={{ format: 'HH:mm:ss' }}
        format={DATE_FORMAT}
        onChange={handleChangeEventDate}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        onOk={handleCloseDatePicker}
        inputRender={() => (
          <InputMask
            value={value ?? detail?.value ?? ''}
            ref={inputRef}
            mask={MASK}
            replacement={REPLACEMENT}
            onFocus={handleFocusInput}
            onBlur={handleCloseDatePicker}
            placeholder={placeholder}
            onChange={handleChangeInput}
            showMask={showMask}
            onMask={(e) => setDetail(e.detail)}
          />
        )}
      />
    </>
  )
}

export default FeedingEventDatePicker;
import { AdminRoute, MainRoute, StatisticsRoute } from '../types/common.type';

export const MainRoutes: Record<MainRoute, string> = {
  main: '/',
  admin: 'admin',
  statistics: 'statistics',
  full_statistics: 'full_statistics',
  feeding_report: 'feeding_report',
  login: 'login',
  feeding: 'feeding',
  feeding_view: 'feeding_view',
};

export const AdminRoutes: Record<AdminRoute, string> = {
  trackers: 'trackers',
  cameras: 'cameras',
  users: 'users',
  areas: 'areas',
  photos: 'photos',
  buildings: 'buildings',
  aisles: 'aisles',
  divisions: 'divisions',
  feeding_events: 'feeding_events',
};

export const StatisticsRoutes: Record<StatisticsRoute, string> = {
  feeding: 'feeding',
  machines: 'machines',
  areas: 'areas',
};
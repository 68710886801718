import { Tracker } from './tracker.type';
import {ListResponse, PaginationResponse} from './common.type';
import {
  FeedingEvent,
  FeedingEventComment,
  FeedingEventStatus,
  FeedingEventTransport,
  FeedingEventType,
} from './feeding.type';

export type TrackersOnlineReport = {
  [key in TrackersOnlineFields]: TrackersOnlineReportItem;
};

export type TrackersOnlineReportItem = {
  total: number;
  trackers: Tracker[];
};

export enum TrackersOnlineFields {
  stand = 'stand',
  stand_more_day = 'stand_more_day',
  no_connection = 'no_connection',
  in_move = 'in_move',
};

export enum TrackersOnlineFieldsNames {
  stand = 'Стоят',
  stand_more_day = 'Стоят более суток',
  no_connection = 'Нет связи',
  in_move = 'В движении',
};

export enum TrackersOnlineFieldsColors {
  stand = '#DC2626',
  stand_more_day = '#F97316',
  no_connection = '#4D7C0F',
  in_move = '#22C55E',
};

export enum DateFlag {
  FROM_TO = 1,
  TODAY = 2,
  DAY_AGO = 3,
  WEEK_AGO = 4,
  MONTH_AGO = 5,
  CURRENT_WEEK = 6,
  LAST_WEEK = 7,
  CURRENT_MONTH = 8,
  LAST_MONTH = 9,
  CURRENT_QUARTER = 10,
  CURRENT_HALF_YEAR = 11,
  CURRENT_YEAR  = 12,
};

export enum ReportType {
  TRACKERS_MILEAGE = 'trackers_mileage', 
  TRACKERS_WORKED_AREA = 'trackers_worked_area', 
  WORKED_AREA = 'worked_area', 
  REPORT_FEEDING = 'report_feeding', 
  ALL = 'all',
} 

export enum DateFlagNames {
  FROM_TO = '',
  TODAY = 'Сегодня',
  DAY_AGO = 'Вчера',
  WEEK_AGO = 'Неделя',
  MONTH_AGO = 'Месяц',
  CURRENT_WEEK = 'Текущая неделя',
  LAST_WEEK = 'Прошедшая неделя',
  CURRENT_MONTH = 'Текущий месяц',
  LAST_MONTH = 'Прошедший месяц',
  CURRENT_QUARTER = 'Текущий квартал',
  CURRENT_HALF_YEAR = 'Текущее полугодие',
  CURRENT_YEAR = 'Текущий год',
};

export type TrackersMileageReport = {
  date_from: string;
  date_to: string;
  date_flag: DateFlag;
  rows: TrackersMileageReportRow[];
};

export type TrackersMileageReportRow = {
  external_tracker_id: number;
  tracker_name: string;
  count: number;
};

export type TrackerReportRow = {
  count: number;
  id: number;
  name: string;
}

export type TrackersMileageReportFilters = {
  date_from?: string;
  date_to?: string;
  date_flag: DateFlag;
  trackers_ids?: number[];
  division_ids?: number[];
  is_show_zeros?: boolean;
  is_show_division?: boolean;
  is_descend?:boolean;
  is_without_division?:boolean; 
};

export type TrackerWithDivision = {
  id: number;
  name: string;
  trackers: TrackerReportRow[];
}

export type AreasWithDivision = {
  id: number;
  name: string;
  areas: AreasReportRow[];
}

export type TrackersReportResponseData = {
  with_division: TrackerWithDivision[],
  without_division: TrackerReportRow[]
}

export type WorkedAreaReportResponseData = {
  with_division: AreasWithDivision[],
  without_division: AreasReportRow[]
}

export enum VerticalBarTypes {
  mileage = 'Транспорт. Пробег',
  worked_area = 'Транспорт. Работа на полях',
};

export enum TrackersBarChartColors {
  main = '#0000FF',
  violation = '#FF0000',
  text = '#666666',
};

export enum TrackersChartUnits {
  kilometer = 'км',
  hectare = 'га',
  hour = 'ч',
  day = 'д',
  minute = 'мин'
};

export type AreasReport = {
  date_from: string;
  date_to: string;
  date_flag: DateFlag;
  rows: AreasReportRow[];
};

// export type AreasReportRow = {
//   external_area_id: number;
//   area_name: string;
//   count: number;
// };

export type AreasReportRow = {
  id: number;
  name: string;
  count: number;
};

export type AreasReportFilters = {
  date_from?: string;
  date_to?: string;
  date_flag: DateFlag;
  area_ids: number[];
  is_show_zeros: boolean;
  is_show_division: boolean;
  is_without_division: boolean;
  is_descend: boolean;
  division_ids: number[];
};

export type FeedingEventReport = {
  building_id: number;
  building_name: string;
  feeding_event_violations: FeedingEventViolation[];
};

export type FeedingEventViolation = {
  building_id: number;
  building_name: string;
  date_report: string;
  violation_count: number;
};

export type FeedingEventReportFilters = {
  date_from?: string;
  date_to?: string;
  date_flag: DateFlag;
  division_ids?: number[];
  building_ids?: number[];
  aisle_ids?: number[];
  grazing_ids?: number[];
};

export interface ExcellFeedingEventReportFilters extends FeedingEventReportFilters {
  is_extended?: boolean;
  is_important?: boolean;
  comment?: string[];
  transport?: string[];
  status?: FeedingEventStatus;
  is_nvr?: boolean;
};

export interface DataFeedingEvent {
  id: number;
  name: string;
  data: {
    all_event?: number;
    rejected_event?: number;
    confirm_event?: number;
    full_event?: number;
    half_event?: number;
    absent_event?: number;
    important_event?: number;
  }
};

export interface FarmFeedingEventReport extends DataFeedingEvent {
  divisions: DivisionFeedingEventReport[];
};

export interface DivisionFeedingEventReport extends DataFeedingEvent {
  buildings: BuildingFeedingEventReport[];
};

export interface BuildingFeedingEventReport extends DataFeedingEvent {
  division_id: number;
};

export interface AislesGrazingsFeedingEventReport extends DataFeedingEvent {
  id: number;
  name: string;
  type: FeedingEventType;
};

export interface DetailFeedingEventReport extends PaginationResponse<FeedingEvent[]> {};

export interface DetailFeedingEventReportFilters {
  date_from?: string;
  date_to?: string;
  date_flag: DateFlag;
  aisle_id?: number;
  grazing_id?: number;
  is_important?: boolean;
  comment?: string[];
  transport?: string[];
  status?: FeedingEventStatus;
  is_extended?: boolean;
  page?: number;
  limit?: number;
  is_nvr?: boolean;
};

interface TrackersBody {
  is_show_zeros: boolean;
  is_show_division: boolean;
  is_descend: boolean;
  trackers_ids: number[];
  division_ids: number[];
  is_without_division: boolean;
}

interface WorkedAreaBody {
  is_show_zeros: boolean;
  is_descend: boolean;
  area_ids: number[];
  division_ids: number[];
  is_without_division: boolean;
  is_show_division: boolean;
}

interface ReportFeedingBody {
  division_ids: number[];
  building_ids: number[];
}

export interface StatisticReportFilters {
  date_from?: string;
  date_to?: string;
  date_flag: DateFlag;
  report_type: ReportType;
}

export interface StatisticReportBody {
  trackers_mileage?: TrackersBody;
  trackers_worked_area?: TrackersBody;
  worked_area?: WorkedAreaBody;
  report_feeding?: ReportFeedingBody;
}

export type StatisticReportRequestParams = {
  farm_id: number;
  urlParams: StatisticReportFilters;
  body?: StatisticReportBody;
}

export type TrackersReportTools = {
  trackers_ids: number[];
  division_ids: number[];
  is_show_zeros: boolean;
  is_show_division: boolean;
  is_descend: boolean;
  is_without_division: boolean;
}

export type WorkedAreaReportTools = {
  is_show_zeros: boolean,
  is_show_division: boolean,
  is_without_division: boolean,
  is_descend: boolean,
  division_ids: number[],
  area_ids: number[]
}

export type FeedingReportTools = {
  building_ids: number[],
  division_ids: number[]
}

export type AllReportTools = {
  trackers_mileage?: TrackersReportTools;
  trackers_worked_area?: TrackersReportTools;
  worked_area?: WorkedAreaReportTools;
  report_feeding?: FeedingReportTools;
}
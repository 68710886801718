import React, { useEffect, useState } from "react";
import { Table, Button, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
// import { useAppDispatch } from "../../redux/hooks";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import {
  // useCreateAreaMutation,
  useGetAreasQuery,
} from "../../redux/api/area.api";
// import { useGetFarmsQuery } from "../../redux/api/farm.api";
// import { Farm } from "../../types/farm.type";
import { ADMIN_TABLE_SCROLL_HEIGHT } from "../../util/constants";
import { Area } from "../../types/area.type";
// import { useGetDivisionsQuery } from "../../redux/api/divisions.api";
// import { Division } from "../../types/division.type";
import AreaEditForm from "../../components/areas/areaEditForm";
import AreasUpdateForm from "../../components/areas/areasUpdateForm";
// import { setOneArea } from "../../redux/slices/areaOneSlice";

const pageLimit = 10;

const AreasList = () => {
  // const dispatch = useAppDispatch();
  // const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});

  // const [createArea, { isLoading: isCreateAreaLoading }] =
  //   useCreateAreaMutation();
  // const { data: divisionsData, isFetching: isDivisionsLoading } =
  //   useGetDivisionsQuery({});
  // const [farmId, setFarmId] = useState(1);
  // const [divisionId, setDivisionId] = useState();
  const [areaEdit, setAreaEdit] = useState<Partial<Area>>({});
  const [openAreaForm, setOpenAreaForm] = useState(false);
  const [openAreasUpdateForm, setOpenAreasUpdateForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const areasParams = {
    page: currentPage,
    limit: pageLimit,
    // farm_id: farmId,
    // division_id: divisionId || 1,
  };
  const {
    data: areasData,
    isFetching: isGetAreasLoading,
    isError: isGetAreasError,
  } = useGetAreasQuery(areasParams);

  useEffect(() => {
    if (isGetAreasError) {
      notification.error({
        message: "Ошибка получения данных",
        description: "Попробуйте позже",
      });
    }
  }, [isGetAreasError]);
  const onClickEdit = (record: Area) => {
    setAreaEdit(record);
    setOpenAreaForm(true);
  };
  const renderActions = (area: Area) => {
    return (
      <div className="flex justify-between">
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => onClickEdit(area)}
        >
          <EditOutlined />
        </div>
      </div>
    );
  };

  const columns: ColumnsType<Area> = [
    {
      title: "Наименование",
      dataIndex: "name",
    },
    {
      title: "Хозяйство",
      dataIndex: "farm_name",
    },
    {
      title: "Подразделение",
      dataIndex: "division_name",
    },
    {
      render: (_, record) => renderActions(record),
    },
  ];
  // const params = { farm_id: farmId, division_id: divisionId };
  // const addOrUpdateAreas = () => {
  //   createArea(params)
  //     .unwrap()
  //     .then((payload) => {
  //       if (payload.success) {
  //         notification.success({
  //           message: "Поля созданы/обновлены",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       notification.error({
  //         message: error.data?.error || "Ошибка создания/обновления полей",
  //       });
  //     });
  // };
  return (
    <div className="areaList">
      {/* <div className="flex justify-start gap-2">
        <Select
          style={{ width: 210 }}
          placeholder="Выберите хозяйство"
          loading={isFarmsLoading}
          options={farmsData?.data.map((farm: Farm) => {
            return { value: farm.id, label: farm.name };
          })}
          value={farmId}
          onChange={(value) => setFarmId(value)}
        />
        <Select
          style={{ width: 210 }}
          placeholder="Выберите подразделение"
          loading={isDivisionsLoading}
          options={divisionsData?.data.map((division: Division) => {
            return { value: division.id, label: division.name };
          })}
          onChange={(value) => setDivisionId(value)}
        />
      </div> */}

      <div className="my-8">
        <Button
          type="primary"
          className="flex items-center"
          icon={<PlusOutlined />}
          // onClick={addOrUpdateAreas}
          onClick={() => setOpenAreasUpdateForm(!openAreasUpdateForm)}
          // disabled={isCreateAreaLoading || !farmId}
        >
          Создать/Обновить поля
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={areasData?.data?.items || []}
        pagination={{
          current: areasData?.data.page,
          defaultPageSize: pageLimit,
          onChange: (page) => setCurrentPage(page),
          total: areasData?.data.total,
          showSizeChanger: false,
        }}
        loading={{
          spinning: isGetAreasLoading,
          indicator: <LoadingOutlined style={{ fontSize: "30px" }} />,
        }}
        scroll={{ y: ADMIN_TABLE_SCROLL_HEIGHT }}
      />
      {openAreaForm && (
        <AreaEditForm
          areaEdit={areaEdit}
          open={openAreaForm}
          onCancel={() => setOpenAreaForm(false)}
        />
      )}
      {openAreasUpdateForm && (
        <AreasUpdateForm
          setCurrentPage={setCurrentPage}
          open={openAreasUpdateForm}
          onCancel={() => setOpenAreasUpdateForm(false)}
        />
      )}
    </div>
  );
};

export default AreasList;

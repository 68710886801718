import React, {ReactNode, useCallback, useEffect} from 'react';
import dayjs, {Dayjs} from "dayjs";
import Timelines from "./timelines";
import {useGetMulticamArchiveRecordsQuery} from "../../redux/api/multicam.api";
import {SliderSingleProps} from "antd";
import {MulticamEvent, MulticamEventType} from "../../types/multicam";
import {EVENT_COLORS} from "../../util/constants";

const TIME_FORMAT = 'HH:mm:ss';

interface Props {
  startTime: Dayjs;
  endTime: Dayjs;
  camera_ids: number[];
  events: MulticamEvent[];
  currentTime: number;
  isToday: boolean;
  onSliderChange: (value: number) => void;
  getMarks:  () => SliderSingleProps['marks'];
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventHandlingMode = ({
  startTime,
  endTime,
  camera_ids,
  events,
  currentTime,
  isToday,
  onSliderChange,
  getMarks,
  setPlaying,
  }: Props) => {
  const {data: archiveRecordsData} = useGetMulticamArchiveRecordsQuery(
    {camera_ids, time_from: startTime.unix(), time_to: endTime.unix()});

  const formatSliderTooltip = (value: number = 0) => {
    return dayjs.unix(value).utc().format(TIME_FORMAT);
  };

  const startOfDate = startTime.startOf('date').unix();

  const min = startTime.unix() - startOfDate;
  const max = endTime.unix() - startOfDate;
  const value = currentTime  + min;

  useEffect(() => {
    if (currentTime > max - min) {
      // console.log('currentTime > max')
      setPlaying(false);
    }
  }, [min, max, currentTime]);

  const handleChangeSlider = (value: number) => {
    const nextValue = value - min;

    onSliderChange(nextValue);
  }

  const getArchiveAvailable = useCallback(() => {
    let isAvailable = false;
    if (archiveRecordsData?.data) {
      for (const item of archiveRecordsData?.data) {
        if (item.records.length) {
          isAvailable = true;
          break;
        }
      }      
    }    
    return isAvailable;
}, [archiveRecordsData]);

  const renderEventMark = (event: MulticamEvent): ReactNode | ReactNode[]  => {
    return <React.Fragment key={event.id}></React.Fragment>;
    // const eventStartTime = dayjs(event.event_start);
    // const eventEndTime = dayjs(event.event_end ?? event.event_start);
    //
    // const start =  ((eventStartTime.unix() - startOfDate - min)/(max - min))*100;
    // const end = ((eventEndTime.unix() - startOfDate - min)/(max - min))*100;
    // const width = end - start;
    //
    //
    // return (
    //   <React.Fragment key={event.id}>
    //     <div
    //       key={event.id}
    //       className={`absolute top-0 min-w-[2px] h-2`}
    //       style={{
    //         background: EVENT_COLORS[event.type],
    //         left: `${start}%`,
    //         width: width > 1 ? `${width}%` : '10px',
    //         zIndex: event.type !== MulticamEventType.FEEDING ? 5 : undefined,
    //         transform: `translateX(${start === 50 ? -50 : 0 }%)`
    //       }}
    //     ></div>
    //     {event.events?.length && event.events.map(event => {
    //       return renderEventMark(event)
    //     })}
    //   </React.Fragment>
    // );
  }

  return (
    <Timelines
      archiveRecords={archiveRecordsData?.data || []}
      // events={events}
      events={[]}
      startTime={startTime?.unix()}
      endTime={endTime?.unix()}
      // currentTime={currentTime}
      currentTime={value}
      onChangeSlider={handleChangeSlider}
      disabled={!getArchiveAvailable()}
      tooltipFormatter={formatSliderTooltip}
      marks={getMarks()}
      isToday={isToday}
      startOfDate={startOfDate}
      renderMark={renderEventMark}
    />
  );
};

export default EventHandlingMode;
export type Coords = {
  type: string;
  coordinates: Coordinate | Coordinates;
  bbox?: any[] | null;
};

export type Coordinates = Array<Array<Coordinate>>;

export type Coordinate = [number, number];

export enum StatusConnection {
  ONLINE = 1,
  OFFLINE = 2,
}

export enum AdminLinks {
  trackers = "Трекеры",
  cameras = "Камеры",
  users = "Пользователи",
  areas = "Поля",
  photos = "Фото",
  buildings = "Строения",
  aisles = "Проходы",
  divisions = "Подразделения",
  feeding_events = "События кормления",
}

export type AdminRoute =
  | "trackers"
  | "cameras"
  | "users"
  | "areas"
  | "photos"
  | "buildings"
  | "aisles"
  | "divisions"
  | "feeding_events";

export enum StatisticsLinks {
  feeding = "Кормление",
  machines = "Техника",
  areas = "Поля",
}

export type StatisticsRoute = "feeding" | "machines" | "areas";

export type MainRoute =
  | "main"
  | "admin"
  | "statistics"
  | "login"
  | "feeding"
  | "feeding_view"
  | "full_statistics"
  | "feeding_report";

export enum Tools {
  distance = "distance",
  area = "area",
}

export interface BaseResponse<T> {
  data: T;
  success: boolean;
}

export interface ListResponse<T> {
  data: PaginationResponse<T>;
  success: boolean;
}

export interface PaginationResponse<T> {
  total: number;
  limit: number;
  page: number;
  items: T;
}

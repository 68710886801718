import React, {useEffect} from 'react';
import {Button} from "antd";
import {useDeleteReportsTools} from "../../../hooks/useDeleteReportsTools";
import {ReportType} from "../../../types/report.type";
import {useAppDispatch} from "../../../redux/hooks";
import {resetCurrentReportTools} from "../../../redux/slices/commonReportsSlice";

interface Props {
  reportType: ReportType;
  onClick?: () => void;
}

const DeleteToolsButton = ({reportType, onClick}: Props) => {
  const dispatch = useAppDispatch();

  const {handleResetTools, isLoading: isDeleteReportsLoading, isSuccess} = useDeleteReportsTools()

  const handleClick = (reportType: ReportType) => {
    handleResetTools(reportType);

    onClick && onClick()
  }

  return (
    <Button
      size="small"
      type="link"
      danger
      loading={isDeleteReportsLoading}
      onClick={() => handleClick(reportType)}
    >
      удалить
    </Button>
  );
};

export default DeleteToolsButton;
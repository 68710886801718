import React, {ReactNode, useEffect, useMemo} from 'react';
import {SliderSingleProps} from "antd";
import dayjs, {Dayjs} from "dayjs";
import {
  MulticamArchiveRecords,
  MulticamEvent,
  MulticamEventType
} from "../../types/multicam";
import {EVENT_COLORS, UTC_OFFSET} from "../../util/constants";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  getActiveMulticamEvent,
  getLoadedCams,
  setActiveEvent
} from "../../redux/slices/multicamSlice";
import Timelines from "./timelines";
import {useGetMulticamArchiveRecordsQuery} from "../../redux/api/multicam.api";

interface Props {
  getMarks:  () => SliderSingleProps['marks']
  value: number;
  events: MulticamEvent[];
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  date?: Dayjs | null;
  onSliderChange: (value: number) => void;
  isToday: boolean;
  startOfDate: number;
  camera_ids: number[];
  time_to: number;
  emptyFragments?:  MulticamArchiveRecords[];
  setIndexOfSelectedEvent: (uuid: string) => void;
}

const TIME_FORMAT = 'HH:mm:ss';
const ADDITIONAL_TIME = 10; // +-10 секунд к евентам

const EventsMode = ({
  getMarks,
  value,
  camera_ids,
  startOfDate,
  events,
  onSliderChange,
  isToday,
  date,
  time_to,
  emptyFragments,
  setIndexOfSelectedEvent,
  setPlaying,
}: Props) => {
  const dispatch = useAppDispatch();
  const activeEvent = useAppSelector(getActiveMulticamEvent);

  const now = useMemo(() => dayjs().utcOffset(UTC_OFFSET), [dayjs, date]);
  const startTime = dayjs(activeEvent?.event_start).utcOffset(UTC_OFFSET, true).subtract(ADDITIONAL_TIME, 'second');
  const endTime = dayjs(activeEvent?.event_end ?? activeEvent?.event_start).utcOffset(UTC_OFFSET, true).add(ADDITIONAL_TIME, 'second');

  const min = startTime.unix() - (date?.utcOffset(UTC_OFFSET).startOf('date') || now.startOf('date')).unix();
  const max = endTime.unix() - (date?.utcOffset(UTC_OFFSET).startOf('date') || now.startOf('date')).unix();

  const { data: archiveRecordsData } = useGetMulticamArchiveRecordsQuery(
    { camera_ids, time_from: startTime.unix(), time_to: endTime.unix() }, { skip: !activeEvent }
  );

  const formatSliderTooltip = (value: number = 0) => {
    return dayjs.unix(value + min).utc().format(TIME_FORMAT);
  };

  useEffect(() => {
    if (events.length) {
      dispatch(setActiveEvent(events[0]));
      setIndexOfSelectedEvent(events[0].uuid_event);
      // setPlaying(false);
    }
  }, [events]);

  useEffect(() => {
    if (value === (max - min + 1) && activeEvent) {
      const currentEventIndex = events.indexOf(activeEvent);
      const nextEvent = events[currentEventIndex + 1];

      if (!nextEvent) return setPlaying(false);

      setIndexOfSelectedEvent(nextEvent.uuid_event);
    }
  }, [value, max]);


  useEffect(() => {
    return () => {
      dispatch(setActiveEvent(null))
      setIndexOfSelectedEvent('');
    }
  }, []);

  if (!events.length) {
    return (
      <Timelines
        archiveRecords={emptyFragments || []}
        events={[]}
        startTime={startOfDate}
        endTime={time_to}
        currentTime={value}
        onChangeSlider={onSliderChange}
        tooltipFormatter={formatSliderTooltip}
        marks={getMarks()}
        isToday={isToday}
        startOfDate={startOfDate}
      />
    )
  }

  const renderEventMark = (event: MulticamEvent): ReactNode | ReactNode[]  => {
    const eventStartTime = dayjs(event.event_start).utcOffset(UTC_OFFSET, true);
    const eventEndTime = dayjs(event.event_end ?? event.event_start).utcOffset(UTC_OFFSET, true);

    const start =  ((eventStartTime.unix() - (date?.utcOffset(UTC_OFFSET).startOf('date') || now.startOf('date')).unix() - min)/(max - min))*100;
    const end = ((eventEndTime.unix() - (date?.utcOffset(UTC_OFFSET).startOf('date') || now.startOf('date')).unix() - min)/(max - min))*100;
    const width = end - start;

    return (
      <React.Fragment key={event.id}>
        <div
          key={event.id}
          className={`absolute top-0 min-w-[2px] h-2`}
          style={{
            background: EVENT_COLORS[event.type],
            left: `${start}%`,
            width: width > 1 ? `${width}%` : '10px',
            zIndex: event.type !== MulticamEventType.FEEDING ? 5 : undefined,
            transform: `translateX(${start === 50 ? -50 : 0 }%)`
          }}
        ></div>
        {event.events?.length > 0 && event.events.map(event => {
          return renderEventMark(event)
        })}
      </React.Fragment>
    );
  }

  return (
    <>
      {activeEvent && (
        <Timelines
          archiveRecords={archiveRecordsData?.data || []}
          events={[activeEvent]}
          startTime={startTime.unix()}
          endTime={endTime.unix()}
          currentTime={value}
          onChangeSlider={onSliderChange}
          tooltipFormatter={formatSliderTooltip}
          marks={getMarks()}
          isToday={isToday}
          startOfDate={startOfDate}
          renderMark={renderEventMark}
          eventsMode={true}
        />
      )}
    </>

  );
};

export default EventsMode;
import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import Header from '../components/header/header';
import { useAppSelector } from '../redux/hooks';

export const PrivateRoute = () => {
  const user = useAppSelector((state) => state.authReducer.user);

  return (
    user !== null ?
      <div>
        <Header />
        <Outlet />
      </div>
    :
      <Navigate to="/login" replace />
  );
};

import React from "react";
import { Popover } from 'antd';

import InfoIcon from "../ui/infoIcon";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addTrackLineId, removeTrackLineId, setTrackLineIds, setTracker } from "../../redux/slices/trackerSlice";
import { Tracker, TypeTracker, StatusMovementTracker } from "../../types/tracker.type";
import { Coordinate, StatusConnection } from "../../types/common.type";
import { formatDiffDate } from "../../util/helpers";
import { TRACKERS_MAP_ZOOM } from "../../util/constants";

type TrackerItemProps = {
  tracker: Tracker;
  map?: ymaps.Map;
  withTrackLine?: boolean;
  withBorder?: boolean;
};

export default function TrackerItem({ tracker, map, withTrackLine, withBorder }: TrackerItemProps) {
  const dispatch = useAppDispatch();
  const selectedTracker = useAppSelector((state) => state.trackerReducer.tracker);
  const trackLineIds = useAppSelector((state) => state.trackerReducer.trackLineIds);

  const handleClickTracker = () => {
    dispatch(setTracker(tracker));
    map?.setCenter(tracker.coords?.coordinates as Coordinate, TRACKERS_MAP_ZOOM, { checkZoomRange: true });
  };

  const handleClickBuildTrack = () => {
    if (!tracker.tracker_info?.tracker_polyline) return;
    if (trackLineIds.includes(tracker.id)) {
      dispatch(removeTrackLineId(tracker.id));
    } else {
      dispatch(setTrackLineIds([tracker.id]))
      // dispatch(addTrackLineId(tracker.id));
      // handleClickTracker();
    }
  };

  const renderExtraTrackerInfo = (tracker: Tracker) => {
    const {
      last_position_time,
      speed,
      mileage,
      engine_hours,
      coordinates,
    } = tracker.tracker_info;

    return (
      <div className="w-[500px]">
        <div className="flex justify-between mb-4">
          <div>{tracker.name}</div>
          {last_position_time && <div>
            <div>{formatDiffDate(Date.now() / 1000 - last_position_time)}</div>
            <div>( {new Date(last_position_time * 1000).toLocaleString()} )</div>
          </div>}
        </div>
        <div className="border border-gray-300 flex text-center">
          <div className="p-3 min-w-[120px] border-r border-gray-300">{speed} км/ч</div>
          <span className="p-3 min-w-[120px] border-r border-gray-300">{mileage} км</span>
          <span className="p-3 min-w-[120px] border-r border-gray-300">{engine_hours} ч</span>
          <span className="py-1 pl-3 flex flex-col text-left">{coordinates?.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        `flex justify-between items-center font-roboto px-2 relative ${
          withBorder ? 'border-t last:border-b border-gray-200' : ''
        } hover:bg-gray-100`
      }
    >
      <div className="flex items-center">
        {!withTrackLine && <div className="w-4 h-4">
          <img src={`/images/${TypeTracker[tracker.type_tracker]}.png`} alt="" />
        </div>}
        <Popover
          content={renderExtraTrackerInfo(tracker)}
          trigger="hover"
          placement="bottomRight"
        >
          <div className="ml-2 cursor-pointer" onClick={handleClickTracker}>
            {tracker.name}
          </div>
        </Popover>
      </div>
      <div className="inline-flex">
        {!withTrackLine && <div className="cursor-pointer ml-2">
          <InfoIcon
            tooltip="Следить за объектом на карте"
            iconName={selectedTracker?.id === tracker.id ? 'icon_watch_active' : 'icon_watch'}
            iconColorClassName={selectedTracker?.id === tracker.id ? 'text-green-600' : 'text-gray-500'}
            onClick={handleClickTracker}
          />
        </div>}
        <div className="ml-2">
          <InfoIcon
            tooltip={tracker.status}
            iconName={
              tracker.status_movement === StatusMovementTracker.PARKING ?
                'icon_stop'
              : tracker.status_movement === StatusMovementTracker.STOP ?
                  'icon_stop_active'
                :
                  'icon_moving'
            }
            iconColorClassName={
              tracker.status_movement === StatusMovementTracker.TRIP ?
                'text-green-600'
              : tracker.status_movement === StatusMovementTracker.PARKING
                && tracker.status_connection === StatusConnection.OFFLINE ?
                  'text-gray-400'
                :
                  'text-red-500'
            }
          />
        </div>
        <div className="ml-2">
          <InfoIcon
            tooltip={tracker.status_connection === StatusConnection.ONLINE ? 'Объект на связи' : 'Объект не на связи'}
            iconName="icon_connection"
            iconColorClassName={tracker.status_connection === StatusConnection.ONLINE ? 'text-green-600' : 'text-gray-400'}
          />
        </div>
        {withTrackLine && <div
          className={`ml-2 ${tracker.tracker_info?.tracker_polyline ? 'cursor-pointer' : ''}`}
          onClick={handleClickBuildTrack}
        >
          <InfoIcon
            tooltip={
              tracker.tracker_info?.tracker_polyline ?
                trackLineIds.includes(tracker.id) ? 'Удалить трек' : 'Построить трек'
              : 
                'Нет информации о треке'
            }
            iconName="icon_trackline"
            iconColorClassName={
              tracker.tracker_info?.tracker_polyline ?
                trackLineIds.includes(tracker.id) ? 'text-red-500' : 'text-green-600'
              : 
                'text-gray-400'}
          />
        </div>}
      </div>
    </div>
  );
}

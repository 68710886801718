import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { YMaps } from '@pbe/react-yandex-maps';
import { LoadingOutlined } from '@ant-design/icons';
import './App.css';

import { PublicRoute } from './app/util/PublicRoute';
import { PrivateRoute } from './app/util/PrivateRoute';
import Main from './app/pages/main';
import Login from './app/pages/login';
import CommonStatistics from './app/pages/commonStatistics';
// import Statistics from './app/pages/statistics';
import FeedingReport from './app/pages/feedingReport';
import Feeding from './app/pages/feeding';
import FeedingView from './app/pages/feedingView';
import Admin from './app/pages/admin';
import TrackersList from './app/pages/admin/trackersList';
// import CamerasList from './app/pages/admin/camerasList';
import UsersList from './app/pages/admin/usersList';
import AreasList from './app/pages/admin/areasList';
import PhotosList from './app/pages/admin/photosList';
import BuildingsList from './app/pages/admin/buildingsList';
import DivisionsList from './app/pages/admin/divisionsList';
// import AislesList from './app/pages/admin/aislesList';
import FeedingEventsList from './app/pages/admin/feedingEventsList';
import { useLazyMeQuery } from './app/redux/api/auth.api';
import { useAppDispatch } from './app/redux/hooks';
import { setUser } from "./app/redux/slices/authSlice";
import { MainRoutes, AdminRoutes, StatisticsRoutes } from './app/util/routes';

function App() {
  const [getMe] = useLazyMeQuery();
  const dispatch = useAppDispatch();
  const [init, setInit] = useState(false);

  useEffect(() => {
    getMe({}).unwrap()
    .then((payload) => {
      const { token, me } = payload.data;
      if (me.id) {
        localStorage.setItem('agroToken', token);
        dispatch(setUser({ token, me }));
      }
    })
    .catch((error) => {
      console.log('error', error.message);
    })
    .finally(() => {
      setInit(true);
    });
  }, [getMe, dispatch]);

  if (!init) return (
    <div className="flex justify-center items-center h-screen">
      <LoadingOutlined style={{ fontSize: '60px' }} />
    </div>
  );
  return (
    <div className="App">
      <YMaps
        query={{
          ns: "ymaps",
          // load: "Map,Placemark,Polyline,geoObject.addon.balloon,geoObject.addon.editor,control.ZoomControl,control.RulerControl,control.Button,control.TypeSelector,meta,ObjectManager,Layer,projection.sphericalMercator",
          load: "package.full",
          apikey: process.env.REACT_APP_YMAPS_API_KEY
        }}
      >
        <Router>
          <Routes>
            <Route path={MainRoutes.main} element={<PrivateRoute />}>
              <Route index element={<Main />} />
              <Route path={`${MainRoutes.statistics}/:farm_id`} element={<CommonStatistics />} />
              <Route path={MainRoutes.feeding_report} element={<FeedingReport />} />
              {/* <Route path={`${MainRoutes.full_statistics}/:farm_id`} element={<Statistics />}>
                <Route index element={<></>} />
                <Route path={StatisticsRoutes.feeding} element={<></>} />
                <Route path={StatisticsRoutes.machines} element={<></>} />
                <Route path={StatisticsRoutes.areas} element={<></>} />
              </Route> */}
              <Route path={`${MainRoutes.feeding}/:farm_id`} element={<Feeding />} />
              <Route path={MainRoutes.feeding_view} element={<FeedingView />} />
              <Route path={MainRoutes.admin} element={<Admin />}>
                <Route index element={<TrackersList />} />
                <Route path={AdminRoutes.trackers} element={<TrackersList />} />
                {/* <Route path={AdminRoutes.cameras} element={<CamerasList />} /> */}
                <Route path={AdminRoutes.users} element={<UsersList />} />
                <Route path={AdminRoutes.areas} element={<AreasList />} />
                <Route path={AdminRoutes.photos} element={<PhotosList />} />
                <Route path={AdminRoutes.buildings} element={<BuildingsList />} />
                <Route path={AdminRoutes.divisions} element={<DivisionsList />} />
                {/* <Route path={AdminRoutes.aisles} element={<AislesList />} /> */}
                <Route path={AdminRoutes.feeding_events} element={<FeedingEventsList />} />
              </Route>
            </Route>
            <Route path={MainRoutes.main} element={<PublicRoute />}>
              <Route path={MainRoutes.login} element={<Login />} />
            </Route>
          </Routes>
        </Router>
      </YMaps>
    </div>
  );
}

export default App;

import React, { useEffect, useCallback, useState, memo } from "react";
// import { Navigate, Outlet, useBeforeUnload, useLocation } from 'react-router-dom';
import { Map, RulerControl, TypeSelector } from "@pbe/react-yandex-maps";
import { YMapsApi } from "@pbe/react-yandex-maps/typings/util/typing";
import ymaps from "yandex-maps";
import useWebSocket from 'react-use-websocket';

import ControlBlock from "../components/controlBlock/controlBlock";
import Cameras from "../components/cameras/cameras";
import Trackers from "../components/trackers/trackers";
import Areas from "../components/areas/areas";
import AreasButton from "../components/areas/areasButton";
import InfoMarkers from "../components/InfoMarkers/InfoMarkers";
import CameraImage from "../components/cameras/cameraImage";
import NewInfoMarker from "../components/InfoMarkers/NewInfoMarker";
import InfoMarkersButton from "../components/InfoMarkers/InfoMarkersButton";
import InfoMarkerForm from "../components/InfoMarkers/InfoMarkerForm";
import InfoMarkerContextMenu from "../components/InfoMarkers/infoMarkerContextMenu";
import ToolsButton from "../components/tools/toolsButton";
import ToolsPanel from "../components/tools/toolsPanel";
import DistanceTool from "../components/tools/distanceTool";
import AreaTool from "../components/tools/areaTool";
import CopterPhotosButton from "../components/copter/copterPhotosButton";
import WialonButton from "../components/redirectButtons/wialonButton";
import DevlineButton from "../components/redirectButtons/devlineButton";
import TrackLines from "../components/trackers/trackLines";
import FeedingButton from "../components/feeding/feedingButton";
import FeedingReportButton from "../components/statistics/feeding/feedingReportButton";
import Buildings from "../components/buildings/buildings";
import { useLazyGetRegionsQuery } from "../redux/api/region.api";
import { useLazyGetFarmsQuery } from "../redux/api/farm.api";
import { setRegions } from "../redux/slices/regionSlice";
import { setFarms } from "../redux/slices/farmSlice";
import { setTrackers } from "../redux/slices/trackerSlice";
import { setCameras } from "../redux/slices/cameraSlice";
import { setInfoMarker } from "../redux/slices/infoMarkerSlice";
import { setWebSocket, setWsCorrectClosed } from "../redux/slices/mapSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Coordinate, Tools } from "../types/common.type";
import { Camera } from "../types/camera.type";
import { Tracker } from "../types/tracker.type";
import { InfoMarker } from "../types/infoMarker.type";
import { apiBaseUrl } from "../util/api";
import { loadJS } from "../util/helpers";
import { Aisle } from "../types/aisle.type";
import { Grazing } from "../types/grazing.type";
import {
  MAP_TYPE_SELECTOR_POSITION,
  MAP_ZOOM_50_M,
  MIN_MAP_ZOOM,
} from "../util/constants";
import { useBeforeUnload } from "react-router-dom";

export default memo(function Main() {
  const [getRegions] = useLazyGetRegionsQuery();
  const [getFarms] = useLazyGetFarmsQuery();
  const dispatch = useAppDispatch();
  // const location = useLocation();
  const mapCenter = useAppSelector((state) => state.mapReducer.center);
  const mapZoom = useAppSelector((state) => state.mapReducer.zoom);
  // const webSocket = useAppSelector((state) => state.mapReducer.webSocket);
  // const wsCorrectClosed = useAppSelector((state) => state.mapReducer.wsCorrectClosed);
  const trackers = useAppSelector((state) => state.trackerReducer.trackers);
  const cameras = useAppSelector((state) => state.cameraReducer.cameras);
  const camera = useAppSelector((state) => state.cameraReducer.camera);
  const farm = useAppSelector((state) => state.farmReducer.farm);
  const [map, setMap] = useState<ymaps.Map>();
  const [ymaps, setYmaps] = useState<YMapsApi>();
  const [copterLayer, setCopterLayer] = useState<ymaps.Layer>();
  const [isShowAreas, setIsShowAreas] = useState(false);
  const [isShowInfoMarkers, setIsShowInfoMarkers] = useState(false);
  const [selectedInfoMarker, setSelectedInfoMarker] =
    useState<InfoMarker | null>(null);
  const [infoMarkerMenuCoords, setInfoMarkerMenuCoords] = useState<
    [number, number] | null
  >(null);
  const [isShowInfoMarkerForm, setIsShowInfoMarkerForm] = useState(false);
  const [isShowCopterPhotos, setIsShowCopterPhotos] = useState(false);
  const [newMarkerCoords, setNewMarkerCoords] = useState<Coordinate | null>(
    null
  );
  const [isShowTools, setIsShowTools] = useState(false);
  const [activeTool, setActiveTool] = useState<Tools | "">("");
  const [resultOfMesure, setResultOfMesure] = useState("");
  const [currentMapZoom, setCurrentMapZoom] = useState(mapZoom);

  useEffect(() => {
    map?.cursors.push("arrow");

    const handleWheelMap = () => {
      if (!map) return;

      setCurrentMapZoom(map.getZoom());
    };

    map?.events.add("boundschange", handleWheelMap);

    return () => {
      map?.events.remove("wheel", handleWheelMap);
    };
  }, [map]);

  useEffect(() => {
    dispatch(setWsCorrectClosed(false));
    getRegions({})
      .unwrap()
      .then((payload) => {
        dispatch(setRegions(payload.data));
      });
    getFarms({})
      .unwrap()
      .then((payload) => {
        dispatch(setFarms(payload.data));
      });
  }, [getRegions, getFarms, dispatch, setWsCorrectClosed]);

  const gettingData = useCallback<(event: WebSocketEventMap['message']) => void>((event) => {
    if (!event) return;
    //подписка на получение данных по вебсокету
    const message = JSON.parse(event.data);
    if (
      message.cameras?.aisles?.length ||
      message.cameras?.grazings?.length ||
      message.cameras?.single_cameras?.length
    ) {
      const farmSingleСameras = message.cameras.single_cameras.filter(
        (item: Camera) => item.farm_id === farm?.id
      );
      const farmAisles = message.cameras.aisles.filter(
        (item: Aisle) => item.farm_id === farm?.id
      );
      const farmGrazings = message.cameras.grazings.filter(
        (item: Grazing) => item.farm_id === farm?.id
      );
      // console.log('farmSingleСameras', farmSingleСameras);
      // console.log('farmAisles', farmAisles);
      // console.log('farmGrazings', farmGrazings);
      const existingCameras = structuredClone(cameras);
      farmSingleСameras.forEach((farmSingleСamera: Camera) => {
        const index = existingCameras?.single_cameras?.findIndex(
          (item) => item.id === farmSingleСamera.id
        );
        if (index !== -1) {
          existingCameras?.single_cameras?.splice(
            index as number,
            1,
            farmSingleСamera
          );
        }
        if (index === -1) {
          const targetSingle = existingCameras?.division_groups?.map(
            (target) =>
              target.buildings.map((build) => {
                return build.single_cameras?.find(
                  (single) => single.id === farmSingleСamera.id
                );
              })
          );
          
          const removedUndefinedFromTarget = targetSingle?.map((elem) => {
            return elem.filter(
              (target) => target !== undefined
            );
          });
          const notUndefinedTarget = removedUndefinedFromTarget?.find((elem) => elem.length);
          
           if (notUndefinedTarget?.length !== 0) {
            const singleElem = notUndefinedTarget?.[0];
            const buildingID = singleElem?.building_id;
            const building = existingCameras?.division_groups.filter(
              (group) => group.buildings.find((gr) => gr.id === buildingID)
            );
            const divisionID = building?.[0].division.id;
            const divisionIndex = existingCameras?.division_groups.findIndex(
              (group) => group.division.id === divisionID
            );
            if (divisionIndex !== undefined && divisionIndex >= 0) {
              const buildingIndex = existingCameras?.division_groups[
                divisionIndex
              ].buildings.findIndex((build) => build.id === buildingID);
              if (buildingIndex !== undefined && buildingIndex >= 0) {
                const singleIndex = existingCameras?.division_groups[
                  divisionIndex
                ].buildings[buildingIndex]?.single_cameras?.findIndex(
                  (single) => single.id === singleElem?.id
                );

                if (
                  divisionIndex !== -1 &&
                  buildingIndex !== -1 &&
                  singleIndex !== undefined &&
                  singleIndex !== -1 &&
                  singleIndex >= 0
                ) {
                  existingCameras?.division_groups[divisionIndex].buildings[
                    buildingIndex
                  ].single_cameras.splice(singleIndex, 1, farmSingleСamera);
                }
              }
            }
          }
        }
      });
      farmAisles.forEach((farmAisle: Aisle) => {
        const targetAisle = existingCameras?.division_groups?.map((target) =>
          target.buildings.map((build) => {
            return build.aisles?.find((aisle) => aisle.id === farmAisle.id);
          })
        );
        const removedUndefinedFromTarget = targetAisle?.map((elem) => {
          return elem.filter(
            (target) => target !== undefined
          );
        });
        const notUndefinedTarget = removedUndefinedFromTarget?.find((elem) => elem.length);

        if (notUndefinedTarget?.length !== 0) {
          const aisleElem = notUndefinedTarget?.[0];
          const buildingID = aisleElem?.building_id;
          const building = existingCameras?.division_groups.filter((group) =>
            group.buildings.find((gr) => gr.id === buildingID)
          );
          const divisionID = building?.[0].division.id;
          const divisionIndex = existingCameras?.division_groups.findIndex(
            (group) => group.division.id === divisionID
          );
          if (divisionIndex !== undefined && divisionIndex >= 0) {
            const buildingIndex = existingCameras?.division_groups[
              divisionIndex
            ].buildings.findIndex((build) => build.id === buildingID);
            if (buildingIndex !== undefined && buildingIndex >= 0) {
              const aisleIndex = existingCameras?.division_groups[
                divisionIndex
              ].buildings[buildingIndex].aisles.findIndex(
                (aisle) => aisle.id === aisleElem?.id
              );
              if (
                divisionIndex !== -1 &&
                buildingIndex !== -1 &&
                aisleIndex !== undefined &&
                aisleIndex !== -1 &&
                aisleIndex >= 0
              ) {
                existingCameras?.division_groups[divisionIndex].buildings[
                  buildingIndex
                ].aisles.splice(aisleIndex, 1, farmAisle);
              }
            }
          }
        }
      });
      farmGrazings.forEach((farmGrazing: Grazing) => {
        const targetGrazing = existingCameras?.division_groups?.map(
          (target) =>
            target.buildings.map((build) => {
              return build.grazings?.find(
                (grazing) => grazing.id === farmGrazing.id
              );
            })
        );
        const removedUndefinedFromTarget = targetGrazing?.map((elem) => {
          return elem.filter(
            (target) => target !== undefined
          );
        })
        const notUndefinedTarget = removedUndefinedFromTarget?.find((elem) => elem.length);

        if (notUndefinedTarget?.length !== 0) {
          const grazingElem = notUndefinedTarget?.[0];
          const buildingID = grazingElem?.building_id;
          const building = existingCameras?.division_groups.filter((group) =>
            group.buildings.find((gr) => gr.id === buildingID)
          );
          const divisionID = building?.[0].division.id;
          const divisionIndex = existingCameras?.division_groups.findIndex(
            (group) => group.division.id === divisionID
          );
          if (divisionIndex !== undefined && divisionIndex >= 0) {
            const buildingIndex = existingCameras?.division_groups[
              divisionIndex
            ].buildings.findIndex((build) => build.id === buildingID);
            if (buildingIndex !== undefined && buildingIndex >= 0) {
              const grazingIndex = existingCameras?.division_groups[
                divisionIndex
              ].buildings[buildingIndex]?.grazings?.findIndex(
                (graz) => graz.id === grazingElem?.id
              );

              if (
                divisionIndex !== -1 &&
                buildingIndex !== -1 &&
                grazingIndex !== undefined &&
                grazingIndex !== -1 &&
                grazingIndex >= 0
              ) {
                existingCameras?.division_groups[divisionIndex].buildings[
                  buildingIndex
                ].grazings.splice(grazingIndex, 1, farmGrazing);
              }
            }
          }
        }
      });

      dispatch(setCameras(existingCameras));
    }
    if (message.trackers.length) {
      const farmTrackers = message.trackers.filter(
        (item: Tracker) => item.farm_id === farm?.id
      );
      // console.log('farmTrackers', farmTrackers);
      const existingTrackers = structuredClone(trackers);
      farmTrackers.forEach((farmTracker: Tracker) => {
        const index = existingTrackers?.findIndex(
          (item) => item.id === farmTracker.id
        );
        if (index) {
          existingTrackers?.splice(index, 1, farmTracker);
        }
      });
      dispatch(setTrackers(existingTrackers));
    }
  }, [dispatch, farm, trackers, cameras]);

  const { getWebSocket } = useWebSocket(
    `${process.env.REACT_APP_WS_HOST}/?sid=${localStorage.getItem("agroToken")}`,
    {
      onMessage: gettingData,
      onOpen: () => console.log('WS соединение установлено'),
      onClose: (e) => console.log('WS соединение закрыто', e.code),
      onError: () => console.log('WS ошибка'),
      retryOnError: true,
      shouldReconnect: (event) => event.code !== 1000 && event.code !== 1001,
    }
  );

  useBeforeUnload(() =>  getWebSocket()?.close(1000));

  // const createWS = () => {
  //   const ws = new WebSocket(
  //     `${process.env.REACT_APP_WS_HOST}/?sid=${localStorage.getItem(
  //       "agroToken"
  //     )}`
  //   );
  //   dispatch(setWebSocket(ws));
  // };

  // const closeWebSocket = useCallback(() => {      
  //   if (!webSocket) return;      
  //   webSocket.close(1000);
  //   dispatch(setWebSocket(null));
  //   dispatch(setWsCorrectClosed(true));
  // }, [webSocket]);
  
  // useBeforeUnload(closeWebSocket);

  // useEffect(() => {
  //   // if (location.pathname === '/') {
  //     return () => {
  //       if (location.pathname === '/') {
  //         console.log('location', location);
          
  //         if (!webSocket?.CONNECTING) closeWebSocket();
  //       }
  //     }
  //   // }
  // }, [location, closeWebSocket]);

  // useEffect(() => {
  //   if (!webSocket) {
  //     if (!wsCorrectClosed) createWS();
  //   } else {
  //     webSocket.onopen = () => {
  //       console.log('WS соединение установлено');
  //     };
  //     webSocket.onclose = (event) => {
  //       // console.log('event', event);
        
  //       console.log('WS соединение закрыто');
  //       // if (event.code !== 1000) {
  //       //   setTimeout(() => createWS(), 1000);
  //       // }
  //     };
  //     webSocket.onerror = () => {
  //       console.log('WS ошибка');
  //     };
  //   }
  //   // gettingData();
  // }, [webSocket, wsCorrectClosed, gettingData, setWebSocket, setWsCorrectClosed]);

  // useEffect(() => {
  //   if (webSocket && !wsCorrectClosed) {
  //     return () => {
  //       console.log('out!!!!!!!!');
  //       console.log('webSocket', webSocket);
  //       webSocket?.close(1000);
  //       dispatch(setWebSocket(null));
  //       dispatch(setWsCorrectClosed(true));
  //     };
  //   }
  // }, [webSocket]);

  // useEffect(() => {
  //   const listener = () => {
  //     console.log('visibilityState', document.visibilityState);
  //     if (document.visibilityState === 'hidden') getWebSocket()?.close();
  //   };
  //   document.addEventListener('visibilitychange', listener);
  //   return () => {
  //     document.removeEventListener('visibilitychange', listener);
  //     // webSocket?.close(1000);
  //     // dispatch(setWebSocket(null));
  //     // dispatch(setWsCorrectClosed(true));
  //   };
  // }, []);

  useEffect(() => {
    if (map && ymaps && farm) {
      const layer = new ymaps.Layer(
        `${apiBaseUrl}/copter/tile/${
          farm.id
        }/?x=%x&y=%y&z=%z&sid=${localStorage.getItem("agroToken")}`,
        // @ts-ignore
        {
          projection: (ymaps as any)?.projection?.sphericalMercator,
          tileTransparent: true,
        }
      );
      setCopterLayer((oldLayer) => {
        if (oldLayer) map?.layers.remove(oldLayer);
        return layer;
      });
    }
  }, [map, ymaps, farm]);

  useEffect(() => {
    if (!copterLayer) return;
    if (isShowCopterPhotos) {
      map?.layers.add(copterLayer);
    } else {
      map?.layers.remove(copterLayer);
    }
  }, [copterLayer, isShowCopterPhotos, map?.layers]);

  const getAreasCalculate = (ymaps: any) => {
    loadJS(
      "calculateArea",
      "https://yastatic.net/s3/mapsapi-jslibs/area/0.0.1/util.calculateArea.min.js",
      document.head,
      () => {
        ymaps
          .ready([
            "util.calculateArea",
            "Layer",
            "projection.sphericalMercator",
          ])
          .then(() => {
            setYmaps(ymaps);
          });
      }
    );
  };

  const onMapClick = (e: ymaps.Event) => {
    if (!isShowInfoMarkers && !isShowTools) return;
    if (isShowInfoMarkers && !isShowTools) {
      const coords = e.get("coords");
      setNewMarkerCoords(coords);
    } else if (isShowTools) {
      if (activeTool === Tools.distance) {
        setActiveTool("");
        setTimeout(() => {
          setActiveTool(Tools.distance);
        });
      } else if (activeTool === Tools.area) {
        setActiveTool("");
        setTimeout(() => {
          setActiveTool(Tools.area);
        });
      }
      setResultOfMesure("");
    }
  };

  const handleContextMenuMap = (e: ymaps.Event) => {
    if (!isShowInfoMarkers) return;
    handleCloseInfoMarkerMenu();
    const originalEvent = e.get("domEvent").originalEvent;
    setInfoMarkerMenuCoords([
      originalEvent.clientY - 110,
      originalEvent.clientX,
    ]);
    const coords = e.get("coords");
    setNewMarkerCoords(coords);
  };

  const handleContextMenuInfoMarker = (
    e: ymaps.Event,
    infoMarker: InfoMarker
  ) => {
    e.stopPropagation();
    handleCloseInfoMarkerMenu();
    const originalEvent = e.get("domEvent").originalEvent;
    setInfoMarkerMenuCoords([
      originalEvent.clientY - 100,
      originalEvent.clientX + 30,
    ]);
    setSelectedInfoMarker(infoMarker);
  };

  const handleCloseInfoMarkerMenu = () => {
    setInfoMarkerMenuCoords(null);
    setNewMarkerCoords(null);
    setSelectedInfoMarker(null);
  };

  const handleOpenInfoMarkerForm = () => {
    dispatch(setInfoMarker(selectedInfoMarker));
    setInfoMarkerMenuCoords(null);
    setIsShowInfoMarkerForm(true);
  };

  const handleCloseInfoMarkerForm = () => {
    setIsShowInfoMarkerForm(false);
    setNewMarkerCoords(null);
    dispatch(setInfoMarker(null));
  };

  const handleClickInfoMarkerButton = () => {
    handleCloseInfoMarkerForm();
    setIsShowInfoMarkers(!isShowInfoMarkers);
  };

  const handleClickToolsButton = () => {
    setIsShowTools(!isShowTools);
    setResultOfMesure("");
  };

  const handleClickCopterPhotosButton = () => {
    setIsShowCopterPhotos(!isShowCopterPhotos);
  };

  const calculateDistance = useCallback(
    (
      distanceBetweenVertex: number,
      lastPoint?: Coordinate,
      nextPixels?: Coordinate
    ) => {
      let currentDistance = 0;
      if (lastPoint && nextPixels) {
        const projection = map?.options.get("projection", {});
        // @ts-ignore
        const nextPoint = projection.fromGlobalPixels(
          nextPixels,
          map?.getZoom()
        );
        // @ts-ignore
        const coordSystem = projection?.getCoordSystem();
        currentDistance = coordSystem.getDistance(lastPoint, nextPoint);
      }
      setResultOfMesure(
        `${(distanceBetweenVertex + currentDistance).toFixed(2)} м`
      );
    },
    [map, setResultOfMesure]
  );

  const calculateArea = useCallback(
    (polygon?: ymaps.Polygon) => {
      if (isShowTools && activeTool === Tools.area && polygon) {
        // @ts-ignore
        const area = ymaps?.util.calculateArea(polygon);
        setResultOfMesure(`${(area / 10000).toFixed(2)} га`);
      }
    },
    [ymaps, setResultOfMesure, isShowTools, activeTool]
  );

  return (
    <main className="main">
      <div className="relative">
        <Map
          state={{
            center: mapCenter,
            zoom: mapZoom,
            controls: ["zoomControl"],
          }}
          className="map"
          onClick={onMapClick}
          onContextMenu={handleContextMenuMap}
          instanceRef={(ref) => setMap(ref)}
          onLoad={(ymaps) => getAreasCalculate(ymaps)}
          options={{ suppressMapOpenBlock: true, minZoom: MIN_MAP_ZOOM }}
        >
          {/* Линейка в левом нижнем углу для удобства масштаба */}
          <RulerControl options={{ position: { bottom: 10, left: 10 } }} />

          {isShowAreas && <Areas />}
          <TrackLines />

          {currentMapZoom > MAP_ZOOM_50_M && <Cameras map={map} />}
          {currentMapZoom <= MAP_ZOOM_50_M && <Buildings map={map} />}

          <Trackers map={map} />
          {isShowInfoMarkers && (
            <InfoMarkers onContextMenu={handleContextMenuInfoMarker} />
          )}
          {newMarkerCoords && isShowInfoMarkerForm && (
            <NewInfoMarker coords={newMarkerCoords} />
          )}
          {isShowTools && activeTool === Tools.distance && (
            <DistanceTool calculateDistance={calculateDistance} />
          )}
          {isShowTools && activeTool === Tools.area && (
            <AreaTool calculateArea={calculateArea} />
          )}
          <TypeSelector
            options={{
              // @ts-ignore
              float: "none",
              position: MAP_TYPE_SELECTOR_POSITION,
              panoramasItemMode: "off",
            }}
          />

          <AreasButton
            onClick={() => setIsShowAreas(!isShowAreas)}
            selected={isShowAreas}
          />
          <InfoMarkersButton
            onClick={handleClickInfoMarkerButton}
            selected={isShowInfoMarkers}
          />
          <ToolsButton
            onClick={handleClickToolsButton}
            selected={isShowTools}
          />
          <CopterPhotosButton
            onClick={handleClickCopterPhotosButton}
            selected={isShowCopterPhotos}
          />
          <WialonButton />
          <DevlineButton />
          <FeedingButton />
          <FeedingReportButton />
        </Map>
        <ControlBlock map={map} />
        {infoMarkerMenuCoords && (
          <InfoMarkerContextMenu
            top={infoMarkerMenuCoords[0]}
            left={infoMarkerMenuCoords[1]}
            selectedInfoMarker={selectedInfoMarker}
            onClose={handleCloseInfoMarkerMenu}
            onClick={handleOpenInfoMarkerForm}
          />
        )}
        {isShowTools && (
          <ToolsPanel
            setActiveTool={setActiveTool}
            activeTool={activeTool}
            resultOfMesure={resultOfMesure}
          />
        )}
        {camera && <CameraImage />}
        {isShowInfoMarkerForm && (
          <InfoMarkerForm
            onClose={handleCloseInfoMarkerForm}
            coords={newMarkerCoords}
            setNewMarkerCoords={setNewMarkerCoords}
          />
        )}
      </div>
    </main>
  );
});

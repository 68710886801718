import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InfoMarker } from '../../types/infoMarker.type';

const initialState = {
  infoMarkers: null as InfoMarker[] | null,
  infoMarker: null as InfoMarker | null,
};

type InitialStateType = typeof initialState;

const infoMarkerSlice = createSlice({
  name: 'infoMarkerSlice',
  initialState,
  reducers: {
    setInfoMarkers: (
      state: InitialStateType,
      action: PayloadAction<InfoMarker[] | null >,
    ) => {
      state.infoMarkers = action.payload;
    },
    setInfoMarker: (
      state: InitialStateType,
      action: PayloadAction<InfoMarker | null >,
    ) => {
      state.infoMarker = action.payload;
    },
    addInfoMarker: (
      state: InitialStateType,
      action: PayloadAction<InfoMarker>,
    ) => {
      state.infoMarkers?.push(action.payload);
    },
    updateInfoMarker: (
      state: InitialStateType,
      action: PayloadAction<InfoMarker>,
    ) => {
      const index = state.infoMarkers?.findIndex((item) => item.id === action.payload.id);
      if (index) state.infoMarkers?.splice(index, 1, action.payload);
    },
    deleteInfoMarker: (
      state: InitialStateType,
      action: PayloadAction<InfoMarker>,
    ) => {
      const index = state.infoMarkers?.findIndex((item) => item.id === action.payload.id);
      if (index) state.infoMarkers?.splice(index, 1);
    },
  },
});

export const {
  setInfoMarkers,
  setInfoMarker,
  addInfoMarker,
  updateInfoMarker,
  deleteInfoMarker,
} = infoMarkerSlice.actions;
export default infoMarkerSlice.reducer;

import { useEffect } from "react";
import { Form, Select, Modal, notification } from "antd";
// import { useAppSelector } from "../../redux/hooks";

import { useCreateAreaMutation } from "../../redux/api/area.api";
import { useGetFarmsQuery } from "../../redux/api/farm.api";
import { Farm } from "../../types/farm.type";
// import { Division } from "../../types/division.type";
// import { useGetDivisionsQuery } from "../../redux/api/divisions.api";
// import { Area } from "recharts";

interface AreaFormProps {
  open: boolean;
  onCancel: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const AreasUpdateForm: React.FC<AreaFormProps> = ({
  open,
  onCancel,
  setCurrentPage,
}) => {
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  // const { data: divisionsData, isFetching: isDivisionsLoading } =
  //   useGetDivisionsQuery({});
  //   const [farmId, setFarmId] = useState(1);
  //   const [divisionId, setDivisionId] = useState();
  const [form] = Form.useForm();
  const [createArea, { isLoading: isCreateAreaLoading }] =
    useCreateAreaMutation();
  //   const area = useAppSelector((state) => state.areaOneReducer.area);
  const handleCancel = () => {
    // form.resetFields();
    onCancel();
  };
  const submitForm = () => {
    form.validateFields().then((values) => {
      createArea({ farm_id: values.farm_id })
        .unwrap()
        .then((payload) => {
          if (payload.success) {
            setCurrentPage(1);
            handleCancel();
            notification.success({
              message: "Поля созданы/обновлены",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: error.data?.error || "Ошибка создания/обновления полей",
          });
        });
    });
  };

  useEffect(() => {
    if (farmsData) {
      form.setFieldsValue(farmsData);
    }
  }, [farmsData, form]);

  return (
    <Modal
      open={open}
      title={"Редактирование полей"}
      okText={"Создать/Обновить"}
      cancelText="Закрыть"
      //   destroyOnClose={true}
      confirmLoading={isCreateAreaLoading}
      onCancel={handleCancel}
      centered
      onOk={submitForm}
    >
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        className="mt-8"
        autoComplete="off"
      >
        {/* <Form.Item
          name="name"
          label="Наименование"
          rules={[{ required: true, message: "Пожалуйста, выберите район!" }]}
        >
          <Input readOnly />
        </Form.Item> */}
        <Form.Item
          label="Хозяйство"
          name="farm_id"
          initialValue={1}
          //   rules={[{ required: true, message: "Пожалуйста, выберите файл!" }]}
        >
          <Select
            // style={{ width: 210 }}
            placeholder="Выберите хозяйство"
            loading={isFarmsLoading}
            options={farmsData?.data.map((farm: Farm) => {
              return { value: farm.id, label: farm.name };
            })}
          />
        </Form.Item>
        {/* <Form.Item label="Подразделение" name="division_id">
          <Select
            // style={{ width: 210 }}
            placeholder="Выберите подразделение"
            loading={isDivisionsLoading}
            options={divisionsData?.data.map((division: Division) => {
              return { value: division.id, label: division.name };
            })}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AreasUpdateForm;

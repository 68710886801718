import type {RcFile} from 'antd/es/upload';
import dayjs from 'dayjs';

import {DateFlag} from '../types/report.type';
import {Camera} from "../types/camera.type";
import {Coordinate, Coordinates} from "../types/common.type";
import {notification} from "antd";


export function formatDiffDate(timestamp: number) {
  let diffSec = timestamp;
  let diffMin = diffSec / 60;
  let diffHour = diffMin / 60;
  let diffDay = diffHour / 24;

  // форматирование
  if (diffSec < 1) {
    return 'прямо сейчас';
  } else if (diffMin < 1) {
    return `${Math.round(diffSec)} сек. назад`
  } else if (diffHour < 1) {
    return `${Math.round(diffMin)} мин. назад`
  } else if (diffDay < 1) {
    return `${Math.round(diffHour)} ч. назад`
  } else {
    return `${Math.round(diffDay)} дн. назад`
  }
}

export function loadJS(id: string, url: string, location: HTMLElement, onLoad?: () => void) {
  const targetScriptTag = document.getElementById(id);
  targetScriptTag?.remove();
  if (!document.getElementById(id)) {
    const scriptTag = document.createElement("script");
    location.appendChild(scriptTag);
    scriptTag.src = url;
    scriptTag.id = id;
    if (onLoad) {
      scriptTag.onload = onLoad;
    }
  }
}

export function getBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function scrollToView(parentId: string, elemId: string) {    
  const parent = document.getElementById(parentId);
  const element = document.getElementById(elemId);
  if (parent && element) {
    const parentCoords = parent?.getBoundingClientRect();
    const elementCoords = element?.getBoundingClientRect();
    if (elementCoords.y < parentCoords.y) {
      parent.scrollTop -= parentCoords.y - elementCoords.y;
    } else if (elementCoords.y + elementCoords.height > parentCoords.y + parentCoords.height) {
      parent.scrollTop += (elementCoords.y + elementCoords.height) - (parentCoords.y + parentCoords.height);
    }
  }
}

export function getDatesPlaceholder(dateFlagValue: DateFlag) {
  const now = dayjs();
  let result: [string, string] = ['От', 'До'];
  switch (dateFlagValue) {
    case DateFlag.TODAY:
      result = [now.startOf('day').format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
    case DateFlag.DAY_AGO:
      result = [now.subtract(1, 'day').format('DD.MM.YYYY'), now.subtract(1, 'day').format('DD.MM.YYYY')];
      break;
    case DateFlag.WEEK_AGO:
        result = [now.subtract(1, 'week').format('DD.MM.YYYY'), now.subtract(1, 'day').format('DD.MM.YYYY')];
        break;
    case DateFlag.MONTH_AGO:
        result = [now.subtract(1, 'month').format('DD.MM.YYYY'), now.subtract(1, 'day').format('DD.MM.YYYY')];
        break;
    case DateFlag.CURRENT_WEEK:        
      result = [now.day(1).format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
    case DateFlag.LAST_WEEK:
      result = [
        now.subtract(1, 'week').day(1).format('DD.MM.YYYY'),
        now.day(1).subtract(1, 'day').format('DD.MM.YYYY')
      ];
      break;
    case DateFlag.CURRENT_MONTH:        
      result = [now.date(1).format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
    case DateFlag.LAST_MONTH:
      result = [
        now.subtract(1, 'month').date(1).format('DD.MM.YYYY'),
        now.date(1).subtract(1, 'day').format('DD.MM.YYYY')
      ];
      break;
    case DateFlag.CURRENT_QUARTER:
      const currentMonth = now.month();
      let firstDate = now;
      if (currentMonth >= 9) {
        firstDate = now.month(9).date(1);
      } else if (currentMonth >= 6) {
        firstDate = now.month(6).date(1);
      } else if (currentMonth >= 3) {
        firstDate = now.month(3).date(1);
      } else {
        firstDate = now.month(0).date(1);
      }
      result = [firstDate.format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
    case DateFlag.CURRENT_HALF_YEAR:
      const currentMonthForHalfYear = now.month();
      let firstDateForHalfYear = now;
      if (currentMonthForHalfYear >= 6) {
        firstDateForHalfYear = now.month(6).date(1);
      } else {
        firstDateForHalfYear = now.month(0).date(1);
      }
      result = [firstDateForHalfYear.format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
    case DateFlag.CURRENT_YEAR:        
      result = [now.month(0).date(1).format('DD.MM.YYYY'), now.format('DD.MM.YYYY')];
      break;
  }
  return result;
};

export function range(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

export function convertHoursToDaysMonthsMinutes(timeInHours?: string | number ) {
  const timeNumber = timeInHours ? +timeInHours : 0;

  const days = Math.floor(timeNumber / 24);
  const remainingHours = timeNumber % 24;
  const minutes = Math.round((remainingHours % 1) * 60);

  return {
    days: days,
    hours: Math.floor(remainingHours),
    minutes: minutes
  };
}

// Функция для вычисления крайних точек координат
export function calculateExtremePoints(cameras: Camera[]): { minLat: number, maxLat: number, minLng: number, maxLng: number } | null {
  if (cameras.length === 0) {
    return null;
  }

  // Инициализация переменных минимальных и максимальных значений
  let minLat = Number.MAX_VALUE;
  let maxLat = Number.MIN_VALUE;
  let minLng = Number.MAX_VALUE;
  let maxLng = Number.MIN_VALUE;

  // Перебор всех камер и обновление минимальных и максимальных значений
  cameras.forEach((camera) => {
    if (camera.coords && camera.coords.coordinates) {
      const coordinates = camera.coords.coordinates;

      // Проверка типа координат
      if (isCoordinates(coordinates)) {
        coordinates.forEach((coordGroup: Coordinate[]) => {
          coordGroup.forEach(([lng, lat]) => {
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
            minLng = Math.min(minLng, lng);
            maxLng = Math.max(maxLng, lng);
          });
        });
      } else if (isCoordinate(coordinates)) {
        // Если координаты представлены одним массивом
        const [lng, lat] = coordinates;
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLng = Math.min(minLng, lng);
        maxLng = Math.max(maxLng, lng);
      }
    }
  });

  if (minLat === Number.MAX_VALUE || maxLat === Number.MIN_VALUE || minLng === Number.MAX_VALUE || maxLng === Number.MIN_VALUE) {
    return null;
  }

  return { minLat, maxLat, minLng, maxLng };
}

// Вспомогательные функции для проверки типов координат
function isCoordinates(coord: Coordinate | Coordinates): coord is Coordinates {
  return Array.isArray(coord[0]);
}

function isCoordinate(coord: Coordinate | Coordinates): coord is Coordinate {
  return !isCoordinates(coord);
}


// Функция для вычисления центра по экстремальным точкам
export function calculateCenterFromExtremePoints(extremePoints: { minLat: number, maxLat: number, minLng: number, maxLng: number } | null): { lat: number, lng: number } | null {
  if (extremePoints === null) {
    return null;
  }

  const avgLat = (extremePoints.minLat + extremePoints.maxLat) / 2;
  const avgLng = (extremePoints.minLng + extremePoints.maxLng) / 2;

  return { lat: avgLat, lng: avgLng };
}

export async function downloadFile(response: Response) {

  let filename = response.headers.get('filename');

  if (!filename) {
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      // const matches = contentDisposition.match(/filename\*=UTF-8''(.+)/);
      const matches = contentDisposition.split('"');
      
      if (matches && matches.length > 1) {
        filename = matches[1];
      }
    }
  }

  const result = await response.blob();
  const a = document.createElement('a'),
    url = URL.createObjectURL(result);
  a.href = url;
  a.download = `${filename}`;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export const getTwoCharValue = (value: number) => {
  return `${value ? (value < 10 ? '0' + value : value) : '00'}`
};

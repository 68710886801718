import {Divider, Modal, Typography} from "antd";
import dayjs, {Dayjs} from "dayjs";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {Title} = Typography;

interface Props {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  event_time: string;
  camera_ids: number[];
  date: Dayjs;
}

export default function ImportantEventModal(
  {
    open,
    onCancel,
    onOk,
    date,
    event_time,
    camera_ids
  }: Props
) {
  return (
    <Modal
      className={'important_event_modal'}
      title="Важное событие"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      cancelButtonProps={{danger: true}}
      okText={'Принять'}
      cancelText={'Отменить'}
    >
      <div className={'flex flex-col gap-3 pt-4'}>
        <div className={'flex justify-between items-center'}>
          <div>
            <div className={'flex gap-3'}>
              <span className={'text-gray-500'}>Дата:</span>
              <span className={'font-semibold'}>{date.format('DD.MM.YYYY')}</span>
            </div>

            <div className={'flex gap-3'}>
              <span className={'text-gray-500'}>Время:</span>
              <span className={'font-semibold'}>{event_time}</span>
            </div>
          </div>

          <ExclamationCircleOutlined style={{fontSize: '68px', color: '#eab308'}}/>
        </div>

        <div>
          <Divider/>
          <Title className={'m-0'} level={5}>Установить метку "Важное событие"?</Title>
        </div>
      </div>
    </Modal>
  )
}
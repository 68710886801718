import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseUrl} from '../../util/api';
import {
  StatisticReportRequestParams,
  TrackersReportResponseData,
  WorkedAreaReportResponseData,
} from '../../types/report.type';
import {BaseResponse} from "../../types/common.type";

export const reportsApi = createApi({
  reducerPath: 'reportsQuery',
  tagTypes: ['Reports'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getReportTrackersOnline: build.query({
      query: (farm_id) => ({
        url: `/reports/trackers_online/${farm_id}/`,
      }),
      // providesTags: [{ type: 'Reports' }],
    }),
    getReportTrackersMileage: build.mutation<BaseResponse<TrackersReportResponseData>, any>({
      query: (data) => ({
        url: `/reports/trackers_mileage/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getReportTrackersWorkedArea: build.mutation<BaseResponse<TrackersReportResponseData>, any>({
      query: (data) => ({
        url: `/reports/trackers_worked_area/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getReportWorkedArea: build.mutation<BaseResponse<WorkedAreaReportResponseData>, any>({
      query: (data) => ({
        url: `/reports/worked_area/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getReportFeedingEvents: build.mutation({
      query: (data) => ({
        url: `/reports/feeding_events/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getFeedingReport: build.mutation({
      query: (data) => ({
        url: `/reports/report_feeding/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getDetailReportFeedingEvents: build.mutation({
      query: (body) => ({
        url: `/reports/get_list_feeding_event/`,
        method: 'POST',
        body: body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getReportFarmFeedingEvents: build.mutation({
      query: (data) => ({
        url: `/reports/farm_feeding_events/${data.farm_id}/`,
        method: 'POST', 
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    generateExcelFarmReportFeedingEvents: build.mutation({
      query: (data) => ({
        url: `/reports/generate_excel_report/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    getReportBuildingFeedingEvents: build.mutation({
      query: (data) => ({
        url: `/reports/building_feeding_events/${data.building_id}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Reports' }],
    }),
    generateExcelFarmReportStatistic: build.mutation <BaseResponse<string>, StatisticReportRequestParams>({
      query: (data) => {
        const {farm_id, body, urlParams} = data
        return {
          url: `/reports/excel_reports/${farm_id}/?date_flag=${urlParams.date_flag}&report_type=${urlParams.report_type}${urlParams.date_from}${urlParams.date_to}`,
          method: 'POST',
          body: body,
          // responseHandler: async (response) => {
          //   if (response.ok) {
          //     downloadExcelFile(response)
          //   } else {
          //     notification.error({
          //       message: 'Ошибка выгрузки в Excel',
          //       description: response?.statusText || 'Попробуйте позже',
          //     });
          //   }
          // },
          //   cache: 'no-cache'
        }
      },
    }),
    checkFileReport: build.mutation<BaseResponse<string>, {uuid: string}>({
      query: (data) => {
        return {
          url: `/reports/check_file_report/${data.uuid}/`,
          method: 'POST',
        }
      },
    }),
    checkFileReportFeeding: build.query({
      query: (uuid) => ({
        url: `/reports/check_file_report/${uuid}/`,
        method: 'POST',
      }),
      // providesTags: [{ type: 'Reports' }],
    }),
  }),
});

export const { 
  useGetReportTrackersMileageMutation,
  useGetReportTrackersWorkedAreaMutation,
  useGetReportWorkedAreaMutation,
  useGetReportFeedingEventsMutation,
  useLazyGetReportTrackersOnlineQuery,
  useGetReportTrackersOnlineQuery,
  useGetDetailReportFeedingEventsMutation,
  useGetReportFarmFeedingEventsMutation,
  useGetReportBuildingFeedingEventsMutation,
  useGenerateExcelFarmReportFeedingEventsMutation,
  useGetFeedingReportMutation,
  useCheckFileReportFeedingQuery,
  useCheckFileReportMutation,
  useGenerateExcelFarmReportStatisticMutation
} = reportsApi;

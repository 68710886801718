import React, { useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, Select, notification } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';

import { useUploadPhotosMutation } from '../../redux/api/copter.api';
import { useGetFarmsQuery } from '../../redux/api/farm.api';
import { Farm } from '../../types/farm.type';
import { getBase64 } from '../../util/helpers';


const UploadForm = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [farmId, setFarmId] = useState(1);
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [uploadPhotos, { isLoading }] = useUploadPhotosMutation();

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('photos', file.originFileObj as RcFile);
    });
    const params = {
      farm_id: farmId,
      body: formData,
    };
    uploadPhotos(params).unwrap()
      .then((payload) => {
        if (payload.success) {
          setFileList([]);
          notification.success({ 
            message: 'Успешно загружено',
            duration: null,
          });
        } else {
          notification.error({ 
            message: 'Ошибка загрузки',
            description: 'Проверьте файлы или попробуйте позже',
            duration: null,
          });
        }
      })
      .catch((error) => {
        notification.error({ 
          message: error.data?.error || 'Ошибка загрузки',
          duration: null,
        });
      })
  };

  return (
    <div>
      <h3 className="text-2xl font-medium mb-4">Выберите хозяйство и добавьте не менее 3 фотографий</h3>
      <div className="mb-4">
        <Select
          style={{ width: '200px' }}
          placeholder="Выберите хозяйство"
          loading={isFarmsLoading}
          defaultValue={1}
          onChange={(value) => setFarmId(value)}
          options={farmsData?.data.map((farm: Farm) => {
            return { value: farm.id, label: farm.name };
          })}
        />
      </div>
      <div>
        {isLoading ?
          <div className="flex justify-center items-center min-h-[100px]">
            <LoadingOutlined style={{ fontSize: '30px' }} />
          </div>
        :
          <Upload
            listType="picture-card"
            multiple
            fileList={fileList}
            beforeUpload={(file) => {
              setFileList([...fileList, file]);
              return false;
            }}
            onPreview={handlePreview}
            onChange={handleChange}
            disabled={!farmId}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Добавить</div>
            </div>
          </Upload>
        }
        <div>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length < 3 || isLoading}
            style={{ marginTop: 16 }}
          >
            Загрузить
          </Button>
        </div>
      </div>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadForm;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BuildingType, Camera, CamerasState } from "../../types/camera.type";
import { Aisle } from "../../types/aisle.type";
import { Grazing } from "../../types/grazing.type";

const initialState = {
  cameras: null as CamerasState | null,
  camera: null as Camera | Aisle | Grazing | BuildingType | null,
  selectedCamerasIds: [] as number[],
};

type InitialStateType = typeof initialState;

const cameraSlice = createSlice({
  name: "cameraSlice",
  initialState,
  reducers: {
    setCameras: (
      state: InitialStateType,
      action: PayloadAction<CamerasState | null>
    ) => {
      state.cameras = action.payload;
    },
    setCamera: (
      state: InitialStateType,
      action: PayloadAction<Camera | Aisle | Grazing | BuildingType | null>
    ) => {
      state.camera = action.payload;
    },
    setSelectedCamerasIds: (
      state: InitialStateType,
      action: PayloadAction<number[]>
    ) => {
      state.selectedCamerasIds = action.payload;
    },
  },
});

export const { setCameras, setCamera, setSelectedCamerasIds } =
  cameraSlice.actions;
export default cameraSlice.reducer;

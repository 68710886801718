import React, { useEffect, useState } from "react";
import {Button, Card, Space, Form, Input, InputNumber, Tooltip} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Map, Placemark, TypeSelector } from '@pbe/react-yandex-maps';
import { v4 } from 'uuid';

import { useAppSelector } from "../../redux/hooks";
import { NewCamera, Camera } from "../../types/camera.type";
import { Coordinate } from "../../types/common.type";
import {
  CAMERAS_MAP_ZOOM,
  FARM_MAP_ZOOM,
  MAP_TYPE_SELECTOR_POSITION,
  CAMERA_MARKER_ICON_OFFSET,
  CAMERA_MARKER_ICON_SIZE,
  MAX_CAMERAS_MAP_ZOOM,
  MIN_MAP_ZOOM,
} from "../../util/constants";
import {useLazyGetCameraUuidQuery} from "../../redux/api/camera.api";

type CamerasMapProps = {
  onSetCameras: (cameras: Array<NewCamera | Camera>) => void;
  cameras?: Array<NewCamera | Camera>;
  mapCenter?: Coordinate;
};

export default function CamerasMap({ onSetCameras, cameras, mapCenter }: CamerasMapProps) {
  const initialMapCenter = useAppSelector((state) => state.mapReducer.center);
  const [map, setMap] = useState<ymaps.Map>();
  const [currentAlias, setCurrentAlias] = useState<number>();
  const [selectedCameraIndex, setSelectedCameraIndex] = useState<number>();
  const [form] = Form.useForm();
  const camerasValue = Form.useWatch('cameras', form);

  const [getUuid] = useLazyGetCameraUuidQuery();

  useEffect(() => {
    map?.cursors.push('arrow');
    let coordinate: Coordinate;
    if (mapCenter) {
      coordinate = mapCenter;
    } else if (cameras) {
      coordinate = cameras[0]?.coords?.coordinates as Coordinate;
    } else {
      coordinate = initialMapCenter;
    }
    if (coordinate) {
      map?.setCenter(coordinate, mapCenter || cameras ? CAMERAS_MAP_ZOOM : FARM_MAP_ZOOM, { checkZoomRange: true });
    }
  }, [map, mapCenter]);

  useEffect(() => {     
    if (cameras?.length) {
      form.setFieldsValue({ cameras: cameras })
    }
  }, [cameras]);

  useEffect(() => {
    const element = document.getElementById(`camera_card-${selectedCameraIndex}`);
    element?.scrollIntoView();
  }, [selectedCameraIndex]);

  const onMapClick = (e: any) => {
    const coords = e.get('coords');
    const cameras = form.getFieldValue('cameras');

    if (selectedCameraIndex !== undefined) {
      const camera = cameras[selectedCameraIndex];
      if (camera) {
        camera.coords.coordinates = coords;
        cameras[selectedCameraIndex] = camera;
        form.setFieldsValue({ cameras: cameras })
      }
    } else {
      try {
        getUuid().unwrap().then(res => {
          const uuid = res.data;

          const newCamera = {
            coords: {
              type: 'Point',
              coordinates: coords,
            },
            name: '',
            // external_id: '',
            uuid: v4(),
            unique_id: uuid,
          };
          const newCameras = cameras ? [...cameras, newCamera] : [newCamera];
          form.setFieldsValue({ cameras: newCameras });
          setSelectedCameraIndex(newCameras.length - 1);
        })
      }
      catch {
        throw new Error('Не удалось сгенерировать uuid')
      }
    }
  };

  const onFinish = ({ cameras }: { cameras: Array<NewCamera | Camera> }) => {    
    onSetCameras(cameras);
    form.setFieldsValue({ cameras: null });
  };

  const onChangeIndex = (value: number | null, currentIndex: number) => {
    if (value) {
      const cameras = structuredClone(camerasValue);
      const dubleIndex = camerasValue.findIndex((camera: NewCamera | Camera) => {
        if (camera.alias === value) return true;
        return false;
      });
      if (dubleIndex !== -1) {
        cameras[dubleIndex].alias = camerasValue[currentIndex].alias || currentAlias;
      }
      cameras[currentIndex].alias = value;
      form.setFieldsValue({ cameras });
    } else {
      setCurrentAlias(camerasValue[currentIndex].alias);
    }
  };

  const handleClickMarker = (index: number) => {
    if (selectedCameraIndex === index) {
      setSelectedCameraIndex(undefined);
    } else {
      setSelectedCameraIndex(index);
    }
  };

  const handleClickCamera = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    const target = e.target as HTMLElement;
    if (target.closest('button')?.id === 'ready_button') {
      setSelectedCameraIndex(undefined);
      setCurrentAlias(undefined);
    } else if (target.closest('.anticon')?.id === 'close_button') {
      return;
    } else {
      setSelectedCameraIndex(index);
    }
  };

  const handleRemoveCamera = (formCallback: () => void, index: number) => {
    setTimeout(() => {
      if (index === selectedCameraIndex) {
        setSelectedCameraIndex(undefined);
        formCallback();
      }
    }, 200);
  };

  const getInitialAliasValue = () => {
    let aliases = camerasValue?.map((item: Camera | NewCamera) => item.alias).sort((a: number, b: number) => b - a);        
    return aliases?.[0] ? aliases[0] + 1 : 1;
  };

  return (
    <div
      className="absolute top-14 left-6 w-[calc(100%-48px)] z-10 h-[calc(100%-70px)] flex flex-row-reverse overflow-hidden bg-white"
    >
      <div className="ml-4">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="w-[400px]"
          autoComplete="off"
        >
          <Form.Item className="flex w-full justify-end mb-4">
            <div className="mr-2">
              {/* <Button onClick={onCancel} className="mr-4">
                Назад
              </Button> */}
              <Button type="primary" htmlType="submit" disabled={/*!camerasValue?.length*/false}>
                Готово
              </Button>
            </div>
          </Form.Item>
          <Form.List name="cameras">
            {(fields, { remove }) => (
              <div id="cameras_list" className="h-[740px] overflow-y-auto scroll-smooth">
                {fields?.map(({ key, name,...restField }, index) => {
                  const currentCamera = cameras?.[index] ?? form.getFieldValue('cameras')[index];

                  return (
                    <Card
                      id={`camera_card-${index}`}
                      size="small"
                      title={
                        <span
                          className="cursor-pointer"
                        >
                          Камера {index + 1}
                      </span>
                      }
                      key={key}
                      extra={index === selectedCameraIndex ?
                        <CloseOutlined
                          id="close_button"
                          className="text-red-500"
                          onClick={() => handleRemoveCamera(() => remove(name), index)}
                        /> : null
                      }
                      className={
                        `mb-4 last:mb-0 mr-2 cursor-pointer ${index === selectedCameraIndex ?
                          'border-2 border-blue-500' : 'border-gray-300'}`
                      }
                      onClick={(e) => handleClickCamera(e, index)}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'unique_id']}
                        label="Идентификатор"
                        initialValue={currentCamera?.unique_id}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="uuid" disabled={index !== selectedCameraIndex} readOnly/>
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        label="Наименование"
                        rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
                      >
                        <Input placeholder="Введите наименование" disabled={index !== selectedCameraIndex} />
                      </Form.Item>
                      <Space size="large" className="flex justify-between">
                        {/* <Form.Item
                          {...restField}
                          name={[name, 'external_id']}
                          label="Внешний id"
                          rules={[{ required: true, message: 'Пожалуйста, введите внешний id!' }]}
                        >
                          <Input placeholder="Введите внешний id" disabled={index !== selectedCameraIndex} />
                        </Form.Item> */}
                        <Form.Item
                          {...restField}
                          name={[name, 'alias']}
                          initialValue={getInitialAliasValue()}
                          label="Индекс"
                          rules={[{ required: true, message: 'Пожалуйста, введите индекс!' }]}
                        >
                          <InputNumber
                            min={1}
                            onChange={(value) => onChangeIndex(value, index)}
                            disabled={index !== selectedCameraIndex}
                          />
                        </Form.Item>
                      </Space>
                      {index === selectedCameraIndex &&
                        <Space size="large" className="flex justify-end">
                          <Button id="ready_button" type="primary">Готово</Button>
                        </Space>
                      }
                    </Card>
                  )
                })}
              </div>
            )}
          </Form.List>
        </Form>
      </div>
      <Map
        state={{ center: initialMapCenter, zoom: FARM_MAP_ZOOM, controls: ['zoomControl'] }}
        className="cameras_map"
        onClick={onMapClick}
        instanceRef={(ref) => setMap(ref)}
        options={{ maxZoom: MAX_CAMERAS_MAP_ZOOM, suppressMapOpenBlock: true, minZoom: MIN_MAP_ZOOM }}
      >
        {camerasValue?.map((camera: NewCamera | Camera, index: number) => {
          return (
            <Placemark
              key={camera.uuid}
              geometry={camera?.coords}
              options={{
                iconLayout: 'default#image',
                iconImageHref: index === selectedCameraIndex ?
                  '/images/location_point_blue.svg' : '/images/location_point.svg',
                iconImageSize: CAMERA_MARKER_ICON_SIZE,
                iconImageOffset: CAMERA_MARKER_ICON_OFFSET,
                cursor: "pointer",
              }}
              onClick={() => handleClickMarker(index)}
            />
          );
        })}
        <TypeSelector
          options={ 
            { // @ts-ignore
              float: 'none',
              position: MAP_TYPE_SELECTOR_POSITION,
              panoramasItemMode: 'off',
            }
          }
        />
      </Map>
    </div>
  );
}

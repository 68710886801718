import React, { useRef } from "react";
import { Polygon } from '@pbe/react-yandex-maps';
// import ymaps from "yandex-maps";

import { Area } from "../../types/area.type";
import { AREA_STROKE_WIDTH, AREA_FILL_OPACITY } from "../../util/constants";

type AreaProps = {
  area: Area;
  // calculateArea: (polygon?: ymaps.Polygon) => void;
};

export default function AreaComponent({ area }: AreaProps) {
  const ref = useRef();
  // console.log('area', area);
  
  
  return (
    <Polygon
      instanceRef={ref}
      geometry={area.coords?.coordinates}
      options={{
        zIndex: 1,
        strokeColor: area.border_color,
        strokeWidth: AREA_STROKE_WIDTH,
        fillColor: area.color,
        fillOpacity: AREA_FILL_OPACITY,
      }}
      // onClick={() => calculateArea(ref.current)}
    />
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Farm } from '../../types/farm.type';

const initialState = {
  farms: null as Farm[] | null,
  farm: null as Farm | null,
};

type InitialStateType = typeof initialState;

const farmSlice = createSlice({
  name: 'farmSlice',
  initialState,
  reducers: {
    setFarms: (
      state: InitialStateType,
      action: PayloadAction<Farm[] | null>,
    ) => {
      state.farms = action.payload;
      // state.farm = action.payload[0];
    },
    setFarm: (
      state: InitialStateType,
      action: PayloadAction<Farm | null>,
    ) => {
      state.farm = action.payload;
    },
  },
});

export const { setFarms, setFarm } = farmSlice.actions;
export default farmSlice.reducer;

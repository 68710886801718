import {useDeleteToolsReportsMutation} from "../redux/api/toolsReports.api";
import {ReportType} from "../types/report.type";
import {notification} from "antd";
import {useAppDispatch} from "../redux/hooks";
import {
  resetCurrentReportTools,
  setDefaultReportTools, setIsToolsLoaded
} from "../redux/slices/commonReportsSlice";

export const useDeleteReportsTools = () => {
  const dispatch = useAppDispatch();

  const [resetTools, {isLoading, isSuccess}] = useDeleteToolsReportsMutation();

  const setSuccessReset = (reportType: ReportType) => {
    notification.success({ message: "Настройки сброшены" });
    if (reportType === ReportType.ALL) {
      dispatch(setIsToolsLoaded(false))
      dispatch(setDefaultReportTools())

      return setTimeout(() => dispatch(setIsToolsLoaded(true)), 0)
    }

    dispatch(resetCurrentReportTools(reportType))
  }

  const handleResetTools = (reportType: ReportType) => {
    resetTools({
      params: { report_type: reportType }})
      .unwrap()
      .then((payload) => {
        if (payload.success)
          setSuccessReset(reportType)
      })
      .catch((error) => {
        if (error.status === 404) {
          return setSuccessReset(reportType)
        }

        notification.error({
          message: error.data.message || "не удалось удалить настройки",
        })
      });
  }

  return {
    handleResetTools,
    isLoading,
    isSuccess,
  };
}
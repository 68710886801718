import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Area } from '../../types/area.type';

const initialState = {
  area: null as Area | null,
};

type InitialStateType = typeof initialState;

const areaOneSlice = createSlice({
  name: 'areaOneSlice',
  initialState,
  reducers: {
    setOneArea: (
      state: InitialStateType,
      action: PayloadAction<Area | null >,
    ) => {
      state.area = action.payload;
    },
  },
});

export const { setOneArea } = areaOneSlice.actions;
export default areaOneSlice.reducer;

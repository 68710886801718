export type LoginFields = {
  email: string;
  password: string;
};

export type User = {
  id: number;
  en?: boolean;
  name?: string;
  email?: string;
  role?: Role;
  farm_id?: number;
  region_id?: number;
  farm_ids?: number[];
  ctime?: string;
  atime?: string;
  dtime?: string;
  surname?: string;
  middle_name?: string;
  phone?:string;
};

export enum Role {
  ANONYMOUS = 0,
  ADMIN = 1,
  REGION = 2,
  FARM = 3,
  OPERATOR = 4,
};

export enum RoleNames {
  ANONYMOUS = 'Гость',
  ADMIN = 'Администратор',
  REGION = 'Район',
  FARM = 'Хозяйство',
  OPERATOR = 'Оператор',
};

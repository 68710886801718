import React, { memo } from "react";

import Icon from "../ui/icon";
import { Tools } from "../../types/common.type";

type ToolsPanelProps = {
  setActiveTool: React.Dispatch<React.SetStateAction<Tools | ''>>;
  activeTool: string;
  resultOfMesure: string;
};

export default memo(function ToolsPanel({ setActiveTool, activeTool, resultOfMesure }: ToolsPanelProps) {
  
  return (
    <div className="absolute right-[310px] bottom-16 bg-white border border-gray-300">
      <div className="flex items-center px-4 py-1">
        <div
          className={`${activeTool === Tools.distance ? 'border-orange-500' : 'border-transparent'} p-1 border-2 cursor-pointer`} 
          onClick={() => setActiveTool(Tools.distance)}
        >
          <Icon name="Distance" className="text-gray-700" />
        </div>
        <div
          className={`${activeTool === Tools.area ? 'border-orange-500' : 'border-transparent'} border-2 ml-4 cursor-pointer`} 
          onClick={() => setActiveTool(Tools.area)}
        >
          <Icon name="Square" size={30} className="text-gray-700" />
        </div>
      </div>
      <div className=" flex items-center border-t border-gray-300 p-2 h-[30px]">
        {resultOfMesure ? resultOfMesure : null}
      </div>
    </div>
  )
})

import React, { useEffect, useState, memo } from "react";
import { Polyline } from '@pbe/react-yandex-maps';
import ymaps from "yandex-maps";

import { Coordinate } from "../../types/common.type";
import { TOOL_STROKE_WIDTH } from "../../util/constants";

type DistanceToolProps = {
  calculateDistance: (
      distanceBetweenVertex: number,
      vertexCoords?: Coordinate,
      nextPixels?: Coordinate
    ) => void;
};

export default memo(function DistanceTool({ calculateDistance }: DistanceToolProps) {
  const [tool, setTool] = useState<ymaps.Polyline>();

  useEffect(() => {
    if (tool) {
      // @ts-ignore
      tool.editor.startDrawing();
      tool.editor.events.add('vertexdraw', (e) => {
        const distanceBetweenVertex = e.get('target').geometry.getDistance();
        const vertexCoords = e.get('target').geometry.getCoordinates();
        if (vertexCoords.length) {
          calculateDistance(distanceBetweenVertex, vertexCoords[vertexCoords.length - 1], e.get('globalPixels'));
        }
      });
      tool.editor.events.add(['vertexdragend', 'vertexadd'], (e) => {
        const distanceBetweenVertex = e.get('target').geometry.getDistance();
        calculateDistance(distanceBetweenVertex);
      });
      tool.events.add('geometrychange', (e) => {
        const distanceBetweenVertex = e.get('target').geometry.getDistance();
        calculateDistance(distanceBetweenVertex);
      });
      calculateDistance(0);
    }
  }, [tool]);
  
  return (
    <Polyline
      instanceRef={(ref) => { 
        // @ts-ignore
        setTool(ref);
      }}
      geometry={[]}
      options={{
        strokeColor: "#0000FF",
        strokeWidth: TOOL_STROKE_WIDTH,
        strokeStyle: 'solid',
      }}
    />
  )
})

import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { PlusOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

import { Division } from '../../types/division.type';
import DivisionForm from '../../components/divisions/divisionForm';
import { useGetDivisionsQuery, useDeleteDivisionMutation } from '../../redux/api/divisions.api';
import { ADMIN_TABLE_SCROLL_HEIGHT } from '../../util/constants';

// const pageLimit = 10;

const DivisionsList = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  const [openDivisionForm, setOpenDivisionForm] = useState(false);
  const [division, setDivision] = useState<Division | null>(null);

  const { data: divisionsData, isFetching: isGetDivisionsLoading, isError: isGetDivisionsError } = useGetDivisionsQuery(null);  
  const [ deleteDivision, { isLoading: isDeleteDivisionLoading } ] = useDeleteDivisionMutation();  
  
  useEffect(() => {
    if (isGetDivisionsError) {
      notification.error({
        message: 'Ошибка получения данных',
        description: 'Попробуйте позже',
      });
    }
  }, [isGetDivisionsError]);

  // const fetchTrackers = () => {
  //   const params = {
  //     // page: currentPage,
  //     // limit: pageLimit,
  //     // farm_id: 1,
  //     // region_id: 1,
  //   };
  //   getTrackers(params);
  // };

  // useEffect(() => {
  //   fetchTrackers();
  // }, [/*pageLimit, currentPage,*/getTrackers]);

  const onClickDelete = (divisionId: number) => {
    deleteDivision(divisionId).unwrap()
      .then((payload) => {
        if (payload.success) {
          notification.success({ 
            message: 'Подразделение удалено',
          });
        }
      })
      .catch((error) => {
        notification.error({ 
          message: error.data?.error || 'Ошибка удаления подразделения',
        });
      });
  };

  const renderActions = (division: Division) => {
    return (
      <div className="flex justify-between">
        <div className="text-blue-500 cursor-pointer" onClick={() => onClickEdit(division)}>
          <EditOutlined />
        </div>
        <Popconfirm
          title={`Удаление подразделения ${division.name}`}
          description={`Вы действительно хотите удалить подразделение ${division.name}?`}
          onConfirm={() => onClickDelete(division.id)}
          placement="left"
          okText="Да"
          cancelText="Нет"
        >
          <Button
            danger
            className="cursor-pointer"
            type="text"
            size="small"
            icon={<CloseOutlined className="align-baseline" />}
          />
        </Popconfirm>
      </div>
    );
  };

  const columns: ColumnsType<Division> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Хозяйство',
      dataIndex: 'farm_name',
    },
    {
      render: (_, record) => renderActions(record),
    },
  ];

  const onClickEdit = (record: Division) => {
    setDivision(record);
    setOpenDivisionForm(true);
  };

  const onCancelDivisionForm = () => {
    // if (!server) setCurrentPage(1);
    setOpenDivisionForm(false);
    setDivision(null);
    // if (!serversData?.data.items.length && currentPage > 1) {
    //   setCurrentPage((page) => page - 1);
    // }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-4 mb-8">
        <Button
          type="primary"
          className="flex items-center"
          icon={<PlusOutlined />}
          onClick={() => setOpenDivisionForm(true)}
        >
          Создать подразделение
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={divisionsData?.data || []}
        // pagination={{
        //   current: trackersData?.data.page,
        //   defaultPageSize: pageLimit,
        //   onChange: (page) => setCurrentPage(page),
        //   total: trackersData?.data.total,
        //   showSizeChanger: false,
        // }}
        pagination={false}
        loading={{
          spinning: isGetDivisionsLoading || isDeleteDivisionLoading,
          indicator: <LoadingOutlined style={{ fontSize: '30px' }} />,
        }}
        scroll={{ y: ADMIN_TABLE_SCROLL_HEIGHT }}
      />
      {openDivisionForm && <DivisionForm
        open={openDivisionForm}
        onCancel={onCancelDivisionForm}
        division={division}
      />}
    </div>
  );
};

export default DivisionsList;

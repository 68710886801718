import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./api/auth.api";
import { regionApi } from "./api/region.api";
import { cameraApi } from "./api/camera.api";
import { trackerApi } from "./api/tracker.api";
import { farmApi } from "./api/farm.api";
import { infoMarkersApi } from "./api/infoMarkers.api";
import { areaApi } from "./api/area.api";
import { userApi } from "./api/user.api";
import { copterApi } from "./api/copter.api";
import { buildingApi } from "./api/building.api";
import { aisleApi } from "./api/aisle.api";
import { scheduleApi } from "./api/schedule.api";
import { feedingApi } from "./api/feeding.api";
import { reportsApi } from "./api/reports.api";
import { divisionsApi } from "./api/divisions.api";
import { toolsReportsApi } from "./api/toolsReports.api";
import { multicamApi } from './api/multicam.api'
import authReducer from "./slices/authSlice";
import regionReducer from "./slices/regionSlice";
import cameraReducer from "./slices/cameraSlice";
import trackerReducer from "./slices/trackerSlice";
import farmReducer from "./slices/farmSlice";
import infoMarkerReducer from "./slices/infoMarkerSlice";
import areaReducer from "./slices/areaSlice";
import areaOneReducer from "./slices/areaOneSlice";
import mapReducer from "./slices/mapSlice";
import userReducer from "./slices/userSlice";
import reportsReducer from "./slices/commonReportsSlice";
import feedingReportsReducer from "./slices/feedingReportsSlice";
import multicamReducer from './slices/multicamSlice';

export const store = configureStore({
  reducer: {
    authReducer,
    regionReducer,
    cameraReducer,
    trackerReducer,
    farmReducer,
    infoMarkerReducer,
    areaReducer,
    areaOneReducer,
    mapReducer,
    userReducer,
    reportsReducer,
    feedingReportsReducer,
    multicamReducer,
    // Add the generated reducer as a specific top-level slice
    [authApi.reducerPath]: authApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [cameraApi.reducerPath]: cameraApi.reducer,
    [trackerApi.reducerPath]: trackerApi.reducer,
    [farmApi.reducerPath]: farmApi.reducer,
    [infoMarkersApi.reducerPath]: infoMarkersApi.reducer,
    [areaApi.reducerPath]: areaApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [copterApi.reducerPath]: copterApi.reducer,
    [buildingApi.reducerPath]: buildingApi.reducer,
    [aisleApi.reducerPath]: aisleApi.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [feedingApi.reducerPath]: feedingApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [divisionsApi.reducerPath]: divisionsApi.reducer,
    [toolsReportsApi.reducerPath]: toolsReportsApi.reducer,
    [multicamApi.reducerPath]: multicamApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['reportsSlice/setDates', 'mapSlice/setWebSocket'],
        // Ignore these paths in the state
        ignoredPaths: ['reportsReducer.dates.0', 'reportsReducer.dates.1', 'mapReducer.webSocket'],
      },
    }).concat(
      authApi.middleware,
      regionApi.middleware,
      cameraApi.middleware,
      trackerApi.middleware,
      farmApi.middleware,
      infoMarkersApi.middleware,
      areaApi.middleware,
      userApi.middleware,
      copterApi.middleware,
      buildingApi.middleware,
      aisleApi.middleware,
      scheduleApi.middleware,
      feedingApi.middleware,
      reportsApi.middleware,
      divisionsApi.middleware,
      toolsReportsApi.middleware,
      multicamApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

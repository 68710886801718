import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const copterApi = createApi({
  reducerPath: 'CopterQuery',
  // tagTypes: ['Copter'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    uploadPhotos: build.mutation({
      query: (data) => ({
        url: `/copter/${data.farm_id}/`,
        method: 'POST',
        body: data.body,
      }),
    }),
  }),
});

export const {
  useUploadPhotosMutation,
} = copterApi;

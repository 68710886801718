import React, { useState, useEffect } from "react";
import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';

import {Kvadrator} from "../kvadrator/kvadrator";
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setCamera } from "../../redux/slices/cameraSlice";
import { BuildingType, Camera } from "../../types/camera.type";
import { Aisle } from "../../types/aisle.type";
import { Grazing } from "../../types/grazing.type";

dayjs.extend(utc);

export default function CameraImage() {
  const data = useAppSelector((state) => state.cameraReducer.camera);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [cameras, setCameras] = useState<Camera[]>();

  useEffect(() => {
    setCameras(undefined);
    setTimeout(() => {
      if (data) {
        if (data?.hasOwnProperty('aisles')) {
          const building = data as BuildingType;
          const buildingCameras: Camera[] = [];
          building.aisles.forEach((aisle) => {
            if (aisle.cameras) buildingCameras.push(...aisle.cameras);
          });
          building.grazings.forEach((grazing) => {
            if (grazing.camera) buildingCameras.push(grazing.camera);
          });
          building.single_cameras.forEach((camera) => {
            if (camera) buildingCameras.push(camera);
          });
          setCameras(buildingCameras);
        } else if (data?.hasOwnProperty('cameras')) {
          const aisle = data as Aisle;
          setCameras([aisle.cameras?.[0] as Camera, aisle.cameras?.[aisle.cameras.length - 1] as Camera]);
        } else if (data?.hasOwnProperty('camera')) {
          const grazing = data as Grazing;
          setCameras([grazing.camera]);
        } else {
          setCameras([data as Camera]);
        }
      }
    });
  }, [data, setCameras]);

  const handleCloseIcon = () => {
    dispatch(setCamera(null));
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current < dayjs().subtract(10, 'day').startOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <div className="w-[calc(100%-400px)] h-[calc(100vh-60px)] bg-gray-700 absolute left-12 top-3 overflow-hidden">
      <div className="cameras-title px-4 py-2 flex justify-between text-xl text-gray-200">
        <DatePicker
          size="small"
          className="bg-transparent"
          locale={locale}
          allowClear={false}
          placeholder="Выберите дату"
          format="DD.MM.YYYY"
          disabledDate={disabledDate}
          defaultValue={dayjs()}
          value={date}
          onChange={(value) => setDate(value)}
        />
        <div>{data?.name}</div>
        <div className="w-32 text-end cursor-pointer">
          <CloseOutlined className="ml-4" onClick={handleCloseIcon} />
        </div>
      </div>
      <Kvadrator key={date?.unix()} date={date} cameras={cameras || []} />
    </div>
  )
}

import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, InputNumber, Select, Button, Modal, Card, Space, notification } from 'antd';
import { EnvironmentOutlined, CloseOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';

import CamerasMap from './camerasMap';
import { useUpdateBuildingMutation, useLazyGetBuildingQuery } from '../../redux/api/building.api';
// import { useGetFarmsQuery } from '../../redux/api/farm.api';
import { useGetDivisionsQuery } from '../../redux/api/divisions.api';
// import { Farm } from '../../types/farm.type';
import { Division } from '../../types/division.type';
import { Building } from '../../types/building.type';
import { Camera, NewCamera } from '../../types/camera.type';
import { Aisle, NewAisle } from '../../types/aisle.type';
import { Grazing, NewGrazing } from '../../types/grazing.type';
import { Coordinate } from '../../types/common.type';

interface UpdateBuildingFormProps {
  open: boolean;
  onCancel: () => void;
  building: Building | null;
}


const UpdateBuildingForm: React.FC<UpdateBuildingFormProps> = ({
  open,
  onCancel,
  building,
}) => {

  // const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [updateBuilding, { isLoading: isUpdateBuildingLoading }] = useUpdateBuildingMutation();  
  const [getBuilding] = useLazyGetBuildingQuery();
  const [currentAisleAlias, setCurrentAisleAlias] = useState<number>();
  const [currentGrazingAlias, setCurrentGrazingAlias] = useState<number>();
  const [openMap, setOpenMap] = useState(false);
  const [mapCenter, setMapCenter] = useState<Coordinate>();
  const [removeAisleIds, setRemoveAisleIds] = useState<number[]>([]);
  const [removeGrazingIds, setRemoveGrazingIds] = useState<number[]>([]);
  const [removeCameraIds, setRemoveCameraIds] = useState<number[]>([]);
  const [buildingDataSetting, setBuildingDataSetting] = useState(false);
  const [singleCameras, setSingleCameras] = useState<Array<Camera | NewCamera>>([]);
  const [form] = Form.useForm();
  // const farmIdValue = Form.useWatch('farm_id', form);
  const aislesValue = Form.useWatch('aisles', form);
  const grazingsValue = Form.useWatch('grazings', form);
  const camerasValue = Form.useWatch('cameras', form);
  const { data: divisionsData, isFetching: isDivisionsLoading } = useGetDivisionsQuery(null);  

  useEffect(() => {
    if (building) {
      setBuildingDataSetting(true);
      getBuilding(building.id)
      .unwrap()
      .then((payload) => {
        const allCameras = [] as Camera[];
        const buildingData = structuredClone(payload.data) as Building;
        buildingData?.aisles?.forEach((aisle) => {
          aisle.uuid = v4();
          aisle.cameras_uuids = [];
          aisle?.cameras?.forEach((camera) => {
            const uuid = v4();
            camera.uuid = uuid;
            aisle.cameras_uuids?.push(uuid);
            allCameras.push(camera);
          });
        });
        buildingData?.grazings?.forEach((grazing) => {
          grazing.uuid = v4();
          if (grazing?.camera) {
            const uuid = v4();
            grazing.camera.uuid = uuid;
            grazing.camera_uuid = uuid;
            allCameras.push(grazing?.camera);
          };
        });
        buildingData?.single_cameras?.forEach((single_camera) => {
          single_camera.uuid = v4();
          allCameras.push(single_camera);
        });
        setSingleCameras(buildingData?.single_cameras || []);
        const formData = {
          name: buildingData.name,
          // farm_id: buildingData.farm_id,
          division_id: buildingData.division_id,
          aisles: buildingData.aisles,
          grazings: buildingData.grazings,
          cameras: allCameras,
        };        
        form.setFieldsValue(formData);
        if (buildingData.aisles?.length) {
          setMapCenter(buildingData.aisles[0].coords.coordinates as Coordinate);
        } else if (buildingData.grazings?.length) {
          setMapCenter(buildingData.grazings[0].camera.coords?.coordinates as Coordinate);
        }
        setBuildingDataSetting(false);
      })
    }
  }, [building]);

  useEffect(() => {
    if (aislesValue?.[0]) {
      const lastAisle = aislesValue[aislesValue.length - 1];
      if (lastAisle && !lastAisle.uuid) {
        lastAisle.uuid = v4();
      }
    }
  }, [aislesValue]);

  useEffect(() => {
    if (grazingsValue?.[0]) {
      const lastGrazing = grazingsValue[grazingsValue.length - 1];
      if (lastGrazing && !lastGrazing.uuid) {
        lastGrazing.uuid = v4();
      }
    }
  }, [grazingsValue]);

  const onSetCameras = (cameras: Array<NewCamera | Camera>) => {
    const allCamerasUuids = cameras?.map(camera => camera.uuid);
    camerasValue?.forEach((item: any) => {
      if (item.id && !allCamerasUuids.includes(item.uuid)) {
        setRemoveCameraIds((ids) => {
          return [...ids, item.id];
        });
      }
    });
    form.setFieldValue('cameras', cameras);
    if (aislesValue) {
      aislesValue?.forEach((item: NewAisle | Aisle) => {
        if (item.cameras_uuids) {
          for (let uuid of item.cameras_uuids) {
            if (!allCamerasUuids.includes(uuid)) {
              const index = item.cameras_uuids.indexOf(uuid);
              item.cameras_uuids.splice(index, 1);
              break;
            }
          }
        }
      });
    }
    if (grazingsValue) {
      for (let item of grazingsValue) {
        if (item.camera_uuid && !allCamerasUuids.includes(item.camera_uuid)) {
          item.camera_uuid = undefined;
          break;
        }
      }
    }
    // form.validateFields(['new_cameras']);
    setOpenMap(false);
    getNotSelectedCameras();
  };

  const handleCancelForm = () => {
    form.setFieldsValue({
      name: '',
      // farm_id: 0,
      division_id: null,
      aisles: null,
      grazings: null,
      new_cameras: null,
    });
    onCancel();
    setRemoveAisleIds([]);
    setRemoveGrazingIds([]);
    setRemoveCameraIds([]);
    setOpenMap(false);
    setCurrentAisleAlias(undefined);
    setCurrentGrazingAlias(undefined);
    setSingleCameras([]);
};

  // const checkNotSelectedCamera = () => {
  //   let result = false;
  //   const allCamerasUuids = camerasValue?.map((camera: NewCamera) => camera.uuid);
  //   const allAislesCamerasUuids: string[] = [];
  //   aislesValue?.forEach((item: NewAisle) => {
  //     if (item.cameras_uuids) {
  //       item.cameras_uuids.forEach((uuid) => {
  //         allAislesCamerasUuids.push(uuid);
  //       });
  //     }
  //   });
  //   const allGrazingCamerasUuids = grazingsValue?.map((item: NewGrazing) => item.camera_uuid);
  //   if (allCamerasUuids && allCamerasUuids.length) {
  //     for (let uuid of allCamerasUuids) {
  //       if (!allAislesCamerasUuids?.includes(uuid) && !allGrazingCamerasUuids?.includes(uuid)) {
  //         result = true;
  //         break;
  //       }
  //     }
  //   }
  //   return result;
  // };

  const getNotSelectedCameras = useCallback(() => {
    let notSelectedCameras: NewCamera[] = [];
    const allAislesCamerasUuids: string[] = [];
    aislesValue?.forEach((item: NewAisle) => {
      if (item?.cameras_uuids) {
        item.cameras_uuids.forEach((uuid) => {
          allAislesCamerasUuids.push(uuid);
        });
      }
    });
    const allGrazingCamerasUuids = grazingsValue?.map((item: NewGrazing) => item?.camera_uuid);
    notSelectedCameras = camerasValue?.filter((newCamera: NewCamera) => (
      !allAislesCamerasUuids?.includes(newCamera.uuid) && !allGrazingCamerasUuids?.includes(newCamera.uuid)
    ));
    setSingleCameras(notSelectedCameras);
  }, [camerasValue, aislesValue, grazingsValue]);

  useEffect(() => {
    getNotSelectedCameras();
  }, [getNotSelectedCameras]);

  const submitForm = () => {
    form
    .validateFields()
    .then((values) => {
      // if (checkNotSelectedCamera()) {
      //   notification.error({ 
      //     message: 'Остались не выбранные камеры!',
      //     description: 'Не все камеры распределены по проходам и выпасам.',
      //   });
      // } else {        
        const body = {
          name: values.name,
          // farm_id: values.farm_id,
          division_id: values.division_id,
          aisles: values.aisles.filter((aisle: any) => aisle.id),
          grazings: values.grazings.filter((grazing: any) => grazing.id),
          aisles_new: values.aisles.filter((aisle: any) => !aisle.id),
          grazings_new: values.grazings.filter((grazing: any) => !grazing.id),
          remove_aisle_ids: removeAisleIds,
          remove_grazing_ids: removeGrazingIds,
          remove_camera_ids: removeCameraIds,
          single_cameras: singleCameras.filter((singleCamera: any) => singleCamera.id),
          single_cameras_new: singleCameras.filter((singleCamera: any) => !singleCamera.id),
        };

        const setNewAislesCameras = (aisles: NewAisle[]) => {
          aisles.forEach((aisle: NewAisle) => {
            const newCameras: NewCamera[] = [];
            const existCameras: Camera[] = [];
            aisle?.cameras_uuids?.forEach((uuid, index) => {
              const camera = camerasValue?.find((camera: NewCamera | Camera) => camera.uuid === uuid);
              if (index === 0) {
                camera.is_enter = true;
                camera.is_exit = false;
              } else if (aisle?.cameras_uuids && index === aisle?.cameras_uuids.length - 1) {
                camera.is_enter = false;
                camera.is_exit = true;
              } else {
                camera.is_enter = false;
                camera.is_exit = false;
              }
              if (camera.id) {
                existCameras.push(camera);
              } else {
                newCameras.push(camera);
              }
            });
            aisle.cameras = newCameras;
            aisle.exist_cameras = existCameras;
          });
        };
        setNewAislesCameras(body.aisles);
        setNewAislesCameras(body.aisles_new);

        const setNewGrazingsCamera = (grazings: NewGrazing[]) => {
          grazings.forEach((grazing: NewGrazing) => {
            const camera = camerasValue?.find((camera: NewCamera | Camera) => camera.uuid === grazing.camera_uuid);
            if (camera.id) {
              grazing.exist_camera = camera;
              grazing.camera = null;
            } else {
              grazing.camera = camera;
              grazing.exist_camera = null;
            }
          });
        };
        setNewGrazingsCamera(body.grazings);
        setNewGrazingsCamera(body.grazings_new);

        const params = {
          body: body,
          pk: building?.id,
        };       
        updateBuilding(params).unwrap()
        .then((payload) => {
          if (payload.success) {
            handleCancelForm();
            notification.success({ 
              message: 'Строение обновлено',
            });
          }
        })
        .catch((error) => {
          notification.error({ 
            message: 'Ошибка обновления строения',
            description: error.data?.error || 'Проверьте данные или попробуйте позже',
          });
        })
        .finally(() => {
          setRemoveAisleIds([]);
          setRemoveGrazingIds([]);
          setRemoveCameraIds([]);
          setCurrentAisleAlias(undefined);
          setCurrentGrazingAlias(undefined);
        });
      // }
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  const handleClickOpenMap = () => {
    setOpenMap(true);
    setCurrentAisleAlias(undefined);
    setCurrentGrazingAlias(undefined);
  };

  const onChangeAisleIndex = (value: number | null, currentIndex: number) => {
    if (value) {
      const aisles = structuredClone(aislesValue);
      const dubleIndex = aislesValue.findIndex((aisle: NewAisle, index: number) => {
        if (aisle.alias === value && index !== currentIndex) return true;
        return false;
      });
      if (dubleIndex !== -1) {
        aisles[dubleIndex].alias = aislesValue[currentIndex].alias || currentAisleAlias;
      }
      aisles[currentIndex].alias = value;
      form.setFieldsValue({ aisles });
    } else {
      setCurrentAisleAlias(aislesValue[currentIndex].alias);
    }
  };

  const onChangeGrazingIndex = (value: number | null, currentIndex: number) => {
    if (value) {
      const grazings = structuredClone(grazingsValue);
      const dubleIndex = grazingsValue.findIndex((grazing: NewGrazing) => {
        if (grazing.alias === value) return true;
        return false;
      });
      if (dubleIndex !== -1) {
        grazings[dubleIndex].alias = grazingsValue[currentIndex].alias || currentGrazingAlias;
      }
      grazings[currentIndex].alias = value;
      form.setFieldsValue({ grazings });
    } else {
      setCurrentGrazingAlias(grazingsValue[currentIndex].alias);
    }
  };

  const handleRemoveAisle = (formCallback: () => void, aisleIndex: number) => {
    const removingId = aislesValue[aisleIndex].id;
    if (removingId) {
      setRemoveAisleIds((ids) => {
        return [...ids, removingId];
      });
    }
    formCallback();
  };

  const handleRemoveGrazing = (formCallback: () => void, grazingIndex: number) => {
    const removingId = grazingsValue[grazingIndex].id;
    if (removingId) {
      setRemoveGrazingIds((ids) => {
        return [...ids, removingId];
      });
    }
    formCallback();
  };

  const getCameraOptionDisabled = (uuid: string | number, targetUuid: string) => {
    let result = false;
    if (aislesValue) {
      for (let aisle of aislesValue) {
        if (aisle?.uuid !== targetUuid && aisle?.cameras_uuids?.includes(uuid)) {
          result = true;
          break;
        }
      }
    }
    if (grazingsValue) {
      for (let grazing of grazingsValue) {
        if (grazing?.uuid !== targetUuid && grazing?.camera_uuid === uuid) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  const getInitialAisleAliasValue = () => {
    let aliases = aislesValue?.map((item: Aisle | NewAisle) => item?.alias).sort((a: number, b: number) => b - a);    
    return aliases?.[0] ? aliases[0] + 1 : 1;
  };

  const getInitialGrazingAliasValue = () => {
    let aliases = grazingsValue?.map((item: Grazing | NewGrazing) => item?.alias).sort((a: number, b: number) => b - a);    
    return aliases?.[0] ? aliases[0] + 1 : 1;
  };

  return (
    <Modal
      open={open}
      title={building ? `Строение ${building.name}` : 'Новое строение'}
      okText={building ? 'Сохранить' : 'Создать'}
      cancelText="Закрыть"
      destroyOnClose={true}
      okButtonProps={{
        disabled: isUpdateBuildingLoading || buildingDataSetting
      }}
      onCancel={handleCancelForm}
      centered
      width={1500}
      onOk={submitForm}
    >
      {isUpdateBuildingLoading || buildingDataSetting ?
        <div className="h-[760px] flex justify-center items-center">
          <LoadingOutlined style={{ fontSize: '60px', color: '#6b7280' }} />
        </div>
      :
        <div className="h-[760px]">
          <Form
            form={form}
            layout="vertical"
            className="flex justify-between overflow-hidden"
            autoComplete="off"
          >
            <div className="w-[350px] mt-4">
              <Form.Item
                name="name"
                label="Наименование"
                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
              >
                <Input
                  placeholder="Введите наименование"
                />
              </Form.Item>
              {/* <Form.Item
                name="farm_id"
                label="Хозяйство"
                rules={[{ required: true, message: 'Пожалуйста, выберите хозяйство!' }]}
              >
                <Select
                  placeholder="Выберите хозяйство"
                  loading={isFarmsLoading}
                  options={farmsData?.data.map((farm: Farm) => {
                    return { value: farm.id, label: farm.name };
                  })}
                />
              </Form.Item> */}
              <Form.Item
                name="division_id"
                label="Подразделение"
                rules={[{ required: true, message: 'Пожалуйста, выберите подразделение!' }]}
              >
                <Select
                  allowClear
                  placeholder="Выберите подразделение"
                  loading={isDivisionsLoading}
                  // disabled={!farmIdValue || !divisionsData?.data.length}
                  options={divisionsData?.data.map((division: Division) => {
                    return { value: division.id, label: `${division.farm_name} , ${division.name}` };
                  })}
                />
              </Form.Item>
              <Form.Item
                name="cameras"
                // rules={[{ required: true, message: 'Пожалуйста, добавьте камеры!' }]}
              >
                <Button
                  type="primary"
                  icon={<EnvironmentOutlined />}
                  className="flex items-center mt-4"
                  onClick={handleClickOpenMap}
                >
                  Редактировать камеры на карте
                </Button>
              </Form.Item>
              <Form.Item>
                <div className="mt-2">
                {singleCameras?.length ? <p>Камеры наблюдения:</p> : null}
                  {singleCameras?.map((camera: NewCamera | Camera) => {
                    return (
                      <p key={camera.uuid} className="font-medium">{camera.name}</p>
                    );
                  })}
                </div>
              </Form.Item>
            </div>
            <div className="w-[460px] ml-6">
              <Form.Item
                label="Проходы:"
              >
                <div className="max-h-[730px] overflow-y-auto">
                  <Form.List
                    name="aisles"
                    // rules={[
                    //   {
                    //     validator: async (_, names) => {
                    //       if (form.getFieldValue('new_cameras')?.length && !names) {
                    //         return Promise.reject(new Error('Пожалуйста, добавьте проход!'));
                    //       }
                    //     },
                    //   },
                    // ]}
                  >
                    {(fields, { remove, add }, { errors }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, aisleIndex) => (
                          <Card
                            size="small"
                            title={`Проход ${aisleIndex + 1}`}
                            key={key}
                            extra={
                              <CloseOutlined
                                className="text-red-500"
                                onClick={() => handleRemoveAisle(() => remove(name), aisleIndex)}
                              />
                            }
                            className="mb-4 mr-2 border-gray-300"
                          >
                            <div className={`${!aislesValue?.[aisleIndex]?.cameras_uuids?.length ? 'mb-10' : 'mb-4'}`}>
                              <Form.Item
                                {...restField}
                                name={[name, 'cameras_uuids']}
                                label="Камеры"
                                rules={[
                                  { required: true, message: 'Пожалуйста, выберите камеры!' },
                                  { type: 'array', min: 2, message: 'Пожалуйста, выберите не менее 2 камер!' },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  showSearch={false}
                                  placeholder="Выберите камеры"
                                  options={camerasValue?.map((camera: NewCamera) => {
                                    return {
                                      value: camera.uuid,
                                      label: camera.name,
                                      disabled: getCameraOptionDisabled(camera.uuid, aislesValue?.[aisleIndex]?.uuid),
                                    };
                                  })}
                                />
                              </Form.Item>
                              <div className="-mt-4">
                                {aislesValue?.[aisleIndex]?.cameras_uuids
                                  ?.map((cameraUuid: string, cameraUuidIindex: number) => {
                                    return (
                                      <p key={cameraUuidIindex}>
                                        <span>
                                          {cameraUuidIindex ?
                                            cameraUuidIindex === aislesValue?.[aisleIndex]?.cameras_uuids.length - 1 ?
                                              'ВЫХОД: '
                                            :
                                              `ДОПОЛНИТЕЛЬНАЯ КАМЕРА ${cameraUuidIindex}: `
                                          :
                                            'ВХОД: '
                                          }
                                        </span>
                                        <span className="font-medium">
                                          {camerasValue?.find((item: NewCamera) => item.uuid === cameraUuid)?.name}
                                        </span>
                                      </p>
                                    );
                                  })
                                }
                              </div>
                            </div>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label="Наименование"
                                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
                              >
                                <Input placeholder="Введите наименование" className="w-[160px]" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'min_time_event']}
                                initialValue={1}
                                label="Мин. время между событиями"
                                rules={[{ required: true, message: 'Пожалуйста, введите мин. время между событиями!' }]}
                              >
                                <InputNumber min={1} addonAfter="сек" />
                              </Form.Item>
                            </Space>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'alias']}
                                initialValue={getInitialAisleAliasValue()}
                                label="Индекс"
                                rules={[{ required: true, message: 'Пожалуйста, введите индекс!' }]}
                              >
                                <InputNumber
                                  min={1}
                                  onChange={(value) => onChangeAisleIndex(value, aisleIndex)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'delta_time']}
                                initialValue={1}
                                label="Макс. время ожидания события"
                                rules={[{ required: true, message: 'Пожалуйста, введите макс. время ожидания события!' }]}
                              >
                                <InputNumber min={1} addonAfter="мин" />
                              </Form.Item>
                            </Space>
                          </Card>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className="flex items-center"
                            disabled={!camerasValue?.length}
                          >
                            Добавить проход
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </Form.Item>
            </div>
            <div className="w-[460px] ml-6">
              <Form.Item
                label="Выпасы:"
              >
                <div className="max-h-[730px] overflow-y-auto">
                  <Form.List
                    name="grazings"
                    // rules={[
                    //   {
                    //     validator: async (_, names) => {
                    //       if (form.getFieldValue('new_cameras')?.length && !names) {
                    //         return Promise.reject(new Error('Пожалуйста, добавьте выпас!'));
                    //       }
                    //     },
                    //   },
                    // ]}
                  >
                    {(fields, { remove, add }, { errors }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, grazingIndex) => (
                          <Card
                            size="small"
                            title={`Выпас ${grazingIndex + 1}`}
                            key={key}
                            extra={
                              <CloseOutlined
                                className="text-red-500"
                                onClick={() => handleRemoveGrazing(() => remove(name), grazingIndex)}
                              />
                            }
                            className="mb-4 mr-2 border-gray-300"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'camera_uuid']}
                              label="Камера"
                              rules={[{ required: true, message: 'Пожалуйста, выберите камеру!' }]}
                            >
                              <Select
                                placeholder="Выберите камеру"
                                allowClear
                                options={camerasValue?.map((camera: NewCamera) => {
                                  return {
                                    value: camera.uuid,
                                    label: camera.name,
                                    disabled: getCameraOptionDisabled(camera.uuid, grazingsValue?.[grazingIndex]?.uuid),
                                  };
                                })}
                              />
                            </Form.Item>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                label="Наименование"
                                rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
                              >
                                <Input placeholder="Введите наименование" className="w-[160px]" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'count_detections']}
                                initialValue={1}
                                label="Предельное число событий"
                                rules={[{ required: true, message: 'Пожалуйста, введите предельное число событий!' }]}
                              >
                                <InputNumber min={1} className="w-full" />
                              </Form.Item>
                            </Space>
                            <Space size="large" className="flex justify-between">
                              <Form.Item
                                {...restField}
                                name={[name, 'alias']}
                                initialValue={getInitialGrazingAliasValue()}
                                label="Индекс"
                                rules={[{ required: true, message: 'Пожалуйста, введите индекс!' }]}
                              >
                                <InputNumber
                                  min={1}
                                  onChange={(value) => onChangeGrazingIndex(value, grazingIndex)}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'delta_time']}
                                initialValue={1}
                                label="Макс. время ожидания события"
                                rules={[{ required: true, message: 'Пожалуйста, введите макс. время ожидания события!' }]}
                              >
                                <InputNumber min={1} addonAfter="мин" />
                              </Form.Item>
                            </Space>
                          </Card>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className="flex items-center"
                            disabled={!camerasValue?.length}
                          >
                            Добавить выпас
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </Form.Item>
            </div>
          </Form>
        </div>
      }
      {openMap &&
        <CamerasMap
          onSetCameras={onSetCameras}
          cameras={camerasValue}
          mapCenter={mapCenter}
        />
      }
    </Modal>
  );
};

export default UpdateBuildingForm;

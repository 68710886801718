import React from "react";
import { Button } from '@pbe/react-yandex-maps';

import { apiBaseUrl } from '../../util/api';
import { WIALON_BUTTON_POSITION } from "../../util/constants";


export default function WialonButton() {

  const onClick = () => {
    const a = document.createElement('a'),
    url = `${process.env.REACT_APP_HOST}${apiBaseUrl}/trackers/wialon/?sid=${localStorage.getItem('agroToken')}`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  
  return (
    <Button
      options={{
        float: 'none',
        position: WIALON_BUTTON_POSITION,
        size: 'small',
      }}
      data={{ image: '/images/car.svg', title: 'модуль Техники' }}
      onClick={onClick}
    />
  );
}

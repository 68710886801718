import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, Navigate, useParams, useSearchParams} from 'react-router-dom';
import {
  CloseOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {Button, Checkbox, Form, Layout, notification, Select, Tabs} from 'antd';
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import dayjs from 'dayjs';

import {
  useGetLastFeedingMutation,
  useLazyGetFeedingsQuery,
  useLazyGetNewFeedingsQuery,
  useUpdateFeedingStatusMutation,
} from '../redux/api/feeding.api';
import {useGetFarmQuery} from '../redux/api/farm.api';
import {
  useGetBuildingsQuery,
  useLazyGetShortBuildingQuery
} from '../redux/api/building.api';
import {useAppDispatch, useAppSelector} from '../redux/hooks';
import {setFarm} from '../redux/slices/farmSlice';
import {
  FeedingEvent,
  FeedingEventComment,
  FeedingEventSide,
  FeedingEventSideNames,
  FeedingEventStatus,
  FeedingEventTransport,
  FeedingEventType,
  FeedingEventTypeNames,
} from '../types/feeding.type';
import {Role} from '../types/auth.type';
import {Building, ShortBuilding} from '../types/building.type';
import {Kvadrator} from "../components/kvadrator/kvadrator";
import { UTC_OFFSET } from '../util/constants';

const { Content, Sider } = Layout;

const Feeding: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.authReducer.user);
  const { farm_id } = useParams();  
  const [searchParams] = useSearchParams();
  const aisle_id = searchParams.get('aisle_id');
  const grazing_id = searchParams.get('grazing_id');
  const building_id = searchParams.get('building_id');
  const [feedingData, setFeedingData] = useState<FeedingEvent[]>([]);
  // const videoRef1 = useRef<HTMLVideoElement>(null);
  // const videoRef2 = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(true);
  const [currentEvent, setCurrentEvent] = useState<FeedingEvent>();
  const [lastReturnedEvent, setLastReturnedEvent] = useState<FeedingEvent>();
  const [canLastReturn, setCanLastReturn] = useState(true);
  const [isImportant, setIsImportant] = useState(false);
  const [isAisleFeeding, setIsAisleFeeding] = useState(false);
  const [eventComment, setEventComment] = useState<string | null>(null);
  const [eventTransport, setEventTransport] = useState<string | null>(null);
  const [feedingSide, setFeedingSide] = useState<FeedingEventSideNames>(FeedingEventSideNames.SIDE_1);
  const [filterItems, setFilterItems] =
    useState<Array<{ aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }>>();
  const [form] = Form.useForm();
  const buildingIdValue = Form.useWatch('building_id', form);
  const [getFeedings, { isFetching: feedingEventsLoading, isError: feedingEventsError }] = useLazyGetFeedingsQuery();
  const [getNewFeedings] = useLazyGetNewFeedingsQuery();
  const [updateFeedingStatus, { isLoading: isUpdateLoading, isError: isUpdateError }] = useUpdateFeedingStatusMutation();
  const [getLastFeeding, { isLoading: isGetLastFeedingLoading }] = useGetLastFeedingMutation();
  const { data: farmData } = useGetFarmQuery(farm_id);
  const { data: buildingsData, isLoading: buildingsDataLoading } = useGetBuildingsQuery({ farm_id });
  const [getShortBuilding, { isFetching: getShortBuildingLoading }] = useLazyGetShortBuildingQuery();

  const cameras = useMemo(() => {
    return (currentEvent?.aisle?.cameras || (currentEvent?.grazing?.camera && [currentEvent?.grazing?.camera]) ) ?? []
  }, [currentEvent]);

  const startVideoTime = useMemo(() => dayjs(currentEvent?.event_start).utcOffset(UTC_OFFSET, true).subtract(1, 'minutes'), [currentEvent]);
  const endVideoTime = useMemo(() => dayjs(currentEvent?.event_end).utcOffset(UTC_OFFSET, true).add(1, 'minutes'), [currentEvent]);
  const eventsId = useMemo(() => currentEvent?.id ? [currentEvent?.id] : [], [currentEvent]);
  const date = useMemo(() => dayjs(currentEvent?.event_start).utcOffset(UTC_OFFSET, true), [currentEvent]);

  const applyFilters = (building_id?: string | null, aisle_id?: string | null, grazing_id?: string | null) => {
    const buildingId = building_id ? building_id : form.getFieldValue('building_id');
    const itemFromForm = form.getFieldValue('item');
    let item: any = null;
    if (itemFromForm) item = JSON.parse(itemFromForm);
    const params = buildingId ? {
      building_id: buildingId,
      aisle_id: aisle_id || item?.aisle_id,
      grazing_id: grazing_id || item?.grazing_id,
    } : null;
    getFeedings({ farm_id, params }).unwrap()
    .then((payload) => {
      setFeedingData(payload.data);
      setCurrentEvent(payload.data[0]);
    })
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const buildingId = form.getFieldValue('building_id');
      const itemFromForm = form.getFieldValue('item');
      let item: any = null;
      if (itemFromForm) item = JSON.parse(itemFromForm);
      const params = buildingId ? {
        building_id: buildingId,
        aisle_id: item?.aisle_id,
        grazing_id: item?.grazing_id,
      } : null;
      getNewFeedings({ farm_id, params }).unwrap()
      .then((payload) => {
        if (payload.data.length) {
          console.log('payload.data.length')
          setFeedingData(feedingData => {
            return [...feedingData, ...payload.data];
          })
        }
      });
    }, 10000);
    return () => clearInterval(timer);
  }, [getNewFeedings]);

  useEffect(() => {
    if (farmData) dispatch(setFarm(farmData.data));
  }, [farmData]);

  useEffect(() => {
    if (!(user?.role === Role.ADMIN || user?.role === Role.OPERATOR)) return;
    if (building_id) {
      let filterItem: any;
      let filterItems: any[] = [];
      getShortBuilding(building_id).unwrap()
        .then((payload) => {
          const building = payload.data as ShortBuilding;
          if (aisle_id) {
            filterItem = JSON.stringify(
              { aisle_id: +aisle_id, name: building.aisles?.find((item) => item.id === +aisle_id)?.name }
            );
          } else if (grazing_id) {
            filterItem = JSON.stringify(
              { grazing_id: +grazing_id, name: building.grazings?.find((item) => item.id === +grazing_id)?.name }
            );
          }
          const aislesOptions = building.aisles?.map((aisle) => {
            return { aisle_id: aisle.id, name: aisle.name };
          });
          const grazingsOptions = building.grazings?.map((grazing) => {
            return { grazing_id: grazing.id, name: grazing.name };
          });
          if (aislesOptions && grazingsOptions) {
            filterItems = [...aislesOptions, ...grazingsOptions];
            setFilterItems(filterItems);
          }
            form.setFieldsValue({ building_id: building.id, item: filterItem });
        });  
    }
    applyFilters(building_id, aisle_id, grazing_id);
  }, [building_id, aisle_id, grazing_id, user]);

  useEffect(() => {    
    if (lastReturnedEvent) {
      // console.log('lastReturnedEvent')
      setCurrentEvent(lastReturnedEvent);
    }
  }, [lastReturnedEvent]);

  useEffect(() => {
    const element = document.getElementById(`${currentEvent?.id}`);
    element?.scrollIntoView();
  }, [currentEvent]);

  useEffect(() => {
    if (feedingEventsError) {
      notification.error({
        message: 'Ошибка получения данных',
        description: 'Попробуйте позже',
      });
    }
  }, [feedingEventsError]);

  useEffect(() => {
    if (isUpdateError) {
      notification.error({
        message: 'Ошибка подтверждения кормления',
      });
    }
  }, [isUpdateError]);

  useEffect(() => {
    handleChangeFeedingSide(currentEvent)
  }, [currentEvent]);

  const handleChangeFeedingSide = (event: FeedingEvent | undefined) => {
    if (!event) return;

    if (event.side) {
      const numericKeys = Object.keys(FeedingEventSide) as Array<keyof typeof FeedingEventSide>;

      for (const key of numericKeys) {
        if (FeedingEventSide[key] === event.side) {
          return setFeedingSide(FeedingEventSideNames[key])
        }
      }
    }

    return setFeedingSide(FeedingEventSideNames.SIDE_1)
  }

  const handleChangeBuilding = (value: number) => {
    form.setFieldsValue({ building_id: value, item: null });
    setCanLastReturn(true);
    let items: any;
    if (value) {
      getShortBuilding(value).unwrap()
      .then((payload) => {
        const building = payload.data as ShortBuilding;
        const aislesOptions = building.aisles?.map((aisle) => {
          return { aisle_id: aisle.id, name: aisle.name };
        });
        const grazingsOptions = building.grazings?.map((grazing) => {
          return { grazing_id: grazing.id, name: grazing.name };
        });
        if (aislesOptions && grazingsOptions) {
          items = [...aislesOptions, ...grazingsOptions];
          setFilterItems(items);
        }
      });
    }
    applyFilters();
  };

  const handleChangeFilterItem = (value: any) => {    
    form.setFieldsValue({ item: value});
    setCanLastReturn(true);
    applyFilters();
  };

  const handleClickEventItem = (event: FeedingEvent) => {
    setCurrentEvent(event);
    handleChangeFeedingSide(event)
  };

  const getFeedingEventTime = (eventStart: string, eventEnd: string) => {
    let result = '';
    const difference = dayjs(eventEnd).diff(eventStart, 'minute', true);
    const differenceMinute = Math.trunc(difference);
    const differenceSecond = Math.round(difference % 1 * 60);
    if (difference) {
      if (differenceMinute) {
        result += `${differenceMinute} мин`;
      }
      if (differenceSecond) {
        result += `  ${differenceSecond} сек`;
      }
    } else {
      result = '0';
    }
    return result;
  }

  const renderFeedingEventsList = (
    <div>
      <div className="text-center text-lg text-gray-500 font-semibold pb-2 mb-4 border-b border-gray-400">
        <h4>Список необработанных событий</h4>
        <Form
          layout="inline"
          form={form}
          className="flex w-full justify-between mt-1"
        >
          <Form.Item name="building_id">
            <Select
              allowClear
              style={{ width: '110px' }}
              placeholder="Строение"
              onChange={handleChangeBuilding}
              loading={buildingsDataLoading}
              options={buildingsData?.data.map((building: Building) => {
                return { value: building.id, label: building.name };
              })}
            />
          </Form.Item>
          <Form.Item name="item">
            <Select
              allowClear
              style={{ width: '160px' }}
              placeholder="Проход / выпас"
              onChange={handleChangeFilterItem}
              loading={getShortBuildingLoading}
              disabled={!buildingIdValue}
              options={
                filterItems?.map(
                  (item: { aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }) => {
                    return { value: JSON.stringify(item), label: item.name };
                  }
                )
              }
            />
          </Form.Item>
        </Form>
      </div>
      <div id="feeding-list" className="h-[calc(100vh-206px)] overflow-y-auto scroll-smooth pr-2">
        {feedingData.map((event: FeedingEvent) => {
          return (
            <div
              key={event.id}
              id={`${event.id}`}
              className={
                `px-4 py-2 border rounded-xl mb-4 last:mb-0 cursor-pointer hover:bg-gray-100 ${
                  event.id === currentEvent?.id ? 'bg-gray-100 border-orange-500' : 'border-gray-400'
                }`
              }
              onClick={() => handleClickEventItem(event)}
            >
              <p className="text-xl">{event.farm_name}</p>
              <p className="text-lg">{event.building_name}</p>
              {event.type === FeedingEventType.AISLE &&
                <div>
                  <p>{event.aisle_name}</p>
                  <p>Сторона: {event.side}</p>
                </div>
              }
              {event.type === FeedingEventType.GRAZING &&
                  <p>{event.grazing_name}</p>
                }
              <p>
                Разность времени:
                <span className="font-medium ml-2">
                  {getFeedingEventTime(event?.event_start as string, event?.event_end as string)}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );

  // const handleLoadVideo = () => {
  //   const parent = document.querySelector('.feeding_video_container');
  //   if (parent) {
  //     parent.classList.add('opacity-100');
  //     const videoRect = videoRef1.current?.getBoundingClientRect();
  //     if (videoRect?.width && videoRect?.height) {
  //       if (videoRect?.width > videoRect?.height) {
  //         parent.classList.add('flex-col');
  //         videoRef1.current?.classList.add('max-w-[600px]');
  //         videoRef2.current?.classList.add('max-w-[600px]');
  //       }
  //     }
  //     setIsPlaying(true);
  //   }
  // };

  // const handleClickPlay = () => {
  //   if (isPlaying) {
  //     videoRef1.current?.pause();
  //     videoRef2.current?.pause();
  //   } else {
  //     videoRef1.current?.play();
  //     videoRef2.current?.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  const handleChangeEventComment = (value: string) => {    
    setEventComment(value);
  };

  const handleChangeEventTransport = (value: string) => {
    setEventTransport(value);
  };

  const handleChangeEventSide = (value: string) => {
    setFeedingSide(value as FeedingEventSideNames)
  }

  const handleClickCheckEvent = useCallback((status: FeedingEventStatus, eventId?: number) => {
    const side = feedingSide === FeedingEventSideNames.SIDE_1 ? FeedingEventSide.SIDE_1 : FeedingEventSide.SIDE_2

    const params = {
      pk: eventId,
      body: {
        status,
        comment: eventComment,
        transport: eventTransport,
        is_important: isImportant,
        is_duplicate: status === FeedingEventStatus.REJECTED ? false : isAisleFeeding,
        side
      },
    };
    setCurrentEvent(undefined);
    updateFeedingStatus(params)
    .unwrap()
    .then(() => {
      setEventComment(null);
      setEventTransport(null);
      setIsAisleFeeding(false);
      setLastReturnedEvent(undefined);
      setCanLastReturn(true);
      applyFilters();
    });
  }, [updateFeedingStatus, eventComment, eventTransport, isImportant, isAisleFeeding, feedingSide]);

  const handleChangeImportant = (e: CheckboxChangeEvent) => {
    setIsImportant(e.target.checked);
  };

  const handleAisleFeeding = (e: CheckboxChangeEvent) => {
    setIsAisleFeeding(e.target.checked);
  };

  const renderCheckButtons = (
    <div>
      <div className="w-full flex justify-between mt-2 mb-4">
        <Button
          className="bg-green-600 text-white border-green-600"
          onClick={() => handleClickCheckEvent(FeedingEventStatus.CONFIRMED, currentEvent?.id)}
        >
          КОРМЛЕНИЕ
        </Button>
        <Button
          className="bg-red-600 text-white border-red-600"
          onClick={() => handleClickCheckEvent(FeedingEventStatus.REJECTED, currentEvent?.id)}
        >
          ЛОЖНАЯ СРАБОТКА 
        </Button>
      </div>
      {currentEvent?.type === FeedingEventType.AISLE && <div className="mt-4">
        <Checkbox onChange={handleAisleFeeding}> Кормление прохода</Checkbox>
      </div>}
      <div className="w-full flex justify-center mt-4">
        <Checkbox onChange={handleChangeImportant}> Важное событие</Checkbox>
      </div>
    </div>
  );

  const handleClickReturnLast = () => {
    setCurrentEvent(undefined);
    const buildingId = form.getFieldValue('building_id');
    const itemFromForm = form.getFieldValue('item');
    let item: any = null;
    if (itemFromForm) item = JSON.parse(itemFromForm);
    const params = {
      farm_id,
      building_id: buildingId,
      aisle_id: item?.aisle_id,
      grazing_id: item?.grazing_id,
    };
    getLastFeeding(params)
    .unwrap()
    .then((payload) => {      
      setLastReturnedEvent(payload.data);
      setEventComment(null);
      setEventTransport(null);
      applyFilters();
    })
    .catch((error) => {      
      notification.error({
        message: error.data?.error || 'Ошибка возвращения предыдущего кормления',
      });
      if (error.status === 404) {
        setCanLastReturn(false);
      }
    });
  };

  const handleClickPrev = () => {
    const currentIndex = feedingData.findIndex((item) => item.id === currentEvent?.id);
    if (currentIndex) {
      setCurrentEvent(feedingData[currentIndex - 1]);
    }
  };

  const handleClickNext = () => {
    const currentIndex = feedingData.findIndex((item) => item.id === currentEvent?.id);
    if (currentIndex < feedingData.length - 1) {
      setCurrentEvent(feedingData[currentIndex + 1]);
    }
  };

  return user?.role === Role.ADMIN || user?.role === Role.OPERATOR ?
    (
      <Layout className="feeding_page h-[calc(100vh-40px)]">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={330}
          style={{ backgroundColor: '#fff', padding: '10px' }}
        >
          {feedingEventsLoading || isUpdateLoading || isGetLastFeedingLoading ?
            <div className="flex justify-center items-center h-[300px]">
              <LoadingOutlined style={{ fontSize: '30px', color: '#6b7280' }} />
            </div>
          :
            <div>
              <Tabs
                type="card"
                size="small"
                defaultActiveKey="feeding"
                items={[
                  {
                    label: 'ТРАНСПОРТ',
                    key: 'transport',
                    disabled: true,
                    children: <div></div>,
                  },
                  {
                    label: 'КОРМЛЕНИЕ',
                    key: 'feeding',
                    children: renderFeedingEventsList,
                  },
                  {
                    label: 'СВЯЗЬ',
                    key: 'connection',
                    disabled: true,
                    children: <div></div>,
                  },
                ]}
              />
            </div>
          }
        </Sider>
        <Content>
          <div className="h-full flex flex-col justify-between bg-white border-l border-r border-gray-400 p-[10px] gap-2">
            <div className="text-center text-lg text-gray-500 font-semibold pb-3 border-b border-gray-400">
              Просмотр и обработка событий оператором
            </div>
            {feedingData.length ?
              <>
                <div className="feeding_video_container h-full flex">
                  {currentEvent?.id && (
                    <Kvadrator
                      key={currentEvent.id}
                      cameras={cameras}
                      date={date}
                      events_ids={eventsId}
                      startTime={startVideoTime}
                      endTime={endVideoTime}
                    />
                  )}
                </div>

                {/*{currentEvent?.video_files?.length ?*/}
                {/*  <>*/}

                      {/*<div className="feeding_video_container h-full flex">*/}
                        {/*<div className="mx-2">*/}
                        {/*  <p className="px-4 py-2 text-xl text-gray-600">*/}
                        {/*    Первое событие*/}
                        {/*  </p>*/}
                        {/*  <video*/}
                        {/*    ref={videoRef1}*/}
                        {/*    src={`${process.env.REACT_APP_VIDEO_HOST}${currentEvent.video_files?.[0]?.storage.url}`}*/}
                        {/*    className="max-h-[750px]"*/}
                        {/*    autoPlay*/}
                        {/*    loop*/}
                        {/*    controls*/}
                        {/*    onLoadedData={handleLoadVideo}*/}
                        {/*  ></video>*/}
                        {/*</div>*/}
                        {/*<div className="mx-2">*/}
                        {/*  <p className="px-4 py-2 text-xl text-gray-600">*/}
                        {/*    Последнее событие*/}
                        {/*  </p>*/}
                        {/*  <video*/}
                        {/*    ref={videoRef2}*/}
                        {/*    src={`${process.env.REACT_APP_VIDEO_HOST}${currentEvent.video_files?.[1]?.storage.url}`}*/}
                        {/*    className="max-h-[750px]"*/}
                        {/*    autoPlay*/}
                        {/*    loop*/}
                        {/*    controls*/}
                        {/*  ></video>*/}
                        {/*</div>*/}
                      {/*</div>*/}

                    {/*<div className="flex w-full justify-center py-1">*/}
                    {/*  <Button*/}
                    {/*    type="primary"*/}
                    {/*    icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}*/}
                    {/*    className="flex justify-center items-center"*/}
                    {/*    onClick={handleClickPlay}*/}
                    {/*    disabled={!currentEvent}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  {/*</>*/}
                {/*: null*/}
                {/*}*/}
              </>
            :
              (!feedingEventsLoading && !isUpdateLoading && !isGetLastFeedingLoading) &&
                <div className="flex justify-center items-center w-full h-full">
                  <p className="text-gray-500 text-3xl">
                    Событий пока нет
                  </p>
                </div>
            }
          </div>
        </Content>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={330}
          style={{ backgroundColor: '#fff', padding: '10px' }}
        >
          <div>
            <div className="text-center text-lg text-gray-500 font-semibold pb-3 mb-4 border-b border-gray-400">
              Дополнительная информация
            </div>
            <div className="h-fit">
              {currentEvent &&
                <div>
                  <div className="mb-4">
                    <p className="text-2xl font-semibold">{currentEvent?.building_name}</p>
                    <p className="text-xl font-medium mb-4">
                      {FeedingEventTypeNames[FeedingEventType[currentEvent?.type] as keyof typeof FeedingEventTypeNames]}
                    </p>
                    {currentEvent.type === FeedingEventType.AISLE &&
                      <div>
                        <p>{currentEvent.aisle_name}</p>
                        <p>Сторона: {currentEvent.side}</p>
                        <p>Въезд: {` ${dayjs(currentEvent.event_start).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                        <p>Выезд: {` ${dayjs(currentEvent.event_end).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                      </div>
                    }
                    {currentEvent.type === FeedingEventType.GRAZING &&
                      <div>
                        <p>{currentEvent.grazing_name}</p>
                        <p>Начало: {` ${dayjs(currentEvent.event_start).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                        <p>Окончание: {` ${dayjs(currentEvent.event_end).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                      </div>
                    }
                    <div>
                      <p>
                        Разность времени:
                        <span className="font-medium ml-2">
                          {getFeedingEventTime(currentEvent?.event_start as string, currentEvent?.event_end as string)}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <Select
                      className="w-full"
                      placeholder="Выбор комментариев к событию"
                      allowClear
                      value={eventComment}
                      onChange={handleChangeEventComment}
                      options={Object.values(FeedingEventComment).map((value) => {
                        return { value, label: value };
                      })}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      className="w-full"
                      placeholder="Выбор транспорта"
                      allowClear
                      value={eventTransport}
                      onChange={handleChangeEventTransport}
                      options={Object.values(FeedingEventTransport).map((value) => {
                        return { value, label: value };
                      })}
                    />
                  </div>
                  {currentEvent.type === FeedingEventType.GRAZING && (
                    <div className="mb-4">
                      <Select
                        className="w-full"
                        placeholder={'Выберите выгон или загон'}
                        allowClear={false}
                        value={feedingSide}
                        onChange={handleChangeEventSide}
                        options={Object.values(FeedingEventSideNames).map((value) => {
                          return { value, label: value };
                        })}
                      />
                    </div>
                  )}
                  {renderCheckButtons}
                  <div className="my-8">
                    <h4 className="text-center text-lg">Дополнительные камеры</h4>
                  </div>
                </div>
              }
            </div>
            <div>
              <div className="flex justify-center w-full mt-6">
                <Button
                  className="border-yellow-500"
                  onClick={handleClickReturnLast}
                  disabled={!canLastReturn}
                >
                  ВЕРНУТЬ ПРЕДЫДУЩЕЕ
                </Button>
              </div>
              <div className="flex justify-evenly mt-6">
                {currentEvent &&
                  <>
                    <Button
                      className="flex items-center justify-center"
                      icon={<LeftOutlined />}
                      onClick={handleClickPrev}
                    />
                    <Button
                      className="flex items-center justify-center"
                      icon={<RightOutlined />}
                      onClick={handleClickNext}
                    />
                  </>
                }
                <Link to="/">
                  <Button
                    className="flex items-center"
                    icon={<CloseOutlined />}
                  >
                    ЗАКРЫТЬ
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Sider>
      </Layout>
    )
  :
    <Navigate to="/" replace />
};

export default Feeding;
import React from "react";
import { Button } from '@pbe/react-yandex-maps';

import { apiBaseUrl } from '../../util/api';
import { DEVLINE_BUTTON_POSITION } from "../../util/constants";


export default function DevlineButton() {

  const onClick = () => {
    const a = document.createElement('a'),
    url = `${process.env.REACT_APP_HOST}${apiBaseUrl}/cameras/devline/?sid=${localStorage.getItem('agroToken')}`;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  
  return (
    <Button
      options={{
        float: 'none',
        position: DEVLINE_BUTTON_POSITION,
        size: 'small',
      }}
      data={{ image: '/images/camera.svg', title: 'модуль Видео' }}
      state={{ enabled: false }}
      // onClick={onClick}
    />
  );
}

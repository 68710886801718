import {useAppSelector} from "../redux/hooks";
import {ReportType, StatisticReportBody} from "../types/report.type";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {
  useCheckFileReportMutation,
  useGenerateExcelFarmReportStatisticMutation,
} from "../redux/api/reports.api";
import {
  getAreasForWorkedAreaReportIds,
  getBuildingsForFeedingReportIds,
  getDateFlag,
  getDates,
  getDivisionsForFeedingReportIds,
  getDivisionsForMileageReportIds,
  getDivisionsForTrackersWorkedAreaReportIds,
  getDivisionsForWorkedAreaReportIds,
  getIsDescentForMileageReport,
  getIsDescentForTrackersWorkedAreaReport,
  getIsDescentForWorkedAreaReport,
  getIsShowDivisionForMileageReport,
  getIsShowDivisionForTrackersWorkedAreaReport,
  getIsShowDivisionForWorkedAreaReport,
  getIsShowZerosForMileageReport,
  getIsShowZerosForTrackersWorkedAreaReport,
  getIsShowZerosForWorkedAreaReport,
  getIsWithoutDivisionForMileageReport,
  getIsWithoutDivisionForTrackersWorkedAreaReport,
  getIsWithoutDivisionForWorkedAreaReport,
  getTrackersForMileageReportIds,
  getTrackersForWorkedAreaReportIds
} from "../redux/slices/commonReportsSlice";
import {notification} from "antd";
import {apiBaseMediaUrl} from "../util/api";
import {useRef, useState} from "react";

const POLLING_TIMER = 1000;
const MAX_TIMEOUT = 5;

export const useDownloadStatisticReport = () => {
  const { farm_id } = useParams();

  const [loading, setLoading] = useState(false);
  const dateNow = useRef(0);

  const dates = useAppSelector(getDates);
  const date_flag = useAppSelector(getDateFlag);

  const trackersForMileageReportIds = useAppSelector(getTrackersForMileageReportIds);
  const isDescentForMileageReport = useAppSelector(getIsDescentForMileageReport);
  const isShowZerosForMileageReport = useAppSelector(getIsShowZerosForMileageReport);
  const isShowDivisionMileageReport = useAppSelector(getIsShowDivisionForMileageReport);
  const isWithoutDivisionForMileageReport = useAppSelector(getIsWithoutDivisionForMileageReport);
  const divisionsForMileageReportIds = useAppSelector(getDivisionsForMileageReportIds);

  const isDescentForTrackersWorkedAreaReport = useAppSelector(
    getIsDescentForTrackersWorkedAreaReport
  );
  const isShowZerosForTrackersWorkedAreaReport = useAppSelector(
    getIsShowZerosForTrackersWorkedAreaReport
  );
  const isShowDivisionForTrackersWorkedAreaReport = useAppSelector(
    getIsShowDivisionForTrackersWorkedAreaReport
  );
  const isWithoutDivisionForTrackersWorkedAreaReport = useAppSelector(getIsWithoutDivisionForTrackersWorkedAreaReport);
  const trackersForWorkedAreaReportIds = useAppSelector(getTrackersForWorkedAreaReportIds);
  const divisionsForTrackersWorkedAreaReportIds = useAppSelector(getDivisionsForTrackersWorkedAreaReportIds);

  const areasForWorkedAreaReportIds = useAppSelector(getAreasForWorkedAreaReportIds);
  const isDescentForWorkedAreaReport = useAppSelector(getIsDescentForWorkedAreaReport);
  const isShowZeros = useAppSelector(getIsShowZerosForWorkedAreaReport);
  const divisionForWorkedAreaIds = useAppSelector(getDivisionsForWorkedAreaReportIds);
  const isShowDivisionsForWorkedArea = useAppSelector(getIsShowDivisionForWorkedAreaReport);
  const isShowWithoutDivision = useAppSelector(getIsWithoutDivisionForWorkedAreaReport);

  const divisionsIdsForFeeding = useAppSelector(getDivisionsForFeedingReportIds);
  const buildingsIdsForDeeding = useAppSelector(getBuildingsForFeedingReportIds);


  const [getReportUuid] = useGenerateExcelFarmReportStatisticMutation();
  const [checkReport] = useCheckFileReportMutation();

  const checkRequestTimeout = () => {
    const now = Date.now();

    const differenceInMilliseconds = now - dateNow.current ;

    // Перевод разницы в минуты
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    return differenceInMinutes > MAX_TIMEOUT;
  }

  const generateExcelRequestBody = (reportType: ReportType) => {
    dateNow.current = Date.now();

    const reportAll: StatisticReportBody = {
      trackers_mileage: {
        trackers_ids: trackersForMileageReportIds,
        division_ids: divisionsForMileageReportIds,
        is_descend: isDescentForMileageReport,
        is_show_zeros: isShowZerosForMileageReport,
        is_without_division: isWithoutDivisionForMileageReport,
        is_show_division: isShowDivisionMileageReport,
      },
      trackers_worked_area: {
        trackers_ids: trackersForWorkedAreaReportIds,
        is_show_zeros: isShowZerosForTrackersWorkedAreaReport,
        is_descend: isDescentForTrackersWorkedAreaReport,
        is_without_division: isWithoutDivisionForTrackersWorkedAreaReport,
        division_ids: divisionsForTrackersWorkedAreaReportIds,
        is_show_division: isShowDivisionForTrackersWorkedAreaReport
      },
      worked_area: {
        area_ids: areasForWorkedAreaReportIds,
        division_ids: divisionForWorkedAreaIds,
        is_descend: isDescentForWorkedAreaReport,
        is_show_zeros: isShowZeros,
        is_show_division: isShowDivisionsForWorkedArea,
        is_without_division: isShowWithoutDivision,
      },
      report_feeding: {
        division_ids: divisionsIdsForFeeding,
        building_ids: buildingsIdsForDeeding,
      },
    };

    if (reportType === ReportType.TRACKERS_MILEAGE) {
      return { trackers_mileage: reportAll.trackers_mileage };
    }
    if (reportType === ReportType.TRACKERS_WORKED_AREA) {
      return { trackers_worked_area: reportAll.trackers_worked_area };
    }
    if (reportType === ReportType.WORKED_AREA) {
      return { worked_area: reportAll.worked_area };
    }
    if (reportType === ReportType.REPORT_FEEDING) {
      return { report_feeding: reportAll.report_feeding };
    }

    return reportAll;
  };

  const downloadReport = (uuid: string) => {
    if (checkRequestTimeout()) {
      setLoading(false);

      return notification.error({
        message: 'Превышение времени ожидания',
        description: 'Попробуйте еще раз',
      });
    }

    checkReport({uuid})
      .unwrap()
      .then(res => {
        const a = document.createElement('a');

        a.href = `${apiBaseMediaUrl}${res.data}`;
        document.body.appendChild(a);
        a.click();

        setTimeout(function () {
          document.body.removeChild(a);
          setLoading(false);
        }, 0);
      })
      .catch(e => {
        if (e.status === 404) {
          setTimeout(() => {downloadReport(uuid)}, POLLING_TIMER)
        }
      })
  }

  const downloadStatisticReport = (reportType: ReportType) => {
    setLoading(true);

    const dateFrom = dates
      ? `&date_from=${dayjs(dates[0] || "").format("YYYY-MM-DD")}`
      : "";
    const dateTo = dates
      ? `&date_to=${dayjs(dates[1] || "").format("YYYY-MM-DD")}`
      : "";

    getReportUuid({
      farm_id: Number(farm_id),
      urlParams: {
        report_type: reportType,
        date_from: dateFrom,
        date_to: dateTo,
        date_flag: date_flag,
      },
      body: generateExcelRequestBody(reportType),
    })
      .unwrap()
      .then(res => {
        if (res.data) {
          downloadReport(res.data)
        }
      })
      .catch(e => {
        notification.error({
          message: 'Ошибка выгрузки в Excel',
          description: 'Попробуйте позже',
        });
      })
  };

  return {downloadStatisticReport, isLoading: loading };
};

import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

// import { useAppDispatch } from "../../redux/hooks";
import { User, Role, RoleNames } from "../../types/auth.type";
import UserForm from "../../components/users/userForm";
import {
  useGetUsersQuery,
  useDeleteUserMutation,
} from "../../redux/api/user.api";
// import { setUser } from '../../redux/slices/userSlice';
import { ADMIN_TABLE_SCROLL_HEIGHT } from "../../util/constants";

// const pageLimit = 10;

const UsersList = () => {
  // const dispatch = useAppDispatch();

  // const [currentPage, setCurrentPage] = useState(1);
  const [openUserForm, setOpenUserForm] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const {
    data: usersData,
    isLoading: isGetUsersLoading,
    isError: isGetUsersError,
  } = useGetUsersQuery({});
  const [deleteUser, { isLoading: isDeleteUserLoading }] =
    useDeleteUserMutation();

  useEffect(() => {
    if (isGetUsersError) {
      notification.error({
        message: "Ошибка получения данных",
        description: "Попробуйте позже",
      });
    }
  }, [isGetUsersError]);

  const onClickDelete = (serverId: number) => {
    deleteUser(serverId)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          notification.success({
            message: "Пользователь удалён",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: error.data?.error || "Ошибка удаления пользователя",
        });
      });
  };

  const renderActions = (tracker: User) => {
    return (
      <div className="flex justify-between">
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => onClickEdit(tracker)}
        >
          <EditOutlined />
        </div>
        <Popconfirm
          title={`Удаление пользователя ${tracker.name}`}
          description={`Вы действительно хотите удалить пользователя ${tracker.name}?`}
          onConfirm={() => onClickDelete(tracker.id)}
          placement="left"
          okText="Да"
          cancelText="Нет"
        >
          <Button
            danger
            className="cursor-pointer"
            type="text"
            size="small"
            icon={<CloseOutlined className="align-baseline" />}
          />
        </Popconfirm>
      </div>
    );
  };

  const columns: ColumnsType<User> = [
    {
      title: "Имя",
      dataIndex: "name",
    },
    {
      title: "Фамилия",
      dataIndex: "surname",
    },
    {
      title: "Отчество",
      dataIndex: "middle_name",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Роль",
      dataIndex: "role",
      render: (role) => RoleNames[Role[role] as keyof typeof RoleNames],
    },
    {
      title: "Район",
      dataIndex: "region_name",
    },
    {
      title: "Хозяйство",
      dataIndex: "farm_name",
    },
    {
      render: (_, record) => renderActions(record),
    },
  ];

  const onClickEdit = (record: User) => {
    setUser(record);
    setOpenUserForm(true);
  };

  const onCancelUserForm = () => {
    // if (!server) setCurrentPage(1);
    setUser(null);
    setOpenUserForm(false);
    // if (!serversData?.data.items.length && currentPage > 1) {
    //   setCurrentPage((page) => page - 1);
    // }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-4 mb-8">
        <Button
          type="primary"
          className="flex items-center"
          icon={<PlusOutlined />}
          onClick={() => setOpenUserForm(true)}
        >
          Создать пользователя
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={usersData?.data || []}
        // pagination={{
        //   current: trackersData?.data.page,
        //   defaultPageSize: pageLimit,
        //   onChange: (page) => setCurrentPage(page),
        //   total: trackersData?.data.total,
        //   showSizeChanger: false,
        // }}
        pagination={false}
        loading={{
          spinning: isGetUsersLoading || isDeleteUserLoading,
          indicator: <LoadingOutlined style={{ fontSize: "30px" }} />,
        }}
        scroll={{ y: ADMIN_TABLE_SCROLL_HEIGHT }}
      />
      {openUserForm && (
        <UserForm open={openUserForm} onCancel={onCancelUserForm} user={user} />
      )}
    </div>
  );
};

export default UsersList;

import React, { useEffect, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  useCreateTrackerMutation,
  useUpdateTrackerMutation,
} from "../../redux/api/tracker.api";
import { useGetFarmsQuery } from "../../redux/api/farm.api";
import { useAppSelector } from "../../redux/hooks";
import { Farm } from "../../types/farm.type";
import { TypeTracker, TypeTrackerNames } from "../../types/tracker.type";
import { useLazyGetDivisionsQuery } from "../../redux/api/divisions.api";
import { Division } from "../../types/division.type";
import dayjs, { Dayjs } from "dayjs";
import { UTC_OFFSET } from "../../util/constants";

const { TextArea } = Input;

interface TrackerFormProps {
  open: boolean;
  onCancel: () => void;
  fetchTrackers: () => void;
}

const TrackerForm: React.FC<TrackerFormProps> = ({
  open,
  onCancel,
  fetchTrackers,
}) => {
  const tracker = useAppSelector((state) => state.trackerReducer.tracker);
  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [selectedFarmId, setSelectedFarmId] = useState(1);
  const [
    getDivisions,
    { data: divisionsData, isFetching: isDivisionsLoading },
  ] = useLazyGetDivisionsQuery();
  const [form] = Form.useForm();
  const [createTracker, { isLoading: isCreateTrackerLoading }] =
    useCreateTrackerMutation();
  const [updateTracker, { isLoading: isUpdateTrackerLoading }] =
    useUpdateTrackerMutation();

  const [isBreakdown, setIsBreakdown] = useState(false);

  useEffect(() => {
    getDivisions({ farm_id: selectedFarmId });
  }, [selectedFarmId, getDivisions]);

  useEffect(() => {
    if (tracker) {
      setSelectedFarmId(tracker.farm_id);
      form.setFieldsValue({
        ...tracker,
        is_not_breakdown: !tracker.is_not_breakdown,
      });
      if (tracker.breakdown_datetime) {
        form.setFieldValue(
          "breakdown_date",
          dayjs(tracker?.breakdown_datetime).add(UTC_OFFSET, "minute")
        );
      }
      setIsBreakdown(!tracker.is_not_breakdown);
    }
  }, [tracker]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const disabledDate = (current: dayjs.Dayjs | null) => {
    // Заблокировать все даты начиная с завтрашнего дня
    return current ? current.isAfter(dayjs().endOf("day")) : false;
  };

  const disabledTime = (current: Dayjs | null): any => {
    const now = dayjs();

    if (current && current.isSame(now, "day")) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => (i > now.hour() ? i : null)),
        disabledMinutes: () =>
          Array.from({ length: 60 }, (_, i) => (i > now.minute() ? i : null)),
      };
    }

    return {};
  };

  const handleOpenRepairDatePicker = () => {
    form.setFieldValue("repair_date", dayjs());
  };

  const submitForm = () => {
    form
      .validateFields()
      .then((values) => {
        if (tracker) {
          const breakdown_datetime = isBreakdown
            ? values.breakdown_date
            : dayjs(values.repair_date).subtract(UTC_OFFSET, "minute");
          let { breakdown_date, repair_date, ...paramsValues } = values;
          paramsValues = paramsValues.division_id
            ? paramsValues
            : { ...paramsValues, remove_division: tracker.division_name };
          const params = {
            body: {
              ...paramsValues,
              is_not_breakdown: !isBreakdown,
              breakdown_datetime: breakdown_datetime
                ? dayjs(breakdown_datetime).toISOString()
                : breakdown_datetime,
            },
            pk: tracker.id,
          };

          updateTracker(params)
            .unwrap()
            .then((payload) => {
              if (payload.success) {
                handleCancel();
                notification.success({
                  message: "Трекер обновлён",
                });
                fetchTrackers();
              }
            })
            .catch((error) => {
              notification.error({
                message: "Ошибка обновления трекера",
                description:
                  error.data?.error || "Проверьте данные или попробуйте позже",
              });
            });
        } else {
          createTracker(values)
            .unwrap()
            .then((payload) => {
              if (payload.success) {
                handleCancel();
                notification.success({
                  message: "Трекер создан",
                });
                fetchTrackers();
              }
            })
            .catch((error) => {
              notification.error({
                message: "Ошибка создания трекера",
                description:
                  error.data?.error || "Проверьте данные или попробуйте позже",
              });
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      open={open}
      title={tracker ? `Трекер ${tracker.name}` : "Новый трекер"}
      okText={tracker ? "Сохранить" : "Создать"}
      cancelText="Закрыть"
      destroyOnClose={true}
      // confirmLoading={isCreateTrackerLoading || isUpdateTrackerLoading}
      okButtonProps={{
        disabled: isCreateTrackerLoading || isUpdateTrackerLoading,
      }}
      onCancel={handleCancel}
      centered
      onOk={submitForm}
    >
      <div className="h-fit mt-8 transition-all">
        {isCreateTrackerLoading || isUpdateTrackerLoading ? (
          <div className="h-full flex justify-center items-center">
            <LoadingOutlined style={{ fontSize: "30px", color: "#6b7280" }} />
          </div>
        ) : (
          <Form form={form} layout="vertical" autoComplete="off">
            <Form.Item
              name="name"
              label="Наименование"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!",
                },
              ]}
            >
              <Input placeholder="Введите наименование" />
            </Form.Item>
            <Form.Item
              name="farm_id"
              label="Хозяйство"
              initialValue={1}
              rules={[
                { required: true, message: "Пожалуйста, выберите хозяйство!" },
              ]}
            >
              <Select
                onChange={(value) => setSelectedFarmId(value)}
                placeholder="Выберите хозяйство"
                loading={isFarmsLoading}
                options={farmsData?.data.map((farm: Farm) => {
                  return { value: farm.id, label: farm.name };
                })}
              />
            </Form.Item>
            <Form.Item
              name="type_tracker"
              label="Тип"
              rules={[{ required: true, message: "Пожалуйста, выберите тип!" }]}
            >
              <Select
                placeholder="Выберите тип"
                options={[
                  {
                    value: TypeTracker.harvester,
                    label: TypeTrackerNames.harvester,
                  },
                  { value: TypeTracker.maz, label: TypeTrackerNames.maz },
                  {
                    value: TypeTracker.tractor_powerful,
                    label: TypeTrackerNames.tractor_powerful,
                  },
                  {
                    value: TypeTracker.tractor,
                    label: TypeTrackerNames.tractor,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="external_id"
              label="Внешний id"
              rules={[
                { required: true, message: "Пожалуйста, введите внешний id!" },
              ]}
            >
              <Input placeholder="Введите внешний id" />
            </Form.Item>
            <Form.Item name="division_id" label="Подразделение">
              <Select
                allowClear
                placeholder="Выберите подразделение"
                loading={isDivisionsLoading}
                options={divisionsData?.data.map((division: Division) => {
                  return { value: division.id, label: division.name };
                })}
              />
            </Form.Item>

            {tracker && (
              <>
                <Form.Item
                  name="is_not_breakdown"
                  valuePropName={"checked"}
                  initialValue={false}
                >
                  <Checkbox onChange={(e) => setIsBreakdown(!isBreakdown)}>
                    Транспорт неисправен
                  </Checkbox>
                </Form.Item>

                <div className={"flex justify-between"}>
                  <div className={"flex gap-5"}>
                    <Form.Item name="breakdown_date" label="Дата поломки">
                      <DatePicker
                        disabled={!isBreakdown}
                        showTime
                        placeholder={"Дата поломки"}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                      />
                    </Form.Item>
                  </div>

                  <div className={"flex gap-5"}>
                    <Form.Item name="repair_date" label="Дата починки">
                      <DatePicker
                        disabled={isBreakdown || !tracker.breakdown_datetime}
                        showTime
                        placeholder={"Дата починки"}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        onOpenChange={handleOpenRepairDatePicker}
                      />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  name="breakdown_description"
                  label="Описание неисправности"
                >
                  <TextArea
                    disabled={!isBreakdown}
                    placeholder="Описание неисправности"
                    autoSize={{ minRows: 3 }}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default TrackerForm;

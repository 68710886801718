import React, { useEffect } from 'react';
import { Form, Input, Select, Modal, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useCreateDivisionMutation, useUpdateDivisionMutation } from '../../redux/api/divisions.api';
import { useGetFarmsQuery } from '../../redux/api/farm.api';
import { Farm } from '../../types/farm.type';
import { Division } from '../../types/division.type';

interface DivisionFormProps {
  open: boolean;
  onCancel: () => void;
  division?: Division | null;
}


const DivisionForm: React.FC<DivisionFormProps> = ({
  open,
  onCancel,
  division,
}) => {

  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [form] = Form.useForm();
  const [createDivision, { isLoading: isCreateDivisionLoading }] = useCreateDivisionMutation();  
  const [updateDivision, { isLoading: isUpdateDivisionLoading }] = useUpdateDivisionMutation();  

  useEffect(() => {
    if (division) {
      form.setFieldsValue(division);
   }
  }, [division]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const submitForm = () => {
    form
    .validateFields()
    .then((values) => {
      if (division) {
        const params = {
          body: values,
          pk: division.id,
        };
        updateDivision(params).unwrap()
        .then((payload) => {
          if (payload.success) {
            handleCancel();
            notification.success({ 
              message: 'Подразделение обновлено',
            });
          }
        })
        .catch((error) => {
          notification.error({ 
            message: 'Ошибка обновления подразделения',
            description: error.data?.error || 'Проверьте данные или попробуйте позже',
          });
        });
      } else {
        createDivision(values).unwrap()
        .then((payload) => {
          if (payload.success) {
            handleCancel();
            notification.success({ 
              message: 'Подразделение создано',
            });
          }
        })
        .catch((error) => {
          notification.error({ 
            message: 'Ошибка создания подразделения',
            description: error.data?.error || 'Проверьте данные или попробуйте позже',
          });
        });
      }
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
};

  return (
    <Modal
      open={open}
      title={division ? `Подразделение ${division.name}` : 'Новое подразделение'}
      okText={division ? 'Сохранить' : 'Создать'}
      cancelText="Закрыть"
      destroyOnClose={true}
      okButtonProps={{ disabled: isCreateDivisionLoading || isUpdateDivisionLoading }}
      onCancel={handleCancel}
      centered
      onOk={submitForm}
    >
      <div className="h-[320px] mt-8">
        {isCreateDivisionLoading || isUpdateDivisionLoading ?
          <div className="h-full flex justify-center items-center">
            <LoadingOutlined style={{ fontSize: '30px', color: '#6b7280' }} />
          </div>
        :
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="name"
              label="Наименование"
              rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
            >
              <Input
                placeholder="Введите наименование"
              />
            </Form.Item>
            <Form.Item
              name="farm_id"
              label="Хозяйство"
              initialValue={1}
              rules={[{ required: true, message: 'Пожалуйста, выберите хозяйство!' }]}
            >
              <Select
                placeholder="Выберите хозяйство"
                loading={isFarmsLoading}
                options={farmsData?.data.map((farm: Farm) => {
                  return { value: farm.id, label: farm.name };
                })}
              />
            </Form.Item>
          </Form>
        }
      </div>
    </Modal>
  );
};

export default DivisionForm;

import React from 'react';
import {Button, Form, Input, notification, Typography} from 'antd';

import {useLoginMutation} from '../redux/api/auth.api';
import {LoginFields} from '../types/auth.type';
import {setUser} from "../redux/slices/authSlice";
import {useAppDispatch} from "../redux/hooks";

const { Text } = Typography;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [login, result] = useLoginMutation();
  const [form] = Form.useForm();

  const inputClassName = 'button_auth w-full hover:!border-[#FFD401] focus:!shadow-[0_0_0_2px_rgba(255,195,94,0.1)] focus-within:!border-[#FFD401]';

  const onFinish = (data: LoginFields) => {
    login(data).unwrap()
    .then((payload) => {
      const { token, me } = payload.data;
      localStorage.setItem('agroToken', token);
      dispatch(setUser({ token, me }));
    })
    .catch((error) => {
      notification.error({ 
        message: error.data?.error || 'Ошибка авторизации!',
      });
    });
  };

  const onFinishFailed = () => {
    console.log('failed');
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({'email': e.target.value.trim()});
  };

  return (
    <div className="flex justify-between min-h-screen">
     <div className={'min-h-screen flex flex-col justify-center items-center min-w-[600px]'}>
       <div className={'flex flex-col gap-1 mb-10'}>
         <img className={'w-[52px] h-[52px] self-center'} src={'/images/auth/logo.png'} alt={'logo'} />

         <h1 className={'text-[#654A03] text-[38px] font-[900] text-center'}>Агрономика</h1>

         <Text className={'text-[#654A03] text-[14px] font-[600] text-center'}>Технологии управления сельским хозяйством</Text>
       </div>

       <Form
         className={'w-[90%] max-w-[350px]'}
         form={form}
         name="basic"
         // labelCol={{ span: 8 }}
         // wrapperCol={{ span: 16 }}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         autoComplete="off"
         layout={'vertical'}
       >
         <Form.Item
           className='w-full'
           label="Email"
           name="email"
           rules={[
             { required: true, message: 'Пожалуйста, введите email!' },
             { type: 'email', message: 'Пожалуйста, введите правильный email!' }
           ]}
         >
           <Input
             className={inputClassName}
             disabled={result.isLoading}
             onChange={(e) => changeEmail(e)}
           />
         </Form.Item>
         <Form.Item
           className='w-full'
           label="Пароль"
           name="password"
           rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}
         >
           <Input.Password className={inputClassName} disabled={result.isLoading} />
         </Form.Item>
         <Form.Item className='w-full'>

           <Button
             className='button_auth w-full !bg-[#FFD401] hover:!bg-[#ec7e16]'
             // className='button_auth w-full !bg-[#ec7e16] hover:!bg-[#fc9841]'
             type="primary"
             htmlType="submit"
             disabled={result.isLoading}
             style={{
               // background: '#fc9841',
               background: 'rgba(255,195,94,0.1)',
             }}
           >
             Войти
           </Button>

         </Form.Item>
       </Form>
     </div>

      <div className={'relative min-h-screen w-full object-cover'}>
        <img className={'h-full w-full object-cover'} src={'/images/auth/auth_bg.jpg'} alt={'login page background'}/>
      </div>
    </div>
  );
};

export default Login;
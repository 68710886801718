import React, { useEffect, useState, useCallback } from "react";
import {Table, Button, Popconfirm, notification, Checkbox, Tooltip} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useAppDispatch } from "../../redux/hooks";
import {
  Tracker,
  TypeTracker,
  TypeTrackerNames,
} from "../../types/tracker.type";
import TrackerForm from "../../components/trackers/trackerForm";
import {
  useGetTrackersMutation,
  useDeleteTrackerMutation,
} from "../../redux/api/tracker.api";
import { setTracker } from "../../redux/slices/trackerSlice";
import {ADMIN_TABLE_SCROLL_HEIGHT, UTC_OFFSET} from "../../util/constants";
import dayjs from "dayjs";

// const pageLimit = 10;

const TrackersList = () => {
  const dispatch = useAppDispatch();

  // const [currentPage, setCurrentPage] = useState(1);
  const [openTrackerForm, setOpenTrackerForm] = useState(false);

  const [
    getTrackers,
    {
      data: trackersData,
      isLoading: isGetTrackersLoading,
      isError: isGetTrackersError,
    },
  ] = useGetTrackersMutation();
  const [deleteTracker, { isLoading: isDeleteTrackerLoading }] =
    useDeleteTrackerMutation();

  useEffect(() => {
    if (isGetTrackersError) {
      notification.error({
        message: "Ошибка получения данных",
        description: "Попробуйте позже",
      });
    }
  }, [isGetTrackersError]);

  const fetchTrackers = useCallback(() => {
    const params = {
      // page: currentPage,
      // limit: pageLimit,
      // farm_id: 1,
      // region_id: 1,
    };
    getTrackers(params);
  }, [getTrackers]);

  useEffect(() => {
    fetchTrackers();
  }, [/*pageLimit, currentPage,*/ fetchTrackers]);

  const onClickDelete = (trackerId: number) => {
    deleteTracker(trackerId)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          notification.success({
            message: "Трекер удалён",
          });
          fetchTrackers();
        }
      })
      .catch((error) => {
        notification.error({
          message: error.data?.error || "Ошибка удаления трекера",
        });
      });
  };

  const renderActions = (tracker: Tracker) => {
    return (
      <div className="flex justify-between">
        <div
          className="text-blue-500 cursor-pointer"
          onClick={() => onClickEdit(tracker)}
        >
          <EditOutlined />
        </div>
        <Popconfirm
          title={`Удаление трекера ${tracker.name}`}
          description={`Вы действительно хотите удалить трекер ${tracker.name}?`}
          onConfirm={() => onClickDelete(tracker.id)}
          placement="left"
          okText="Да"
          cancelText="Нет"
        >
          <Button
            danger
            className="cursor-pointer"
            type="text"
            size="small"
            icon={<CloseOutlined className="align-baseline" />}
          />
        </Popconfirm>
      </div>
    );
  };

  const columns: ColumnsType<Tracker> = [
    {
      title: "Наименование",
      dataIndex: "name",
    },
    {
      title: "Хозяйство",
      dataIndex: "farm_name",
    },
    {
      title: "Тип",
      dataIndex: "type_tracker",
      render: (type_tracker) =>
        TypeTrackerNames[
          TypeTracker[type_tracker] as keyof typeof TypeTrackerNames
        ],
    },
    {
      title: "Внешний id",
      dataIndex: "external_id",
    },
    {
      title: "Подразделение",
      dataIndex: "division_name",
    },
    {
      title: "Неисправен",
      dataIndex: "is_not_breakdown",
      render: (value, row) => {
        const date = row?.breakdown_datetime
          ? dayjs(row?.breakdown_datetime).add(UTC_OFFSET, 'minute').format('YYYY-MM-DD HH:mm:ss')
          : null

        return (
          <Tooltip title={date}>
            <Checkbox checked={!value} className={'ml-7 breakdown'} />
          </Tooltip>
        )
      }
    },
    {
      render: (_, record) => renderActions(record),
    },
  ];

  const onClickEdit = (record: Tracker) => {
    dispatch(setTracker(record));
    setOpenTrackerForm(true);
  };

  const onCancelTrackerForm = () => {
    // if (!server) setCurrentPage(1);
    dispatch(setTracker(null));
    setOpenTrackerForm(false);
    // if (!serversData?.data.items.length && currentPage > 1) {
    //   setCurrentPage((page) => page - 1);
    // }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center mt-4 mb-8">
        <Button
          type="primary"
          className="flex items-center"
          icon={<PlusOutlined />}
          onClick={() => setOpenTrackerForm(true)}
        >
          Создать трекер
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={trackersData?.data || []}
        // pagination={{
        //   current: trackersData?.data.page,
        //   defaultPageSize: pageLimit,
        //   onChange: (page) => setCurrentPage(page),
        //   total: trackersData?.data.total,
        //   showSizeChanger: false,
        // }}
        pagination={false}
        loading={{
          spinning: isGetTrackersLoading || isDeleteTrackerLoading,
          indicator: <LoadingOutlined style={{ fontSize: "30px" }} />,
        }}
        scroll={{ y: ADMIN_TABLE_SCROLL_HEIGHT }}
      />
      {openTrackerForm && (
        <TrackerForm
          open={openTrackerForm}
          onCancel={onCancelTrackerForm}
          fetchTrackers={fetchTrackers}
        />
      )}
    </div>
  );
};

export default TrackersList;

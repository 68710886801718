import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';

export const farmApi = createApi({
  reducerPath: 'FarmQuery',
  tagTypes: ['Farm'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getFarms: build.query({
      query: () => ({
        url: '/farms/',
      }),
      providesTags: [{ type: 'Farm' }],
    }),
    createFarm: build.mutation({
      query: (data) => ({
        url: '/farms/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Farm' }],
    }),
    getFarm: build.query({
      query: (pk) => ({
        url: `/farms/${pk}/`,
      }),
    }),
    updateFarm: build.mutation({
      query: (data) => ({
        url: `/farms/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Farm' }],
    }),
    deleteFarm: build.mutation({
      query: (pk) => ({
        url: `/farms/${pk}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Farm' }],
    }),
  }),
});

export const {
  useCreateFarmMutation,
  useDeleteFarmMutation,
  useLazyGetFarmQuery,
  useLazyGetFarmsQuery,
  useUpdateFarmMutation,
  useGetFarmsQuery,
  useGetFarmQuery,
} = farmApi;

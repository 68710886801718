import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from '@pbe/react-yandex-maps';

import { useAppSelector } from '../../redux/hooks';
import { Role } from "../../types/auth.type";
import { MainRoutes } from "../../util/routes";
import { FEEDING_BUTTON_POSITION } from "../../util/constants";

export default function FeedingButton() {
  const user = useAppSelector((state) => state.authReducer.user);
  const navigate = useNavigate();
  const farm = useAppSelector((state) => state.farmReducer.farm);

  const onClick = () => {
    if (user?.role === Role.ADMIN || user?.role === Role.OPERATOR) {
      navigate(`/${MainRoutes.feeding}/${farm?.id}`);
    }
  };
  
  return (
    <Button
      options={{
        float: 'none',
        position: FEEDING_BUTTON_POSITION,
        size: 'small',
      }}
      data={{ image: '/images/clipboard-verification.svg', title: 'модуль Кормления' }}
      state={{ enabled: (user?.role === Role.ADMIN || user?.role === Role.OPERATOR) && !!farm?.id }}
      onClick={onClick}
    />
  );
}

export type ImportantEventRequestBody = {
  camera_ids: number[];
  event_time: string;
}

export type MulticamEventsRequestBody = {
  camera_ids: number[];
  date_from: string;
  is_nvr?: boolean;
}

export type MulticamEvent = {
  id: number;
  uuid_event: string;
  aisle_name?: string;
  grazing_name?: string;
  building_name?: string;
  camera_name?: string;
  event_start: string;
  event_end: string;
  cam1_id?: number;
  cam2_id?: number;
  events: MulticamEvent[];
  type: MulticamEventType;
  status: FeedingEventStatus;
}

export enum MulticamEventType {
  FEEDING = 'Кормление',
  IMPORTANT = 'Важное событие',
  NVR = 'NVR',
}

export enum FeedingEventStatus {
  'На проверке' = 1,
  'Подтвержденное событие',
  'Ложное событие',
}

export type DownloadVideoFragmentData = {
  cam_id: number;
  params: {
    time_from: string,
    time_to: string,
  };
}

export type MulticamArchiveRecordsRequestBody = {
  camera_ids: number[];
  time_from: number;
  time_to: number;
}

export type MulticamArchiveRecords = {
  id: number;
  unique_id: string;
  records: ArchiveRecord[];
}

export type ArchiveRecord = {
  time_start: number;
  duration: number;
};

export type TimelineSegment = {
  start: number;
  width: number;
};

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Form, Radio, DatePicker, Space, Select } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { RangePickerProps } from 'antd/es/date-picker';

import { useAppDispatch } from '../../../redux/hooks';
import { DateFlag, DateFlagNames, FeedingEventReportFilters } from '../../../types/report.type';
import { useGetShortBuildingsMutation, useLazyGetBuildingsQuery } from '../../../redux/api/building.api';
import { useGetFarmsQuery } from '../../../redux/api/farm.api';
import { useLazyGetDivisionsQuery } from '../../../redux/api/divisions.api';
import { Division } from '../../../types/division.type';
import { Farm } from '../../../types/farm.type';
import { Building, ShortBuilding } from '../../../types/building.type';
import { getDatesPlaceholder } from '../../../util/helpers';
import { setFarmId, setMainFilters } from '../../../redux/slices/feedingReportsSlice';

const { RangePicker } = DatePicker;


const FeedingMainFilters = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const farm_id = searchParams.get('farm_id');
  const division_id = searchParams.get('division_id');
  const building_id = searchParams.get('building_id');
  const date_flag = searchParams.get('date_flag');
  const dates = searchParams.get('dates');
  const [mainForm] = Form.useForm();
  const farmIdValue = Form.useWatch('farm_id', mainForm);
  const divisionIdsValue = Form.useWatch('division_ids', mainForm);
  const buildingIdsValue = Form.useWatch('building_ids', mainForm);
  const datesValue = Form.useWatch('dates', mainForm);
  const dateFlagValue = Form.useWatch('date_flag', mainForm);

  const [filterItems, setFilterItems] =
    useState<Array<{ aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }>>([]);
  const [divisionFilterItems, setDivisionFilterItems] = useState<Division[]>([]);
  const [buildingFilterItems, setBuildingFilterItems] = useState<Building[]>([]);
  const [farm, setFarm] = useState<Farm>();

  const { data: farmsData, isFetching: isFarmsLoading } = useGetFarmsQuery({});
  const [getDivisions, { isFetching: isDivisionsLoading }] = useLazyGetDivisionsQuery();  
  const [getBuildings, { isLoading: buildingsDataLoading }] = useLazyGetBuildingsQuery();
  const [getShortBuildings, { isLoading: getShortBuildingsLoading }] = useGetShortBuildingsMutation();

  useEffect(() => {
    if (farmIdValue) {
      const farm = farmsData?.data.find((item: Farm) => item.id === farmIdValue);
      setFarm(farm);
    }
  }, [farmIdValue, farmsData]);

  const applyMainFilters = () => {
    const values = mainForm.getFieldsValue();
    dispatch(setFarmId(values.farm_id));
    const filters: FeedingEventReportFilters = {
      date_flag: values.date_flag,
      division_ids: values.division_ids,
      building_ids: values.building_ids,
    };
    const itemFromForm = values.items;    
    let items: any = { aisle_ids: [], grazing_ids: [] };
    if (itemFromForm && itemFromForm.length) {
      itemFromForm.forEach((item: any) => {
        const parsedItem = JSON.parse(item);
        if (parsedItem.aisle_id) {
          items.aisle_ids.push(parsedItem.aisle_id);
        } else if (parsedItem.grazing_id) {
          items.grazing_ids.push(parsedItem.grazing_id);
        }
      });
    };
    filters.aisle_ids = items.aisle_ids;
    filters.grazing_ids = items.grazing_ids;
    if (values.dates) {
      filters.date_from = values.dates[0].format('YYYY-MM-DD');
      filters.date_to = values.dates[1].format('YYYY-MM-DD');
    }
    dispatch(setMainFilters(filters));
  };
      
  useEffect(() => {
    mainForm.setFieldValue('date_flag', date_flag ? +date_flag : DateFlag.TODAY);
    if (dates) {
      const datesValues = dates?.split(',');
      mainForm.setFieldValue('dates', [dayjs(datesValues[0], 'DD.MM.YYYY'), dayjs(datesValues[1], 'DD.MM.YYYY')]);
    }
    if (farm_id) {
      mainForm.setFieldValue('farm_id', +farm_id);
      handleChangeFarm(+farm_id);
      if (division_id) {
        mainForm.setFieldValue('division_ids', [+division_id]);
        handleChangeDivisions([+division_id]);
        if (building_id) {
          mainForm.setFieldValue('building_ids', [+building_id]);
          handleChangeBuildings([+building_id]);
        }
      };
    }
    applyMainFilters();
  }, [farm_id, division_id, building_id, date_flag, dates]);

  useEffect(() => {
    const listener = () => {
      if (document.visibilityState === 'visible') {
        applyMainFilters();
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);

  const onDateRangeChange = () => {
    mainForm.setFieldValue('date_flag', DateFlag.FROM_TO);
  };

  const onChangeDateFlag = () => {
    mainForm.setFieldValue('dates', null);
  };

  const handleChangeBuildings = (value: number[]) => {
    mainForm.setFieldsValue({ building_ids: value, items: [] });
    setFilterItems([]);
    let items: any[] = [];
    if (value.length) {
      getShortBuildings({ building_ids: value }).unwrap()
      .then((payload) => {
        const buildings = payload.data as ShortBuilding[];
        buildings.forEach((building) => {
          const aislesOptions = building.aisles?.map((aisle) => {
            return { aisle_id: aisle.id, name: aisle.name };
          }) || [];
          const grazingsOptions = building.grazings?.map((grazing) => {
            return { grazing_id: grazing.id, name: grazing.name };
          }) || [];
          items = [...items, ...aislesOptions, ...grazingsOptions];
        });
        setFilterItems(items);
      });
    }
  };

  const handleChangeDivisions = (value: number[]) => {
    mainForm.setFieldsValue({ division_ids: value, building_ids: [], items: [] });
    setBuildingFilterItems([]);
    setFilterItems([]);
    if (value.length) {
      const params: any = {
        farm_id: farmIdValue,
        division_ids: value,
      };
      getBuildings(params).unwrap()
      .then((payload) => {
        setBuildingFilterItems(payload.data);
      });
    }
  };

  const handleChangeFarm = (value: number) => {
    mainForm.setFieldsValue({ farm_id: value, division_ids: [], building_ids: [], items: [] });
    setDivisionFilterItems([]);
    setBuildingFilterItems([]);
    setFilterItems([]);
    if (value) {
      getDivisions({ farm_id: value, is_feeding: true }).unwrap()
      .then((payload) => {
        setDivisionFilterItems(payload.data);
      });
    }
  };

  const handleChangeFilterItems = (value: any[]) => {        
    mainForm.setFieldsValue({ items: value });    
  };

  const getDisabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  }

  return (
    <>
      <Form form={mainForm} layout="inline">
        <Space className="flex flex-wrap">
          <Form.Item name="date_flag">
            <Radio.Group onChange={onChangeDateFlag}>
              <Radio.Button value={DateFlag.TODAY}>{DateFlagNames.TODAY}</Radio.Button>
              <Radio.Button value={DateFlag.DAY_AGO}>{DateFlagNames.DAY_AGO}</Radio.Button>
              <Radio.Button value={DateFlag.CURRENT_WEEK}>{DateFlagNames.CURRENT_WEEK}</Radio.Button>
              <Radio.Button value={DateFlag.LAST_WEEK}>{DateFlagNames.LAST_WEEK}</Radio.Button>
              <Radio.Button value={DateFlag.CURRENT_MONTH}>{DateFlagNames.CURRENT_MONTH}</Radio.Button>
              <Radio.Button value={DateFlag.LAST_MONTH}>{DateFlagNames.LAST_MONTH}</Radio.Button>
              <Radio.Button value={DateFlag.CURRENT_QUARTER}>{DateFlagNames.CURRENT_QUARTER}</Radio.Button>
              <Radio.Button value={DateFlag.CURRENT_HALF_YEAR}>{DateFlagNames.CURRENT_HALF_YEAR}</Radio.Button>
              <Radio.Button value={DateFlag.CURRENT_YEAR}>{DateFlagNames.CURRENT_YEAR}</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dates">
            <RangePicker
              className={`font-semibold focus:shadow-transparent ${datesValue ? 'border-blue-500' : ''}`}
              changeOnBlur={true}
              placeholder={getDatesPlaceholder(dateFlagValue)}
              locale={locale}
              format="DD.MM.YYYY"
              disabledDate={getDisabledDate}
              value={mainForm.getFieldValue('dates')}
              onChange={onDateRangeChange}
            />
          </Form.Item>
        </Space>
        <Space className="flex flex-wrap mt-2">
          <Form.Item
            name="farm_id"
            rules={[{ required: true, message: 'Пожалуйста, выберите хозяйство!' }]}
          >
            <Select
              className="min-w-[200px]"
              placeholder="Хозяйство"
              loading={isFarmsLoading}
              onChange={handleChangeFarm}
              options={farmsData?.data.map((farm: Farm) => {
                return { value: farm.id, label: farm.name };
              })}
            />
          </Form.Item>
          <Form.Item name="division_ids">
            <Select
              allowClear
              mode="multiple"
              showSearch={false}
              className="min-w-[200px]"
              placeholder="Подразделение"
              onChange={handleChangeDivisions}
              loading={isDivisionsLoading}
              disabled={!divisionFilterItems.length}
              options={divisionFilterItems.map((division: Division) => {
                return { value: division.id, label: division.name };
              })}
            />
          </Form.Item>
          <Form.Item name="building_ids">
            <Select
              allowClear
              mode="multiple"
              showSearch={false}
              className="min-w-[200px]"
              placeholder="Строение"
              onChange={handleChangeBuildings}
              disabled={!divisionIdsValue?.length || !buildingFilterItems.length}
              loading={buildingsDataLoading || isDivisionsLoading}
              options={buildingFilterItems.map((building: Building) => {
                return { value: building.id, label: building.name };
              })}
            />
          </Form.Item>
          <Form.Item name="items">
            <Select
              allowClear
              mode="multiple"
              showSearch={false}
              className="min-w-[200px]"
              placeholder="Проход / выпас"
              onChange={handleChangeFilterItems}
              loading={getShortBuildingsLoading || buildingsDataLoading || isDivisionsLoading}
              disabled={!buildingIdsValue?.length || !filterItems.length}
              options={
                filterItems?.map(
                  (item: { aisle_id: number; name: string | undefined } | { grazing_id: number; name: string | undefined }) =>  ({ value: JSON.stringify(item), label: item.name })
                )
              }
            />
          </Form.Item>
        </Space>
      </Form>
      <div className="flex flex-col justify-between items-end">
        <div>
          {farm?.name}
        </div>
        <Button
          className="font-semibold"
          onClick={applyMainFilters}
        >
          Применить
        </Button>
      </div>
    </>
  );
};

export default FeedingMainFilters;

import React from 'react';

import UploadForm from "../../components/copter/uploadForm";


const PhotosList = () => {
  return (
    <div>
      <div className="mt-4 mb-8">
        <UploadForm />
      </div>
    </div>
  );
};

export default PhotosList;

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect,
  memo
} from "react";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  BackwardOutlined,
  ForwardOutlined,
  StepBackwardOutlined,
  DownloadOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import type { SliderSingleProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Player from "./player";
import EventBox from "./EventBox";
import ImportantEventButton from "./ImportantEventButton";
import Timelines from "./timelines";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  useLazyDownloadVideoFragmentQuery,
  useGetMulticamEventsQuery,
  useGetMulticamArchiveRecordsQuery,
} from "../../redux/api/multicam.api";
import { getLoadedCams, resetLoadedCams, setActiveEvent } from "../../redux/slices/multicamSlice";
import { Camera } from "../../types/camera.type";
// import {
//   getActiveMulticamEvent,
// } from "../../redux/slices/multicamSlice";
import { apiBaseMediaUrl } from "../../util/api";
import { MulticamEvent } from "../../types/multicam";
import clsx from "clsx";
import EventsMode from "./EventsMode";
import EventHandlingMode from "./EventHandlingMode";
import {DATE_FORMAT, UTC_OFFSET} from "../../util/constants";
import { StatusConnection } from "../../types/common.type";

dayjs.extend(utc);

type KvadratorProps = {
  cameras: Camera[];
  date?: Dayjs | null;
  className?: string;
  events_ids?: number[];
  startTime?: Dayjs;
  endTime?: Dayjs;
};

// Задержка воспроизведения текущего момента для того, чтобы подгрузились фрагменты
const ONLINE_DELAY = 30;


const KvadratorComponent = ({ cameras, date, startTime, endTime, events_ids }: KvadratorProps) => {
  const dispatch = useAppDispatch();

  const now = useMemo(() => dayjs().utcOffset(UTC_OFFSET), [dayjs, date]);

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [timeToSeek, setTimeToSeek] = useState(0);
  const [fragmentStart, changeFragmentStart] = useState<number | null>(null);
  const [fragmentEnd, changeFragmentEnd] = useState<number | null>(null);
  const [showEventBox, setShowEventBox] = useState(false);
  const [eventsForTimeline, setEventsForTimeline] = useState<MulticamEvent[]>([]);
  const [currentEventIndex, setCurrentEventIndex] = useState<number | null>(null);
  const [isToday, setIsToday] = useState(false);
  const [isArchiveAvailable, setIsArchiveAvailable] = useState(false);
  // const [isConnected, setIsConnected] = useState(false);

  const [startOfDate, setStartOfDate] = useState(date?.utcOffset(UTC_OFFSET).startOf('date').unix() || now.startOf('date').unix());
  const [time_to, setTimeTo] = useState((endTime && endTime.unix()) || date?.utcOffset(UTC_OFFSET).endOf('date').unix() || now.endOf('date').unix());

  const [eventsMode, setEventsMode] = useState(false);

  const camera_ids = useMemo(() => cameras.map(camera => camera.id), [cameras]);

  const loadedCams = useAppSelector(getLoadedCams);
  // const activeEvent = useAppSelector(getActiveMulticamEvent);
  
  const { data: archiveRecordsData, isFetching: archiveRecordsLoading } = useGetMulticamArchiveRecordsQuery(
    { camera_ids, time_from: startTime ? startTime.unix() : startOfDate, time_to }, { skip: cameras.length === 0 }
  );

  useEffect(() => {
    // console.log('archiveRecordsData?.data', archiveRecordsData?.data);
    
    if (archiveRecordsData?.data.length) {
      let isAvailable = false;
      for (const item of archiveRecordsData?.data) {
        if (item?.records?.length) {
          isAvailable = true;
          break;
        }
      }      
      setIsArchiveAvailable(isAvailable);
    }
  }, [archiveRecordsData?.data]);

  const getCameraDayArchiveRecords = useCallback((cameraId: number) => {
    const cameraArchiveRecords = archiveRecordsData?.data.find((item) => item?.id === cameraId)?.records || [];
    return archiveRecordsLoading ? [] : cameraArchiveRecords.map((record) => ({ time_start: record.time_start - (startTime ? startTime.unix() : startOfDate), duration: record.duration }));
  }, [archiveRecordsData?.data, startOfDate]);

  const { data: eventsData, isFetching: eventsLoading } = useGetMulticamEventsQuery(
    {
      camera_ids,
      date_from: date?.utcOffset(UTC_OFFSET).format('YYYY-MM-DD') || now.format('YYYY-MM-DD'),
      // is_nvr
    }, {
      skip: cameras.length === 0 || !!startTime,
    }
  );
  const events = useMemo(() => {
    if (events_ids) {
      return eventsData?.data.filter(event => events_ids.includes(event.id)) || []
    }
    return eventsData?.data || []
  }, [eventsData, events_ids]);

  useEffect(() => {
    const eventsForTimeline: MulticamEvent[] = [];
    events.forEach((event) => {
      eventsForTimeline.push(event);
      if (event.events?.length) {
        event.events.forEach((item) => eventsForTimeline.push(item));
      }
    });

    const filteredEvents = events_ids ? eventsForTimeline.filter(event => events_ids.includes(event.id)) : eventsForTimeline;

    setEventsForTimeline(filteredEvents);
  }, [events]);

  const [downloadVideoFragment, { isLoading: fragmentLoading }] = useLazyDownloadVideoFragmentQuery();

  const buttonClassname = 'text-4xl disabled:text-gray-500 disabled:hover:text-gray-500';

  useEffect(() => {
    if (startTime && loadedCams.length === cameras.length && isArchiveAvailable) {
      goToTime(0, true);
    }
  }, [startTime, loadedCams, isArchiveAvailable, cameras]);

  useEffect(() => {    
    if (loadedCams.length /*&& loadedCams.length === cameras.length*/ && isArchiveAvailable && !eventsMode && !startTime) {
      if (isToday) {
        const nowSeconds = dayjs().utcOffset(UTC_OFFSET).unix() - startOfDate;
        goToTime(nowSeconds - ONLINE_DELAY);
        // setIsConnected(cameras.some((camera) => camera.status_connection === StatusConnection.ONLINE));
      } else {
        goToTime(0);
      }
      setPlaying(true);
    }
  }, [cameras, loadedCams, isToday, startOfDate, eventsMode, isArchiveAvailable, startTime]);

  useEffect(() => {
    dispatch(resetLoadedCams());
    // goToTime(0);
    setIsToday(date?.utcOffset(UTC_OFFSET).startOf('date').unix() === now.startOf('date').unix());
    setCurrentEventIndex(null);
    // setPlaying(false);
    changeFragmentStart(null);
    changeFragmentEnd(null);
  }, [date, cameras, startTime]);

  useLayoutEffect(() => {
    if (currentEventIndex !== null) {
      const currentEvent =  eventsForTimeline[currentEventIndex];
      // для eventsMode необходимо отнимать 10 секунд в начале события
      const nextStartTimeValue = dayjs(currentEvent.event_start).utcOffset(UTC_OFFSET, true).subtract(eventsMode ? 10 : 0, 'seconds').unix();

      if (eventsMode && loadedCams) {
        const endTime = dayjs(currentEvent.event_end ?? currentEvent.event_start).utcOffset(UTC_OFFSET, true).add(10, 'seconds').unix();
        setStartOfDate(nextStartTimeValue)
        setTimeTo(endTime);

        goToTime(0, playing);
      } else {
        goToTime(nextStartTimeValue - startOfDate, playing);
      }

      dispatch(setActiveEvent(currentEvent));
    }
    return () => {
      dispatch(setActiveEvent(null));
    }
  }, [currentEventIndex, loadedCams]);

  const getMarks = useCallback(() => {
    const marks: SliderSingleProps['marks'] = {};
    if (fragmentStart) marks[fragmentStart] = { style: { display: 'none' }, label: 's' };
    if (fragmentEnd) marks[fragmentEnd] = { style: { display: 'none' }, label: 'e' };
    return marks;
  }, [fragmentStart, fragmentEnd]);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (playing) {
        if (!document.hidden && currentTime <= time_to - startOfDate) {
          setCurrentTime?.(time => time + 1);
        } else {
          setPlaying(false);
          clearInterval(intervalId);
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [playing, time_to, startOfDate, startTime]);

  useEffect(() => {
    const listener = () => {      
      if (document.visibilityState === 'visible') {
        if (isArchiveAvailable) setPlaying(true);
      } else {
        setPlaying(false);
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [setPlaying, isArchiveAvailable]);

  const togglePlaying = () => {
    setPlaying(!playing);
  };

  const setFragmentStart = () => {
    changeFragmentStart(currentTime);
    changeFragmentEnd(null);
  };

  const setFragmentEnd = () => {
    changeFragmentEnd(currentTime);
  };

  const goToTime = (value: number, playing = true) => {
    setCurrentTime(value);
    setPlaying(playing);
    setTimeout(() => setTimeToSeek(value));
  };

  const goToNextEvent = () => {
    setCurrentEventIndex(index => {
      if (index === null) return 0;
      return index + 1;
    });
  };

  const goToPreviousEvent = () => {
    setCurrentEventIndex(index => {
      if (!index) return index;
      return index - 1;
    });
  };

  const setIndexOfSelectedEvent = (uuid: string) => {
    const index = eventsForTimeline.findIndex((event) => event.uuid_event === uuid);
    if (index !== -1) return setCurrentEventIndex(index);

    setCurrentEventIndex(null);
  };

  const downloadFragment = () => {
    if (!date) return;

    const downloadFromCamera = async (camera: Camera) => {
      const params = {
        time_from: dayjs.unix(startOfDate + (fragmentStart as number)).utcOffset(UTC_OFFSET).format(),
        time_to: dayjs.unix(startOfDate + (fragmentEnd as number)).utcOffset(UTC_OFFSET).format(),
      };

      downloadVideoFragment({
        cam_id: camera.id,
        params: params,
      });
    };
    const downloadings = [downloadFromCamera(cameras[0])];
    if (cameras[1]) downloadings.push(downloadFromCamera(cameras[1]));
    Promise.all(downloadings)
    .finally(() => {
      changeFragmentStart(null);
      changeFragmentEnd(null);
    });
  };

  const formatSliderTooltip = (value: number = 0) => {
    return dayjs.unix(startOfDate + value).utcOffset(UTC_OFFSET).format('HH:mm:ss')
  };

  const handleSetOnEventMode = () => {
    if (eventsMode) {
      setEventsMode(false);
      setShowEventBox(false);
      handleChangeSlider(86399);

      setStartOfDate(date?.utcOffset(UTC_OFFSET).startOf('date').unix() || now.startOf('date').unix());
      setTimeTo(date?.utcOffset(UTC_OFFSET).endOf('date').unix() || now.endOf('date').unix());

      return dispatch(setActiveEvent(null))
    }

    dispatch(setActiveEvent(events?.[0]))

    changeFragmentStart(null);
    changeFragmentEnd(null);

    setEventsMode(true);
    setShowEventBox(true);
  }

  const handleChangeSlider = (value: number) => {
    if (isToday && !startTime) {
      const nowSeconds = dayjs().utcOffset(UTC_OFFSET).unix() - now.startOf('date').unix();
      // console.log('isToday now =', nowSeconds);
      if (value < nowSeconds - ONLINE_DELAY) {
        goToTime(value);
      } else {
        goToTime(nowSeconds - ONLINE_DELAY);
      }
    } else {
      goToTime(value);
    }
  };

  return (
    <div className={'player-container bg-gray-700 overflow-hidden w-full h-[calc(100vh- - 240px - 44px)]'}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorText: '#f97316',
            },
          },
        }}
      >
        <div className="h-[calc(100vh-240px)]">
          {isArchiveAvailable ?
            <div className={clsx(`h-[calc(100vh-240px)] flex gap-1 justify-start`)}>
              <div
                className={
                  `h-[calc(100vh-240px)] grid gap-1 ${
                    cameras.length > 9 ?
                      'grid-cols-4'
                      :
                      cameras.length > 4 ?
                        'grid-cols-3'
                        :
                        cameras.length > 1 ?
                          'grid-cols-2'
                          :
                          'grid-cols-1'
                  } w-full [transition:width.1s.2s]`
                }
              >
                {(cameras).map((camera) => {
                  return (
                    <Player
                      key={camera.id}
                      url={
                        `${apiBaseMediaUrl}/video/${camera.unique_id}/archive-${startTime ? startTime.unix() : startOfDate}-${
                          (isToday && !eventsMode && !startTime) ? 'now' : endTime && startTime ? endTime.unix() - startTime?.unix() : time_to - startOfDate
                        }.fmp4.m3u8`
                      }
                      name={camera.name}
                      statusConnection={camera.status_connection}
                      playing={playing}
                      timeToSeek={timeToSeek}
                      archiveRecords={getCameraDayArchiveRecords(camera?.id)}
                      currentTime={currentTime}
                      isToday={isToday}
                      height={
                        cameras.length < 3 ?
                          'h-[calc(100vh-240px)]'
                        :
                          cameras.length < 7 ?
                            'h-[calc((100vh-244px)/2)]'
                          :
                            cameras.length < 13 ?
                              'h-[calc((100vh-248px)/3)]'
                            :
                              'h-[calc((100vh-252px)/4)]'
                      }
                    />
                  );
                })}
              </div>

              <EventBox
                showEventBox={showEventBox}
                setShowEventBox={setShowEventBox}
                events={startTime ? [] : events}
                eventsLoading={eventsLoading}
                goToTime={goToTime}
                setIndexOfSelectedEvent={setIndexOfSelectedEvent}
              />
            </div>
          :
            <div className="h-full flex justify-center items-center text-white text-3xl">
              {archiveRecordsLoading ?
                <LoadingOutlined className="text-7xl" />
              :
                /*startTime &&*/ <span>Архив не доступен</span>
              }
            </div>
          }
        </div>

        <div className="player-controls flex w-full justify-center h-20">
          <div className={'controls-left w-[30%] flex justify-center'}>
            {cameras.length < 3 && (
              <Button
                type="text"
                className={`${buttonClassname}`}
                onClick={downloadFragment}
                disabled={!isArchiveAvailable || fragmentStart === null || fragmentEnd === null || fragmentLoading}
              >
                {fragmentLoading ? <LoadingOutlined /> : <DownloadOutlined />}
              </Button>
            )}

            <Button
              type="text"
              className={clsx(buttonClassname, eventsMode && 'button_active text-[#dc2626]')}
              onClick={handleSetOnEventMode}
              disabled={!isArchiveAvailable || !!startTime}
            >
              <SwapOutlined height={15} />
            </Button>
          </div>

          <div className={'controls-center w-[40%] flex justify-center'}>
            {cameras.length < 3 && (
              <div className="text-gray-200">
                <Button
                  type="text"
                  className={buttonClassname}
                  onClick={setFragmentStart}
                  disabled={!isArchiveAvailable}
                >
                  <StepBackwardOutlined className="-rotate-90" />
                </Button>

                <div className="h-6 ml-1">{fragmentStart ? formatSliderTooltip(fragmentStart) : ''}</div>
              </div>
            )}
            <Button
              type="text"
              className={buttonClassname}
              onClick={goToPreviousEvent}
              disabled={!isArchiveAvailable || !currentEventIndex || !eventsForTimeline.length}
            >
              <BackwardOutlined />
            </Button>
            <Button
              type="text"
              className={buttonClassname}
              onClick={togglePlaying}
              disabled={!isArchiveAvailable}
            >
              {playing ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            </Button>
            <Button
              type="text"
              className={buttonClassname}
              onClick={goToNextEvent}
              disabled={!isArchiveAvailable || currentEventIndex === eventsForTimeline.length -1 || eventsForTimeline.length <= 1}
            >
              <ForwardOutlined />
            </Button>
            {cameras.length < 3 && (
              <div className="text-gray-200">
                <Button
                  type="text"
                  className={buttonClassname}
                  onClick={setFragmentEnd}
                  disabled={!isArchiveAvailable}
                >
                  <StepBackwardOutlined className="-rotate-90" />
                </Button>
                <div className="h-6 ml-1">{fragmentEnd ? formatSliderTooltip(fragmentEnd) : ''}</div>
              </div>
            )}
          </div>

          <div className={'controls-right w-[30%] flex justify-center'}>
            {cameras.length < 3 && (
              <ImportantEventButton
                date={date ?? dayjs()}
                setPlaying={setPlaying}
                event_time={currentTime}
                camera_ids={camera_ids}
                disabled={!isArchiveAvailable}
                eventStartTime={startOfDate}
              />
            )}

            <Button
              type={'text'}
              className={clsx(buttonClassname, showEventBox && 'button_active text-[#dc2626]', 'ml-3')}
              onClick={() => setShowEventBox(prevState => !prevState)}
              disabled={!isArchiveAvailable || !!startTime}
            >
              <UnorderedListOutlined />
            </Button>
          </div>

        </div>
        {!eventsMode && !startTime && (
          <Timelines
            archiveRecords={archiveRecordsData?.data || []}
            archiveRecordsLoading={archiveRecordsLoading}
            events={eventsForTimeline}
            startTime={startOfDate}
            endTime={time_to}
            currentTime={currentTime}
            onChangeSlider={handleChangeSlider}
            disabled={!isArchiveAvailable}
            tooltipFormatter={formatSliderTooltip}
            marks={getMarks()}
            isToday={isToday}
            startOfDate={startOfDate}
          />
        )}

        {startTime && (
          <EventHandlingMode
            startTime={startTime}
            endTime={endTime || dayjs().utcOffset(UTC_OFFSET)}
            isToday={isToday}
            events={eventsForTimeline}
            camera_ids={camera_ids}
            currentTime={currentTime}
            onSliderChange={handleChangeSlider}
            getMarks={getMarks}
            setPlaying={setPlaying}
          />
        )}

        {eventsMode && (
          <>
            <EventsMode
              events={eventsForTimeline}
              getMarks={getMarks}
              setPlaying={setPlaying}
              value={currentTime}
              onSliderChange={handleChangeSlider}
              isToday={isToday}
              startOfDate={startOfDate}
              time_to={time_to}
              camera_ids={camera_ids}
              emptyFragments={archiveRecordsData?.data}
              setIndexOfSelectedEvent={setIndexOfSelectedEvent}
            />
          </>
        )}
      </ConfigProvider>
    </div>
  )
}

export const Kvadrator = memo(KvadratorComponent);

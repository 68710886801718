import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  LoadingOutlined,
  CloseOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Layout, Tabs, Button, Select, Checkbox, notification } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';

import {
  useGetProcessedFeedingQuery,
  useUpdateProcessedFeedingStatusMutation,
  useUpdateFullProcessedFeedingMutation,
} from '../redux/api/feeding.api';
import {
  FeedingEvent,
  FeedingEventComment, FeedingEventSide, FeedingEventSideNames,
  FeedingEventStatus,
  FeedingEventTransport,
  FeedingEventType,
  FeedingEventTypeNames,
  FeedingEventUpdateBody,
  FullEventUpdateParams,
} from '../types/feeding.type';
import {Kvadrator} from "../components/kvadrator/kvadrator";

const { Content, Sider } = Layout;


const FeedingView: React.FC = () => {
  const [searchParams] = useSearchParams();
  const feeding_id = searchParams.get('feeding_id');
  
  const [feedingData, setFeedingData] = useState<FeedingEvent>();
  // const videoRef1 = useRef<HTMLVideoElement>(null);
  // const videoRef2 = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(true);
  const [isImportant, setIsImportant] = useState(false);
  const [isAisleFeeding, setIsAisleFeeding] = useState(false);
  const [eventComment, setEventComment] = useState<FeedingEventComment | null>(null);
  const [eventTransport, setEventTransport] = useState<FeedingEventTransport | null>(null);
  const [feedingSide, setFeedingSide] = useState<FeedingEventSideNames>(FeedingEventSideNames.SIDE_1);
  const [isEditing, setIsEditing] = useState(false);

  const {
    data: feedingEventData,
    isFetching: feedingEventLoading,
    isError: feedingEventError,
  } = useGetProcessedFeedingQuery(feeding_id);

  const cameras = useMemo(() => {
    return (feedingData?.aisle?.cameras || (feedingData?.grazing?.camera && [feedingData?.grazing?.camera]) ) ?? []
  }, [feedingData]);

  const startVideoTime = dayjs(feedingData?.event_start).subtract(1, 'minutes');
  const endVideoTime = dayjs(feedingData?.event_end).add(1, 'minutes');

  const [
    updateFeedingStatus,
    { isLoading: isUpdateLoading, isError: isUpdateError },
  ] = useUpdateProcessedFeedingStatusMutation();

  const [
    updateFullFeeding,
    { isLoading: isUpdateFullFeedingLoading, isError: isUpdateFullFeedingError },
  ] = useUpdateFullProcessedFeedingMutation();

  useEffect(() => {
    if (feedingEventData) {
      setFeedingData(feedingEventData.data);
      setEventComment(feedingEventData.data.comment);
      setEventTransport(feedingEventData.data.transport);
      setIsImportant(feedingEventData.data.is_important);
      setIsAisleFeeding(
        feedingEventData.data.child_ids?.length ?
          !!feedingEventData.data.child_ids?.length
        :
          feedingEventData.data.is_duplicate
      );
    } 
  }, [feedingEventData]);

  useEffect(() => {
    if (feedingEventError) {
      notification.error({
        message: 'Ошибка получения данных',
        description: 'Попробуйте позже',
      });
    }
  }, [feedingEventError]);

  useEffect(() => {
    if (isUpdateError || isUpdateFullFeedingError) {
      notification.error({
        message: 'Ошибка подтверждения кормления',
      });
    }
  }, [isUpdateError, isUpdateFullFeedingError]);

  useEffect(() => {
    if (feedingEventData && feedingEventData.data.side) {
      const numericKeys = Object.keys(FeedingEventSide) as Array<keyof typeof FeedingEventSide>;

      for (const key of numericKeys) {
        if (FeedingEventSide[key] === feedingEventData.data.side) {
          setFeedingSide(FeedingEventSideNames[key])
        }
      }
    }
    else {
      return setFeedingSide(FeedingEventSideNames.SIDE_1)
    }

  }, [feedingEventData]);


  const renderFeedingEvent = (
    <div>
      <div className="text-center text-lg text-gray-500 font-semibold pb-2 mb-4 border-b border-gray-400">
        <h4>Событие</h4>
      </div>
      <div id="feeding-list" className="h-[calc(100vh-206px)] overflow-y-auto scroll-smooth pr-2">
        {!!feedingData && <div
          className="px-4 py-2 border rounded-xl mb-4 last:mb-0 cursor-pointer hover:bg-gray-100 bg-gray-100 border-orange-500"
        >
          <p className="text-xl">{feedingData?.farm_name}</p>
          <p className="text-lg">{feedingData?.building_name}</p>
          {feedingData?.type === FeedingEventType.AISLE &&
            <div>
              <p>{feedingData?.aisle_name}</p>
              {!feedingData.child_ids?.length && <p>Сторона: {feedingData?.side}</p>}
            </div>
          }
          {feedingData?.type === FeedingEventType.GRAZING &&
            <p>{feedingData?.grazing_name}</p>
          }
          <p>
            Разность времени (минут):
            <span className="font-medium">
              {` ${dayjs(feedingData?.event_end).diff(feedingData?.event_start, 'minute')}`}
            </span>
          </p>
        </div>}
      </div>
    </div>
  );

  // const handleLoadVideo = () => {
  //   const parent = document.querySelector('.feeding_video_container');
  //   if (parent) {
  //     parent.classList.add('opacity-100');
  //     const videoRect = videoRef1.current?.getBoundingClientRect();
  //     if (videoRect?.width && videoRect?.height) {
  //       if (videoRect?.width > videoRect?.height) {
  //         parent.classList.add('flex-col');
  //         videoRef1.current?.classList.add('max-w-[600px]');
  //         videoRef2.current?.classList.add('max-w-[600px]');
  //       }
  //     }
  //     setIsPlaying(true);
  //   }
  // };

  // const handleClickPlay = () => {
  //   if (isPlaying) {
  //     videoRef1.current?.pause();
  //     videoRef2.current?.pause();
  //   } else {
  //     videoRef1.current?.play();
  //     videoRef2.current?.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  const handleChangeEventComment = (value: FeedingEventComment | null) => {    
    setEventComment(value);
  };

  const handleChangeEventTransport = (value: FeedingEventTransport | null) => {
    setEventTransport(value);
  };

  const handleChangeEventSide = (value: string) => {
    setFeedingSide(value as FeedingEventSideNames)
  }

  const handleClickCheckEvent = useCallback((status: FeedingEventStatus, eventId?: number) => {
    const side = feedingSide === FeedingEventSideNames.SIDE_1 ? FeedingEventSide.SIDE_1 : FeedingEventSide.SIDE_2;

    const params: { pk?: number; body: FeedingEventUpdateBody; params?: FullEventUpdateParams } = {
      pk: eventId,
      body: {
        status,
        comment: eventComment || undefined,
        transport: eventTransport || undefined,
        is_important: isImportant,
        is_duplicate: status === FeedingEventStatus.REJECTED ? false : isAisleFeeding,
        side: side,
      },
    };
    if (feedingData?.child_ids?.length) {
      params.body.is_full = status === FeedingEventStatus.REJECTED ? false : isAisleFeeding;
      params.params = {
        event_1_id: feeding_id as string,
        event_2_id: feedingData.child_ids[0],
      };
    }
    const updateMethod = feedingData?.child_ids?.length ? updateFullFeeding : updateFeedingStatus;
    updateMethod(params)
    .unwrap()
    .then(() => {
      setEventComment(null);
      setEventTransport(null);
      setIsAisleFeeding(false);
      setIsEditing(false);
    });
  }, [updateFeedingStatus, updateFullFeeding, eventComment, eventTransport, isImportant, isAisleFeeding, feedingSide]);

  const handleChangeImportant = (e: CheckboxChangeEvent) => {
    setIsImportant(e.target.checked);
  };

  const handleAisleFeeding = (e: CheckboxChangeEvent) => {
    setIsAisleFeeding(e.target.checked);
  };

  const renderCheckButtons = (
    <div>
      <div className="w-full flex justify-between mt-2 mb-4">
        <Button
          className="bg-green-600 text-white border-green-600"
          onClick={() => handleClickCheckEvent(FeedingEventStatus.CONFIRMED, feedingData?.id)}
          disabled={isUpdateLoading || isUpdateFullFeedingLoading}
        >
          КОРМЛЕНИЕ
        </Button>
        <Button
          className="bg-red-600 text-white border-red-600"
          onClick={() => handleClickCheckEvent(FeedingEventStatus.REJECTED, feedingData?.id)}
          disabled={isUpdateLoading || isUpdateFullFeedingLoading}
        >
          ЛОЖНАЯ СРАБОТКА 
        </Button>
      </div>
    </div>
  );

  return (
    <Layout className="feeding_page h-[calc(100vh-40px)]">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={330}
        style={{ backgroundColor: '#fff', padding: '10px' }}
      >
        {feedingEventLoading || isUpdateLoading || isUpdateFullFeedingLoading ?
          <div className="flex justify-center items-center h-[300px]">
            <LoadingOutlined style={{ fontSize: '30px', color: '#6b7280' }} />
          </div>
        :
          <div>
            <Tabs
              type="card"
              size="small"
              defaultActiveKey="feeding"
              items={[
                {
                  label: 'ТРАНСПОРТ',
                  key: 'transport',
                  disabled: true,
                  children: <div></div>,
                },
                {
                  label: 'КОРМЛЕНИЕ',
                  key: 'feeding',
                  children: renderFeedingEvent,
                },
                {
                  label: 'СВЯЗЬ',
                  key: 'connection',
                  disabled: true,
                  children: <div></div>,
                },
              ]}
            />
          </div>
        }
      </Sider>
      <Content>
        <div className="h-full flex flex-col justify-between bg-white border-l border-r border-gray-400 p-[10px] gap-2">
          <div className="text-center text-lg text-gray-500 font-semibold pb-3 border-b border-gray-400">
            Просмотр события
          </div>
          {feedingData && cameras.length ?
            <>
              <div className="feeding_video_container h-full flex">
                <Kvadrator
                  key={feedingData.id}
                  cameras={cameras}
                  date={dayjs(feedingData.event_start)}
                  startTime={startVideoTime}
                  endTime={endVideoTime}
                  events_ids={[feedingData.id]}
                />
              </div>

              {/*{feedingData?.video_files?.length ?*/}
              {/*  <>*/}
                  {/*<div className="h-[calc(100%-84px)] flex flex-col">*/}
                  {/*  <div className="feeding_video_container h-full flex justify-evenly items-center opacity-0">*/}
                  {/*    <div className="mx-2">*/}
                  {/*      <p className="px-4 py-2 text-xl text-gray-600">*/}
                  {/*        Первое событие*/}
                  {/*      </p>*/}
                  {/*      <video*/}
                  {/*        ref={videoRef1}*/}
                  {/*        src={`${process.env.REACT_APP_VIDEO_HOST}${feedingData?.video_files?.[0]?.storage.url}`}*/}
                  {/*        className="max-h-[750px]"*/}
                  {/*        autoPlay*/}
                  {/*        loop*/}
                  {/*        controls*/}
                  {/*        onLoadedData={handleLoadVideo}*/}
                  {/*      ></video>*/}
                  {/*    </div>*/}
                  {/*    <div className="mx-2">*/}
                  {/*      <p className="px-4 py-2 text-xl text-gray-600">*/}
                  {/*        Последнее событие*/}
                  {/*      </p>*/}
                  {/*      <video*/}
                  {/*        ref={videoRef2}*/}
                  {/*        src={`${process.env.REACT_APP_VIDEO_HOST}${feedingData?.video_files?.[1]?.storage.url}`}*/}
                  {/*        className="max-h-[750px]"*/}
                  {/*        autoPlay*/}
                  {/*        loop*/}
                  {/*        controls*/}
                  {/*      ></video>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="flex w-full justify-center py-1">*/}
                  {/*  <Button*/}
                  {/*    type="primary"*/}
                  {/*    icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}*/}
                  {/*    className="flex justify-center items-center"*/}
                  {/*    onClick={handleClickPlay}*/}
                  {/*    disabled={!feedingData}*/}
                  {/*  />*/}
                  {/*</div>*/}
                {/*</>*/}
              {/*: null*/}
              {/*}*/}
            </>
          :
            null
            // (!feedingEventLoading && !isUpdateLoading && !fullFeedingEventLoading && !isUpdateFullFeedingLoading) &&
            //   <div className="flex justify-center items-center w-full h-full">
            //     <p className="text-gray-500 text-3xl">
            //       Событий пока нет
            //     </p>
            //   </div>
          }
        </div>
      </Content>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={330}
        style={{ backgroundColor: '#fff', padding: '10px' }}
      >
        <div>
          <div className="text-center text-lg text-gray-500 font-semibold pb-3 mb-4 border-b border-gray-400">
            Дополнительная информация
          </div>
          <div className="h-fit">
            {feedingData &&
              <div>
                <div className="mb-4">
                  <p className="text-2xl font-semibold">{feedingData?.building_name}</p>
                  <p className="text-xl font-medium mb-4">
                    {FeedingEventTypeNames[FeedingEventType[feedingData?.type] as keyof typeof FeedingEventTypeNames]}
                  </p>
                  {feedingData.type === FeedingEventType.AISLE &&
                    <div>
                      <p>{feedingData.aisle_name}</p>
                      {!feedingData.child_ids?.length && <p>Сторона: {feedingData.side}</p>}
                      <p>Въезд: {`${dayjs(feedingData.event_start).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                      <p>Выезд: {`${dayjs(feedingData.event_end).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                    </div>
                  }
                  {feedingData.type === FeedingEventType.GRAZING &&
                    <div>
                      <p>{feedingData.grazing_name}</p>
                      <p>Начало: {`${dayjs(feedingData.event_start).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                      <p>Окончание: {`${dayjs(feedingData.event_end).format('DD.MM.YYYY HH.mm.ss')}`}</p>
                    </div>
                  }
                  <div>
                    <p>
                      Разность времени (минут):
                      <span className="font-medium">
                        {`${dayjs(feedingData.event_end).diff(feedingData.event_start, 'minute')}`}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="mb-4">
                  <Select
                    className="w-full"
                    listHeight={300}
                    placeholder={isEditing ? 'Выбор комментариев к событию' : ''}
                    allowClear
                    value={eventComment}
                    onChange={handleChangeEventComment}
                    disabled={!isEditing}
                    options={Object.values(FeedingEventComment).map((value) => {
                      return { value, label: value };
                    })}
                  />
                </div>
                <div className="mb-4">
                  <Select
                    className="w-full"
                    placeholder={isEditing ? 'Выбор транспорта' : ''}
                    allowClear
                    value={eventTransport}
                    onChange={handleChangeEventTransport}
                    disabled={!isEditing}
                    options={Object.values(FeedingEventTransport).map((value) => {
                      return { value, label: value };
                    })}
                  />
                </div>
                {feedingEventData.data.type === FeedingEventType.GRAZING && (
                  <div className="mb-4">
                    <Select
                      className="w-full"
                      placeholder={'Выберите выгон или загон'}
                      allowClear={false}
                      value={feedingSide}
                      onChange={handleChangeEventSide}
                      disabled={!isEditing}
                      options={Object.values(FeedingEventSideNames).map((value) => {
                        return { value, label: value };
                      })}
                    />
                  </div>
                )}

                {isEditing && renderCheckButtons}
                {feedingData?.type === FeedingEventType.AISLE &&
                  <div className="w-full flex justify-center mt-4">
                    <Checkbox
                      checked={isAisleFeeding}
                      onChange={handleAisleFeeding}
                      disabled={!isEditing}
                    >
                      Кормление прохода
                    </Checkbox>
                  </div>
                }
                <div className="w-full flex justify-center mt-4">
                  <Checkbox
                    checked={isImportant}
                    onChange={handleChangeImportant}
                    disabled={!isEditing}
                  >
                    Важное событие
                  </Checkbox>
                </div>
                <div className="mt-2 text-center uppercase">
                  {feedingData.status === FeedingEventStatus.CONFIRMED ? 'Кормление' : 'Ложная сработка'}
                </div>
                <div className="my-8">
                  <h4 className="text-center text-lg">Дополнительные камеры</h4>
                </div>
              </div>
            }
          </div>
          <div>
            <div className="flex justify-evenly mt-6">
              <Button
                className="flex items-center"
                icon={<EditOutlined />}
                onClick={() => setIsEditing(true)}
              >
                РЕДАКТИРОВАТЬ
              </Button>
            </div>
            <div className="flex justify-evenly mt-6">
              <Button
                className="flex items-center"
                icon={<CloseOutlined />}
                onClick={() => window.close()}
              >
                ЗАКРЫТЬ
              </Button>
            </div>
          </div>
        </div>
      </Sider>
    </Layout>
  )
};

export default FeedingView;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { apiBaseUrl } from '../../util/api';
import {BaseResponse} from "../../types/common.type";

export const cameraApi = createApi({
  reducerPath: 'CameraQuery',
  tagTypes: ['Camera'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getCameras: build.mutation({
      query: (data) => ({
        url: '/cameras/search/',
        method: 'POST',
        body: data,
      }),
      // providesTags: [{ type: 'Camera' }],
    }),
    getCamerasByGroup: build.query({
      query: (farm_id) => ({
        url: `/cameras/by_group/${farm_id}/`,
      }),
      // providesTags: [{ type: 'Camera' }],
    }),
    createCamera: build.mutation({
      query: (body) => ({
        url: '/cameras/',
        method: 'POST',
        body: body,
      }),
      // invalidatesTags: [{ type: 'Camera' }],
    }),
    getCameraImage: build.query({
      query: (pk) => ({
        url: `/cameras/${pk}/image/`,
      }),
    }),
    getCameraStream: build.query({
      query: (pk) => ({
        url: `/cameras/${pk}/stream/`,
      }),
    }),
    updateCamera: build.mutation({
      query: (data) => ({
        url: `/cameras/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      // invalidatesTags: [{ type: 'Camera' }],
    }),
    deleteCamera: build.mutation({
      query: (pk) => ({
        url: `/cameras/${pk}/`,
        method: 'DELETE',
      }),
      // invalidatesTags: [{ type: 'Camera' }],
    }),
    getCameraUuid: build.query<BaseResponse<string>, void>({
      query: () => ({
        url: `/cameras/uuid/`,
      }),
    }),
  }),
});

export const {
  useCreateCameraMutation,
  useDeleteCameraMutation,
  useGetCameraImageQuery,
  useGetCameraStreamQuery,
  useGetCamerasMutation,
  useUpdateCameraMutation,
  useLazyGetCamerasByGroupQuery,
  useLazyGetCameraUuidQuery,
} = cameraApi;

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

import {apiBaseUrl} from '../../util/api';
import {ListResponse} from "../../types/common.type";
import type {Area, getAreasParams} from "../../types/area.type";

export const areaApi = createApi({
  reducerPath: 'AreaQuery',
  tagTypes: ['Area'],
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    // prepareHeaders: (headers) => {
    //   const token: string | null = localStorage.getItem('agroToken');
    //   if (token) {
    //     headers.set('authorization', `Bearer ${token}`);
    //   }
    //   return headers;
    // },
  }),
  endpoints: (build) => ({
    getAreas: build.query<ListResponse<Area[]>,getAreasParams>({
      query: (params) => ({
        url: '/areas/',
        params: params,
      }),
      providesTags: [{ type: 'Area' }],
    }),
    createArea: build.mutation({
      query: (params) => ({
        url: `/areas/insert_or_update/${params.farm_id}/`,
        method: 'POST',
        params: params,
      }),
      invalidatesTags: [{ type: 'Area' }],
    }),
    updateArea: build.mutation({
      query: (data) => ({
        url: `/areas/${data.pk}/`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Area' }],
    }),
    getArea: build.query({
      query: (pk) => ({
        url: `/areas/${pk}/`,
      }),
    }),
  }),
});

export const {
  useUpdateAreaMutation,
  useCreateAreaMutation,
  useLazyGetAreaQuery,
  useLazyGetAreasQuery,
  useGetAreasQuery,
} = areaApi;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ymaps from "yandex-maps";

import { START_MAP_ZOOM } from '../../util/constants';
import { Coordinate } from '../../types/common.type';

const initialState = {
  map: null as unknown as ymaps.Map,
  ym: null as unknown as typeof ymaps,
  center: [52.325628444923915, 31.361727571411095] as Coordinate,
  zoom: START_MAP_ZOOM,
  webSocket: null as unknown as WebSocket | null,
  wsCorrectClosed: false,
};

type InitialStateType = typeof initialState;

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setMap: (
      state: InitialStateType,
      action: PayloadAction<ymaps.Map>,
    ) => {
      state.map = action.payload;
    },
    setYMaps: (
      state: InitialStateType,
      action: PayloadAction<typeof ymaps>,
    ) => {
      state.ym = action.payload;
    },
    setMapCenter: (
      state: InitialStateType,
      action: PayloadAction<Coordinate>,
    ) => {
      state.center = action.payload;
    },
    setMapZoom: (
      state: InitialStateType,
      action: PayloadAction<number>,
    ) => {
      state.zoom = action.payload;
    },
    setWebSocket: (
      state: InitialStateType,
      action: PayloadAction<WebSocket | null>,
    ) => {
      state.webSocket = action.payload;
    },
    setWsCorrectClosed: (
      state: InitialStateType,
      action: PayloadAction<boolean>,
    ) => {
      state.wsCorrectClosed = action.payload;
    },
  },
});

export const { setMap, setYMaps, setMapCenter, setMapZoom, setWebSocket, setWsCorrectClosed } = mapSlice.actions;
export default mapSlice.reducer;

import React, { useState, useEffect, memo } from "react";
import { Placemark, Clusterer, useYMaps } from '@pbe/react-yandex-maps';

import { useAppSelector } from '../../redux/hooks';
import { Portal } from "../portal/portal";
import TrackerItem from "./trackerItem";
import { TypeTracker, Tracker } from "../../types/tracker.type";
import { StatusConnection } from "../../types/common.type";
import {
  CLASTER_GRID_SIZE,
  TRACKER_ICON_CONTENT_OFFSET,
  TRACKER_ICON_OFFSET,
  TRACKER_ICON_SIZE,
} from "../../util/constants";

type TrackersProps = {
  map?: ymaps.Map;
};

export default memo(function Trackers({ map }: TrackersProps) {
  const trackers = useAppSelector((state) => state.trackerReducer.trackers);
  const [activePortal, setActivePortal] = useState<Tracker | null>(null);
  const [clusterOptions, setClusterOptions] = useState({});
  const [clusterTrackers, setClusterTrackers] = useState<Tracker[]>([]);
  const ymaps = useYMaps();
  // console.log('trackers', trackers);
  
  useEffect(() => {
    const balloonLayout = ymaps?.templateLayoutFactory.createClass('<div id=tracker-cluster class="balloon"></div>');
    const clusterOptions = {
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: true,
      hideIconOnBalloonOpen: false,
      gridSize: CLASTER_GRID_SIZE,
      clusterBalloonContentLayout: balloonLayout,
    };
    setClusterOptions(clusterOptions);
  }, [ymaps]);

  const getArrowLayout = (rotate: number) => {
    return ymaps?.templateLayoutFactory.createClass(
      `<div style="width: 32px; height: 96px; transform: rotate(${rotate}deg)">
        <img src='/images/tracker_arrow.svg' alt='' />
      </div>`
    );
  };
  
  const onClickPlacemark = (tracker: Tracker) => {
    setActivePortal(null);
    setTimeout(() => {setActivePortal(tracker)}, 0)
  };

  const onClusterBalloonOpen = (e: ymaps.Event) => {
    const cluster = e.get("cluster");
    if (cluster) {
      const clusterPlacemarks = e.get("cluster").getGeoObjects();
      const ids = clusterPlacemarks.map((item: ymaps.Placemark) => item.properties.get('placemarkId', {}));
      const clusterTrackers = trackers?.filter((item) => ids.includes(item.id));
      setClusterTrackers(clusterTrackers || []);
      const balloon = document.getElementById('tracker-cluster');            
      const balloonParent: HTMLElement | null | undefined = balloon?.closest('ymaps[id]');            
      const balloonLayout: HTMLElement | null | undefined = balloon?.closest('.ymaps-2-1-79-balloon');            
      // const balloonContent: HTMLElement | null | undefined = balloon?.closest('.ymaps-2-1-79-balloon__content');            
      if (balloonParent) {
        balloonParent.style.height = `${24 * (clusterTrackers?.length || 1)}px`
      }
      // if (balloonContent) {
      //   balloonContent.style.maxHeight = `60px`;
      //   balloonContent.style.overflowY = 'auto';
      // }
      if (balloonLayout) {
        balloonLayout.style.top = `${-(24 * (clusterTrackers?.length || 1) + 30)}px`
      }
    }
  };

  return (
    <>
      <Clusterer
        options={clusterOptions}
        onBalloonOpen={onClusterBalloonOpen}
      >
        {trackers?.map((tracker) => {
          return (
            <Placemark
              key={`${tracker.id}-${tracker.coords?.coordinates?.[0]}-${tracker.coords?.coordinates?.[1]}`}
              geometry={tracker.coords?.coordinates || []}
              options={{
                iconLayout: 'default#imageWithContent',
                iconImageHref: tracker.status_connection === StatusConnection.ONLINE ? 
                    `/images/${TypeTracker[tracker.type_tracker]}.png`
                  :
                    `/images/${TypeTracker[tracker.type_tracker]}_blur.png`,
                iconImageSize: TRACKER_ICON_SIZE,
                iconImageOffset: TRACKER_ICON_OFFSET,
                hideIconOnBalloonOpen: false,
                iconContentLayout: tracker.tracker_info.direction ?
                    getArrowLayout(tracker.tracker_info.direction)
                  : undefined,
                iconContentOffset: TRACKER_ICON_CONTENT_OFFSET,
              }}
              properties={{
                type: 'tracker',
                placemarkId: tracker.id,
                balloonContent: `<div id=tracker-${tracker.id} class='balloon'></div>`
              }}
              onClick={() => onClickPlacemark(tracker)}
            />
          );
        })}
      </Clusterer>
      {activePortal && <Portal elementId={`tracker-${activePortal.id}`}>
        <TrackerItem tracker={activePortal} map={map} withTrackLine />
      </Portal>}
      {clusterTrackers.length ?
        <Portal elementId="tracker-cluster">
          <div>
            {clusterTrackers.map((tracker) => {
              return <TrackerItem key={tracker.id} tracker={tracker} map={map} withTrackLine />;
            })}
          </div>
        </Portal>
      : null}
    </>
  );
})

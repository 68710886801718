import React, { Fragment, useEffect, useState } from "react";
import { Clusterer, Placemark, useYMaps } from "@pbe/react-yandex-maps";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Portal } from "../portal/portal";
import CameraItem from "../cameras/cameraItem";
import { Camera } from "../../types/camera.type";
import {
  BUILDING_BALLOON_CAMERA_ITEM_HEIGHT,
  BUILDING_CLUSTER_GRID_SIZE,
  BUILDING_ICON_OFFSET,
  BUILDING_ICON_SIZE,
} from "../../util/constants";
import ymaps from "yandex-maps";
import {
  calculateCenterFromExtremePoints,
  calculateExtremePoints,
} from "../../util/helpers";

type CamerasProps = {
  map?: ymaps.Map;
};

export default function Buildings({ map }: CamerasProps) {
  const cameras = useAppSelector((state) => state.cameraReducer.cameras);

  const [activePortal, setActivePortal] = useState(false);
  const [clusterOptions, setClusterOptions] = useState({});
  const [preparedCameras, setPrepareCameras] = useState<Camera[]>([]);
  const [clusterBuildings, setClusterBuilding] = useState<number[]>([]);

  const ymaps = useYMaps();

  const setToGroup = (cameras: Camera[]) => {
    cameras.forEach((camera) => {
      const buildingId = camera.building_id || 0;

      if (!groupedCameras[buildingId]) {
        groupedCameras[buildingId] = [];
      }

      // Проверка наличия камеры в массиве
      if (!groupedCameras[buildingId].some((c) => c.id === camera.id)) {
        groupedCameras[buildingId].push(camera);
      }
    });
  };

  // Создаем объект для группировки
  const groupedCameras: Record<number, Camera[]> = {};

  cameras?.division_groups.map((division) => {
    return division.buildings.map((build) => {
      return build.aisles.forEach((item) => setToGroup(item.cameras ?? []));
    });
  });

  cameras?.division_groups.map((division) => {
    return division.buildings.map((build) => {
      return build.grazings.forEach((item) => setToGroup([item.camera] ?? []));
    });
  });

  cameras?.division_groups.map((division) => {
    return division.buildings.map((build) => {
      return build.single_cameras.forEach((item) => setToGroup([item] ?? []));
    });
  });

  //
  setToGroup(cameras?.single_cameras ?? []);

  useEffect(() => {
    // const balloonLayout = ymaps?.templateLayoutFactory.createClass('<div id=building-cluster class="balloon"></div>');
    const clusterOptions = {
      preset: "islands#brownClusterIcons",
      groupByCoordinates: false,
      clusterDisableClickZoom: true,
      // clusterOpenBalloonOnClick: true,
      clusterOpenBalloonOnClick: false,
      hideIconOnBalloonOpen: false,
      gridSize: BUILDING_CLUSTER_GRID_SIZE,
      // clusterBalloonContentLayout: balloonLayout,
    };
    setClusterOptions(clusterOptions);
  }, [ymaps]);

  /* Останвить, на случай есл инужно будет показывать balloon по клику */
  const onClickPlacemark = (cameras: Camera[]) => {
    setActivePortal(false);
    setPrepareCameras(cameras);

    setTimeout(() => {
      setActivePortal(true);
    }, 0);
  };

  const onClusterBalloonOpen = (e: ymaps.Event) => {
    const cluster = e.get("cluster");
    if (cluster) {
      const clusterPlacemarks = e.get("cluster").getGeoObjects();

      const buildingsIds = clusterPlacemarks.map((item: ymaps.Placemark) =>
        item.properties.get("placemarkId", {})
      );
      const clusterBuildings = Object.keys(groupedCameras)
        .filter((item) => buildingsIds.includes(item))
        .map((item) => +item);

      setClusterBuilding(clusterBuildings);

      const balloon = document.getElementById("building-cluster");
      const balloonParent: HTMLElement | null | undefined =
        balloon?.closest("ymaps[id]");
      const balloonLayout: HTMLElement | null | undefined = balloon?.closest(
        ".ymaps-2-1-79-balloon"
      );
      // const balloonContent: HTMLElement | null | undefined = balloon?.closest('.ymaps-2-1-79-balloon__content');
      if (balloonParent) {
        balloonParent.style.height = `${
          24 * (clusterBuildings?.length || 1)
        }px`;
      }
      // if (balloonContent) {
      //   balloonContent.style.maxHeight = `60px`;
      //   balloonContent.style.overflowY = 'auto';
      // }
      if (balloonLayout) {
        balloonLayout.style.top = `${-(
          24 * (clusterBuildings?.length || 1) +
          30
        )}px`;
      }
    }
  };

  const calculateBalloonHeight = (itemsCount: number) => {
    return BUILDING_BALLOON_CAMERA_ITEM_HEIGHT * itemsCount;
  };

  /*  `````````````````````    */

  return (
    <>
      <Clusterer
        options={clusterOptions}
        // onBalloonOpen={onClusterBalloonOpen}
      >
        {Object.keys(groupedCameras).map((buildingId, index) => {
          const cameras = groupedCameras[Number(buildingId)];
          const lng = calculateCenterFromExtremePoints(
            calculateExtremePoints(cameras)
          )?.lng;
          const lat = calculateCenterFromExtremePoints(
            calculateExtremePoints(cameras)
          )?.lat;

          return (
            <Fragment key={index}>
              <Placemark
                geometry={[lng, lat]}
                options={{
                  preset: "islands#brownHomeCircleIcon",
                  iconImageSize: BUILDING_ICON_SIZE,
                  iconImageOffset: BUILDING_ICON_OFFSET,
                  hideIconOnBalloonOpen: false,
                  openBalloonOnClick: false,
                }}
                properties={{
                  type: "camera",
                  placemarkId: buildingId,
                  balloonContent: `<div id='balloon-building' class='balloon balloon-building ' style="height: ${calculateBalloonHeight(
                    cameras.length
                  )}px"></div>`,
                }}
                // onClick={() => onClickPlacemark(cameras)}
              />
            </Fragment>
          );
        })}

        {/* Оставить, если нужно будет показывать balloon при клике */}
        {activePortal && (
          <Portal elementId={`balloon-building`}>
            <>
              {preparedCameras.map((camera, index) => {
                return <CameraItem key={camera.id} camera={camera} map={map} />;
              })}
            </>
          </Portal>
        )}
      </Clusterer>

      {/* Оставить, если нужно будет показывать balloon при клике по кластеру */}
      {clusterBuildings.length ? (
        <Portal elementId="building-cluster">
          <div>
            {clusterBuildings.map((buildingId, index) => {
              return <div key={index}>{buildingId}</div>;
            })}
          </div>
        </Portal>
      ) : null}
    </>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/auth.type';

const initialState = {
  users: null as User[] | null,
  user: null as User | null,
};

type InitialStateType = typeof initialState;

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUsers: (
      state: InitialStateType,
      action: PayloadAction<User[] | null>,
    ) => {
      state.users = action.payload;
    },
    setUser: (
      state: InitialStateType,
      action: PayloadAction<User | null>,
    ) => {
      state.user = action.payload;
    },
  },
});

export const { setUsers, setUser } = userSlice.actions;
export default userSlice.reducer;

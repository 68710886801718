import React, { useEffect } from 'react';
import { Button, Form, Input, InputNumber, notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import {
  useCreateInfoMarkerMutation,
  // useDeleteInfoMarkerMutation,
  useUpdateInfoMarkerMutation,
} from '../../redux/api/infoMarkers.api';
import { InfoMarkerFormFields } from '../../types/infoMarker.type';
import { Coordinate } from "../../types/common.type";
import { addInfoMarker, updateInfoMarker, deleteInfoMarker } from "../../redux/slices/infoMarkerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const { TextArea } = Input;

type InfoMarkerFormProps = {
  onClose: () => void;
  coords?: Coordinate | null;
  setNewMarkerCoords: React.Dispatch<React.SetStateAction<Coordinate | null>>;
};

const InfoMarkerForm = ({ onClose, coords, setNewMarkerCoords }: InfoMarkerFormProps) => {
  const dispatch = useAppDispatch();
  const infoMarker = useAppSelector((state) => state.infoMarkerReducer.infoMarker);
  const [createMarker, { isLoading: createLoading } ] = useCreateInfoMarkerMutation();
  // const [deleteMarker, { isLoading: deleteLoading } ] = useDeleteInfoMarkerMutation();
  const [updateMarker, { isLoading: updateLoading } ] = useUpdateInfoMarkerMutation();
  const [form] = Form.useForm();
  const latitudeValue = Form.useWatch('latitude', form);
  const longitudeValue = Form.useWatch('longitude', form);

  useEffect(() => {
    if (infoMarker) {
      form.setFieldsValue({
        name: infoMarker.name,
        latitude: infoMarker.coords?.coordinates[0],
        longitude: infoMarker.coords?.coordinates[1],
        description: infoMarker.description,
      });
    } else {
      form.setFieldsValue({
        name: '',
        latitude: coords ? coords[0] : '',
        longitude: coords ? coords[1] : '',
        description: '',
      });
    }
  }, [infoMarker]);

  useEffect(() => {
    if (coords) {
      form.setFieldValue('latitude', coords[0]);
      form.setFieldValue('longitude', coords[1]);
    }
  }, [coords, form]);

  useEffect(() => {
    if (latitudeValue && longitudeValue) {
      setNewMarkerCoords([latitudeValue, longitudeValue]);
    }
  }, [latitudeValue, longitudeValue]);

  const handleCloseIcon = () => {
    onClose();
  };

  const onFinishFailed = () => {
    console.log('failed');
  };

  const onFinish = (data: InfoMarkerFormFields) => {
    const body = {
      name: data.name,
      coords: {
        type: 'Point',
        coordinates: [
          data.latitude,
          data.longitude,
        ],
      },
      description: data.description,
    };
    if (infoMarker) {
      updateMarker({ body, pk: infoMarker.id })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          dispatch(updateInfoMarker(payload.data));
          onClose();
        }
      })
      .catch((error) => {
        console.log('error', error.message);
        notification.error({ 
          message: 'Ошибка обновления маркера',
          description: error.data?.error || 'Проверьте данные или попробуйте позже',
        });
      });
    } else {      
      createMarker(body)
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          dispatch(addInfoMarker(payload.data));
          onClose();
        }
      })
      .catch((error) => {
        console.log('error', error.message);
        notification.error({ 
          message: 'Ошибка создания маркера',
          description: error.data?.error || 'Проверьте данные или попробуйте позже',
        });
      });
    }
  };

  // const handleDeleteMarker = () => {
  //   deleteMarker(infoMarker?.id)
  //   .unwrap()
  //   .then((payload) => {
  //     if (payload.success) {
  //       dispatch(deleteInfoMarker(payload.data));
  //       onClose();
  //     }
  //   })
  //   .catch((error) => {
  //     console.log('error', error.message);
  //     notification.error({ 
  //       message: 'Ошибка удаления маркера',
  //       description: error.data?.error || 'Проверьте данные или попробуйте позже',
  //     });
  //   });
  // };

  return (
    <div className="min-w-[200px] bg-white border border-gray-300 absolute left-4 bottom-12">
      <div className="bg-gray-400 p-2 text-center text-xl font-semibold relative">
        {infoMarker ? `Маркер ${infoMarker.name}` : 'Новый маркер'}
        <div className="absolute top-0 right-2 cursor-pointer" onClick={handleCloseIcon}>
          <CloseOutlined />
        </div>
      </div>
      <Form
        name="InfoMarkerForm"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 22 }}
        style={{ width: 420, padding: '1rem' }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            { required: true, message: 'Пожалуйста, введите название!' },
          ]}
        >
          <Input disabled={createLoading || updateLoading} />
        </Form.Item>
        <Form.Item
          label="Широта"
          name="latitude"
          rules={[{ required: true, message: 'Пожалуйста, введите широту!' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={90}
            step={0.00000000000001}
            // onChange={onChangeLatitude}
            disabled={createLoading || updateLoading}
          />
        </Form.Item>
        <Form.Item
          label="Долгота"
          name="longitude"
          rules={[{ required: true, message: 'Пожалуйста, введите долготу!' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={360}
            step={0.00000000000001}
            disabled={createLoading || updateLoading}
          />
        </Form.Item>
        <Form.Item
          label="Описание"
          name="description"
          rules={[{ required: true, message: 'Пожалуйста, введите описание!' }]}
        >
          <TextArea rows={2} disabled={createLoading || updateLoading} />
        </Form.Item>
        <div className="flex justify-end">
          {/* {infoMarker && 
            <Button
              className="mr-4"
              disabled={createLoading || updateLoading || deleteLoading}
              onClick={handleDeleteMarker}
            >
              Удалить
            </Button>
          } */}
          <Button htmlType="submit" disabled={createLoading || updateLoading}>
            Сохранить
          </Button>
        </div>
      </Form>
    </div>
  )
};

export default InfoMarkerForm;
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
  ArrowLeftOutlined,
  FileTextOutlined,
  LoadingOutlined,
  ToolFilled,
} from "@ant-design/icons";
import {Button, Checkbox, notification, Table} from "antd";
import type {ColumnsType} from "antd/es/table";

import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useGetFeedingReportMutation} from "../../../redux/api/reports.api";
import {
  BuildingFeedingEventReport,
  DataFeedingEvent,
  DateFlag,
  DivisionFeedingEventReport,
  FarmFeedingEventReport,
  ReportType,
} from "../../../types/report.type";
import {MainRoutes} from "../../../util/routes";
import {FEEDING_TABLE_SCROLL_HEIGHT} from "../../../util/constants";
import {getDatesPlaceholder} from "../../../util/helpers";
import DownloadExcelReportButton from "./downloadExcelReportButton";
import {useGetDivisionsQuery} from "../../../redux/api/divisions.api";
import {Division} from "../../../types/division.type";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useGetBuildingsQuery} from "../../../redux/api/building.api";
import {Building} from "../../../types/building.type";
import {useSaveToolsReportsMutation} from "../../../redux/api/toolsReports.api";
import DeleteToolsButton from "../toolsButtons/deleteToolsButton";
import {
  getBuildingsForFeedingReportIds,
  getDateFlag,
  getDates,
  getDivisionsForFeedingReportIds,
  getIsToolsLoaded,
  setBuildingsForFeedingReportIds,
  setDivisionsForFeedingReportIds,
} from "../../../redux/slices/commonReportsSlice";

interface TableDataType extends DataFeedingEvent {
  children?: TableDataType[];
  division_id?: number;
  action: (record: TableDataType) => React.ReactNode;
}

const FeedingTable = () => {
  const { farm_id } = useParams();
  const dispatch = useAppDispatch();

  const farm = useAppSelector((state) => state.farmReducer.farm);
  const dates = useAppSelector(getDates);
  const date_flag = useAppSelector(getDateFlag);
  const isToolsLoaded = useAppSelector(getIsToolsLoaded);
  const selectedDivisionsIds = useAppSelector(getDivisionsForFeedingReportIds);
  const selectedBuildings = useAppSelector(getBuildingsForFeedingReportIds);

  const [feedingData, setFeedingData] = useState<FarmFeedingEventReport>();
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [isOpenTrackersList, setIsOpenTrackersList] = useState(false);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);

  const [
    getFeeding,
    {
      data: feedingEventsData,
      isLoading: feedingLoading,
      isError: feedingError,
    },
  ] = useGetFeedingReportMutation();

  const { data: divisions } = useGetDivisionsQuery({ farm_id: farm_id, is_feeding: true });
  const { data: buildings } = useGetBuildingsQuery({ farm_id: farm_id });
  const [saveToolsReports, { isLoading: isSaveReportLoading }] =
    useSaveToolsReportsMutation();

  const renderAction = (division_id?: number, building_id?: number) => {
    let feedingReportUrl = `/${MainRoutes.feeding_report}?farm_id=${farm_id}`;
    if (division_id) {
      feedingReportUrl += `&division_id=${division_id}`;
    }
    if (building_id) {
      feedingReportUrl += `&building_id=${building_id}`;
    }
    if (date_flag === DateFlag.DAY_AGO) {
      feedingReportUrl += `&date_flag=${date_flag}`;
    }
    if (date_flag === DateFlag.WEEK_AGO || date_flag === DateFlag.MONTH_AGO) {
      feedingReportUrl += `&date_flag=${
        DateFlag.FROM_TO
      }&dates=${getDatesPlaceholder(date_flag)}`;
    }
    if (date_flag === DateFlag.FROM_TO) {
      feedingReportUrl += `&date_flag=${date_flag}&dates=${
        [dates[0].format('DD.MM.YYYY'), dates[1].format('DD.MM.YYYY')]
      }`;
    }
    return (
      <div
        className="text-blue-500 cursor-pointer flex justify-center items-center w-[50px]"
        onClick={() => window.open(feedingReportUrl)}
      >
        <FileTextOutlined />
      </div>
    );
  };

  const columns: ColumnsType<TableDataType> = [
    {
      title: "Подразделение",
      dataIndex: "name",
      render: (name, record) => (
        <p className={`${record.division_id ? "pl-6" : ""}`}>
          <span
            className={`text-sm ${record.division_id ? "" : "font-medium"}`}
          >
            {name}
          </span>
        </p>
      ),
      width: 150,
    },
    {
      title: "Отсутствие",
      dataIndex: "data",
      align: "center",
      render: (data) => {
        return data.absent_event ? (
          <span className="text-sm text-red-500">
            {Math.round(data.absent_event * 10) / 10}
          </span>
        ) : (
          <span>&mdash;</span>
        );
      },
      width: 90,
    },
    {
      title: "Неполное",
      dataIndex: "data",
      align: "center",
      render: (data) => (
        <span className="text-sm">{Math.round(data.half_event * 10) / 10}</span>
      ),
      width: 90,
    },
    {
      title: "Полное",
      dataIndex: "data",
      align: "center",
      render: (data) => (
        <span className="text-sm">{Math.round(data.full_event * 10) / 10}</span>
      ),
      width: 70,
    },
    {
      title: "Важное",
      dataIndex: "data",
      align: "center",
      render: (data) => (
        <span className="text-sm">
          {Math.round(data.important_event * 10) / 10}
        </span>
      ),
      width: 70,
    },
    {
      dataIndex: "action",
      align: "center",
      width: 50,
    },
  ];

  const onChangeSelectedDivision = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const value = e.target.value;

    dispatch(setDivisionsForFeedingReportIds(
      checked
        ? [...selectedDivisionsIds, value]
        : selectedDivisionsIds.filter(id => id !== value)
    ))

    if (!checked) {
      const currentDivision = feedingEventsData.data.divisions.find((division: DivisionFeedingEventReport) => division.id === value);
      const buildingsIdsFromCurrentDivision = currentDivision.buildings.map((building: BuildingFeedingEventReport) => building.id);

      const updatedBuildingIds = selectedBuildings.filter((buildingId) => !buildingsIdsFromCurrentDivision.includes(buildingId));

      dispatch(setBuildingsForFeedingReportIds(updatedBuildingIds))
    }
  };

  const onChangeSelectedBuildings = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const value = e.target.value;

    dispatch(setBuildingsForFeedingReportIds(
      checked
        ? [...selectedBuildings, value]
        : selectedBuildings.filter(id => id !== value)
    ))
  };

  const onArrowClick = () => {
    getFeedingReport();
    setIsOpenTrackersList(false);
  };

  const saveReports = () => {
    saveToolsReports({
      body: {
        report_feeding: {
          building_ids: selectedBuildings,
          division_ids: selectedDivisionsIds,
        },
        report_type: "report_feeding",
      },
    })
      .unwrap()
      .then((payload) => {
        if (payload.success)
          notification.success({ message: "Настройки успешно сохранены" });
      })
      .catch((error) =>
        notification.error({
          message: error.data.message || "не удалось сохранить настройки",
        })
      );
  };

  const getFeedingReport = useCallback(() => {
    const params: { farm_id: string; body: any } = {
      farm_id: farm_id as string,
      body: {
        date_flag,
        division_ids: selectedDivisionsIds,
        building_ids: selectedBuildings,
      },
    };
    if (dates) {
      params.body.date_from = dates[0].format("YYYY-MM-DD");
      params.body.date_to = dates[1].format("YYYY-MM-DD");
    }
    getFeeding(params)
      .then(() => setIsGlobalLoading(false));
  }, [dates, date_flag, getFeeding, selectedBuildings, selectedDivisionsIds, farm_id]);

  useEffect(() => {
    if (isToolsLoaded) {
      getFeedingReport();
    }
  }, [isToolsLoaded, dates, date_flag]);

  useEffect(() => {
    if (feedingEventsData) {
      setFeedingData(feedingEventsData.data);
      const tableData: TableDataType[] = feedingEventsData.data.divisions.map(
        (division: DivisionFeedingEventReport) => {
          return {
            id: division.id,
            name: division.name,
            data: division.data,
            action: renderAction(division.id),
            children: division.buildings.map((building) => {
              return {
                id: `building-${building.id}`,
                name: building.name,
                data: building.data,
                division_id: building.division_id,
                action: renderAction(building.division_id, building.id),
              };
            }),
          };
        }
      );
      setTableData(tableData);
    }
  }, [feedingEventsData]);

  return (
    <div className="relative h-[410px] w-full bg-white rounded px-2">
      {feedingLoading || isGlobalLoading ? (
        <div className="flex justify-center items-center h-full">
          <LoadingOutlined style={{ fontSize: "30px" }} />
        </div>
      ) : (
        <>
          <div className="w-full px-4 py-2 flex justify-between font-semibold">
            <div className={'text-xl flex items-center'}>
              {isOpenTrackersList && (
                <Button
                  type="text"
                  className="flex justify-center items-center"
                  icon={<ArrowLeftOutlined />}
                  onClick={onArrowClick}
                />
              )}
              <div className="ml-4">
                <p>Кормление</p>
              </div>
            </div>

            <div className="flex gap-2">
             <div>
               <DownloadExcelReportButton
                 reportType={ReportType.REPORT_FEEDING}
               />
             </div>
              <div
                className="cursor-pointer"
                onClick={() => setIsOpenTrackersList(true)}
              >
                <ToolFilled />
              </div>
            </div>
          </div>

          {isOpenTrackersList ? (
            <div>
              <div className="mx-auto pb-2 w-fit">
                <Button
                  size="small"
                  type="link"
                  loading={isSaveReportLoading}
                  onClick={saveReports}
                >
                  сохранить
                </Button>
                <DeleteToolsButton reportType={ReportType.REPORT_FEEDING} />
              </div>
              <details className="z-[2] bg-slate-50 cursor-pointer mt-2">
                <summary>Список подразделений</summary>
                <div className="px-4 pb-4">
                  {divisions.data.map((division: Division) => {
                    return (
                      <div key={division.id} className="h-6 flex items-center">
                        <Checkbox
                          checked={selectedDivisionsIds.includes(division.id)}
                          value={division.id}
                          onChange={onChangeSelectedDivision}
                        >
                          <div className="flex justify-between w-full ml-2 font-semibold">
                            <span>{division.name}</span>
                          </div>
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              </details>
              <details className="z-[2] bg-slate-50 cursor-pointer mt-2">
                <summary>Список зданий</summary>
                <div className="px-4 pb-4">
                  {buildings.data.map((building: Building) => {
                    return (
                      <div key={building.id} className="h-6 flex items-center">
                        <Checkbox
                          disabled={
                            !selectedDivisionsIds.includes(
                              building.division_id as number
                            )
                          }
                          checked={selectedBuildings.includes(building.id)}
                          value={building.id}
                          onChange={onChangeSelectedBuildings}
                        >
                          <div className="flex justify-between w-full ml-2 font-semibold">
                            <span>{building.name}</span>
                          </div>
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              </details>
            </div>
          ) : (
            <div className="h-full">
              {/*{feedingError ? (*/}
              {/*  <div className="flex justify-center items-center w-full h-full">*/}
              {/*    <p className="text-red-700 text-2xl">*/}
              {/*      Ошибка получения данных*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <div className="pb-4 px-2">*/}
              {/*    <p className="font-semibold">*/}
              {/*      Предприятие: <span className="text-lg">{farm?.name}</span>*/}
              {/*    </p>*/}
              {/*    <div className="flex justify-between w-full mt-2 mb-6 pb-2 border-b border-gray-500 font-semibold">*/}
              {/*      <span className="text-red-500">*/}
              {/*        Отсутствие: {feedingData?.data.absent_event}*/}
              {/*      </span>*/}
              {/*      <span>Неполное: {feedingData?.data.half_event}</span>*/}
              {/*      <span>Полное: {feedingData?.data.full_event}</span>*/}
              {/*      <span>Важное: {feedingData?.data.important_event}</span>*/}
              {/*      {renderAction()}*/}
              {/*    </div>*/}
              {/*    <Table*/}
              {/*      size="small"*/}
              {/*      columns={columns}*/}
              {/*      rowKey={(record) => record.id}*/}
              {/*      dataSource={tableData}*/}
              {/*      expandable={{*/}
              {/*        defaultExpandedRowKeys:*/}
              {/*          feedingEventsData?.data.divisions.map(*/}
              {/*            (division: DivisionFeedingEventReport) => division.id*/}
              {/*          ),*/}
              {/*        expandIcon: () => null,*/}
              {/*      }}*/}
              {/*      pagination={false}*/}
              {/*      scroll={{ y: FEEDING_TABLE_SCROLL_HEIGHT }}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="pb-4 px-2">
                <p className="font-semibold">
                  Предприятие: <span className="text-lg">{farm?.name}</span>
                </p>
                <div className="flex justify-between w-full mt-2 mb-6 pb-2 border-b border-gray-500 font-semibold">
                    <span className="text-red-500">
                      Отсутствие: {feedingData?.data.absent_event}
                    </span>
                  <span>Неполное: {feedingData?.data.half_event}</span>
                  <span>Полное: {feedingData?.data.full_event}</span>
                  <span>Важное: {feedingData?.data.important_event}</span>
                  {renderAction()}
                </div>
                <Table
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={tableData}
                  expandable={{
                    defaultExpandedRowKeys:
                      feedingEventsData?.data.divisions.map(
                        (division: DivisionFeedingEventReport) => division.id
                      ),
                    expandIcon: () => null,
                  }}
                  pagination={false}
                  scroll={{ y: FEEDING_TABLE_SCROLL_HEIGHT }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FeedingTable;

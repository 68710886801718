import React from "react";
import { Tooltip } from 'antd';

type InfoIconProps = {
  tooltip?: string;
  iconName: string;
  iconColorClassName: string;
  onClick?: () => void;
};

const InfoIcon = ({ tooltip, iconName, iconColorClassName, onClick }: InfoIconProps) => {
  return (
    <Tooltip
      title={tooltip}
      color="white"
      overlayInnerStyle={{ color: 'black' }}
      placement="topRight"
    >
      <span
        className={`mr-1 icon ${iconName} ${iconColorClassName}`}
        onClick={onClick}
      ></span>
    </Tooltip>
  );
};

export default InfoIcon;
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, DatePicker, Form, Layout, notification, Radio } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { RangePickerProps } from "antd/es/date-picker";
import locale from "antd/es/date-picker/locale/ru_RU";

import TrackersOnline from "../components/statistics/common/trackersOnline";
import AreasTable from "../components/statistics/common/areasTable";
import TrackersVerticalBar from "../components/statistics/common/trackersVerticalBar";
import FeedingTable from "../components/statistics/common/feedingTable";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useLazyGetFarmQuery } from "../redux/api/farm.api";
import { useGetTrackersMutation } from "../redux/api/tracker.api";
import { useLazyGetAreasQuery } from "../redux/api/area.api";
import { useGetReportTrackersOnlineQuery } from "../redux/api/reports.api";
import { setFarm } from "../redux/slices/farmSlice";
import {
  setAllAreas,
  setAllTrackers,
  setAreasDivisionsIds,
  setAreasForWorkedAreaReportIds,
  setDateFlag,
  setDates,
  setDivisionsForMileageReportIds,
  setTrackersDivisionsIds,
  setTrackersForMileageReportIds,
  setTrackersForWorkedAreaReportIds,
  setAllDivisionsIds,
  getTrackersForMileageReportIds,
  getTrackersForWorkedAreaReportIds,
  getAreasForWorkedAreaReportIds,
  getAllTrackers,
  getAllAreas,
  getDates,
  getDateFlag,
  updateReportTools,
  setDefaultReportTools,
  setAllBuildingsIds,
  getAllBuildingsIds,
  getAllDivisionsIds, setIsToolsLoaded,
} from "../redux/slices/commonReportsSlice";
import {
  DateFlag,
  DateFlagNames,
  ReportType,
  VerticalBarTypes,
} from "../types/report.type";
import { Tracker } from "../types/tracker.type";
import { Area } from "../types/area.type";
import { getDatesPlaceholder } from "../util/helpers";
import { useDownloadStatisticReport } from "../hooks/useDownloadStatisticReport";
import { useLazyGetDivisionsQuery } from "../redux/api/divisions.api";
import { Division } from "../types/division.type";
import { useLazyGetToolsReportsQuery } from "../redux/api/toolsReports.api";
import ResetAllToolsButton from "../components/statistics/toolsButtons/resetAllToolsButton";
import { useLazyGetBuildingsQuery } from "../redux/api/building.api";
import { Building } from "../types/building.type";

const { Content, Header } = Layout;
const { RangePicker } = DatePicker;

const CommonStatistics: React.FC = () => {
  const dispatch = useAppDispatch();
  const { farm_id } = useParams();

  const [allTrackersIds, setAllTrackersIds] = useState([]);
  const [divisionsIds, setDivisionsIds] = useState<number[]>([]);
  const [allAreasIds, setAllAreasIds] = useState<number[]>([]);

  const farm = useAppSelector((state) => state.farmReducer.farm);

  const trackersForMileageReportIds = useAppSelector(
    getTrackersForMileageReportIds
  );
  const trackersForWorkedAreaReportIds = useAppSelector(
    getTrackersForWorkedAreaReportIds
  );
  const areasForWorkedAreaReportIds = useAppSelector(
    getAreasForWorkedAreaReportIds
  );
  const allAreas = useAppSelector(getAllAreas);
  const dates = useAppSelector(getDates);
  const date_flag = useAppSelector(getDateFlag);

  const [getToolsReports] = useLazyGetToolsReportsQuery();
  const [getBuildings] = useLazyGetBuildingsQuery();
  const [getFarm] = useLazyGetFarmQuery();
  const [getDivisions] = useLazyGetDivisionsQuery();
  const [getTrackers] = useGetTrackersMutation();
  const [getAreas] = useLazyGetAreasQuery();

  const { data: trackersOnlineData } = useGetReportTrackersOnlineQuery(
    farm_id,
    { pollingInterval: 30000 }
  );

  const {downloadStatisticReport: downloadAllExcelReport, isLoading: isStatisticReportLoading} = useDownloadStatisticReport();

  const [form] = Form.useForm();
  const datesValue = Form.useWatch("dates", form);

  const applyFilters = useCallback(() => {
    const filterValues = form.getFieldsValue();
    dispatch(setDates(filterValues.dates));
    dispatch(setDateFlag(filterValues.date_flag));
  }, [dispatch, form]);

  useEffect(() => {
    if (!farm_id) return;
    getFarm(farm_id)
      .unwrap()
      .then((payload) => {
        dispatch(setFarm(payload.data));
      });
    getDivisions({ farm_id })
      .unwrap()
      .then((payload) => {
        dispatch(
          setAllDivisionsIds(
            payload.data.map((division: Division) => division.id)
          )
        );
      });
    getBuildings({ farm_id })
      .unwrap()
      .then((payload) => {
        const allBUildingsIds = payload?.data?.map((item: Building) => item.id);
        dispatch(setAllBuildingsIds(allBUildingsIds));
      });
  }, [farm_id]);

  useEffect(() => {
    if (!farm_id) return;
    getTrackers({ farm_id })
      .unwrap()
      .then((payload) => {
        setAllTrackersIds(payload.data.map((item: Tracker) => item.id));

        const divisionsIds = payload.data.reduce(
          (acc: number[], item: Tracker) => {
            item?.division_id && acc.push(item.division_id);
            return acc;
          },
          []
        );
        dispatch(setAllTrackers(payload.data));
        dispatch(setTrackersDivisionsIds(divisionsIds));
      });

    getAreas({ farm_id: +farm_id, limit: 0 })
      .unwrap()
      .then((payload) => {
        dispatch(setAllAreas(payload.data.items));

        setAllAreasIds(payload.data.items.map((item: Area) => item.id));
        setDivisionsIds(
          payload.data.items.reduce((acc: number[], item: Area) => {
            item?.division_id && acc.push(item.division_id);
            return acc;
          }, [])
        );
        dispatch(setAreasDivisionsIds(divisionsIds));
        getToolsReports({})
          .unwrap()
          .then((payload) => {
            if (payload.success) {
              dispatch(updateReportTools(payload.data));
            }
          })
          .catch((error) => {
            dispatch(setDefaultReportTools());
          })
          .finally(() => {
            dispatch(setIsToolsLoaded(true));
          })

        return () => {
          dispatch(setTrackersForMileageReportIds([]));
          dispatch(setTrackersForWorkedAreaReportIds([]));
          dispatch(setDivisionsForMileageReportIds([]));
        };
      });
    return () => {
      dispatch(setDates(null));
      dispatch(setDateFlag(DateFlag.TODAY));
      dispatch(setTrackersForMileageReportIds([]));
      dispatch(setTrackersForWorkedAreaReportIds([]));
      dispatch(setAllTrackers([]));
      dispatch(setAllAreas([]));
      dispatch(setAreasForWorkedAreaReportIds([]));
      dispatch(setTrackersDivisionsIds([]));
      dispatch(setIsToolsLoaded(false))
    };
  }, [farm_id]);

  const onChangeRadio = () => {
    form.setFieldValue("dates", null);
    applyFilters();
  };

  const onDateRangeChange = () => {
    form.setFieldValue("date_flag", DateFlag.FROM_TO);
  };

  const getWarningNotification = (duration = 7) => {
    notification.warning({
      message: "Формирование отчетов может занять несколько минут",
      duration,
    });
  }

  const getDurationMessage = useCallback(() => {
    const existNotification = document.querySelector(
      ".ant-notification-notice-warning"
    );
    if (existNotification) return;
    if (
      date_flag === DateFlag.MONTH_AGO ||
      (dates && dates[1]?.diff(dates[0], "day") >= 7)
    ) {
      getWarningNotification()
    }
  }, [dates, date_flag]);

  useEffect(() => {
    getDurationMessage();
  }, [
    getDurationMessage,
    trackersForMileageReportIds,
    trackersForWorkedAreaReportIds,
    areasForWorkedAreaReportIds,
  ]);

  const getDisabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const downloadStatisticReport = () => {
    downloadAllExcelReport(ReportType.ALL);

    getWarningNotification()
  };

  return (
    <Layout>
      <Header className="flex justify-between items-center h-[50px] bg-white text-xl font-semibold">
        <Form
          form={form}
          className="py-0"
          initialValues={{ date_flag: DateFlag.TODAY }}
          layout="inline"
        >
          <Form.Item name="date_flag">
            <Radio.Group onChange={onChangeRadio}>
              <Radio.Button value={DateFlag.TODAY}>
                {DateFlagNames.TODAY}
              </Radio.Button>
              <Radio.Button value={DateFlag.DAY_AGO}>
                {DateFlagNames.DAY_AGO}
              </Radio.Button>
              <Radio.Button value={DateFlag.WEEK_AGO}>
                {DateFlagNames.WEEK_AGO}
              </Radio.Button>
              <Radio.Button value={DateFlag.MONTH_AGO}>
                {DateFlagNames.MONTH_AGO}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="dates">
            <RangePicker
              className={`font-semibold focus:shadow-transparent ${
                datesValue ? "border-blue-500" : ""
              }`}
              changeOnBlur={true}
              placeholder={getDatesPlaceholder(date_flag)}
              locale={locale}
              format="DD.MM.YYYY"
              disabledDate={getDisabledDate}
              value={form.getFieldValue("dates")}
              onChange={onDateRangeChange}
            />
          </Form.Item>
          <Form.Item>
            <Button className="font-semibold" onClick={applyFilters}>
              Применить
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="font-semibold"
              onClick={downloadStatisticReport}
              loading={isStatisticReportLoading}
            >
              Сохранить отчет
            </Button>
          </Form.Item>

          <ResetAllToolsButton className={"ml-3"} />
        </Form>
        <div>{farm?.name}</div>
      </Header>
      <Content className="grid grid-cols-3 auto-rows-max gap-3 p-3 h-[calc(100vh-90px)] bg-gray-200">
        <TrackersVerticalBar title={VerticalBarTypes.mileage} />
        <TrackersOnline data={trackersOnlineData?.data} />
        <AreasTable />
        <TrackersVerticalBar title={VerticalBarTypes.worked_area} />
        <FeedingTable />
      </Content>
    </Layout>
  );
};

export default CommonStatistics;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../types/auth.type';

const initialState = {
  token: null as string | null,
  user: null as User | null,
  // registerData: {} as LoginFields,
  // isLoading: false,
  // error: '',
  // initialize: false,
};

type InitialStateType = typeof initialState;

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (
      state: InitialStateType,
      action: PayloadAction<{ token: string | null; me: User | null }>,
    ) => {
      const { token, me } = action.payload;
      state.user = me;
      state.token = token;
    },
    // setRegisterData: (state, action) => {
    //   state.registerData = action.payload;
    // },
    // setInitialize: (state) => {
    //   state.initialize = true;
    // },
  },
});

export const { setUser } = authSlice.actions;
export default authSlice.reducer;

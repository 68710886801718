import { ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { Slider, ConfigProvider } from 'antd';
import type { SliderSingleProps } from 'antd';
import dayjs from 'dayjs';

import { TimelineSegment, MulticamArchiveRecords, MulticamEvent, MulticamEventType } from '../../types/multicam';
import { EVENT_COLORS, UTC_OFFSET } from '../../util/constants';

type TimelinesProps = {
  archiveRecords: MulticamArchiveRecords[];
  archiveRecordsLoading?: boolean;
  events: MulticamEvent[];
  startTime: number;
  endTime: number;
  currentTime: number;
  onChangeSlider: (value: number) => void;
  disabled?: boolean;
  tooltipFormatter: (value?: number) => string;
  marks: SliderSingleProps['marks'];
  isToday: boolean;
  startOfDate: number;
  renderMark?: (event: MulticamEvent) => ReactNode | ReactNode[];
  eventsMode?: boolean;
};


const Timelines = memo(({
  archiveRecords,
  archiveRecordsLoading,
  events,
  startTime,
  endTime,
  currentTime,
  onChangeSlider,
  disabled,
  tooltipFormatter,
  marks,
  isToday,
  startOfDate,
  renderMark,
  eventsMode = false,
}: TimelinesProps) => {

  const timelineSize = useMemo(() => endTime - startTime, [startTime, endTime]);

  const [emptySegments, setEmptySegments] = useState<TimelineSegment[]>([]);
  const [filledSegments, setFilledSegments] = useState<TimelineSegment[]>([]);

  useEffect(() => {
    const records = archiveRecords.map((item) => item?.records).filter((item) => item?.length) || [];    
    const emptySegments: TimelineSegment[] = [];
    const filledSegments: TimelineSegment[] = [];
    if (records.length) {
      records.forEach((item) => {
        item.forEach((elem, ind) => {
          const start = elem.time_start - startOfDate;
          const width = elem.duration;
          filledSegments.push({ start, width });
          if (ind) {
            const start = item[ind - 1].time_start - startOfDate + item[ind - 1].duration;
            const width = elem.time_start - startOfDate - start;
            emptySegments.push({ start, width });
          } else {
            if (elem.time_start - startOfDate > startTime - startOfDate) {
              emptySegments.push({ start: startTime - startOfDate, width: elem.time_start - startOfDate });
            }
          }
          if (!isToday && ind === item.length - 1 && elem.time_start - startOfDate + elem.duration < endTime) {
            const start = elem.time_start - startOfDate + elem.duration;
            const width = endTime - start;
            emptySegments.push({ start, width });
          }
        });
      });
    } else {
      const start = startTime - startOfDate;
      const width = isToday ? dayjs().utcOffset(UTC_OFFSET).unix() - startTime : timelineSize;
      emptySegments.push({ start, width });
    }    
    setFilledSegments(filledSegments);
    setEmptySegments(emptySegments);
  }, [archiveRecords, eventsMode, isToday, startTime, startOfDate, setFilledSegments, setEmptySegments]);

  const renderEventMark = (event: MulticamEvent) => {
    if (renderMark) return renderMark(event);

    const start = (dayjs(event.event_start).unix() - dayjs(event.event_start).startOf('date').unix()) / timelineSize * 100;
    const width = ((event.event_end ? dayjs(event.event_end) : dayjs(event.event_start).add(1, 'seconds')).unix() - dayjs(event.event_start).unix()) / timelineSize * 100;
    return (
      <div
        key={event.id}
        className={`absolute top-0 min-w-[1px] h-2`}
        style={{
          background: EVENT_COLORS[event.type],
          left: `${start}%`,
          width: `${width}%`,
          zIndex: event.type !== MulticamEventType.FEEDING ? 5 : undefined,
        }}
      ></div>
    );
  };

  const renderSegment = (segment: TimelineSegment, index: number, isEmpty = false) => {
    return (
      <div
        key={index}
        className={`empty_segment absolute top-0 min-w-[1px] h-1`}
        style={{
          background: isEmpty ?'red' : '#059669',
          left: `${(segment.start - (startTime - startOfDate)) / timelineSize * 100}%`,
          width: `${segment.width / timelineSize * 100}%`,
        }}
      ></div>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            trackBg: 'rgba(0, 0, 0, 0)',
            trackHoverBg: 'rgba(0, 0, 0, 0)',
            railBg: 'rgba(0, 0, 0, 0)',
            railHoverBg: 'rgba(0, 0, 0, 0)',
            handleColor: '#f97316',
            handleActiveColor: '#fb923c',
            colorPrimaryBorderHover: '#f97316',
            dotBorderColor: '#2563eb',
            dotActiveBorderColor: '#2563eb',
            dotSize: 9,
          },
        },
      }}
    >
      <div className="player-timelines flex w-full justify-between text-gray-200">
        <div className="mt-2 ml-4 mr-2">{tooltipFormatter(startTime - startOfDate)}</div>
        <div className="w-full relative">
          <Slider
            className="z-10"
            min={startTime - startOfDate}
            max={endTime - startOfDate}
            value={currentTime}
            onChange={onChangeSlider}
            disabled={disabled}
            tooltip={{ formatter: tooltipFormatter }}
            marks={marks}
          />
          <div className={`absolute top-[14px] h-1 mx-[5px] w-[calc(100%-10px)] bg-gray-500 overflow-hidden`}>
            {/* {!archiveRecordsLoading && <div
              className={`absolute h-1 w-full bg-emerald-600`}
              style={{ width: `${((isToday && !eventsMode) ? (dayjs().utcOffset(UTC_OFFSET).unix() - startOfDate) / timelineSize : 1) * 100}%` }}
            ></div>} */}
            {!archiveRecordsLoading && filledSegments.map((segment, index) => renderSegment(segment, index))}
            {!archiveRecordsLoading && emptySegments.map((segment, index) => renderSegment(segment, index, true))}
          </div>
          <div className="absolute top-[20px] w-[calc(100%-10px)] h-2 mx-[5px] bg-black overflow-hidden">
            {events?.map((event) => renderEventMark(event))}
          </div>
        </div>
        <div className="mt-2 mr-4 ml-2">{tooltipFormatter(endTime - startOfDate)}</div>
      </div>
    </ConfigProvider>
  );
});

export default Timelines;
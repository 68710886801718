import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Region } from '../../types/region.type';

const initialState = {
  regions: null as Region[] | null,
  region: null as Region | null,
};

type InitialStateType = typeof initialState;

const regionSlice = createSlice({
  name: 'regionSlice',
  initialState,
  reducers: {
    setRegions: (
      state: InitialStateType,
      action: PayloadAction<Region[] | null>,
    ) => {
      state.regions = action.payload;
    },
    setRegion: (
      state: InitialStateType,
      action: PayloadAction<Region | null>,
    ) => {
      state.region = action.payload;
    },
  },
});

export const { setRegions, setRegion } = regionSlice.actions;
export default regionSlice.reducer;
